import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
  } from "@mui/material";
  import { useTranslation } from "react-i18next";
  
  export const AnnouncementSendAgain = (props: any) => {
    const { t } = useTranslation();
    const handleDialogClose = () => {
      props.setOpenDialog(false);
    };
    return (
      <Dialog
        open={props.openDialog}
        onClose={handleDialogClose}
        sx={{ "textAlign": "center", fontSize: 20 , fontWeight: 350}}>
        <DialogTitle id="alert-dialog-title">
          {t('announcementSendAgainCheck')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose} >{t('abort')}</Button>
          <Button onClick={() => props.sendAgainAnnouncement()}>
              {t('sendAgain')}
            </Button>
        </DialogActions>
      </Dialog>
    );
  };
  