
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader } from "@mui/material";

export const Timestamp = (props: any) => {
    const { timestamp, date, time, card, style } = props;
    const { t } = useTranslation()
    if (!timestamp) return <Typography>-</Typography>;
    const dateV: Date = new Date();
    dateV.setTime(parseInt(timestamp));
    // const weekday = [t('Pazar'), t('Pazartesi'), t('Salı'), t('Çarşamba'), t('Perşembe'), t('Cuma'), t('Cumartesi')];
    const month = [t('Ocak'), t('Şubat'), t('Mart'), t('Nisan'), t('Mayıs'), t('Haziran'), t('Temmuz'), t('Ağustos'), t('Eylül'), t('Ekim'), t('Kasım'), t('Aralık')];
    const formatDate = `${dateV.getDate().toString()}.${(dateV.getMonth() + 1).toString()}.${dateV.getFullYear().toString()}`
    const formatTime = `${dateV.getHours().toString()}:${dateV.getMinutes() < 10 ? '0' : ''}${dateV.getMinutes().toString()}`;
    const monthDay = !!time && !!date ? `${month[dateV.getMonth()]}` : `${month[dateV.getMonth()]} ${dateV.getDate().toString()}`;

    if (card) {
        return (
            <Card sx={{ m: 1, height: 96 }} >
                <CardHeader sx={{ backgroundColor: '#ee0000', height: 20 }} title={monthDay} titleTypographyProps={{ "textAlign": "center", color: 'white', fontSize: 14, fontWeight: 700 }} />
                <CardContent sx={{ height: 40, p: 0, alignContent: 'center' }}>
                    {!!date && <Typography sx={{ "textAlign": "center", fontSize: 32 , fontWeight: 700}}>{dateV.getDate().toString()}</Typography>}
                    {!!time && !!date && <Typography sx={{ "textAlign": "center", fontSize: 10 }} >{formatTime}</Typography>}
                    {!!time && !!!date && <Typography sx={{ "textAlign": "center", fontSize: 24 }}>{formatTime}</Typography>}
                </CardContent>

            </Card>)
    }
    return <Typography style={style}   {...props}>{date ? formatDate : ''}{date && time ? ' ' : ''}{time ? formatTime : ''}</Typography>
}
