import { Box, Fab } from "@mui/material";
import { TableColumn } from "react-data-table-component";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { PaymentType } from "../../api/types";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { DeletePaymentType, PaymentTypeList } from "../../api/paymentType";
import { PortiaTable } from "../../components/PortiaTable";
import { usePortia } from "../../context";
import { RowActions } from "../../components/PortiaTable/row.actions";
import { useTranslation } from 'react-i18next';


export const PaymentTypesList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { dropdownAlert } = usePortia();
  const { mutate: deletePayment, data: dResult, error: dError } = DeletePaymentType()

  const columns: TableColumn<PaymentType>[] = [
    {
      name: t('paymentId'),
      selector: (row) => row.paymentId,
      sortable: false,
    },
    {
      name: t('paymentType'),
      selector: (row) => t(row.type.toString()).toString(),
      sortable: false,
    },
    {
      cell: (row) => (
        <>
          <RowActions
            onEdit={(navigate: NavigateFunction) => {
              navigate(`/paymentTypes/${row.paymentId}`, { state: row });
            }}
            onDelete={() => {
              deletePaymentType(row.paymentId)
            }}

          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "128px",
    },

  ];

  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const { refetch: getPaymentType, data, isLoading, isError } = PaymentTypeList(
    currentPage,
    perPage
  );

  const { data: paymentTypeList }: any = data || {};

  useEffect(() => {
    if (dResult && dResult.status === 200) {
      dropdownAlert.current.alertWithType({ type: 'success', title: "OK", message: t('delete') });
      getPaymentType()
    }
  }, [dResult]);
  useEffect(() => {
    if (dError && dError?.response?.status === 404) {
      dropdownAlert.current.alertWithType({ type: 'error', title: "ERROR", message: dError?.response?.data?.message });
    }
  }, [dError]);
  const deletePaymentType = (id: any) => {
    deletePayment(id);
  };


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
  };

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      {paymentTypeList && (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns}
          data={paymentTypeList}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
        />
      )}
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => navigate(`/paymentTypes/new`, { state: undefined })}
      >
        <AddIcon />
      </Fab>

    </Box>
  );
}
