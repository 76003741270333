import 'chart.js/auto';
import { Bar, Line } from 'react-chartjs-2';
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { TrackCourierInfo, TrackCourierAvgSpeed, TrackCourierMaxSpeed, TrackCourierOdometer } from "../../api/courier";

export const CourierActivityChart = () => {

  const { t } = useTranslation();
  const params = useParams();
  const courierId: any = params && params["courierid"];
  const courierActivity: any[] = [];
  const maxSpeedList: any[] = [];
  const averagelist: any[] = [];
  const odometerList: any[] = [];
  let charge: string = "";
  let moving: string = "";
  let odometer: any = 0;

  const { refetch: getTrack, data: courierInfo } = TrackCourierInfo(courierId);
  const { refetch: getTrackSpeed, data: courierAvgSpeed } = TrackCourierAvgSpeed(courierId);
  const { refetch: getTrackMaxSpeed, data: courierMaxSpeed } = TrackCourierMaxSpeed(courierId);
  const { refetch: getTrackOdometer, data: courierOdometer } = TrackCourierOdometer(courierId);


  if (courierInfo?.data?.data) {
    courierInfo?.data?.data.map((pt: any) =>
      courierActivity.push({ lat: pt.lat, lng: pt.lon, courierId: pt.courier, courierName: pt.courierName, speed: pt.speed, isCharging: pt.is_charging, isMoving: pt.is_moving, heading: pt.heading, ellipsoidalAltitude: pt.ellipsoidal_altitude, altitude: pt.altitude, odometer: pt.odometer, type: pt.type, timestamp: pt.timestamp, level: pt.level }),
    );
  }
  if (courierAvgSpeed?.data?.data) {
    courierAvgSpeed?.data?.data.map((pt: any) =>
      averagelist.push({ speed: pt.speed }),
    );
  }
  if (courierMaxSpeed?.data?.data) {
    courierMaxSpeed?.data?.data.map((pt: any) =>
      maxSpeedList.push({ speed: pt.speed }),
    );
  }
  if (courierOdometer?.data?.data) {
    courierOdometer?.data?.data.map((pt: any) =>
      odometerList.push({ odometer: pt.odometer }),
    );
  }
  console.log(courierOdometer)
  useEffect(() => {
    getTrack();
  }, [courierId]);
  useEffect(() => {
    getTrackSpeed();
  }, [courierId]);
  useEffect(() => {
    getTrackMaxSpeed();
  }, [courierId]);
  useEffect(() => {
    getTrackOdometer();
  }, [courierId]);

  let lastCourierActivity = courierActivity[courierActivity.length - 1];
  const date = new Date(lastCourierActivity?.timestamp);
  const dayOfToday: any = date.getDate();
  const dayOfBeforeSevenDays = new Date(date.getTime() - (6 * 24 * 60 * 60 * 1000)).getDate();
  let dayList: any[] = [];

  for (let a = 0; a <= 7; a++) {
    if (odometerList[a]?.odometer == null) {
      odometerList[a] = 0;
      maxSpeedList[a] = 0;
      averagelist[a] = 0;
    }
    else {
      odometerList[a] = odometerList[a]?.odometer
      maxSpeedList[a] = maxSpeedList[a]?.speed;
      averagelist[a] = averagelist[a]?.speed;
    }
  }
  for (let i = dayOfBeforeSevenDays; i <= dayOfToday; i++) {
    const dateName = new Date(courierMaxSpeed?.data?.data?.[i - dayOfBeforeSevenDays]._time);
    const days = [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")];
    const dayName = days[dateName.getDay()];
    dayList.push(dayName.toString())
  }
  if (lastCourierActivity) {
    if (lastCourierActivity?.is_charging !== "true") {
      charge = t("notCharging")
    }
    else {
      charge = t("onCharging")
    }
    if (lastCourierActivity?.is_charging !== "true") {

      moving = t("onMoving")
    }
    else {
      moving = t("notMoving")
    }
    odometer = (odometerList[7] / 1000).toFixed(2)
  }
  const speedLine = {
    labels: dayList,
    datasets: [{
      label: t("averageSpeed"),
      data: [averagelist[1], averagelist[2], averagelist[3], averagelist[4], averagelist[5], averagelist[6], averagelist[7]],
      fill: false,
      borderColor: "rgba(75,192,192,1)"
    },
    {
      label: t("maxSpeed"),
      data: [maxSpeedList[1], maxSpeedList[2], maxSpeedList[3], maxSpeedList[4], maxSpeedList[5], maxSpeedList[6], maxSpeedList[7]],
      fill: false,
      borderColor: "#742774"
    },
    {
      label: t("nowSpeed"),
      data: [ 0, 0, 0, 0, 0, 0, lastCourierActivity?.speed],
      fill: false,
      borderColor: "#00000",
    },
    ]
  }
  const odometerBar = {
    labels: dayList,
    datasets: [{
      label: t("kilometer"),
      data: [odometerList[1], odometerList[2], odometerList[3], odometerList[4], odometerList[5], odometerList[6], odometerList[7]],
      backgroundColor: [
        'rgba(75, 192, 192, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(255, 99, 132, 0.2)'
      ],
      borderColor: [
        'rgba(75, 192, 192, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(255, 99, 132, 1)'
      ],
      borderWidth: 0.5,
    },
    ]
  }
  return (
    <Box>
      <Grid >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("courier")}</TableCell>
              <TableCell>{t("latitude")}</TableCell>
              <TableCell>{t("longtitude")}</TableCell>
              <TableCell>{t("motionStatus")}</TableCell>
              <TableCell>{t("speed")}</TableCell>
              <TableCell>{t("kilometer")}</TableCell>
              <TableCell>{t("heading")}</TableCell>
              <TableCell>{t("stateCharge")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell>{lastCourierActivity?.courierName}</TableCell>
            <TableCell>{lastCourierActivity?.lat}</TableCell>
            <TableCell>{lastCourierActivity?.lng}</TableCell>
            <TableCell>{moving}</TableCell>
            <TableCell>{lastCourierActivity?.speed} Km/h</TableCell>
            <TableCell>{odometer} Km</TableCell>
            <TableCell>{lastCourierActivity?.heading}</TableCell>
            <TableCell>{charge}</TableCell>
          </TableBody>
        </Table>
        {dayList.length > 1 && <Line data={speedLine} />}
        {dayList.length > 1 && <Bar data={odometerBar} />}

      </Grid></Box>
  )
}

