import { useNavigate, useParams } from "react-router-dom";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { HubBonuses } from "../../api/types";
import { GetHubBonusDetail, PatchHubBonus } from "../../api/hubs";
import { usePortia } from "../../context";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import Authz from "../../components/Authz";

export const HubBonusDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const {
    dropdownAlert,
    store: { setLoading },
  } = usePortia();
  const [hubBonus, setHubBonus] = useState<HubBonuses>();
  const hubBonusId: any = params && params["hubBonusId"];

  const {
    mutate: save,
    data: pResult,
    error: sError,
    isLoading: isPatchLoading,
  } = PatchHubBonus();
  const { refetch: getHubBonus, data: hubBonusData } =
    GetHubBonusDetail(hubBonusId);

  useEffect(() => {
    getHubBonus();
  }, [params, hubBonusId]);

  useEffect(() => {
    setHubBonus(hubBonusData?.data);
  }, [hubBonusData]);

  useEffect(() => {
    setLoading(isPatchLoading);
  }, [isPatchLoading]);

  useEffect(() => {
    if (pResult && (pResult.status === 200 || pResult.status === 201)) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
      setTimeout(() => {
        navigate(`/hubs/${hubBonus?.hubId}`, { state: undefined });
      }, 200);
    }

    if (sError)
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "Error",
        message: t(sError?.response?.data?.message),
      });
  }, [pResult, sError]);

  const saveHubBonus = (c: HubBonuses) => {
    save(c);
  };
  const handlePaste = (event: any) => {
    const pastedText = event.clipboardData.getData("text");
    const pastedValue = parseFloat(pastedText);
    if (pastedValue < 0 || pastedText.includes("e")) {
      event.preventDefault();
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "-" || event.key === "e") {
      event.preventDefault();
    }
  };
  return (
    <Box sx={{ m: 2 }}>
      <FormContainer values={hubBonus} onSuccess={(data) => saveHubBonus(data)}>
        <Grid container spacing={2}>
          <Grid item xs={2} alignItems={"center"}>
            <TextFieldElement
              name={"minPackage"}
              label={t("minPackageRange")}
              type={"number"}
              inputProps={{
                min: 0,
              }}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              validation={{ required: t("required").toString() }}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} alignItems={"center"}>
            <TextFieldElement
              name={"maxPackage"}
              label={t("maxPackageRange")}
              type={"number"}
              inputProps={{
                min: 0,
              }}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} alignItems={"center"}>
            <TextFieldElement
              name={"minPackagePerDaily"}
              label={t("minPackagePerDaily")}
              type={"number"}
              inputProps={{
                min: 0,
              }}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              validation={{ required: t("required").toString() }}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} alignItems={"center"}>
            <TextFieldElement
              name={"amount"}
              label={t("amount")}
              type={"number"}
              inputProps={{
                min: 0,
              }}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              validation={{ required: t("required").toString() }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Authz allowedRoles={["portia-admin", "portia-hub-manager"]}>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                type={"submit"}
                color={"primary"}
              >
                {t("save")}
              </Button>
            </Authz>
            <Button
              variant="outlined"
              color={"secondary"}
              onClick={() => navigate(-1)}
            >
              {t("back")}
            </Button>
          </Grid>
        </Grid>
      </FormContainer>
    </Box>
  );
};
