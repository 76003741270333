import Input from "react-currency-input-field";

export const CurrencyInput = (props: any) => {
  const { value, disabled, onChange } = props;
    
  return (
    <Input
      name="input-name"
      placeholder="0.0"
      allowDecimals={true}
      value={value}
      decimalScale={2}
      decimalsLimit={2}
      decimalSeparator={"."}
      groupSeparator=","
      disableGroupSeparators={true}
      onChange={onChange}
      disabled={disabled} 
      style={{
        fontFamily: "sans-serif",
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "1.4375rem",
        letterSpacing: "0.00938em",
        color: "black",
        boxSizing: "border-box",
        cursor: "text",
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
        position: "relative",
        borderRadius: "4px",
        padding: "16.5px 14px",
        borderColor: "rgb(118 118 118 / 41%)",
      }}
    />
  );
};
