import { useQuery, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '../context';
import { useMutationWithDebounce } from './debounce';
import { FORCE_SHIFTS_END, SHIFTS_CURRENT, SHIFTS_EVENT, SHIFTS_HISTORY, SHIFTS_LIST, SHIFTS_START, SHIFTS_USER } from './paths';



export function Shifts(courierId: string) {
  return useQuery(['shifts'], {
    queryFn: () => !!apiClient && apiClient.get(SHIFTS_USER),
    staleTime: 1000,
    cacheTime: 1500,
    retry: 0,
    enabled: true,
  });
}

export function AllShifts(page: number, limit: number,hubId: any, search: string, shiftStatus: any, courierId: any, startDate: string,
  finishDate: string, sortField: string , sortDirection: string) {
  return useQuery(["list-shifts", page, limit, hubId, shiftStatus,startDate,finishDate], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        SHIFTS_LIST + `/?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}&startDate=${startDate}&finishDate=${finishDate}`,
        { hubId, search, shiftStatus, courierId ,startDate,finishDate},
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}


export function CurrentShift(courierId: string) {
  return useQuery(['current-shift'], {
    queryFn: () => !!apiClient && apiClient.get(SHIFTS_CURRENT),
    staleTime: 0,
    cacheTime: 0,
    retry: 0,
    enabled: true,
  });
}

export function StartShift( debounceTime: number = 500) {
  const queryClient = useQueryClient()
  return useMutationWithDebounce({
    mutationFn: () => apiClient.get(SHIFTS_START),
    onSuccess: (data: any, variables: { id: any; }) => {
      queryClient.invalidateQueries({ queryKey: ['shifts'] })
      queryClient.setQueryData(['current-shift'], data)
    },
    debounceTime,
  });
}

export function SendStatusEvent(debounceTime: number = 500) {
  const queryClient = useQueryClient()
  return useMutationWithDebounce({
    mutationFn: ({ shiftId, event, data }: any) => {
      return apiClient.post(SHIFTS_EVENT, { shiftId, event, data })
    },
    onSuccess: (data: any, variables: { id: any; }) => {
      queryClient.invalidateQueries({ queryKey: ['shifts'] })
      queryClient.resetQueries({ queryKey: ['current-shift'] });
    },
    debounceTime,
  });
}

export function ForceEndShift(debounceTime: number = 500) {
  const queryClient = useQueryClient()

  return useMutationWithDebounce({
    mutationFn: ({ courierId, shiftId }: any) => apiClient.post(FORCE_SHIFTS_END, { courierId, shiftId }),
    onSuccess: (data: any, variables: { id: any; }) => {
      queryClient.invalidateQueries({ queryKey: ['shifts-history'] });
      queryClient.invalidateQueries({ queryKey: ['shifts'] });
      queryClient.resetQueries({ queryKey: ['current-shift'] });
    },
    debounceTime,
  });
}

export function ListShiftHistory(shiftId: string) {
  return useQuery(['shifts-history', shiftId], {
    queryFn: () => !!apiClient && apiClient.get(SHIFTS_HISTORY + `/${shiftId}`),
    staleTime: 1000,
    cacheTime: 1500,
    retry: 0,
    enabled: true,
  });
}

