import Box from "@mui/material/Box";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "@mui/material";
import { Announcement } from "./announcement";
import { AnnouncementsList } from "./announcement-list";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const Announcements = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const tabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Box sx={{}}>
      <Tabs value={tab} onChange={tabChange}>
        <Tab label={t("Announcement")} />
        <Tab label={t("previousAnnouncement")} />
      </Tabs>
      <Box>
        <TabPanel value={tab} index={0}>
          <Box sx={{ m: 2 }}>
            <Announcement />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Box sx={{ m: 2 }}>
            <AnnouncementsList />
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};
