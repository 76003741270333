import { useQuery, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '../context';
import { BRANDS, GET_BRANDS, GET_BRANDS_MAP } from './paths';
import { useMutationWithDebounce } from './debounce';
import { Brand } from './types';


export function Brands() {
  return useQuery(['brands'], {
    queryFn: () => !!apiClient && apiClient.get(GET_BRANDS),
    retry: 0,
    enabled: true,
  });
}
export function GetBrandList(
  page: number,
  limit: number,
  search: string,
  sortField: string,
  sortDirection: string
) {
  return useQuery(["brandspagination"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        BRANDS +
        `/list?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}`
      ),
    retry: 0,
    enabled: true,
  });
}

export function PostBrand(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (brand: Brand) => apiClient.post(BRANDS, brand),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["brands"] });
    },
    debounceTime,
  });
}

export function GetBrand(brandId: string) {
  return useQuery(["getBrand", brandId], {
    queryFn: () => !!apiClient && apiClient.get(BRANDS + `/details/${brandId}`),
    retry: 0,
    enabled: true,
  });
}
export function BrandsMap() {
  return useQuery(['brandsmap'], {
    queryFn: () => !!apiClient && apiClient.get(GET_BRANDS_MAP),
    retry: 0,
    enabled: true,
  });
}
export function UpdateBrand(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (brand: any) =>
      apiClient.patch(BRANDS + `/${brand.id || brand._id}`, brand),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["brands"] });
    },
    debounceTime,
  });
}
export function PatchBrand(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (brand: any) =>
      apiClient.patch(
        GET_BRANDS +'/updateBrandParameters',
        brand
      ),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["updateProvinceParameters"] });
    },
    debounceTime,
  });
}

export function PatchAllBrand(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (brands: any) =>
      apiClient.patch(
        GET_BRANDS +'/changeCanWorkWith',
        brands
      ),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["changeCanWorkWith"] });
    },
    debounceTime,
  });
}
