import { Button, Checkbox, Fab, FormControl, Grid, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PortiaTable } from "../../components/PortiaTable";
import { NavigateFunction, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Hubs } from "../../api/hubs";
import { GetCourierHubs } from "../../api/courier";
import 'dayjs/locale/tr';

import { PostWorkingDaysFilter } from "../../api/workingDay";
import { CourierType, WorkingDay } from "../../api/types";
import { TableColumn } from "react-data-table-component";
import { RowActions } from "../../components/PortiaTable/row.actions";
import { useTranslation } from 'react-i18next';
import { debounce } from "lodash-es";



export const WorkingDaysList = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(new Date()));
    const [finishDate, setFinishDate] = useState<Dayjs | null>(dayjs(new Date()));
    const [hubId, setHub] = useState('');
    const [couriers, setCourier] = useState([] as any);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [search, setSearch] = useState<string>("");
    const [filterData, setFilterData] = useState<any | undefined>(undefined);
    const [sortField, setSortField] = useState<string>("workingDate");
    const [sortDirection, setSortDirection] = useState("desc");
    const hubDetailId: any = params && params["hubid"];

    useEffect(() => {
        if (hubDetailId) {
            setHub(hubDetailId);
            getCourierHubs(hubDetailId);
        }
    }, [hubDetailId])

    const { data } = Hubs();
    const hubs = (data && data?.data?.items) || [];
    const {
        mutate: getCourierHubs,
        data: courierData,

    } = GetCourierHubs();
    const {
        data: workingDays,
        isLoading: isWorkingLoading,
        error: couriersError,
        refetch
    } = PostWorkingDaysFilter(currentPage,
        perPage, filterData, search, sortField,
        sortDirection,);

    const changeHub = (e: any) => {
        const value = e.target.value;
        setHub(value);
        setTimeout(() => {
            getCourierHubs(value);
        }, 100);
    }
    const changeCourier = (event: any) => {
        const value = event.target.value;
        setCourier(value);
    };

    const workingDayFilter = () => {
        const data = {
            startDate: dayjs(startDate).format("YYYY-MM-DD"),
            finishDate: dayjs(finishDate).format("YYYY-MM-DD"),
            hubId: hubId,
            couriers: couriers
        }
        setFilterData(data);
        refresh();
    }
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        refresh();
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setCurrentPage(page);
        setPerPage(newPerPage);
        refresh();
    };
    const handleSearchChange = (searchKey: string) => {
        setSearch(searchKey);
        (searchKey.length === 0 || searchKey.length > 2) && searchRefresh();
    };
    const handleSort = async (column: any, sortDirection: any) => {
        setSortField(column?.sortField === undefined ? "workingDate" : column?.sortField);
        setSortDirection(sortDirection);
        refresh();
    };
    const searchRefresh = debounce(() => {
        refetch();
    }, 500);

    const refresh = debounce(() => {
        refetch();
    }, 50);

    const columns: TableColumn<WorkingDay>[] = [
        {
            name: t('firstName'),
            selector: (row) => row.courierId,
            cell: (row) =>
                row?.couriers?.name,
            sortable: true,
            sortField: 'couriers.name'
        },
        {
            name: t("date"),
            selector: (row) => row.workingDate,
            sortable: true,
            sortField: 'workingDate'
        },
        {
            name: t("startTime"),
            selector: (row) => row.startTime,
            sortable: true,
            sortField: 'startTime'
        },
        {
            name: t("finishTime"),
            selector: (row) => row.finishTime,
            sortable: true,
            sortField: 'finishTime'
        },
        {
            name: t('type'),
            selector: (row) => t(CourierType[row.couriers.type]).toString(),
            sortable: false,
        },
        {
            cell: (row) => (
                <>
                    <RowActions
                        onEdit={(navigate: NavigateFunction) => {
                            navigate(`/workingDays/${row.workingDayId}`, { state: row });
                        }}
                    />
                </>
            ),
            allowOverflow: true,
            button: true,
            width: "128px",
        },

    ];
    return (
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <Grid container spacing={4}>
                <Grid item md={3} xs={6} >
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="test-select-label">{t('hub')}</InputLabel>
                        <Select disabled={hubDetailId ? true : false} onChange={changeHub} name={'hubId'} value={hubId} sx={{ width: '100%' }} labelId="test-select-label" label={t('hub')} >
                            {hubs?.map((option: any) => {
                                return (
                                    <MenuItem key={option?.hubId} value={option?.hubId}>
                                        {option.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>

                    </FormControl>
                </Grid>
                <Grid item md={3} xs={6} >
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="test-select-label">{t('Couriers')}</InputLabel>
                        <Select
                            sx={{ width: '100%' }}
                            labelId="test-select-label"
                            label={t('Couriers')}
                            multiple
                            value={couriers ?? {}}
                            onChange={changeCourier}
                            renderValue={selected => {
                                let names = [] as any;
                                courierData?.data?.data.map((option: any) => selected?.map((courierId: any) => (option.courierId === courierId) && names.push(option.firstName + " " + option.lastName)))
                                return names.toString();
                            }}
                        >
                            {courierData?.data?.data.map((option: any) => (

                                <MenuItem key={option.courierId} value={option.courierId}  >
                                    <ListItemIcon>
                                        <Checkbox checked={couriers.indexOf(option.courierId) > -1} />
                                    </ListItemIcon>
                                    <ListItemText primary={option.firstName + " " + option.lastName} />
                                </MenuItem>
                            ))}
                        </Select>

                    </FormControl>

                </Grid>
                <Grid item md={2} xs={6} >
                    <LocalizationProvider locale={t('dateTimeLocale').toString()} dateAdapter={AdapterDayjs}>
                        <DatePicker
                            renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                            label={t('startDate')}
                            value={startDate}
                            inputFormat="DD-MM-YYYY"
                            onChange={(newValue) => {
                                setStartDate(newValue);
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={2} xs={6} >
                    <LocalizationProvider locale={t('dateTimeLocale').toString()} dateAdapter={AdapterDayjs}>
                        <DatePicker
                            renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                            label={t('finishDate')}
                            value={finishDate}
                            inputFormat="DD-MM-YYYY"
                            onChange={(newValue) => {
                                setFinishDate(newValue);
                            }}

                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={2} xs={12} >
                    <Button variant="contained" color={'primary'} onClick={workingDayFilter} style={{ width: '80%', height: '100%' }}>{t('filter')}</Button>
                </Grid>
                <Grid item md={12}>
                    {workingDays?.data && (
                        <PortiaTable
                            loading={isWorkingLoading && !couriersError}
                            columns={columns}
                            data={workingDays?.data}
                            onChangePage={handlePageChange}
                            onChangePerRows={handlePerRowsChange}
                            serverSideSearch
                            onSearchChange={handleSearchChange}
                            onSort={handleSort}
                        />
                    )}

                </Grid>
            </Grid>
            <Fab
                color="primary"
                aria-label="add"
                onClick={() => {
                    let navRoute = `/workingDays/new`;
                    if (hubDetailId && hubDetailId !== undefined) {
                        navRoute = `/workingDays/new?hubId=${hubDetailId}`;
                    }
                    navigate(navRoute, { state: undefined })
                }}

            >
                <AddIcon />
            </Fab>
        </Box>
    );

}
