import { TableColumn } from "react-data-table-component";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Courier } from "../../api/types";
import { PortiaTable } from "../../components/PortiaTable";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";
import { Hubs } from "../../api/hubs";
import { GetCourierByHubId } from "../../api/announcements";
import { CreateAnnouncement } from "../../api/announcements";
import { AnnouncementSend } from "./announcement.send";
import { usePortia } from "../../context";
import { SHIFT_STATUS } from "../../utils/enums";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useRoles } from "../../hooks/useRoles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as DatePickerMui } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { addDays } from "date-fns";

export const Announcement = () => {
  const { t } = useTranslation();
  const { dropdownAlert } = usePortia();
  const params = useParams();
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(new Date().setHours(23,59,59)));
  const columns: TableColumn<Courier>[] = [
    {
      name: t("name"),
      selector: (row) => row?.name || '',
      sortable: true,
      sortField: "name",
    },
    {
      name: t("mobile"),
      selector: (row) => row?.mobile || '',
      sortable: true,
      sortField: "mobile",
    },
    {
      name: t("shiftStatus"),
      selector: (row) => t(row?.shiftStatusCode).toString(),
      sortable: true,
      sortField: "shiftStatusCode",
    },
    {
      name: t("hub"),
      selector: (row) => row?.hub?.name,
      sortable: true,
      sortField: "hub",
    },
    {
      allowOverflow: true,
      button: true,
      width: "128px",
    },
  ];

  const hubId: any = params && params["hubid"];
  const [shiftStatus, setShiftStatus] = useState<string>("all");
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [selectedHub, setSelectedHub] = useState<string>("all");
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([] as any);
  const [sortField, setSortField] = useState<string>("_id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [action] = useState({ fromUser: false });
  const { data: hubs } = Hubs();
  const hubsData = (hubs && hubs?.data?.items) || [];
  const [sendMessage, setSendMessage] = useState("");
  const [announcementModal, setAnnouncementModal] = useState(false);
  const [selectAllByHub, setselectAllByHub] = useState(false);
  const { mutate: create, data: cResult, error: cError } = CreateAnnouncement();
  const hasPortiaSuperAdmin = useRoles({ allowedRoles: ["portia-super-admin"] });


  const sendAnnouncement = () => {
    let couriers = [] as any;
    selectedRowsPerPage?.forEach((selectedRowsWithIn: any) => {
      selectedRowsWithIn?.forEach((row: any) => {
        couriers.push({courierId: row?.courierId});
      });
    });
    create({
      message: sendMessage,
      hubId: selectedHub,
      selectAllByHub: selectAllByHub,
      couriers: couriers,
      endDate: endDate?.unix()
    });
  };

  useEffect(() => {
    if (cResult && cResult.status === 201) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("announcementSent"),
      });
      setAnnouncementModal(false);
    }
    if (cError && cError?.response?.status === 404) {
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "ERROR",
        message: t("announcementFail"),
      });
      setAnnouncementModal(false);
    }
  }, [cResult, cError]);

  const { data, isLoading, isError, refetch } = GetCourierByHubId(
    currentPage,
    perPage,
    shiftStatus,
    search,
    hubId !== undefined ? hubId : selectedHub,
    sortField,
    sortDirection
  );

  const { data: couriers }: any = data || {};


  const shiftStateOptions = [
    { id: "all", label: t("all") },
    { id: SHIFT_STATUS.ASSIGNED, label: t(SHIFT_STATUS.ASSIGNED) },
    { id: SHIFT_STATUS.AVAILABLE, label: t(SHIFT_STATUS.AVAILABLE) },
    { id: SHIFT_STATUS.OFFLINE, label: t(SHIFT_STATUS.OFFLINE) },
    { id: SHIFT_STATUS.BREAK, label: t(SHIFT_STATUS.BREAK) },
    { id: SHIFT_STATUS.ACCIDENT, label: t(SHIFT_STATUS.ACCIDENT) },
  ];

  const handleChange = useCallback(
    (selectedRows: any) => {
      if (selectedRows?.length !== perPage) {
        if (!action.fromUser) return;
      }
      selectedRowsPerPage[currentPage] = selectedRows;
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );
  const refresh = debounce(() => {
    refetch();
  }, 50);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refresh();
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
    setSelectedRowsPerPage([]);
    refresh();
  };

  const handleMouseEnter = () => {
    action.fromUser = true;
  };
  const handleMouseLeave = () => {
    action.fromUser = false;
  };
  const handleApplySelectedRows = (row: any) =>
    selectedRowsPerPage[currentPage]?.filter(
      (selectedRow: any) => selectedRow._id === row._id
    ).length > 0;

  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(column?.sortField === undefined ? "_id" : column?.sortField);
    setSortDirection(sortDirection);
    refresh();
  };

  const handleShiftStatusChange = useCallback(
    (statusValue: any) => {
      setShiftStatus(statusValue);
      refresh();
    },
    [refresh]
  );

  const handleHubChange = useCallback(
    (statusValue: any) => {
      setSelectedHub(statusValue);
      refresh();
    },
    [refresh]
  );

  const handleSearchChange = (searchKey: string) => {
    setSearch(searchKey);
    (searchKey.length === 0 || searchKey.length > 2) && searchRefresh();
  };

  const searchRefresh = debounce(() => {
    refetch();
  }, 500);

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      <Grid container>
        <Grid item xs={12} md={4} mt={2}>
          <Typography>{t("message")}</Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={5}
            variant="outlined"
            sx={{ width: '100%' }}
            value={sendMessage.substring(0, 240)}
            onChange={(e) => {
              setSendMessage(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} md={2} mt={2} lg={2} marginLeft={3}>
            <Typography>{t("endDate")}</Typography>
            <LocalizationProvider locale={t("dateTimeLocale").toString()} dateAdapter={AdapterDayjs}>
              <DatePickerMui
                renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                value={endDate}
                inputFormat="DD-MM-YYYY"
                onChange={(newValue) => {
                  const newTime = newValue?.set('hour', 23).set('minute', 59).set('second', 59)
                  setEndDate(dayjs(newTime));
                  }}
                minDate={dayjs(addDays(new Date(), -2))}
              />
            </LocalizationProvider>

        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Button
          sx={{ width: 300, mt: 2, height: 45 }}
          variant="contained"
          type={"submit"}
          color={"primary"}
          onClick={() => setAnnouncementModal(!announcementModal)}
        >
          {t("send")}
        </Button>
        <Button
          sx={{ width: 300, mt: 2, height: 45 }}
          variant="outlined"
          color={"secondary"}
          onClick={() => {
            window.location.reload();
          }}
        >
          {t("cancel")}
        </Button>
      </Grid>
      {couriers && (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns}
          data={couriers}
          customFilter={
            <Grid container spacing={3}>
              {!hubId && (
                <Grid item xs={2} mt={-3}>
                  <InputLabel id="select-hub">{t("hubs")}</InputLabel>
                  <Select
                    labelId="select-hub"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    value={selectedHub}
                    onChange={(e) => handleHubChange(e.target.value)}
                    label={t("hubs")}
                  >
                    {
                      hasPortiaSuperAdmin && (
                        <MenuItem key={"all"} value={"all"}>
                          {t("all")}
                        </MenuItem>
                      )
                    }
                    {hubsData?.map((val: any) => (
                      <MenuItem key={val?.hubId} value={val?.hubId}>
                        {val?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              <Grid item xs={2} mt={-3}>
                <InputLabel id="select-status-label">
                  {t("shiftStatus")}
                </InputLabel>
                <Select
                  variant="outlined"
                  labelId="select-status-label"
                  sx={{ width: '100%' }}
                  value={shiftStatus}
                  onChange={(e) => handleShiftStatusChange(e.target.value)}
                  label={t(shiftStatus)}
                >
                  {shiftStateOptions.map((val) => (
                    <MenuItem key={val.id} value={val.id}>
                      {val.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item m={5} xs={5} mt={0.5}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setselectAllByHub(e.target.checked)}
                      />
                    }
                    label={t("announcementHub")}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          }
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          serverSideSearch
          onSearchChange={handleSearchChange}
          onSort={handleSort}
          selectableRows={!selectAllByHub}
          onRowMouseEnter={handleMouseEnter}
          onRowMouseLeave={handleMouseLeave}
          selectableRowSelected={handleApplySelectedRows}
        />
      )}
      <AnnouncementSend
        openDialog={announcementModal}
        setOpenDialog={setAnnouncementModal}
        data={sendMessage}
        courierData={selectedRowsPerPage[1]?.length}
        courierDatabyHub={selectAllByHub}
        sendAnnouncement={sendAnnouncement}
        onListingRefresh={(e: any) => refetch()}
      />
    </Box>
  );
};
