import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { usePortia } from "../../context";

type AuthzProps = {
  allowedRoles: string[];
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
};


type AuthzPropsFunc = {
    allowedRoles: string[];
  };

export const Authz = ({ allowedRoles, children }: AuthzProps): JSX.Element => {
  const {
    store: { session },
  } = usePortia();

  const { roles } = session;
  const isAllowed =
    !allowedRoles ||
    allowedRoles.length === 0 ||
    allowedRoles.find((role) => roles?.includes(role));
  return isAllowed ? <>{children}</> : <></>;
};

export const AuthzOutlet = ({ allowedRoles }: AuthzProps) => {
  const {
    store: { session },
  } = usePortia();

  const { roles } = session;
  const location = useLocation();

  return allowedRoles.find((role) => roles?.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};

export const AuthzCheck = ({ allowedRoles }: AuthzPropsFunc) => {
  const {
    store: { session },
  } = usePortia();

  const { roles } = session;
  return allowedRoles.find((role) => roles?.includes(role)) ? true : false;
};

export default Authz;
