
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PortiaProvider } from './context';
import './translations';
import { Router } from "./routes/Router";

const queryClient = new QueryClient();

function App() {


  return (
    <QueryClientProvider client={queryClient}>
      <PortiaProvider>
        <Router />
      </PortiaProvider>
    </QueryClientProvider>);


}

export default App;