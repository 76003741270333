import { useQueryClient } from '@tanstack/react-query';
import { apiClient } from '../context';
import { useMutationWithDebounce } from './debounce';
import {
  MULTINET_ROLLBACK_WITH_SIGN,
} from './paths';

export function MultinetRollbackWithSign(debounceTime: number = 500) {
    const queryClient = useQueryClient();
    return useMutationWithDebounce({
      mutationFn: (data: any) => {
        return apiClient.post(MULTINET_ROLLBACK_WITH_SIGN, data);
      },
      onSuccess: (data: any, variables: { id: any }) => {
        queryClient.invalidateQueries({ queryKey: ['rollbackWithSign'] });
        queryClient.setQueryData(['multinet-rollbackWithSign'], data);
      },
      debounceTime,
    });}