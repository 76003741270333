import axios from 'axios';

import { createContext, useContext } from 'react';

import { PortiaStore } from './PortiaStore';
import tokenInjector from './axios';
import Keycloak from 'keycloak-js';

if (process.env.NODE_ENV === 'development') {
  console.warn(`DID YOU UPDATE YOUR CURRENT API URL?: ${process.env.REACT_APP_API_URL}`);
}

const _kc  = new Keycloak({
  "url": process.env.REACT_APP_KEYCLOAK_URL,
  "realm": process.env.REACT_APP_KEYCLOAK_REALM as string,
  "clientId": process.env.REACT_APP_KEYCLOAK_CLIENTID as string
})


export const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 10000,
  withCredentials: false,
  headers: { 'Content-Type': 'application/json' },
});

tokenInjector(apiClient, _kc, {
  shouldIntercept: (error: any) => {
    return (
      error.response?.status === 403 ||  error.response.status === 401 ||
      error.response?.data.errorCode === 'EXPIRED_ACCESS_TOKEN'
    );
  },
});

export type PortiaStoreType = {
  user?: any;
  stack?: any;
  error?: any;
  loading?: any;
  session?: any;
  message?: any;
  keycloak?: any;
  mqttClient?: any;
  login: Function;
  logout: Function;
  isUserSessionStarted: Function;
  setUser: Function;
  setMqttClient: Function;
  setKeycloak: Function;
  setMessage: Function;
  setStack: Function;
  setError: Function;
  setLoading: Function;
  setSession: Function;
  reset: Function;
};

export type ContextState = {
  store: PortiaStoreType;
  apiClient: any;
  keycloak: any;
  dropdownAlert: any;
  message: any;
};

export const portiaSecureStore = new PortiaStore();
portiaSecureStore.setKeycloak(_kc);

export const PortiaContext = createContext<ContextState>({
  store: portiaSecureStore,
  apiClient,
  keycloak: _kc,
  dropdownAlert: undefined,
  message: undefined,
});

export function usePortia() {
  return useContext(PortiaContext);
}
