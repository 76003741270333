import { Typography } from '@mui/material';
import Box from '@mui/material/Box';




const Unauthorized = () => {
    return (
        <Box>
            <Typography>Unauthorized</Typography>
        </Box>
    );
}

export default Unauthorized;