const mqtt = require('mqtt')

export class MqttConnection {
    private __client: any = undefined;

    constructor({ user, onConnect }: any) {
        this.connect({user, onConnect})
    }

    end(){
        this.__client && this.__client.end();
        this.__client = undefined;
        return this;
    }
    
    connect({user, onConnect}:any) {
        const conn = this;
        try {
            const { portiaId } = user?.attributes || {} as any;
            if (portiaId) {
                var clientId = `portia_${portiaId}_web_${Math.floor(Math.random() * 100)}`;

                var options = {
                    keepalive: 10,
                    clientId: clientId,
                    clean: true,
                    reconnectPeriod: 5000,
                    connectTimeout: 30 * 1000,
                    username: process.env.REACT_APP_MQTT_USERNAME,
                    password: process.env.REACT_APP_MQTT_PASSWORD,
                    rejectUnauthorized: false
                }
                
                //const mqtturl = `mqtt://${window.location.hostname}:9001/mqtt`;
                const mqtturl = process.env.REACT_APP_MQTT_URL?.replace('LOCATIONHOSTNAME',window.location.hostname);
                this.__client = mqtt.connect(mqtturl, options);
                console.log(`MQTT> connecting to ${mqtturl}`)
                this.__client.on('connect', function () {
                    // console.log(`connected ${mqtturl}. subscribing to: /portia/${portiaId}`)
                    conn.__client.subscribe(`/portia/${portiaId}`, function (err: any) {
                        console.log(`MQTT> subscribed to: /portia/${portiaId}`)
                    })
                    onConnect && onConnect(conn.__client)
                })
            }
        } catch (e) {
        }
        return this;
    }

    onMessage(callback: (payload: any) => any ) {
        if(this.__client){
            this.__client.on('message', function (topic: any, message: any) {
              try{
                const payload = JSON.parse( message.toString()) ;
                console.log("message", payload)
                callback && callback( payload)
              }catch(e){
              }
            })
        }
    }
    client(){
        return this.__client;
    }
} 