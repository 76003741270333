import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const AnnouncementSend = (props: any) => {
  const { t } = useTranslation();
  const handleDialogClose = () => {
    props.setOpenDialog(false);
  };
  return (
    <Dialog
      open={props.openDialog}
      onClose={handleDialogClose}
      sx={{ textAlign: "center", fontSize: 20, fontWeight: 350 }}
    >
      <DialogTitle id="alert-dialog-title">
        {props.data === ""
          ? t("announcementMessageAlert")
          : (((props.courierData ===  undefined) && !props.courierDatabyHub)
            ? t("announcementCourierAlert")
            :((props.courierData ===  0) && !props.courierDatabyHub)
            ? t("announcementCourierAlert")
          : t("announcementSendCheck"))
        }
      </DialogTitle>
      <DialogContent>
        {props.data !== "" && (
          <>
            <span>{t("announcementText")}</span>
            <span>{props.data}</span>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>{t("abort")}</Button>
        {props.data === "" || ((props.courierData ===  0 || props.courierData===undefined) && !props.courierDatabyHub) ? (
          <></>
        ) : (
          <Button onClick={props.sendAnnouncement}>{t("send")}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
