import {
  GetReconciliation,
  PatchReconciliation,
} from "../../api/reconciliation";
import {
  Button,
  Grid,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useCallback, useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { CurrencyInput } from "../../components/CurrencyInput";
import { Payment, Reconciliation } from "../../api/types";
import { usePortia } from "../../context";
import Authz, { AuthzCheck } from "../../components/Authz";
import { ReconciliationStatus } from "../../utils/enums";
import { PortiaTable } from "../../components/PortiaTable";
import { TableColumn } from "react-data-table-component";
import { jsPDF } from "jspdf";
import { amiriFont } from "../../utils/amiriFont";

export const CourierReconciliation = () => {
  const doc = new jsPDF();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const reconciliationId: any = params && params["reconciliationId"];
  const [totalAmount, setTotalAmount] = useState(0);
  const {
    dropdownAlert,
    store: { setLoading },
  } = usePortia();
  const [reconciliationEdit, setReconciliationEdit] = useState<string>();

  const { refetch: getReconciliation, data: reconciliationData } =
    GetReconciliation(reconciliationId);

  const {
    mutate: update,
    data: pResult,
    error: sError,
    isLoading: isPatchLaoding,
  } = PatchReconciliation(reconciliationId);

  useEffect(() => {
    if (reconciliationId) {
      getReconciliation();
    }
  }, [reconciliationId]);

  const ReconciliationEdit = [
    "Aynı gün mutabakat-Paket problemi",
    "Aynı gün mutabakat- Pos problemi ",
    "Aynı gün mutabakat- Sistem problemi",
    "Ertesi gün mutabakat-Saat geçti",
    "Ertesi gün mutabakat- Sistem ",
    "Ertesi gün mutabakat- Unutuldu",
  ];

  const paymentDefault: any = [
    { type: "cash", amount: "00.00" },
    { type: "creditCard", amount: "00.00" },
    { type: "metropolcard", amount: "00.00" },
    { type: "multinet", amount: "00.00" },
    { type: "payecard", amount: "00.00" },
    { type: "setcard", amount: "00.00" },
    { type: "sodexo", amount: "00.00" },
    { type: "ticket", amount: "00.00" },
    { type: "online", amount: "00.00" },
  ];

  const [payment, setPayment] = useState(paymentDefault);

  useEffect(() => {
    setLoading(isPatchLaoding);
  }, [isPatchLaoding, setLoading]);

  useEffect(() => {
    if (pResult && (pResult.status === 200 || pResult.status === 201)) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
    if (sError)
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "Error",
        message: t(sError?.response?.data?.message),
      });
  }, [pResult, sError]);

  useEffect(() => {
    const newPayment: any = [
      { type: "cash", amount: "00.00" },
      { type: "creditCard", amount: "00.00" },
      { type: "metropolcard", amount: "00.00" },
      { type: "multinet", amount: "00.00" },
      { type: "payecard", amount: "00.00" },
      { type: "setcard", amount: "00.00" },
      { type: "sodexo", amount: "00.00" },
      { type: "ticket", amount: "00.00" },
      { type: "online", amount: "00.00" },
    ];
    setTotalAmount(reconciliationData?.data?.totalAmount);
    reconciliationData?.data?.payments.map((item: Payment) => {
      newPayment.map((payment: any) => {
        if (payment.type.toLowerCase() === item.type.toLowerCase()) {
          payment.amount = parseFloat(
            (Number(item.amount) / 100).toString()
          ).toFixed(2)
        }
      });
    });
    setPayment(newPayment);
  }, [reconciliationData]);
  function totalCalcute() {
    let calculatedTotal = 0;
    payment.map((total: any) => {
      calculatedTotal += total?.amount !== "" ? parseFloat(total?.amount) : 0;
    });
    setTotalAmount(parseFloat(calculatedTotal.toFixed(2)));
  }

  useEffect(() => {
    totalCalcute();
  }, [payment]);

  const handleReconciliationEdit = useCallback((reconciliationEdit: string) => {
    setReconciliationEdit(reconciliationEdit);
  }, []);

  const updateReconciliation = () => {
    const data = {
      payments: payment,
      totalAmount: totalAmount,
      updateReason: reconciliationEdit,
    };
    update(data);
  };

  const approveReconciliation = () => {
    const data = {
      reconciliationApproved: true,
    };
    update(data);
  };

  const columns: TableColumn<Reconciliation>[] = [
    {
      name: t("payments"),
      allowOverflow: true,
      maxWidth: "200",
      cell: (row) => (
        <List>
          {row?.payments?.map((item) => (
            <ListItemText>{`${t(item.type)}: ${item.amount}`}</ListItemText>
          ))}
        </List>
      ),
    },
    {
      name: t("totalAmount"),
      selector: (row) => row?.totalAmount,
      sortable: false,
    },
    {
      name: t("updateReason"),
      selector: (row) => row?.updateReason,
      sortable: false,
    },
    {
      name: t("lastActionUser"),
      selector: (row) => row?.actionUser?.username,
      cell: (row) => (
        <InputLabel
          sx={{
            fontWeight: 400,
            color: "#666",
            overflow: "visible",
            wordWrap: "break-word",
            marginTop: 3,
          }}
        >
          {row?.actionUser?.username &&
            t("username") +
            ": " +
            row?.actionUser?.username}
          <br></br>
          {row?.actionUser?.email &&
            t("email") +
            ": " +
            row?.actionUser?.email}
        </InputLabel>
      ),
      sortable: false,
    },
  ];

  const logsData = {
    items: reconciliationData?.data?.logs,
    meta: {
      currentPage: 1,
      itemCount: reconciliationData?.data?.logs?.length,
      itemsPerPage: 1,
      totalItems: reconciliationData?.data?.logs?.length,
      totalPages: 1,
    },
  };

  const dowloandPdf = () => {
    doc.setFontSize(10);
    doc.addFileToVFS("Amiri-Regular.ttf", amiriFont);
    doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
    doc.setFont("Amiri");
    doc.text(t("reconciliationId") + " : " + reconciliationData?.data?.reconciliationId, 20, 20);
    doc.text(t("courierId") + " : " + reconciliationData?.data?.courierId, 20, 25);
    doc.text(t("courierFullName") + " : " + reconciliationData?.data?.courier.name, 20, 30);
    doc.text(t("reconciliation_date") + " : " + reconciliationData?.data?.workDate, 20, 35);
    doc.text(t("reconciliation") + " " + t("state") + " : " + t(reconciliationData?.data?.status).toString(), 20, 40);
    doc.text(t("totalComplateOrders") + " : " + t(reconciliationData?.data?.orderCount).toString(), 20, 45);
    if (reconciliationData?.data?.updateReason) {
      doc.text(t("updateReason") + " : " + t(reconciliationData?.data?.updateReason).toString(), 20, 50);
    }
    if (reconciliationData?.data?.actionUser) {
      doc.text(t("lastActionUser") + " : " + t(reconciliationData?.data?.actionUser?.username).toString() + " " + reconciliationData?.data?.actionUser?.email, 20, 55);
    }
    doc.text(t("paymentMethods") + " : ", 20, 75);

    let count = 80;
    payment.forEach((pymnt: any) => {
      count = count + 5;
      doc.text(t("paymentType") + " (" + t(pymnt?.type) + ")  " + t("amount") + " : " + pymnt.amount + " TL", 20, count);
    });
    doc.text(t("totalAmount") + " : " + Number(parseFloat(totalAmount.toString())).toFixed(2).toString() + " TL", 20, (count + 10));

    doc.save(reconciliationData?.data?.courier?.name + "_" + reconciliationData?.data?.workDate + ".pdf");
  }

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      <Grid container spacing={2} sx={{ "& > :not(style)": { m: 4 } }}>
        <Grid>
          <Grid item xs={12} mt={3}>
            <InputLabel sx={{ fontWeight: 600, color: "#006400" }}>
              {t("reconciliationId")}{" "}
            </InputLabel>
            <TextField
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
              sx={{ width: 300 }}
              name={"reconciliationId"}
              value={reconciliationData?.data?.reconciliationId}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} mt={3}>
            <InputLabel sx={{ fontWeight: 600, color: "#006400" }}>
              {t("courierId")}{" "}
            </InputLabel>
            <TextField
              sx={{ width: 300 }}
              name={"courierId"}
              value={reconciliationData?.data?.courierId}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} mt={3}>
            <InputLabel sx={{ fontWeight: 600, color: "#006400" }}>
              {t("courierFullName")}{" "}
            </InputLabel>
            <TextField
              sx={{ width: 300 }}
              name={"courier.name"}
              value={reconciliationData?.data?.courier.name}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} mt={3}>
            <LocalizationProvider
              locale={t("dateTimeLocale").toString()}
              dateAdapter={AdapterDayjs}
            >
              <InputLabel sx={{ fontWeight: 600, color: "#006400" }}>
                {t("date")}{" "}
              </InputLabel>
              <DatePicker
                renderInput={(props) => (
                  <TextField {...props} sx={{ width: 300 }} />
                )}
                value={reconciliationData?.data?.workDate}
                inputFormat="DD-MM-YYYY"
                onChange={(newValue) => { }}
                disabled={true}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} mt={3}>
          <InputLabel sx={{ fontWeight: 600, color: "#006400" }}>
                {t("totalComplateOrders")}{" "}
              </InputLabel>
                    <CurrencyInput
                      value={reconciliationData?.data?.orderCount}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        !isNaN(Number(event.target.value)) &&
                        setPayment([...payment]);
                      }}
                    />
          </Grid>
        </Grid>
        <Grid>
          <Grid container spacing={2} sx={{ "& > :not(style)": { m: 4 } }}>
            <Grid xs={3}>
              <InputLabel sx={{ fontWeight: 600, color: "#006400" }}>
                {t("paymentMethods")}{" "}
              </InputLabel>
              {payment.map((item: any, index: any) => {
                return (
                  <Grid item xs={12} mt={3} key={index}>
                    <InputLabel>{t(item.type)}</InputLabel>
                    <CurrencyInput
                      value={item.amount}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        item.amount = event.target.value.replace(
                          /[^0-9.]/g,
                          ""
                        );
                        !isNaN(Number(event.target.value)) &&
                        setPayment([...payment]);
                      }}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={12} mt={3}>
                <InputLabel sx={{ fontWeight: 600 }}>
                  {t("totalAmount")}
                </InputLabel>
                <CurrencyInput value={totalAmount} disabled={true} />
              </Grid>
            </Grid>
            <Grid xs={3}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ fontWeight: 600, color: "#006400" }}
              >
                {t("updateReason")}{" "}
              </InputLabel>
              {reconciliationData?.data?.updateReason && (
                <InputLabel
                  sx={{
                    fontWeight: 400,
                    color: "#666",
                    overflow: "visible",
                    wordWrap: "break-word",
                    marginTop: 3,
                  }}
                >
                  {t("lastUpdateReason")}
                  {"; "}
                  <br></br>
                  {reconciliationData?.data?.updateReason}
                </InputLabel>
              )}
              {reconciliationData?.data?.actionUser && (
                <InputLabel
                  sx={{
                    fontWeight: 400,
                    color: "#666",
                    overflow: "visible",
                    wordWrap: "break-word",
                    marginTop: 3,
                  }}
                >
                  {t("lastActionUser")}
                  {"; "}
                  <br></br>
                  {t("username")}
                  {": "}
                  {reconciliationData?.data?.actionUser?.username}
                  <br></br>
                  {reconciliationData?.data?.actionUser?.email &&
                    t("email") +
                    ": " +
                    reconciliationData?.data?.actionUser?.email}
                </InputLabel>
              )}
              <Select
                sx={{ width: 300, mt: 2 }}
                variant="outlined"
                value={reconciliationEdit}
                onChange={(e) => handleReconciliationEdit(e.target.value)}
                label={t("ReconciliationEdit")}
              >
                {ReconciliationEdit.map((val: any, index: any) => (
                  <MenuItem key={index} value={val}>
                    {t(val)}
                  </MenuItem>
                ))}
              </Select>
              <Grid item xs={3}>
                {reconciliationData?.data?.status !=
                  ReconciliationStatus.APPROVED && (
                    <Grid>
                      <Button
                        sx={{
                          width: 300,
                          mt: 2,
                          height: 45,
                          display:
                            reconciliationData?.data?.status ==
                              ReconciliationStatus.HUB_MANAGER_DRAFT &&
                              AuthzCheck({ allowedRoles: ["portia-hub-manager"] })
                              ? "none"
                              : "block",
                        }}
                        variant="contained"
                        type={"submit"}
                        color={"primary"}
                        onClick={() => updateReconciliation()}
                      >
                        {t("update")}
                      </Button>
                      <Button
                        sx={{
                          width: 300,
                          mt: 2,
                          height: 45,
                          display:
                            reconciliationData?.data?.status ==
                              ReconciliationStatus.HUB_MANAGER_DRAFT &&
                              AuthzCheck({ allowedRoles: ["portia-hub-manager"] })
                              ? "none"
                              : "block",
                        }}
                        variant="contained"
                        type={"submit"}
                        color={"warning"}
                        onClick={() => approveReconciliation()}
                      >
                        {t("Approve")}
                      </Button>
                      <Button
                        sx={{ width: 300, mt: 2, height: 45 }}
                        variant="outlined"
                        color={"secondary"}
                        onClick={() => navigate(-1)}
                      >
                        {t("cancel")}
                      </Button>
                    </Grid>
                  )}
                <Authz allowedRoles={["portia-faz-2"]}>
                  <Button
                    sx={{
                      width: 300,
                      mt: 2,
                      height: 45,
                    }}
                    variant="contained"
                    type={"submit"}
                    color={"primary"}
                    onClick={() => dowloandPdf()}
                  >
                    {t("pdfDowloand")}
                  </Button>
                </Authz>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ "& > :not(style)": { m: 4 } }}>
        <Grid xs={12}>
          {logsData?.items?.length > 0 && (
            <PortiaTable loading={false} columns={columns} data={logsData} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
