import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { useMutationWithDebounce } from "./debounce";
import {
  COURIERS,
  GET_COURIER,
  GET_COURIER_AVAILABLE,
  GET_COURIER_DETAILS,
  GET_COURIERS_HUBS,
  GET_COURIERS_HUBS_LIST,
  HUBS,
  GET_COURIER_TRACK,
  GET_HUB_COURIER_TRACK,
  GET_COURIER_TRACK_INFO,
  GET_COURIER_TRACK_AVG_SPEED,
  GET_COURIER_TRACK_MAX_SPEED,
  GET_COURIER_TRACK_ODOMETER,
} from "./paths";
import { Courier as TCourier } from "./types";

export function Courier() {
  return useQuery(["courier"], {
    queryFn: () => !!apiClient && apiClient.get(GET_COURIER),
    staleTime: 100,
    cacheTime: 100,
    retry: 0,
    enabled: true,
  });
}

export function GetCourier(courierId: string) {
  return useQuery(["getcourier", courierId], {
    queryFn: () =>
      !!apiClient && apiClient.get(GET_COURIER_DETAILS + `/${courierId}`),
    staleTime: 100,
    cacheTime: 100,
    retry: 0,
    enabled: false,
  });
}

export function PostCourier(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (courier: TCourier) => apiClient.post(COURIERS, courier),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["couriers"] });
    },
    debounceTime,
  });
}

export function PatchCourier(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (courier: any) =>
      apiClient.patch(COURIERS + `/${courier.id || courier._id}`, courier),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["couriers"] });
    },
    debounceTime,
  });
}

export function Couriers() {
  return useQuery(["couriers"], {
    queryFn: () => !!apiClient && apiClient.get(COURIERS),
    retry: 0,
    onError(err) {
      console.log(err);
    },
  });
}

export function CouriersList(
  page: number,
  limit: number,
  shiftStatus: any,
  courierStatus: any,
  search: string,
  hubId: string,
  sortField: string,
  sortDirection: string
) {
  return useQuery(["courierslist"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        COURIERS +
        `/list?hubId=${hubId}&page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}`,
        { shiftStatus , courierStatus},
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}

export function Hubs() {
  return useQuery(["hubs"], {
    queryFn: () => !!apiClient && apiClient.get(HUBS),
    retry: 0,
    enabled: true,
  });
}

export function AvailableCouriers(restaurantId: string) {
  return useQuery(["couriers", restaurantId], {
    queryFn: () =>
      !!apiClient && apiClient.get(GET_COURIER_AVAILABLE + `/${restaurantId}`),
    retry: 0,
    staleTime: 100,
    cacheTime: 100,
    enabled: false,
    onError(err) {
      console.log(err);
    },
  });
}

export function TrackCourier(courierId: string, startDate: string, endDate: string) {
  return useQuery(["courier-track", courierId], {
    queryFn: () =>
      !!apiClient && apiClient.post(GET_COURIER_TRACK + `/${courierId}`,
        startDate && endDate && { startDate, endDate }),
    retry: 0,
    staleTime: 100,
    cacheTime: 100,
    enabled: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function TrackCourierInfo(courierId: string) {
  return useQuery(["trackinfo", courierId], {
    queryFn: () =>
      !!apiClient && apiClient.post(GET_COURIER_TRACK_INFO + `/${courierId}`),
    retry: 0,
    staleTime: 100,
    cacheTime: 100,
    enabled: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function TrackCourierAvgSpeed(courierId: string) {
  return useQuery(["trackavgspeed", courierId], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(GET_COURIER_TRACK_AVG_SPEED + `/${courierId}`),
    retry: 0,
    staleTime: 100,
    cacheTime: 100,
    enabled: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function TrackCourierMaxSpeed(courierId: string) {
  return useQuery(["trackmaxspeed", courierId], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(GET_COURIER_TRACK_MAX_SPEED + `/${courierId}`),
    retry: 0,
    staleTime: 100,
    cacheTime: 100,
    enabled: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function TrackCourierOdometer(courierId: string) {
  return useQuery(["trackodometer", courierId], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(GET_COURIER_TRACK_ODOMETER + `/${courierId}`),
    retry: 0,
    staleTime: 100,
    cacheTime: 100,
    enabled: false,
    onError(err) {
      console.log(err);
    },
  });
}

export function TrackHubCouriers(hubId: string) {
  return useQuery(["hubtracks", hubId], {
    queryFn: () =>
      !!apiClient && apiClient.post(GET_HUB_COURIER_TRACK + `/${hubId}`),
    retry: 0,
    staleTime: 100,
    cacheTime: 100,
    enabled: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function GetCourierHubsList(
  page: number,
  limit: number,
  hubId: any,
  search: string,
  typeId: any
) {
  return useQuery(["couriers"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        GET_COURIERS_HUBS_LIST +
        `/?page=${page}&limit=${limit}&search=${search}`,
        hubId && typeId && { hubId, typeId }
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function GetCourierHubs(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (hubId: any) => apiClient.get(GET_COURIERS_HUBS + `/${hubId}`),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["hubs"] });
    },
    debounceTime,
  });
}
export function CurrentShift(courierId: string) {
  return useQuery(["current-shift"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.get("/api/shifts/shift-by-courierId/" + courierId),
    staleTime: 0,
    cacheTime: 0,
    retry: 0,
    enabled: true,
  });
}
export function StartShift(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (courierId: string) =>
      apiClient.get("/api/shifts/start-by-courierId/" + courierId),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["shifts"] });
      queryClient.setQueryData(["current-shift"], data);
    },
    debounceTime,
  });
}
export function SendStatusEvent(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: ({ courierId, shiftId, event, data }: any) => {
      return apiClient.post("/api/shifts/event-by-courierId", {
        courierId,
        shiftId,
        event,
        data,
      });
    },
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["shifts"] });
      queryClient.resetQueries({ queryKey: ["current-shift"] });
    },
    debounceTime,
  });
}