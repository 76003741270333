import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { AutocompleteElement, FormContainer } from "react-hook-form-mui";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { CreatePaymentType, GetPaymentType, PatchPaymentType } from "../../api/paymentType";
import { PaymentType } from "../../api/types";
import { usePortia } from "../../context";
import { useTranslation } from 'react-i18next';

export const PaymentTypesDetail = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const { dropdownAlert } = usePortia();
    const [paymentTypeData, setPaymentType] = useState<PaymentType>(location?.state)
    const paymentId: any = params && params['paymentid']
    const { refetch: getPaymentType, data: paymentData } = GetPaymentType(paymentId)
    const { mutate: create, data: cResult, error: cError } = CreatePaymentType()
    const { mutate: update, data: pResult, error: pError } = PatchPaymentType()

    const isNewPos = paymentId == 'new';
    useEffect(() => {
        if (paymentId && paymentId !== 'new') {
            getPaymentType()
        }
    }, [params, paymentId]);

    useEffect(() => {
        setPaymentType(paymentData?.data.data);
    }, [paymentData]);


    useEffect(() => {
        if (cResult && cResult.status === 201 || pResult && pResult.status === 200) {
            dropdownAlert.current.alertWithType({ type: 'success', title: "OK", message: t("saved" )});
            navigate(-1);
        }
    }, [cResult, pResult]);

    useEffect(() => {
        if (cError && cError?.response?.status === 404 || pError && pError?.response?.status === 404) {
            dropdownAlert.current.alertWithType({ type: 'error', title: "ERROR", message: t(pError?.response?.data?.message )|| t(cError?.response?.data?.message) });

        }
    }, [cError, pError]);

    const typeOptions = [{ id: 'cash', label: t('cash') }, { id: 'creditCard', label: t('creditCard') }, { id: 'ticket', label: 'Ticket' }, { id: 'sodexo', label: 'Sodexo'},
    { id: 'paycell', label: 'Paycell' }, { id: 'setcard', label: 'Setcard' }, { id: 'multinet', label: 'Multinet' }, { id: 'metropolcard', label: t('metropolcard') }, { id: 'payecard', label: t('payecard') }, { id: 'gastropay', label: t('gastropay') }]

    const savePaymentType = (payment: PaymentType) => {
        if (isNewPos) {
            create(payment)
        } else {
            update(payment)
        }
    }

    return (
        <Box sx={{ m: 2 }}>
            <FormContainer
                values={paymentTypeData}
                onSuccess={data => savePaymentType(data)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AutocompleteElement label={t('paymentType')} name="type" matchId required
                            options={typeOptions}
                            rules={{ required: t('required').toString() }} />
                    </Grid>
                    <Grid item xs={12} >
                        <Button sx={{ mr: 2 }} variant="contained" type={'submit'} color={'primary'}>{t('save')}</Button>
                        <Button variant="outlined" color={'secondary'} onClick={() => navigate(-1)}>{t('cancel')}</Button>
                    </Grid>
                </Grid>
            </FormContainer >
        </Box >)
}
