import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  AutocompleteElement,
  FormContainer,
  PasswordElement,
  PasswordRepeatElement,
  TextFieldElement,
} from "react-hook-form-mui";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ManageAccounts from "@mui/icons-material/ManageAccounts";
import { User } from "../../api/types";
import { PatchUser, PostUser, GetUser } from "../../api/users";
import {
  NAMEPATTERN,
  USERNAMEPATTERN,
  EMAILPATTERN,
  MOBILEPATTERN,
  TCKNPATTERN,
  PASSWORDPATTERN,
} from "../../components/form/patterns";
import { usePortia } from "../../context";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Hubs } from "../../api/hubs";
import { useRoles } from "../../hooks/useRoles";
import { GetCompanyByTenantId } from "../../api/companies";
import { GetTenant } from "../../api/tenants";

export const UserDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const {
    dropdownAlert,
    store: { setLoading },
  } = usePortia();
  const [user, setUser] = useState<any>(location?.state);
  const userId: any = params && params["userId"];
  const hasPortiaSuperAdmin = useRoles({ allowedRoles: ["portia-super-admin"] });
  const [selectedTenantId, setSelectedTenantId] = useState<string>("null");


  const {
    mutate: create,
    data: cResult,
    error: cError,
    isLoading: isPostLoading,
  } = PostUser();
  const {
    mutate: save,
    data: pResult,
    error: sError,
    isLoading: isPatchLaoding,
  } = PatchUser();
  const {
    refetch: getUser,
    data: userData,
  } = GetUser(userId);

  const { data: dataHub, isLoading: hubIsLoading } = Hubs();
  const hubs = (dataHub && dataHub?.data?.items) || [];

  const isNewUser = !!!location.state && userId == "new";

  useEffect(() => {
    if (userId && userId !== "new") {
      getUser();
    }
  }, [params, userId]);

  useEffect(() => {
    const usrData = {
      ...userData?.data,
      mobile: userData?.data?.mobile.replace(/^(90|0)/, ""),
    };
    setUser(usrData);
    setSelectedUserType(userData?.data?.attributes?.hubId ? 'zone-manager':'admin-manager')
  }, [userData]);

  useEffect(() => {
    setLoading(isPostLoading || isPatchLaoding);
  }, [isPostLoading, isPatchLaoding]);

  const {
    data: companyData,
    refetch: getCompany,
  } = GetCompanyByTenantId(selectedTenantId);

  const { data: tenantData } = GetTenant();

  useEffect(() => {
    if (selectedTenantId) {
      getCompany();
    }
  }, [selectedTenantId]);

  useEffect(() => {
    if (
      (pResult && (pResult.status === 200 || pResult.status === 201)) ||
      (cResult && (cResult.status === 200 || cResult.status === 201))
    ) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }

    if (sError || cError)
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "Error",
        message:
          t(sError?.response?.data?.message) ||
          t(cError?.response?.data?.message),
      });
  }, [pResult, cResult, cError, sError]);

  const userTypeOptions = [{ id: "zone-manager", label: t("zoneManager") }, { id: "admin-manager", label: t("adminManager") }];

  const [selectedUserType, setSelectedUserType] = useState<string>(
    userTypeOptions[0].id
  );

  const saveUser = (c: User) => {
    if (isNewUser) {
      create(c);
    } else {
      save(c);
    }
  };

  return (
    <Box sx={{ m: 2 }}>
      <FormContainer
        values={user}
        onSuccess={(data) => saveUser(data)}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <AutocompleteElement
              label={t("userType")}
              name="userType"
              matchId
              required
              textFieldProps={{
                inputProps: {
                  form: {
                    autocomplete: "off",
                  },
                },
                InputProps: {
                  startAdornment: <ManageAccounts />,
                },
              }}
              autocompleteProps={{
                disabled: !isNewUser,
                onChange: (e, value) => {
                  setSelectedUserType(value.id);
                },
              }}
              options={userTypeOptions || []}
              rules={{ required: t("userTypeRequiredMessage").toString() }}
            />
          </Grid>
          {
            selectedUserType === 'zone-manager' ? (
              <Grid item xs={4}>
                <AutocompleteElement
                  label={t("hub")}
                  name="attributes.hubId"
                  matchId
                  required
                  multiple={true}
                  loading={hubIsLoading}
                  textFieldProps={{
                    inputProps: {
                      form: {
                        autocomplete: "off",
                      },
                    },

                  }}
                  options={hubs.map((h: any) => {
                    return { id: h.hubId, label: h.name };
                  })}
                  rules={{ required: t("hubRequiredMessage").toString() }}
                  autocompleteProps={{
                    onChange: (e, value) => {
                      console.log("value", value);
                    },
                  }}
                />
              </Grid>
            ) : <Grid  item xs={4}></Grid>
          }
          {
            hasPortiaSuperAdmin && isNewUser && (
              <Grid item xs={6}>
                {
                  tenantData?.data && (
                    <AutocompleteElement
                      label={t("tenant")}
                      name="tenantId"
                      matchId
                      required
                      options={tenantData?.data.map((h: any) => {
                        return { id: h.tenantId, label: h.name };
                      })}
                      rules={{
                        required: t("tenantRequiredMessage").toString(),
                      }}
                      autocompleteProps={{
                        onChange: (event, value) => {
                          setSelectedTenantId(value?.id);
                        },
                      }}
                    />
                  )
                }
              </Grid>
            )
          }
          {
            hasPortiaSuperAdmin && isNewUser ? (
              <Grid item xs={6}>
                {
                  companyData?.data && (
                    <AutocompleteElement
                      label={t("company")}
                      name="companyId"
                      matchId
                      required
                      textFieldProps={{
                        inputProps: {
                          form: {
                            autocomplete: "off",
                          },
                        },
                      }}
                      options={companyData?.data?.map((h: any) => {
                        return {
                          id: h.companyId,
                          label: h.name,
                        };
                      })}
                      rules={{ required: t("hubRequiredMessage").toString() }}
                      autocompleteProps={{
                        disabled: !selectedTenantId ? true : false,
                      }}
                    />
                  )
                }
              </Grid>
            ): <Grid item xs={4}></Grid>
          }
          <Grid item xs={6}>
            <TextFieldElement
              sx={{ mr: 2 }}
              validation={{
                required: t("required").toString(),
                pattern: NAMEPATTERN,
              }}
              name={"firstName"}
              label={t("firstName")}
              fullWidth
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldElement
              validation={{
                required: t("required").toString(),
                pattern: NAMEPATTERN,
              }}
              name={"lastName"}
              label={t("lastName")}
              fullWidth
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldElement
              disabled={!isNewUser}
              validation={{
                required: t("required").toString(),
                pattern: USERNAMEPATTERN,
              }}
              name={"username"}
              label={t("username")}
              fullWidth
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldElement
              validation={{
                required: t("required").toString(),
                pattern: EMAILPATTERN,
              }}
              disabled={!isNewUser}
              name={"email"}
              label={t("email")}
              fullWidth
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldElement
              validation={{
                required: t("required").toString(),
                pattern: MOBILEPATTERN,
              }}
              name={"mobile"}
              label={t("mobile")}
              fullWidth
              inputProps={{
                maxLength: 10,
                form: {
                  autocomplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldElement
              validation={{
                required: t("required").toString(),
                pattern: TCKNPATTERN,
              }}
              name={"tckn"}
              label={t("tckn")}
              fullWidth
              inputProps={{
                maxLength: 11,
                form: {
                  autocomplete: "off",
                },
              }}
            />
          </Grid>
          {isNewUser && (
            <Grid item xs={12}>
              <PasswordElement
                validation={{
                  required: t("required").toString(),
                  pattern: PASSWORDPATTERN,
                }}
                name={"password"}
                label={t("password")}
                sx={{ mr: 2 }}
              />
              <PasswordRepeatElement
                validation={{ required: t("required").toString() }}
                name={"password_repeat"}
                label={t("passwordRepeat")}
                passwordFieldName={"password"}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              type={"submit"}
              color={"primary"}
            >
              {t("save")}
            </Button>
            <Button
              variant="outlined"
              color={"secondary"}
              onClick={() => navigate(-1)}
            >
              {t("cancel")}
            </Button>
          </Grid>
        </Grid>
      </FormContainer>
    </Box>
  );
};
