import { debounce, Fab } from "@mui/material";
import { Box } from "@mui/system";
import { TableColumn } from "react-data-table-component";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CourierTypes } from "../../api/types";
import { PortiaTable } from "../../components/PortiaTable";

import { RowActions } from "../../components/PortiaTable/row.actions";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CourierTypesList, DeleteCourierType } from "../../api/courierType";
import i18n from "../../translations";

export const CourierTypeList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [sortField, setSortField] = useState<string>("_id");
  const [sortDirection, setSortDirection] = useState("desc");

  const columns: TableColumn<CourierTypes>[] = [
    {
      name: t("type"),
      selector: (row) => i18n.t(row.courierTypeName).toString(),
      sortable: true,
      sortField: "courierTypeName",
    },
    {
      name: t("amountPerHour"),
      selector: (row) => row.amountPerHour,
      sortable: true,
      sortField: "amountPerHour",
    },
    {
      name: t("amountPerPack"),
      selector: (row) => row.amountPerPack,
      sortable: true,
      sortField: "amountPerPack",
    },
    {
      name: t("fixedAmount"),
      selector: (row) => row.fixedAmount,
      sortable: true,
      sortField: "fixedAmount",
    },
    {
      name: t("contractMinAmount"),
      selector: (row) => row.minAmount,
      sortable: true,
      sortField: "amountPerPack",
    },
    {
      name: t("contractMinPackage"),
      selector: (row) => row.minPackage,
      sortable: true,
      sortField: "minPackage",
    },
    {
      cell: (row) => (
        <>
          <RowActions
            onEdit={(navigate: NavigateFunction) => {
              navigate(`/courierTypes/${row.courierTypeId}`, { state: row });
            }}
            onDelete={() => {
              deleteCourierType(row.courierTypeId);
            }}
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "128px",
    },
  ];

  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, isError, refetch } = CourierTypesList(
    currentPage,
    perPage,
    sortDirection,
    sortField || "all"
  );

  const {
    mutate: deleteCourierTypeMutation,
  } = DeleteCourierType();

  const handleChange = (selectedRows: any) => {
    // console.log("Selected Rows: ", selectedRows);
  };

  const { data: courierTypeData }: any = data || {};

  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(column?.sortField === undefined ? "_id" : column?.sortField);
    setSortDirection(sortDirection);
    refresh();
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
  };

  const refresh = debounce(() => {
    refetch();
  }, 50);

  const deleteCourierType = (courierTypeId: string) => {
    deleteCourierTypeMutation(courierTypeId);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      {courierTypeData && (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns}
          data={courierTypeData}
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          onSort={handleSort}
        />
      )}
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => {
          let navRoute = `/courierTypes/new`;
          navigate(navRoute, { state: undefined });
        }}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
};
