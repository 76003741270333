
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  portiaSecureStore,
  PortiaContext,
  usePortia,
  apiClient
} from './PortiaContext';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import { Toast } from '../components/Toast';




const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type Props = {
  styles?: object;
  children: JSX.Element;
};

const apc: any = apiClient;
apc.crytoGateStore = portiaSecureStore;


const Loader = ({ loading }: any) => {
  const { t } = useTranslation();

  return (
    <Modal open={loading} >
      <Box sx={style}>
        <CircularProgress />
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t('Loading...')}
        </Typography>
      </Box>
    </Modal>);

};


export const PortiaProvider = observer(

  ({ children }: Props): JSX.Element => {

    const [alert, setAlert] = useState<any>()
    const alertWithType = ({ type, title, message }: any) => {
      setAlert({ type, title, message });
      setTimeout(()=>{
        setAlert(undefined)
      }, 6000);
    }

    const {
      store: { stack, isUserSessionStarted, loading, mqttClient, keycloak, setMessage, message },
    } = usePortia();

    useEffect(() => {
      isUserSessionStarted();
    }, [isUserSessionStarted]);

    useEffect(() => {
      mqttClient && mqttClient.onMessage(setMessage)
      return () => {
        mqttClient && mqttClient.end()
      }
    }, [mqttClient, setMessage])

    useEffect(() => {
     alertWithType({ type: 'error', title: "None", message: message?.body });
    }, [message])


    return (
      <PortiaContext.Provider
        value={{ store: portiaSecureStore, dropdownAlert:{current:{alertWithType}}, apiClient, keycloak, message }} >
        {!!stack && children}
        <Toast alert={alert} />
        {!!loading && <Loader loading={loading} />}
      </PortiaContext.Provider>
    );
  },
);
