import { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";

import { usePortia } from "../../context";
import { useTranslation } from "react-i18next";
import { DeleteHub } from "../../api/hubs";
import { useNavigate } from "react-router-dom";

export const HubDelete = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dropdownAlert } = usePortia();
  const { mutate: deleteHub, data: dResult, error: dError } = DeleteHub()

  const handleDialogClose = () => {
    props.setOpenDialog(false); // Use the prop.
  };

  useEffect(() => {
    if (dResult && dResult.status === 200) {
      dropdownAlert.current.alertWithType({ type: 'success', title: "OK", message: t('delete') });
      navigate(0);
    }
    if (dError && dError?.response?.status === 404) {
      dropdownAlert.current.alertWithType({ type: 'error', title: "ERROR", message: t(dError?.response?.data?.message) });
    }
  }, [dResult, dError])

  const handleDeleteHub = () => {
    deleteHub(props?.data?.hubId)
  };

  return (
    <Dialog
      open={props.openDialog}
      onClose={handleDialogClose}
    >
      <DialogTitle id="alert-dialog-title">
        {t('deleteHub')}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleDialogClose} >{t('cancel')}</Button>
        <Button disabled={!props?.data?.hubId} onClick={handleDeleteHub}>
          {t('deleteButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
