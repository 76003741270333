import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { Order } from "../../api/types";
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { OrdersByCourierId } from "../../api/orders";
import { CircularProgress, Fab, Tooltip } from "@mui/material";
import { PortiaTable } from "../../components/PortiaTable";
import { Payment, Refresh } from "@mui/icons-material";
import { debounce } from "lodash-es";
import i18n from "../../translations";
import { DatePicker } from "../../components/DateRangePicker";
import { addDays } from "date-fns";

export const CourierOrders = ({
  courierId,
  orderType,
}: {
  courierId: string;
  orderType?: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [perPage, setPerPage] = useState(50);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [sortField, setSortField] = useState<string>("orderDate");
  const [sortDirection, setSortDirection] = useState("desc");

  const [selectDays, setSelectDays] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const { data, isLoading, isError, refetch } = OrdersByCourierId(
    courierId,
    currentPage,
    perPage,
    orderType || "",
    search,
    startDate,
    finishDate,
    sortField,
    sortDirection
  );

  const { data: orders }: any = data || {};

  const columns: TableColumn<Order>[] = [
    {
      name: t("orderId"),
      selector: (row) => row?.orderId,
      sortable: false,
      wrap: true,
      width: "200px",
      cell: (row) => (
        <Tooltip title={t("orderDetail")} arrow>
          <Button
            sx={{ textAlign: "left" }}
            style={{ fontSize: "12px" }}
            aria-label="detail"
            onClick={() => {
              navigate(`/orders/${row?.orderId}`);
            }}
          >
            {row.orderId}
          </Button>
        </Tooltip>
      ),
    },
    {
      name: t("refOrderId"),
      selector: (row) => row?.refOrderId || "-",
      sortable: true,
      sortField: "refOrderId",
    },
    {
      name: t("date"),
      selector: (row) => row?.orderDate || "-",
      cell: (row) =>
        row?.orderDate &&
        new Date(row?.orderDate).toLocaleString(i18n.language),
      sortable: true,
      sortField: "orderDate",
    },
    {
      name: t("state"),
      selector: (row) => t(row?.status) || "-",
      sortable: true,
      sortField: "status",
    },
    {
      name: t("brand"),
      selector: (row) => row?.brand || "-",
      sortable: true,
      sortField: "brand",
    },
    {
      name: t("channel"),
      selector: (row) => row?.orderSource || "-",
      sortable: true,
      sortField: "orderSource",
    },
    {
      name: t("customer"),
      selector: (row) => row?.firstName + " " + row?.lastName || "-",
      sortable: true,
      sortField: "firstName",
    },
    {
      name: t("total"),
      selector: (row) =>
        parseFloat((Number(row?.total) / 100).toString()).toFixed(2) || "-",
      sortable: true,
      sortField: "total",
    },
    {
      name: t("summary"),
      selector: (row) => row?.orderSummary,
      sortable: false,
      wrap: true,
      width: "300px",
    },
    {
      cell: (row) => (
        <>
          {row?.status === "PICKED" && (
            <Tooltip title={t("payment")} arrow>
              <Fab
                sx={{ width: 32, height: 32, mr: 1 }}
                color="secondary"
                aria-label="edit"
                onClick={() => navigate(`/payment/${row.orderId}`)}
              >
                <Payment />
              </Fab>
            </Tooltip>
          )}
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "128px",
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
  };

  const handleChange = (selectedRows: any) => {
    console.log("Selected Rows: ", selectedRows);
  };

  const handleSearchChange = (searchKey: string) => {
    console.log("searchKey", searchKey);
    setSearch(searchKey);
    (searchKey.length == 0 || searchKey.length > 2) && searchRefresh();
  };

  const searchRefresh = debounce(() => {
    refetch();
  }, 500);
  const refresh = debounce(() => {
    refetch();
  }, 50);
  const handleOnChangeDate = (ranges: any) => {
    const { selection } = ranges;
    setSelectDays([selection]);
  };
  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(
      column?.sortField === undefined ? "orderDate" : column?.sortField
    );
    setSortDirection(sortDirection);
    refresh();
  };
  const orderFilter = () => {
    setStartDate(new Date(selectDays[0]?.startDate).getTime().toString());
    setFinishDate(new Date(selectDays[0]?.endDate).getTime().toString());
    refresh();
  };
  const orderTableRefresh = () => {
    setStartDate("");
    setFinishDate("");
    refresh();
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={8} lg={8}>
          <DatePicker
            selectDays={selectDays}
            handleOnChange={handleOnChangeDate}
          />
        </Grid>
        <Grid mt={2} item xs={6} md={6} lg={1}>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            style={{ float: "right" }}
            onClick={() => orderFilter()}
          >
            {t("filter")}
          </Button>
        </Grid>
        <Grid mt={2} item xs={6} md={6} lg={1}>
          <Fab
            size="small"
            color="primary"
            aria-label="refresh"
            style={{ float: "right" }}
            onClick={() => orderTableRefresh()}
          >
            <Refresh />
          </Fab>
        </Grid>
      </Grid>
      {!isLoading ? (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns}
          data={orders}
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          serverSideSearch
          onSearchChange={handleSearchChange}
          onSort={handleSort}
        />
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
