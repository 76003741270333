import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CurrentShift } from "../../api/courier";
import { ForceEndShift } from "../../api/shifts";
import { usePortia } from "../../context";


export const CourierShiftEnd = (props: any) => {
  const { t } = useTranslation();
  const { dropdownAlert } = usePortia();
  const [shiftId, setShiftId] = useState();
  const { mutate: forceEndShift, data: _endShiftData } = ForceEndShift();
  const {
    data: shiftData,
    refetch: getCurrentShift,
  } = CurrentShift(props?.data?.courierId);

  useEffect(() => {
    getCurrentShift()
  }, [props?.data?.courierId])

  useEffect(() => {
    if (shiftData) {
      setShiftId(shiftData?.data?.id);
    }
  }, [shiftData])

  useEffect(() => {
    if (_endShiftData?.data?.errorMessage !== null) {
      dropdownAlert.current
        .alertWithType({
          type: 'error',
          title: "Vardiya Değişim",
          message: "Üzerinde sipariş olan bir kuryenin  mesai sonlandırılamaz."
        });
    }

  }, [_endShiftData])


  const handleDialogClose = () => {
    props.setOpenDialog(false); // Use the prop.
  };

  const handleShiftEnd = () => {
    forceEndShift({ courierId: props?.data?.courierId, shiftId: shiftData?.data?.id });
  };



  return (
    <Dialog
      open={props.openDialog}
      onClose={handleDialogClose}
    >
      <DialogTitle id="alert-dialog-title">
        {t('endCourierShift')}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleDialogClose} >{t('abort')}</Button>
        <Button disabled={!shiftData?.data} onClick={function () {
          handleShiftEnd();
          handleDialogClose();
        }}>
          {t('end')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
