import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tab, Tabs, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Restaurant } from "../../api/types";
import { CircularProgress, Button, Grid } from '@mui/material';
import { PortiaTable } from "../../components/PortiaTable";
import { DistrictByProvince, PatchDistrict, PatchAllDistrict, PatchProvince, Provinces } from "../../api/provinces";
import { debounce, isEmpty } from "lodash-es";
import { HubsByDistrict, HubsByProvince, HubsWithRestaurant, OrderCountByHub, PatchHubParameters, PatchAllHub} from "../../api/hubs";
import { Brands, PatchAllBrand, PatchBrand } from "../../api/brands";
import { GetRestaurantByHubId , MergeSettings, PatchAllRestaurant,} from "../../api/restaurant";
import { usePortia } from "../../context";
import  CheckBox  from "@mui/material/Checkbox";


enum MergeStageNames{
  first = "firstStageDistance",
  second = "secondStageDistance",
  third = "thirdStageDistance",
  fourth = "fourthStageDistance",
  fifth = "fifthStageDistance"
}

type MergeDistanceProps = {
  distance? : number,
  label: string,
  t: any,
  id: string,
  onChange : (orderMergeDistance: string, restaurantId:string, refRestaurantId:string, label:string) => void
}

type MergeLimitProps = {
  limit?: string,
  label: string,
  id: string,
  updatedRestaurants: {
    [id: string]: {
      id: string,
      refRestaurantId?:string,
      carryLimit?: string;
      orderMergeDistance?: number;
    };
  },
  onChange : (selectedLimit: SelectChangeEvent,refRestaurantId:string) => void
}

type MergeParametersType = {id:string, source:number, patchFunction : (parameter:any) => void}

type DropdownProps = {
  inComingPlaceholder: string,
  menuItem: any[],
  parameters?: Parameters,
  selectedItem: string,
  ddValue: {
    provinceDdValue: string,
    districtDdValue: string,
    hubDdValue: string,
    brandDdValue: string,
    restaurantDdValue: string
  },
  source : number,
  handleChange: (value: SelectChangeEvent) => void
}

type LiveInfoProps = {
  infoLabel: string,
  data: string,
  checkRateGtLimit?: boolean
}

type MergeRuleProps = {
  brand: any,
  labelBrand?: any,
  minRule?: boolean,
  checked?: boolean,
  canWorkWith?: string[],
  brands? : any[],
  mergeRules: {
    [id: string]: {
      brandId: string,
      canWorkWith?: string[],
      minRule?: number,
      isChanged : boolean
    };
  },
  onCheckBoxChange : (value:any, brand: any) => void
  onMinRuleChange : (value:any, brand: any) => void

}


type Parameters = {
  carryLimit?: string ,
  firstStageDistance?: number,
  secondStageDistance?: number,
  thirdStageDistance?: number,
  fourthStageDistance?: number,
  fifthStageDistance?: number,
  mergeBrandRules? : any[]
}

type FormStateType = {
  province: {
    provinces: {
      id: string,
      name: string,
      carryLimit?: string,
      firstStageDistance?: number,
      secondStageDistance?: number,
      thirdStageDistance?: number,
      fourthStageDistance?: number,
      fifthStageDistance?: number,
      mergeBrandRules? : any[]
      
    }[] | {}[],
    selectedProvince: string,
    selectedProvinceParameter?: Parameters
  },
  district: {
    districts: {
      id: string,
      name: string,
      carryLimit?: string,
      firstStageDistance?: number,
      secondStageDistance?: number,
      thirdStageDistance?: number,
      fourthStageDistance?: number,
      fifthStageDistance?: number,
      mergeBrandRules? : any[]
    }[] | {}[],
    selectedDistrict: string,
    selectedDistrictParameter?: Parameters
  },
  selectedHub: string,
  selectedHubParameter?: Parameters
  selectedBrands: string,
  selectedBrandParameter?: {carryLimit?: string , orderMergeDistance?: number}
  selectedRestaurant: string,
  selectedRestaurantParameter?: Parameters
  activeCourierCount: number,
  activeOrderCount: number,
  numberOfOrderByCourier: number,
  numberOfAssignedOrdersToCourier: number,
  unAssignedOrderCount: number,
  checkRateGtLimit?: boolean,
  parameters: Parameters
}

type UpdatedParametersType = {
  [id: string]: {
    id: string,
    source?: number, // 1-Province 2-District 3-Hub 4-Brand 5-Restaurant
    hubId? : string,
    refRestaurantId?:string,
    carryLimit?: string;
    firstStageDistance?: number,
    secondStageDistance?: number,
    thirdStageDistance?: number,
    fourthStageDistance?: number,
    fifthStageDistance?: number
    mergeBrandRules?: any[]
  };
}
const carryLimitMenuItem = ["1","2","3","4"];

const fillMergeBrandRules = (brands: any[]) => {
  const arr : any[] = []
  brands.forEach((item)=> {
    if (item.name === "Pizza Hut"){
      let obj = {brandId: "", canWorkWith:[], name: "", minRule : ""}
      if ("brandId" in item){
        obj.brandId = item.brandId
        obj.name = item.name
        obj.minRule = item.minRule
      }
     
      arr.push(obj);
    }
    else {
      let obj = {brandId: "", canWorkWith:[], name: ""}

      if ("brandId" in item){
        obj.brandId = item.brandId
        obj.name = item.name
      }
      arr.push(obj);  
    }
  
  })

  return arr;
}


const normalizeArray = (anArray: any[], brandRules: any[]) => {
  const newValue = anArray?.map((item) => {
    let obj = {
      id: "",
      name: "",
      brandId: "",
      carryLimit : "1",
      firstStageDistance : 0,
      secondStageDistance : 0,
      thirdStageDistance : 0,
      fourthStageDistance : 0,
      fifthStageDistance : 0,
      mergeBrandRules: brandRules
    }

    if ("provinceId" in item && !("districtId" in item)) {
      obj.id = item.provinceId
      obj.name = item.name
    } else if ("districtId" in item && ("provinceId" in item) && !("hubId" in item)) {
      obj.id = item.districtId
      obj.name = item.name
    } else if ("districtId" in item && ("provinceId" in item) && "hubId" in item) {
      obj.id = item.hubId
      obj.name = item.name
    } else if ("brandId" in item && !("refRestaurantId" in item)) {
      obj.id = item.brandId;
      obj.name = item.name;
      obj.brandId = item.brandId;
    }

    if ("refRestaurantId" in item) {
      obj.id = item.restaurantId;
      obj.name = item.name;
      obj.brandId = item.brandId;
    }
    if ("carryLimit" in item) {
      obj.carryLimit = item.carryLimit
    }

    if (MergeStageNames.first in item) {
      obj.firstStageDistance = item.firstStageDistance
    }
    if (MergeStageNames.second in item) {
      obj.secondStageDistance = item.secondStageDistance
    }
    if (MergeStageNames.third in item) {
      obj.thirdStageDistance = item.thirdStageDistance
    }
    if (MergeStageNames.fourth in item) {
      obj.fourthStageDistance = item.fourthStageDistance
    }
    if (MergeStageNames.fifth in item) {
      obj.fifthStageDistance = item.fifthStageDistance
    }
    if ("mergeBrandRules" in item) {
      obj.mergeBrandRules = item.mergeBrandRules
    }else if ("canWorkWith" in item && "minRule"){
      let _obj = [{brandId: item.brandId,
                  canWorkWith: item.canWorkWith,
                  name: item.name,
                  minRule: item.minRule}] 
      obj.mergeBrandRules = _obj
    }
    else if ("canWorkWith" in item){
      let _obj = [{brandId: item.brandId,
                  canWorkWith: item.canWorkWith,
                  name: item.name}] 
      obj.mergeBrandRules = _obj
    }
    return obj
  });

  return [...newValue]
}


const Dropdown = (props: DropdownProps) => {
  const { inComingPlaceholder, menuItem, selectedItem, parameters, ddValue, handleChange, source } = props;
  let dropdown = "" ;
  switch (source) {
    case 1:
      dropdown = ddValue.provinceDdValue
      break;
    case 2:
      dropdown = ddValue.districtDdValue
    break;
    case 3:
      dropdown = ddValue.hubDdValue
    break;
    case 4:
      dropdown = ddValue.brandDdValue
    break;
    case 5:
      dropdown = ddValue.restaurantDdValue
    break;
    default:
      break;
  }
  return (
    <FormControl
      sx={{
        marginRight: 4,
        marginBottom: 2
      }}
    >
      <InputLabel>{inComingPlaceholder}</InputLabel>
      <Select
        autoWidth
        sx={{
          marginRight: 8, width: "100%",
          boxShadow: 1
        }}
        label={inComingPlaceholder}
        onChange={handleChange}
        value={!isEmpty(selectedItem) ? dropdown: ""}

      >
        {menuItem.map((item: any, index) =>
          <MenuItem value={`${item.id};${item?.carryLimit};${item?.firstStageDistance};${item?.secondStageDistance};${item?.thirdStageDistance};${item?.fourthStageDistance};${item?.fifthStageDistance};${JSON.stringify(item?.mergeBrandRules)}`} key={`${item.id};;${index}`} >
            {item.name}
          </MenuItem>
        )}
        
      </Select>
    </FormControl>

  )
}


const LiveInfo = (props: LiveInfoProps) => {
  const { infoLabel, data , checkRateGtLimit } = props
  return (
    <Box sx={{
      flexDirection: "row",
      marginRight: 3,
      border: "0.5px solid",
      borderColor: "rgba(11,11,11, 0.25)",
      padding: "12px",
      borderRadius: "8px",
      boxShadow: '1.5px 1.5px 4px rgba(11,11,11, 0.25)',
      backgroundColor: checkRateGtLimit === undefined ? "white" : checkRateGtLimit ? "lightgreen" : "red"
    }}>
      <span>{infoLabel}: </span>
      <span>{data} </span>
    </Box>
  )
}

const MergeDistanceInfo = (props : MergeDistanceProps) => {
  const {distance,label,t,id,onChange} = props;
  return (
    <Grid sx={{marginRight:5}}>
      <InputLabel  sx={{ fontWeight: 600, color: "black" , marginBottom:1}}>{t(label)}</InputLabel>
      <TextField
        id="distance"
        variant="outlined"
        type="number"
        aria-label={"mesafe"}
        value={distance}
        onChange={(e: any) => {
          e = e.target.value < 0 ? (e.target.value = 0) : e.target.value
            onChange(e,id,'',label)
        }
         
        }
        InputProps={{
          inputProps: { min: 0 },
        }}
        />
    </Grid>
    
  )
}

const MergeLimitInfo = (props : MergeLimitProps) => {
  const {limit,label,id,updatedRestaurants,onChange} = props
  return (
  
  <Grid>
    <InputLabel sx={{ fontWeight: 600, color: "black" }}>{label}</InputLabel>
    <FormControl
    sx={{
      marginRight: 4,
      marginBottom: 1,
      marginTop: 1
    }}
  >
    <Select
      autoWidth
      sx={{
        marginRight: 8, width: "100%",
        boxShadow: 1
      }}
      name={id}
      onChange={e => {
       onChange(e,id);
      }}
      value={updatedRestaurants[id]?.carryLimit !== undefined ? updatedRestaurants[id]?.carryLimit : limit || "1"}
    >
      {carryLimitMenuItem.map((item: any, index) =>
        <MenuItem value={item} key={`${item};;${index}`}>
          {item}
        </MenuItem>
      )}

    </Select>
  </FormControl>
  </Grid>
  )
}

const MergeRuleLabel = (props: MergeRuleProps) => {
  const {brand,labelBrand,minRule,checked,mergeRules,onCheckBoxChange,onMinRuleChange} = props
  return(
  <Grid sx={{ flexDirection: "row", mt: 2   }} display={"flex"}>
    <CheckBox name={labelBrand?.name} id={labelBrand?.brandId} onChange={(e)=> {onCheckBoxChange(e,brand)}} checked={checked}/>
    <InputLabel sx={{ fontWeight: 600 , color:"black" ,mt: 1 }}>
      {labelBrand?.name}
    </InputLabel>
    {minRule && <TextField 
        id="distance"
        variant="standard"
        type="number"
        value={mergeRules[brand.brandId].minRule || ""}
        onChange={(e)=>{onMinRuleChange(e,brand)}}
        InputProps={{
          inputProps: { min: 0, style:{textAlign:"center"} },
        }}
      style={{height:25,width:55,marginLeft:10,marginRight:5}}
    />}
    {minRule && <InputLabel sx={{ fontWeight: 600 , color:"black" ,mt: 1 }}>DK</InputLabel>}
  </Grid>
  )
}
const MergeRuleInfo = (props: MergeRuleProps) => {
  const {brand,brands,mergeRules,onCheckBoxChange,onMinRuleChange} = props
  const _brands = brands
  return(
    <Grid item sx={{ mt: 4 }}>
      <InputLabel sx={{ fontWeight: 800, mt: 1 , color:"#00494d" , borderBottom:1}}>
        {brand.name}
      </InputLabel>
      {_brands && _brands.map((e) => {
        if (mergeRules[brand.brandId] !== undefined){
          return ( <MergeRuleLabel key={e.brandId} brand={brand} minRule={e.name === "Pizza Hut" && brand.name === "Pizza Hut"} labelBrand={e} mergeRules={mergeRules} checked={mergeRules[brand.brandId].canWorkWith?.includes(e.name)} onCheckBoxChange={onCheckBoxChange} onMinRuleChange={onMinRuleChange}/>)
        }
      })}
    </Grid>
  )
}

export function OrderSettings() {
  const { t } = useTranslation();
  const {
    dropdownAlert,
  } = usePortia();


  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [sortField, setSortField] = useState<string>("_id");
  const [sortDirection, setSortDirection] = useState("desc");

  const [tabIndex, setTabIndex] = useState(0);
  const [hubs, setHubs] = useState([{}]);
  const [brand, setBrand] = useState([{}]);
  const [restaurants, setRestaurants] = useState<{
    id: string,
    name: string,
    brandId: string
  }[]>([{ id: "", name: "", brandId: "" }]);
  
  const [updatedParameters, setUpdatedParameters] = useState<UpdatedParametersType>({})
  
  const [mergeRules, setMergeRules] = useState<{
    [id: string]: {
      brandId: string,
      canWorkWith?: string[],
      minRule?: number,
      name: string,
      isChanged: boolean
    };
  }>({})

  const [updatedMergeParameters,setUpdatedMergeParameters] = useState<MergeParametersType>({id:"", source:1, patchFunction() {
  },});

  const [emptyBrands, setEmptyBrands] = useState<any[]>([]);
  const [dropdownValue , setDropDownValue] = useState<{
    provinceDdValue: string,
    districtDdValue: string,
    hubDdValue: string,
    brandDdValue: string,
    restaurantDdValue: string
  }>({provinceDdValue:"",districtDdValue:"",hubDdValue:"",brandDdValue:"",restaurantDdValue:""});
  const [formState, setFormState] = useState<FormStateType>({
    province: {
      provinces: [{ id: "", name: "" }],
      selectedProvince: "",
      selectedProvinceParameter: {carryLimit: "1" , firstStageDistance: 0,
      secondStageDistance: 0,
      thirdStageDistance: 0,
      fourthStageDistance: 0,
      fifthStageDistance: 0,
      mergeBrandRules:[]
    }
      
    },
    district: {
      districts: [{ id: "", name: "" }],
      selectedDistrict: "",
      selectedDistrictParameter: {carryLimit: "1" , firstStageDistance: 0,
      secondStageDistance: 0,
      thirdStageDistance: 0,
      fourthStageDistance: 0,
      fifthStageDistance: 0,
      mergeBrandRules:[{brandId: '', canWorkWith: [], name: ''}]}
    },
    selectedHub: "",
    selectedBrands: "",
    selectedRestaurant: "",
    activeCourierCount: 0,
    activeOrderCount: 0,
    numberOfOrderByCourier: 0,
    numberOfAssignedOrdersToCourier: 0,
    unAssignedOrderCount: 0,
    parameters: {carryLimit: "1" , firstStageDistance: 0,
      secondStageDistance: 0,
      thirdStageDistance: 0,
      fourthStageDistance: 0,
      fifthStageDistance: 0}
  });
  const restaurantRef = useRef([...restaurants]);


  const {
    mutate: update,
    data: pResultRestaurant,
    error: pErrorRestaurant,
    isLoading: isPatchLaoding,
  } = PatchAllRestaurant();

  const {
    mutate: updateBrand,
    data: pResultBrand,
    error: pErrorBrand,
    isLoading: isPatchBrandLoading,
  } = PatchAllBrand();

  const {
    mutate: updateAllHub,
    data: pResultAllHub,
    error: pErrorAllHub,
    isLoading: isPatchAllHubLoading,
  } = PatchAllHub();
  
  const {
    mutate: updateProvince,
    data: pResultProvince,
    error: pErrorProvince,
    isLoading: isPatchProvinceLoading,
  } = PatchProvince();

  const {
    mutate: updateDistrict,
    data: pResultDistrict,
    error: pErrorDistrict,
    isLoading: isPatchDistrictLoading,
  } = PatchDistrict();

  const {
    mutate: updateAllDistrict,
    data: pResultAllDistrict,
    error: pErrorAllDistrict,
    isLoading: isPatchAllDistrictLoading,
  } = PatchAllDistrict();
  const {
    mutate: updateHub,
    data: pResultHub,
    error: pErrorHub,
    isLoading: isPatchHuboading,
  } = PatchHubParameters();

  const {
    mutate: updateBrandParam,
    data: pResultBrandParam,
    error: pErrorBrandParam,
    isLoading: isPatchBrandParamLoading,
  } = PatchBrand();

  const { data : restaurantData, isLoading: isRestaurantDataLoading, isError, refetch } = MergeSettings(
    currentPage,
    perPage,
    search,
    formState?.selectedHub,
    sortField,
    sortDirection,
    formState?.province.selectedProvince,
    formState?.district.selectedDistrict,
    formState?.selectedBrands,
    formState?.selectedRestaurant

  );
  const { data: restaurantsByHubId }: any = restaurantData || {};
  const { data: province, isLoading: _isLoading } = Provinces();
  const {
    data: district,
    refetch: getDistrict,
    isLoading: isDistrictLoading
  } = DistrictByProvince(formState?.province?.selectedProvince);

  
  const refresh = debounce(() => {
    refetch();
  }, 50);

  const searchRefresh = debounce(() => {
    refetch();
  }, 500);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refresh();
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
    refresh();
  };

  const handleSearchChange = (searchKey: string) => {
    setSearch(searchKey);
    (searchKey.length === 0 || searchKey.length > 2) && searchRefresh();
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(column?.sortField === undefined ? "_id" : column?.sortField);
    setSortDirection(sortDirection);
    refresh();
  };

  const {
    data: hubsByProvince,
  } = HubsByProvince(formState?.province?.selectedProvince);

  const {
    data: hubsByDistrict
  } = HubsByDistrict(formState.district.selectedDistrict);


  const { data: brands } = Brands();
  const { refetch: getRestaurant } = GetRestaurantByHubId(formState.selectedHub);
  


  const { data: restaurantByHubId, isPaused } = HubsWithRestaurant(formState.selectedHub);

  const [loading, setLoading] = useState(_isLoading);

  useEffect(() => {
    if (
      (pResultProvince && (pResultProvince.status === 200 || pResultProvince.status === 201) ||
      pResultDistrict && (pResultDistrict.status === 200 || pResultDistrict.status === 201)
      )
    ) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
    }

    if (pErrorProvince)
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "Error",
        message:
          t(pErrorProvince?.response?.data?.message) 
          
      });

    if (pErrorDistrict)
    dropdownAlert.current.alertWithType({
      type: "error",
      title: "Error",
      message:
        t(pErrorDistrict?.response?.data?.message) 
        
    });
      
  }, [pResultProvince,pErrorProvince, pResultDistrict, pErrorDistrict]);

  useEffect(() => {
    if (
      (pResultRestaurant && (pResultRestaurant.status === 200 || pResultRestaurant.status === 201))
    ) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
    }

    if (pErrorRestaurant)
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "Error",
        message:
          t(pErrorRestaurant?.response?.data?.message) 
          
      });
      
  }, [pResultRestaurant ,pErrorRestaurant]);

  
  useEffect(() => {
    if (_isLoading) {
      setLoading(_isLoading);
    } else {
      setLoading(_isLoading);
    }
  }, [_isLoading]);

  useEffect(() => {
    if (!isEmpty(brands)) {
      const normalizedBrand = normalizeArray(brands.data, emptyBrands);
      setBrand(normalizedBrand);

      const _brands : any[] = brands.data;
      const _mergeRules = mergeRules;

      _brands.forEach(element => {
          if (element.minRule !== undefined){
            _mergeRules[element.brandId] = {
              brandId: element.brandId,
              canWorkWith : element.canWorkWith,
              isChanged: false,
              name: element.name,
              minRule : element.minRule
            }
          }
          else if (element.minRule === undefined){
            _mergeRules[element.brandId] = {
              brandId: element.brandId,
              canWorkWith : element.canWorkWith,
              isChanged: false,
              name: element.name,
            }
          }
          
      });
      setMergeRules(_mergeRules)
      const brandRules = fillMergeBrandRules(_brands)
      setEmptyBrands(brandRules);
    }
  }, [brands]);

  useEffect(() => {
    if (!isEmpty(province)) {
      const normalizedProvince = normalizeArray(province.data.items, emptyBrands)
      setFormState({
        ...formState,
        province: {
          provinces: normalizedProvince,
          selectedProvince: "",
          selectedProvinceParameter: {carryLimit: "1" , firstStageDistance: 0,
          secondStageDistance: 0,
          thirdStageDistance: 0,
          fourthStageDistance: 0,
          fifthStageDistance: 0,
          mergeBrandRules:[{brandId: '', canWorkWith: [], name: ''}]}
        },
        district: {
          districts: [{}],
          selectedDistrict: "",
          selectedDistrictParameter: {carryLimit: "1" , firstStageDistance: 0,
          secondStageDistance: 0,
          thirdStageDistance: 0,
          fourthStageDistance: 0,
          fifthStageDistance: 0,
          mergeBrandRules:[{brandId: '', canWorkWith: [], name: ''}]}
        }
      });

    }
  }, [province,emptyBrands]);

  useEffect(() => {
    if (!!formState.province.selectedProvince) {
      getDistrict()
        .then((response) => {
          if (!isEmpty(response.data?.data.items)) {
            const normalizedDistrict = normalizeArray(response.data?.data.items, emptyBrands);
            setFormState({
              ...formState,
              district: {
                districts: normalizedDistrict,
                selectedDistrict: formState.district.selectedDistrict
              }
            });
          }
        });
    }
  }, [formState.province.selectedProvince, isDistrictLoading]);

  useEffect(() => {
    if (!isEmpty(hubsByProvince) && isEmpty(hubsByDistrict)) {
      const hubs = normalizeArray(hubsByProvince.data, emptyBrands);
      setHubs(hubs);
    } else if (!isEmpty(hubsByDistrict)) {
      const hubs = normalizeArray(hubsByDistrict.data, emptyBrands);
      setHubs(hubs);

    }
  }, [hubsByProvince, hubsByDistrict, _isLoading]);

  useEffect(() => {
    if (!isEmpty(formState.selectedHub)) {
      refetch();
      getRestaurant()
        .then((response) => {
          if (!isEmpty(response.data)) {
            const _restaurant = normalizeArray(response.data.data, emptyBrands);
            restaurantRef.current = [..._restaurant];
            setRestaurants(_restaurant)
            
      
          }
        }) 
    }
  }, [formState.selectedHub])

  const columns: TableColumn<Restaurant>[] = [
    {
      name: t("restaurant"),
      width: "200px",
      selector: (row) => row.name,
      sortable:true,
      sortField: "name",
    },
    {
      name: t("numberOfOrder"),
      width: "200px",
      selector: (row) => row?.totalOrder,
      sortable:true,
      sortField: "totalOrder",
      center:true
    },
    {
      name: t("assignedOrders"),
      width: "200px",
      selector: (row) => (row?.assignedOrder).toString(),
      sortable:true,
      sortField: "assignedOrder",
      center:true
    },
    {
      name: t("approvedCourierOrder"),
      width: "200px",
      selector: (row) => (row?.unAssignedOrder).toString(),
      sortable:true,
      sortField: "unAssignedOrder",
      center:true
    },
    {
      name: t("numberOfOrderByCourier"),
      width: "300px",
      selector: (row) => row?.numberOfOrderByCourier,
      sortable: true,
      sortField: "checkRateGtLimit",
      cell: (row) => (
        <LiveInfo infoLabel={t("numberOfOrderByCourier")} data={row?.numberOfOrderByCourier} checkRateGtLimit = {row?.checkRateGtLimit} />
      ),
    },
  ]

  const handleTabIndex = (event: React.SyntheticEvent, value: number) => { setTabIndex(value) }

  const handlePatchAllFunctions = (source: number, mergeBrandRules: any) =>{
    switch (source) {
      case 1:
        updateAllDistrict(mergeBrandRules);
        updateAllHub(mergeBrandRules);
        break;
      case 2:
        updateAllHub(mergeBrandRules);
        break;
      default:
        break;
    }
  }
  const handleChangeCheckBox = (e:any, brand: any) => {
    const {target: {name, checked}} = e;
    const _mergeRules = mergeRules;
    let _canWorkWith = _mergeRules[brand.brandId].canWorkWith;
    if (checked && !(_canWorkWith?.includes(name))){
       _canWorkWith?.push(name)
    }

    else if (!checked && _canWorkWith?.includes(name)){_canWorkWith = _canWorkWith.filter((e)=> { return e!== name})}
    
    _mergeRules[brand.brandId].canWorkWith = _canWorkWith
    _mergeRules[brand.brandId].isChanged = true;
    
     setMergeRules(_mergeRules)
     refresh();
  }

  const handleChangeMinRule = (e: any,brand : any) =>{
    const {target: {value}} = e;
    const _mergeRules = mergeRules;
    _mergeRules[brand.brandId].minRule = value;
    _mergeRules[brand.brandId].isChanged = true;

    const arr = formState.parameters.mergeBrandRules?.filter(e=> {return e.brandId === brand.brandId});
    if (arr !== undefined){
      arr[0].minRule = value
    }
    setMergeRules(_mergeRules)
    refresh();
  }

  const handleChangeCarryLimit = (selectedLimit: SelectChangeEvent,refRestaurantId:string) => {
    const {target: {value, name}} = selectedLimit;
    const _updatedParameters = updatedParameters;

    if (_updatedParameters[name]=== undefined){
      _updatedParameters[name] = {
        id:name,
        refRestaurantId:refRestaurantId,
        carryLimit:value,
      }
    }
    else{
      _updatedParameters[name] = {
        ..._updatedParameters[name],
        carryLimit:value,
        
      }
    }
    _updatedParameters[name].source = updatedMergeParameters.source;
    _updatedParameters[name].hubId = formState.selectedHub;
    setUpdatedParameters({..._updatedParameters});

  
  }
  const handleChangeOrderMergeDistance = (orderMergeDistance: string, restaurantId:string, refRestaurantId:string , label:string) => {
    const _updatedParameters = updatedParameters;


    if (_updatedParameters[restaurantId]=== undefined){
      switch (label) {

        case MergeStageNames.first:
          _updatedParameters[restaurantId] = {
            id: restaurantId,
            refRestaurantId:refRestaurantId,
            firstStageDistance: Number(orderMergeDistance)
          }
          break;
        case MergeStageNames.second:
          _updatedParameters[restaurantId] = {
            id: restaurantId,
            refRestaurantId:refRestaurantId,
            secondStageDistance: Number(orderMergeDistance)
          }
          break;
        case MergeStageNames.third:
          _updatedParameters[restaurantId] = {
            id: restaurantId,
            refRestaurantId:refRestaurantId,
            thirdStageDistance: Number(orderMergeDistance)
          }
          break;
        case MergeStageNames.fourth:
          _updatedParameters[restaurantId] = {
            id: restaurantId,
            refRestaurantId:refRestaurantId,
            fourthStageDistance: Number(orderMergeDistance)
          }
          break;
        case MergeStageNames.fifth:
          _updatedParameters[restaurantId] = {
            id: restaurantId,
            refRestaurantId:refRestaurantId,
            fifthStageDistance: Number(orderMergeDistance)
          }
          break;  

        default:
          break;
      }
    }
    else{
      switch (label) {

        case MergeStageNames.first:
          _updatedParameters[restaurantId] = {
            ..._updatedParameters[restaurantId],
            firstStageDistance: Number(orderMergeDistance)
            
          }
          break;
        case MergeStageNames.second:
          _updatedParameters[restaurantId] = {
            ..._updatedParameters[restaurantId],
            secondStageDistance: Number(orderMergeDistance)
            
          }
          break;
        case MergeStageNames.third:
          _updatedParameters[restaurantId] = {
            ..._updatedParameters[restaurantId],
            thirdStageDistance: Number(orderMergeDistance)
            
          }
          break;
        case MergeStageNames.fourth:
          _updatedParameters[restaurantId] = {
            ..._updatedParameters[restaurantId],
            fourthStageDistance: Number(orderMergeDistance)
            
          }
        break;
        case MergeStageNames.fifth:
          _updatedParameters[restaurantId] = {
            ..._updatedParameters[restaurantId],
            fifthStageDistance: Number(orderMergeDistance)
            
          }
        break;  

        default:
          break;
      }
    }
    _updatedParameters[restaurantId].source = updatedMergeParameters.source;
    _updatedParameters[restaurantId].hubId = formState.selectedHub;
    setUpdatedParameters({..._updatedParameters});

  }
  const handleChangeProvince = (selectedProvince: SelectChangeEvent) => {
    const { target: { value } } = selectedProvince;
    const values = value.split(";");
    const parameters = {carryLimit : values[1], firstStageDistance: Number(values[2]), secondStageDistance: Number(values[3]),
      thirdStageDistance: Number(values[4]),
      fourthStageDistance: Number(values[5]),
      fifthStageDistance: Number(values[6]),
    mergeBrandRules: JSON.parse(values[7])}
    const dpValue = values[0]+`;${parameters?.carryLimit};${parameters?.firstStageDistance};${parameters?.secondStageDistance};${parameters?.thirdStageDistance};${parameters?.fourthStageDistance};${parameters?.fifthStageDistance};${JSON.stringify(parameters?.mergeBrandRules)}`
    setDropDownValue({...dropdownValue, provinceDdValue:dpValue});
    setFormState({
      ...formState,
      province: {
        ...formState.province,
        selectedProvince: values[0],
        selectedProvinceParameter: parameters
      },
      district: {
        districts: [{}],
        selectedDistrict: "",
      },
      selectedHub: "",
      selectedBrands: "",
      selectedRestaurant: "",
      checkRateGtLimit: undefined,
      parameters: parameters
    });
  
    const _brands : any[] = parameters.mergeBrandRules;
    const _mergeRules = mergeRules;

    _brands.forEach(element => {
        if (element.minRule !== undefined){
          _mergeRules[element.brandId] = {
            brandId: element.brandId,
            canWorkWith : element.canWorkWith,
            isChanged: false,
            minRule : element.minRule,
            name: element.name,
          }
        }
        else if (element.minRule === undefined){
          _mergeRules[element.brandId] = {
            brandId: element.brandId,
            canWorkWith : element.canWorkWith,
            isChanged: false,
            name: element.name,
          }
        }
        
    });
    let _updatedParameters : UpdatedParametersType = {}
    _updatedParameters[values[0]] = {
      id: values[0],
      refRestaurantId:values[0],
      firstStageDistance: parameters?.firstStageDistance,
      secondStageDistance: parameters?.secondStageDistance,
      thirdStageDistance: parameters?.thirdStageDistance,
      fourthStageDistance: parameters?.fourthStageDistance,
      fifthStageDistance: parameters?.fifthStageDistance,
      carryLimit: parameters?.carryLimit,
      hubId:"",
      source:1,
    }
    setMergeRules(_mergeRules)
    setUpdatedMergeParameters({id:values[0], source:1 , patchFunction:updateProvince})
    setHubs([{}]);
    setUpdatedParameters(_updatedParameters)
    refresh();

  }

  const handleChangeDistrict = (selectedDistrict: SelectChangeEvent) => {
    const { target: { value } } = selectedDistrict;
    const values = value.split(';')
    const parameters = {carryLimit : values[1], firstStageDistance: Number(values[2]), secondStageDistance: Number(values[3]),
      thirdStageDistance: Number(values[4]),
      fourthStageDistance: Number(values[5]),
      fifthStageDistance: Number(values[6]),
      mergeBrandRules: JSON.parse(values[7])}
      const dpValue = values[0]+`;${parameters?.carryLimit};${parameters?.firstStageDistance};${parameters?.secondStageDistance};${parameters?.thirdStageDistance};${parameters?.fourthStageDistance};${parameters?.fifthStageDistance};${JSON.stringify(parameters?.mergeBrandRules)}`
      setDropDownValue({...dropdownValue, districtDdValue:dpValue});
    setFormState({
      ...formState,
      district: {
        districts: formState.district.districts,
        selectedDistrict: values[0],
        selectedDistrictParameter: parameters
      },
      selectedHub: "",
      selectedBrands: "",
      selectedRestaurant: "",
      checkRateGtLimit: undefined,
      parameters: parameters
    })

    const _brands : any[] = parameters.mergeBrandRules;
    const _mergeRules = mergeRules;

    _brands.forEach(element => {
        if (element.minRule !== undefined){
          _mergeRules[element.brandId] = {
            brandId: element.brandId,
            canWorkWith : element.canWorkWith,
            isChanged: false,
            minRule : element.minRule,
            name: element.name,
          }
        }
        else if (element.minRule === undefined){
          _mergeRules[element.brandId] = {
            brandId: element.brandId,
            canWorkWith : element.canWorkWith,
            isChanged: false,
            name: element.name,
          }
        }
        
    });
    let _updatedParameters : UpdatedParametersType = {}
    _updatedParameters[values[0]] = {
      id: values[0],
      refRestaurantId:values[0],
      firstStageDistance: parameters?.firstStageDistance,
      secondStageDistance: parameters?.secondStageDistance,
      thirdStageDistance: parameters?.thirdStageDistance,
      fourthStageDistance: parameters?.fourthStageDistance,
      fifthStageDistance: parameters?.fifthStageDistance,
      carryLimit: parameters?.carryLimit,
      hubId:"",
      source:2,
    }
    setMergeRules(_mergeRules)
    setUpdatedMergeParameters({id:values[0], source:2, patchFunction: updateDistrict,})
    setUpdatedParameters(_updatedParameters)
    refresh();
  }

  const handleChangeHub = (selectedHub: SelectChangeEvent) => {
    const { target: { value } } = selectedHub
    const values = value.split(';')
    const parameters = {carryLimit : values[1], firstStageDistance: Number(values[2]), secondStageDistance: Number(values[3]),
      thirdStageDistance: Number(values[4]),
      fourthStageDistance: Number(values[5]),
      fifthStageDistance: Number(values[6]),
      mergeBrandRules: JSON.parse(values[7])}
      const dpValue = values[0]+`;${parameters?.carryLimit};${parameters?.firstStageDistance};${parameters?.secondStageDistance};${parameters?.thirdStageDistance};${parameters?.fourthStageDistance};${parameters?.fifthStageDistance};${JSON.stringify(parameters?.mergeBrandRules)}`
      setDropDownValue({...dropdownValue, hubDdValue:dpValue});
    setFormState({
      ...formState,
      selectedHub: values[0],
      selectedHubParameter: parameters,
      selectedBrands: "",
      selectedRestaurant: "",
      checkRateGtLimit: undefined,
      parameters: parameters
    });

    const _brands : any[] = parameters.mergeBrandRules;
    const _mergeRules = mergeRules;

    _brands.forEach(element => {
        if (element.minRule !== undefined){
          _mergeRules[element.brandId] = {
            brandId: element.brandId,
            canWorkWith : element.canWorkWith,
            isChanged: false,
            minRule : element.minRule,
            name: element.name,
          }
        }
        else if (element.minRule === undefined){
          _mergeRules[element.brandId] = {
            brandId: element.brandId,
            canWorkWith : element.canWorkWith,
            isChanged: false,
            name: element.name,
          }
        }
        
    });
    setMergeRules(_mergeRules)
    setUpdatedMergeParameters({id:values[0], source:3, patchFunction: updateHub,})
    setUpdatedParameters({})
    refetch();
  }

  const handleChangeBrand = (selectedBrand: SelectChangeEvent) => {
    const { target: { value } } = selectedBrand
    const values = value.split(';')
    const parameters = {carryLimit : values[1], firstStageDistance: Number(values[2]), secondStageDistance: Number(values[3]),
      thirdStageDistance: Number(values[4]),
      fourthStageDistance: Number(values[5]),
      fifthStageDistance: Number(values[6]),
      mergeBrandRules: JSON.parse(values[7])}
      const dpValue = values[0]+`;${parameters?.carryLimit};${parameters?.firstStageDistance};${parameters?.secondStageDistance};${parameters?.thirdStageDistance};${parameters?.fourthStageDistance};${parameters?.fifthStageDistance};${JSON.stringify(parameters?.mergeBrandRules)}`
      setDropDownValue({...dropdownValue, brandDdValue:dpValue});
    setFormState({
      ...formState,
      selectedBrands: values[0],
      selectedBrandParameter: parameters,
      selectedRestaurant: "",
      checkRateGtLimit: undefined,
      parameters: parameters
    });
    const _brands : any[] = parameters.mergeBrandRules;
    const _mergeRules = mergeRules;

    _brands.forEach(element => {
        if (element.minRule !== undefined){
          _mergeRules[element.brandId] = {
            brandId: element.brandId,
            canWorkWith : element.canWorkWith,
            isChanged: false,
            minRule : element.minRule,
            name: element.name,
          }
        }
        else if (element.minRule === undefined){
          _mergeRules[element.brandId] = {
            brandId: element.brandId,
            canWorkWith : element.canWorkWith,
            isChanged: false,
            name: element.name
          }
        }
        
    });
    let _updatedParameters : UpdatedParametersType = {}
    _updatedParameters[values[0]] = {
      id: values[0],
      refRestaurantId:values[0],
      firstStageDistance: parameters?.firstStageDistance,
      secondStageDistance: parameters?.secondStageDistance,
      thirdStageDistance: parameters?.thirdStageDistance,
      fourthStageDistance: parameters?.fourthStageDistance,
      fifthStageDistance: parameters?.fifthStageDistance,
      carryLimit: parameters?.carryLimit,
      hubId: formState.selectedHub,
      source:4,
    }
    setMergeRules(_mergeRules)
    const newRest = [...restaurantRef.current.filter((item) => item.brandId === values[0])]
    setRestaurants(newRest)
    setUpdatedMergeParameters({id:values[0], source:4, patchFunction: updateBrandParam,})
    setUpdatedParameters(_updatedParameters)
    refresh();
  }

  const handleChangeRestaurant = (selectedRestaurant: SelectChangeEvent) => {
    const { target: { value } } = selectedRestaurant
    const values = value.split(';')

    const parameters = {carryLimit : values[1], firstStageDistance: Number(values[2]), secondStageDistance: Number(values[3]),
      thirdStageDistance: Number(values[4]),
      fourthStageDistance: Number(values[5]),
      fifthStageDistance: Number(values[6]),
      mergeBrandRules: JSON.parse(values[7])}
      const dpValue = values[0]+`;${parameters?.carryLimit};${parameters?.firstStageDistance};${parameters?.secondStageDistance};${parameters?.thirdStageDistance};${parameters?.fourthStageDistance};${parameters?.fifthStageDistance};${JSON.stringify(parameters?.mergeBrandRules)}`
      
    setDropDownValue({...dropdownValue, restaurantDdValue:dpValue});
    setFormState({
      ...formState,
      selectedRestaurant: values[0],
      selectedRestaurantParameter: parameters,
      parameters: parameters
    });

    const _brands : any[] = parameters.mergeBrandRules;
    const _mergeRules = mergeRules;

    _brands.forEach(element => {
        if (element.minRule !== undefined){
          _mergeRules[element.brandId] = {
            brandId: element.brandId,
            canWorkWith : element.canWorkWith,
            isChanged: false,
            minRule : element.minRule,
            name: element.name,
          }
        }
        else if (element.minRule === undefined){
          _mergeRules[element.brandId] = {
            brandId: element.brandId,
            canWorkWith : element.canWorkWith,
            isChanged: false,
            name: element.name,
          }
        }
        
    });
    let _updatedParameters : UpdatedParametersType = {}
    _updatedParameters[values[0]] = {
      id: values[0],
      refRestaurantId:values[0],
      firstStageDistance: parameters?.firstStageDistance,
      secondStageDistance: parameters?.secondStageDistance,
      thirdStageDistance: parameters?.thirdStageDistance,
      fourthStageDistance: parameters?.fourthStageDistance,
      fifthStageDistance: parameters?.fifthStageDistance,
      carryLimit: parameters?.carryLimit,
      hubId: formState.selectedHub,
      source:5,
    }
    setMergeRules(_mergeRules)
    setUpdatedMergeParameters({id:values[0], source:5, patchFunction: update,})
    setUpdatedParameters(_updatedParameters)
    refresh();
  }

  if (loading) {
    return <CircularProgress color="inherit" variant="indeterminate" />
  }

  return (
    <Box>
      <Tabs value={tabIndex} onChange={handleTabIndex}>
        <Tab label={t("tabMergeSettings")} />
      </Tabs>
      <Box sx={{ marginY: 4, flexDirection: "row" }}>
      <Dropdown
        inComingPlaceholder={t("province")}
        menuItem={formState.province.provinces}
        selectedItem={formState.province.selectedProvince}
        parameters = {formState.province.selectedProvinceParameter}
        ddValue={dropdownValue}
        handleChange={handleChangeProvince}
        source={1}
      />

      <Dropdown
        inComingPlaceholder={t("district")}
        menuItem={formState.district.districts}
        selectedItem={formState.district.selectedDistrict}
        parameters = {formState.district.selectedDistrictParameter}
        ddValue={dropdownValue}
        handleChange={handleChangeDistrict}
        source={2}
      />
      <Dropdown
        inComingPlaceholder={t("hub")}
        menuItem={hubs}
        selectedItem={formState.selectedHub}
        parameters = {formState.selectedHubParameter}
        ddValue={dropdownValue}
        handleChange={handleChangeHub}
        source={3}
      />
     
      <Dropdown
        inComingPlaceholder={t("brand")}
        menuItem={brand}
        selectedItem={formState.selectedBrands}
        parameters = {formState.selectedBrandParameter}
        ddValue={dropdownValue}
        handleChange={handleChangeBrand}
        source={4}
      />
      <Dropdown
      inComingPlaceholder={t("restaurant")}
      menuItem={restaurants}
      selectedItem={formState.selectedRestaurant}
      parameters = {formState.selectedRestaurantParameter}
      ddValue={dropdownValue}
      handleChange={handleChangeRestaurant}
      source={5}
    />
      </Box>
        {!!restaurantsByHubId && <PortiaTable
          columns={columns}
          data={restaurantsByHubId}
          loading={isRestaurantDataLoading}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          onSearchChange={handleSearchChange}
          onSort={handleSort}
        />}

      <Box sx={{ flexDirection: "column" ,marginLeft:5}}>
          <InputLabel sx={{ fontWeight: 600 , color:"black", mb:3 }}>{t("orderMergeDistance")}</InputLabel>
        <Box sx={{ flexDirection: "row" , mb:3}} display={"flex"}>
          
          <MergeDistanceInfo distance={updatedParameters[updatedMergeParameters.id]?.firstStageDistance !== undefined ? updatedParameters[updatedMergeParameters.id]?.firstStageDistance : formState.parameters.firstStageDistance || 0} label={"firstStageDistance"} t={t} id={updatedMergeParameters.id} onChange={handleChangeOrderMergeDistance}/>
          <MergeDistanceInfo distance={updatedParameters[updatedMergeParameters.id]?.secondStageDistance !== undefined ? updatedParameters[updatedMergeParameters.id]?.secondStageDistance : formState.parameters.secondStageDistance || 0} label={"secondStageDistance"} t={t} id={updatedMergeParameters.id} onChange={handleChangeOrderMergeDistance}/>
          <MergeDistanceInfo distance={updatedParameters[updatedMergeParameters.id]?.thirdStageDistance !== undefined ? updatedParameters[updatedMergeParameters.id]?.thirdStageDistance : formState.parameters.thirdStageDistance || 0} label={"thirdStageDistance"} t={t} id={updatedMergeParameters.id} onChange={handleChangeOrderMergeDistance}/>
          <MergeDistanceInfo distance={updatedParameters[updatedMergeParameters.id]?.fourthStageDistance !== undefined ? updatedParameters[updatedMergeParameters.id]?.fourthStageDistance : formState.parameters.fourthStageDistance || 0} label={"fourthStageDistance"} t={t} id={updatedMergeParameters.id} onChange={handleChangeOrderMergeDistance}/>
          <MergeDistanceInfo distance={updatedParameters[updatedMergeParameters.id]?.fifthStageDistance !== undefined ? updatedParameters[updatedMergeParameters.id]?.fifthStageDistance : formState.parameters.fifthStageDistance || 0} label={"fifthStageDistance"} t={t} id={updatedMergeParameters.id} onChange={handleChangeOrderMergeDistance}/>

        </Box>
        <MergeLimitInfo limit={formState.parameters.carryLimit} label={t("carryLimit")} id={updatedMergeParameters.id}  onChange={handleChangeCarryLimit} updatedRestaurants={updatedParameters}/>

        <Grid  item container spacing={0.5} sx={{  marginBottom: 5 }}>
          {formState.parameters.mergeBrandRules?.filter((brand: any) => {return (formState.selectedBrands !== "" && formState.selectedRestaurant === "" || formState.selectedBrands !== "" && formState.selectedRestaurant !== "" ? brand.brandId === formState.selectedBrands : brand)}).map((e:any)=>{
            return(<MergeRuleInfo key={e.brandId} brand={e} canWorkWith={e.canWorkWith} brands={formState.selectedBrands !== "" ? brands?.data : formState.parameters.mergeBrandRules} mergeRules={mergeRules} onCheckBoxChange={handleChangeCheckBox} onMinRuleChange={handleChangeMinRule}/>)})}
        </Grid>
        </Box>
     
      <Grid item xs={4} >
        <Button
          sx={{ width: 300, mt: 2, height: 45 }}
          variant="contained"
          type={"submit"}
          color={"primary"}
          onClick={()=>{
            const _updatedParameters = updatedParameters
            const arr = []
            if (formState.parameters.mergeBrandRules !== undefined){

              for (const item of formState.parameters.mergeBrandRules) {
                const mer = mergeRules[item.brandId]
                if (mer.name === "Pizza Hut"){

                  arr.push({brandId:mer.brandId , name: mer.name , minRule: mer.minRule , canWorkWith: mer.canWorkWith })
                }
                else{
                  arr.push({brandId:mer.brandId , name: mer.name , canWorkWith: mer.canWorkWith})

                }
              }
            }
            _updatedParameters[updatedMergeParameters.id] = {..._updatedParameters[updatedMergeParameters.id], 
              mergeBrandRules:arr, 
              id: updatedMergeParameters.id, 
              source: updatedMergeParameters.source,
            hubId: formState.selectedHub,
            refRestaurantId: formState.selectedRestaurant}
            updatedMergeParameters.patchFunction(Object.values(updatedParameters)[0]);
            update(Object.values(_updatedParameters)[0]);
            handlePatchAllFunctions(updatedMergeParameters.source, Object.values(_updatedParameters)[0]);
          }}
        >
          {t("save")}
        </Button>
        <Button
          sx={{ width: 300, mt: 2, height: 45 }}
          variant="outlined"
          color={"secondary"}
          onClick={() => {
            window.location.reload();
          }}
        >
          {t("cancel")}
        </Button>
      </Grid>
    </Box>
  )
}