import { TableColumn } from "react-data-table-component";
import { useParams } from "react-router-dom";
import { Courier } from "../../api/types";
import { PortiaTable } from "../../components/PortiaTable";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";
import { Hubs } from "../../api/hubs";
import { usePortia } from "../../context";
import { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { GetCourierByAnnouncementId } from "../../api/announcements";


export const AnnouncementDetail = () => {
  const { t } = useTranslation();
  const { dropdownAlert } = usePortia();
  const params = useParams();
  const columns: TableColumn<Courier>[] = [
    {
      name: t("name"),
      selector: (row) => row.name,
      sortable: true,
      sortField: "name",
    },
    {
      name: t("mobile"),
      selector: (row) => row.mobile,
      sortable: true,
      sortField: "mobile",
    },
    {
      name: t("email"),
      selector: (row) => row?.email,
      sortable: true,
      sortField: "email",
    },
    {
      name: t("shiftStatus"),
      selector: (row) => t(row?.shiftStatusCode).toString(),
      sortable: true,
      sortField: "shiftStatusCode",
    },
    {
      name: t("hub"),
      selector: (row) => row?.hub?.name,
      sortable: true,
      sortField: "hub",
    },
    {
      allowOverflow: true,
      button: true,
      width: "128px",
    },
  ];
  const hubId: any = params && params["hubid"];
  const [status, setStatus] = useState<string>("all");
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([] as any);
  const [sortField, setSortField] = useState<string>("_id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [action] = useState({ fromUser: false });
  const { data: hubs } = Hubs();
  const hubsData = (hubs && hubs?.data?.items) || [];
  const announcementId: any = params && params["announcementId"];

  const { refetch, data: CourierData, isLoading, isError } =
    GetCourierByAnnouncementId(announcementId, currentPage, perPage, search);

  useEffect(() => {
    refetch(announcementId);
  }, [announcementId]);


  const handleChange = useCallback(
    (selectedRows: any) => {
      if (selectedRows?.length !== perPage) {
        if (!action.fromUser) return;
      }
      selectedRowsPerPage[currentPage] = selectedRows;
    },
    [currentPage, selectedRowsPerPage, action.fromUser]

  );
  const refresh = debounce(() => {
    refetch();
  }, 50);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refresh();
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
    setSelectedRowsPerPage([]);
    refresh();
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(column?.sortField === undefined ? "_id" : column?.sortField);
    setSortDirection(sortDirection);
    refresh();
  };

  const handleSearchChange = (searchKey: string) => {
    setSearch(searchKey);
    (searchKey.length === 0 || searchKey.length > 2) && searchRefresh();
  };

  const searchRefresh = debounce(() => {
    refetch();
  }, 500);

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      {CourierData ? <PortiaTable
        loading={!isError && isLoading}
        columns={columns}
        data={CourierData?.data}
        customFilter={
          <Grid container spacing={1}>
            <Grid item xs={2} mt={-3}>
            </Grid>
          </Grid>
        }
        onSelect={handleChange}
        onChangePage={handlePageChange}
        onChangePerRows={handlePerRowsChange}
        serverSideSearch
        onSearchChange={handleSearchChange}
        onSort={handleSort}
      />
        : null}
    </Box>
  );
};
