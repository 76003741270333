import {
  debounce,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import { TableColumn } from "react-data-table-component";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { PosList as PosDataList, DeletePosDevice } from "../../api/pos";
import { Pos } from "../../api/types";
import { PortiaTable } from "../../components/PortiaTable";
import { RowActions } from "../../components/PortiaTable/row.actions";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { usePortia } from "../../context";
import { useTranslation } from "react-i18next";
import { Hubs } from "../../api/hubs";

export const PosList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    dropdownAlert,
  } = usePortia();

  const params = useParams();
  const paramsHubId: any = params && params["hubid"];
  const [hubId, setHub] = useState(paramsHubId || "all");
  const [sortField, setSortField] = useState<string>("_id");
  const [sortDirection, setSortDirection] = useState("desc");

  const {
    mutate: deletePos,
    data: dResult,
    error: dError,
  } = DeletePosDevice();

  const columns: TableColumn<Pos>[] = [
    {
      name: t("posId"),
      selector: (row) => row.posId,
      sortable: true,
      sortField: "posId"
    },
    {
      name: t("posSerial"),
      selector: (row) => row.serial,
      sortable: true,
      sortField: "serial"
    },
    {
      name: t("hub"),
      selector: (row) => row?.hub?.name,
      sortable: true,
      sortField: "hub.name"
    },
    {
      name: t("Courier"),
      selector: (row) => row?.courier?.name,
      sortable: true,
      sortField: "courier.name"
    },
    {
      cell: (row) => (
        <>
          <RowActions
            onEdit={(navigate: NavigateFunction) => {
              navigate(`/pos/${row.posId}`, { state: row });
            }}
            onDelete={() => {
              deletePosDevice(row.posId);
            }}
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "128px",
    },
  ];

  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    refetch: getPosDevice,
    data,
    isLoading,
    isError,
    refetch,
  } = PosDataList(currentPage, perPage, hubId, sortDirection, sortField || "all");


  const { data: hubsData } = Hubs();
  const hubs = (hubsData && hubsData?.data?.items) || [];

  const handleChange = (selectedRows: any) => {
    // console.log("Selected Rows: ", selectedRows);
  };

  const { data: posList }: any = data || {};

  const filter: any = (item: Pos, search: string) => {
    const serialHas =
      item.serial && item.serial.toLowerCase().includes(search.toLowerCase());
    return serialHas;
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(column?.sortField === undefined ? "_id" : column?.sortField);
    setSortDirection(sortDirection);
    refresh();
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
  };
  useEffect(() => {
    if (dResult && dResult.status === 200) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("delete"),
      });
      getPosDevice();
    }
  }, [dResult]);
  useEffect(() => {
    if (dError && dError?.response?.status === 404) {
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "ERROR",
        message: t(dError?.response?.data?.message),
      });
    }
  }, [dError]);

  const deletePosDevice = (id: any) => {
    deletePos(id);
  };

  const changeHub = (e: any) => {
    const value = e.target.value;
    setHub(value);
    setTimeout(() => {
      refetch();
    }, 100);
  };

  const refresh = debounce(() => {
    refetch();
  }, 50);

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      {!paramsHubId && (
        <Grid container spacing={4}>
          <Grid item md={3} xs={6}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="test-select-label">{t("hub")}</InputLabel>
              <Select
                onChange={changeHub}
                name={"hubId"}
                value={hubId}
                sx={{ width: "100%" }}
                labelId="test-select-label"
                label={t("hub")}
              >
                <MenuItem key="all" value={"all"}>
                  {t("all")}
                </MenuItem>
                {hubs?.map((option: any) => {
                  return (
                    <MenuItem key={option?.hubId} value={option?.hubId}>
                      {option.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {posList && (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns}
          data={posList}
          filter={filter}
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          onSort={handleSort}
        />
      )}
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => {
          let navRoute = `/pos/new`;
          if (hubId && hubId !== "all") {
            navRoute = `/pos/new?hubId=${hubId}`;
          }
          navigate(navRoute, { state: undefined });
        }}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
};
