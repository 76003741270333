import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";
import {
  Autorenew,
  Preview,
} from "@mui/icons-material";
import LogoutIcon from '@mui/icons-material/Logout';
import { t } from "i18next";


export interface CourierCustomRowActionsProps {
  activeOrders?: Function;
  onChangeStatus?: Function;
  onhandleShiftEnd?:Function;

}

export function CourierCustomRowActions({
  activeOrders,
  onChangeStatus,
  onhandleShiftEnd,
}: CourierCustomRowActionsProps): JSX.Element {
  return (
    <Box style={{ display:'flex'}}>
      {onhandleShiftEnd && (
        <Tooltip title={t("end")} arrow>
          <Fab
            sx={{ width: 34, height: 34, mr: 1 }}
            color="primary"
            aria-label="delete"
            onClick={() => onhandleShiftEnd && onhandleShiftEnd()}
          >
            <LogoutIcon />
          </Fab>
        </Tooltip>
      )}
       {onChangeStatus && (
        <Tooltip title={t("changeStatus")} arrow>
          <Fab
            sx={{ width: 34, height: 34, mr: 1 }}
            color="info"
            aria-label="edit"
            onClick={() => onChangeStatus && onChangeStatus()}
          >
            <Autorenew />
          </Fab>
        </Tooltip>
      )}
      {activeOrders && (
        <Tooltip title={t("CourierActiveOrder")} arrow>
          <Fab
            sx={{ width: 34, height: 34, mr: 1 }}
            color="warning"
            aria-label="view"
            onClick={() => activeOrders && activeOrders()}
          >
            <Preview />
          </Fab>
        </Tooltip>
      )}
    </Box>
  );
}
