import { Box, Button, Grid } from "@mui/material";
import { t } from "i18next";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { HubBonuses } from "../../api/types";
import { TableColumn } from "react-data-table-component";
import { PortiaTable } from "../../components/PortiaTable";
import { useEffect, useState } from "react";
import {
  DeleteHubBonuses,
  GetHubBonuses,
  PostHubBonuses,
} from "../../api/hubs";
import { NavigateFunction, useParams } from "react-router-dom";
import { debounce } from "lodash-es";
import { usePortia } from "../../context";
import { RowActions } from "../../components/PortiaTable/row.actions";

export const HubBonus = () => {
  const {
    dropdownAlert,
  } = usePortia();

  const params = useParams();
  const [hubBonuses, setHubBonuses] = useState<HubBonuses>();
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  const hubId: any = params && params["hubid"];

  const columns: TableColumn<HubBonuses>[] = [
    {
      name: t("minPackageRange"),
      selector: (row) => row.minPackage,
    },
    {
      name: t("maxPackageRange"),
      selector: (row) => row.maxPackage,
    },
    {
      name: t("minPackagePerDaily"),
      selector: (row) => row.minPackagePerDaily,
    },
    {
      name: t("amount"),
      selector: (row) => row.amount,
    },
    {
      cell: (row) => (
        <>
          <RowActions
            onEdit={(navigate: NavigateFunction) => {
              navigate(`/hubBonus/${row.hubBonusId}`, { state: row });
            }}
            onDelete={() => {
              deleteHubBonusesMutation(row.hubBonusId);
              setTimeout(() => {
                refetch();
              }, 500);
            }}
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "128px",
    },
  ];

  const { data, isLoading, isError, refetch } = GetHubBonuses(
    hubId,
    currentPage,
    perPage,
  );
  const { data: hubBonusesData }: any = data || {};

  const {
    mutate: create,
    data: cResult,
    error: cError,
    isLoading: isPostLoading,
  } = PostHubBonuses();

  const {
    mutate: deleteHubBonusesMutation,
  } = DeleteHubBonuses();

  useEffect(() => {
    if (cResult && (cResult.status === 200 || cResult.status === 201)) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
      refetch();
    }

    if (cError) {
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "OK",
        message: t(cError?.response?.data.message),
      });
    }
  }, [cError, cResult]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refresh();
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
    refresh();
  };

  const handleChange = (selectedRows: any) => {
    console.log("Selected Rows: ", selectedRows);
  };

  const refresh = debounce(() => {
    refetch();
  }, 50);

  const createHubBonus = (bonus: HubBonuses) => {
    create(bonus);
  };

  const handlePaste = (event: any) => {
    const pastedText = event.clipboardData.getData("text");
    const pastedValue = parseFloat(pastedText);
    if (pastedValue < 0 || pastedText.includes("e")) {
      event.preventDefault();
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "-" || event.key === "e" ) {
      event.preventDefault();
    }
  };
  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      <FormContainer
        reValidateMode="onChange"
        values={hubBonuses}
        defaultValues={{ hubId: hubId }}
        onSuccess={(data, event) => {
          createHubBonus(data);
        }}
      >
        <Grid container spacing={12}>
          <Grid item xs={2}>
            <TextFieldElement
              name={"minPackage"}
              label={t("minPackageRange")}
              type={"number"}
              inputProps={{
                min: 0,
              }}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              validation={{ required: t("required").toString() }}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextFieldElement
              name={"maxPackage"}
              label={t("maxPackageRange")}
              type={"number"}
              inputProps={{
                min: 0,
              }}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextFieldElement
              name={"minPackagePerDaily"}
              label={t("minPackagePerDaily")}
              type={"number"}
              inputProps={{
                min: 0,
              }}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              validation={{ required: t("required").toString() }}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextFieldElement
              name={"amount"}
              label={t("amount")}
              type={"number"}
              inputProps={{
                min: 0,
              }}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              validation={{ required: t("required").toString() }}
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              type={"submit"}
              fullWidth
            >
              {t("add")}
            </Button>
          </Grid>
        </Grid>
      </FormContainer>

      {hubBonusesData && (
        <PortiaTable
          loading={!isError && isLoading && isPostLoading}
          columns={columns}
          data={hubBonusesData}
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          hideSearchBox
        />
      )}
    </Box>
  );
};
