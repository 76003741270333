import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, List, ListItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PaymentTypeListAll } from "../../api/paymentType";
import { GetPaymentTypeByRestaurant, PostRestaurantPaymentTypes } from "../../api/restaurant";
import { usePortia } from "../../context";
import { useTranslation } from 'react-i18next';


export const RestaurantPaymentType = () => {
    const { dropdownAlert } = usePortia();
    const params = useParams();
    const restaurantId: any = params && params["restaurantid"];
    const { t } = useTranslation();

    const { data } = PaymentTypeListAll();
    const { data: paymentTypes }: any = data || {};
    const {
        refetch: getRestaurantPaymentList,
        data: restaurantPaymentList,
        isLoading: isRestaurantLoading,
    } = GetPaymentTypeByRestaurant(restaurantId);

    const {
        mutate: create,
        data: cResult,
        error: cError,
    } = PostRestaurantPaymentTypes();

    const [payments, setPayment] = useState({});

    const [isChecked, setIsChecked] = useState(() =>
        paymentTypes?.map((payment: any) => {
            if (restaurantPaymentList?.data?.paymentTypes?.[payment?.type]) {
                return restaurantPaymentList?.data?.paymentTypes?.[payment?.type]
            } else {
                return false;
            }
        })
    );
    useEffect(() => {
        setTimeout(() => {
            getRestaurantPaymentList()
            setIsChecked(() => {
                return paymentTypes?.map((payment: any) => {
                    if (restaurantPaymentList?.data?.paymentTypes?.[payment?.type]) {
                        return restaurantPaymentList?.data?.paymentTypes?.[payment?.type]
                    } else {
                        return false;
                    }
                })
            });
        }, 100);
    }, [isRestaurantLoading])

    const isCheckboxChecked = (index: any, checked: any, type: any) => {
        setIsChecked((isChecked: any) => {
            return isChecked?.map((c: any, i: any) => {
                if (i === index) return checked;
                return c;
            });
        });
        setPayment({ ...payments, [type]: checked });
    };
    useEffect(() => {
        if (cResult && cResult.status === 201) {
            dropdownAlert.current.alertWithType({ type: 'success', title: "OK", message: t('saved') });
        }
        if (cError) {
            dropdownAlert.current.alertWithType({ type: 'error', title: "ERROR", message: cError?.response?.data?.message });
        }
    }, [cResult, cError])

    useEffect(()=>{
        if(restaurantPaymentList?.data?.paymentTypes) setPayment(restaurantPaymentList?.data?.paymentTypes);
    },[restaurantPaymentList])

    const save = () => {
        create({
            restaurantId: restaurantId,
            payments: payments
        })
    }

    return (
        <Box sx={{ m: 2 }}>
            <Grid container spacing={2}>
                <FormControl component="fieldset">
                    <List sx={{ m: 2 }} component="nav" aria-label="mailbox folders">
                        {paymentTypes?.map((checkbox: any, index: any) => {
                            return (
                                <ListItem key={index} >
                                    <FormControlLabel
                                        key={checkbox?.type}
                                        className="twocolelement"
                                        control={
                                            <Checkbox
                                                name={checkbox.type}
                                                value={checkbox.type || ''}
                                                id={checkbox.paymentId}
                                                checked={isChecked?.[index] || false}
                                                color="primary"
                                                onChange={(e) => isCheckboxChecked(index, e.target.checked, checkbox.type)}
                                            />
                                        }
                                        label={t(checkbox.type)}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </FormControl>

            </Grid>
            <Grid item xs={12} >
                <Button sx={{ mr: 2 }} variant="contained" type={'submit'} color={'primary'} onClick={save}>{t('save')} </Button>
            </Grid>

        </Box>
    );
}