import { Point } from "geojson";

export enum CourierType {
  POOL = 1,
  DEDICATED,
  STABLE,
  PAYROLL,
  JOKER,
}

export interface Hub {
  _id: string;
  hubId: string;
  name: string;
  managers: [User];
  location: Point;
  status: boolean;
  provinceId: string;
  districtId: string;
}

export interface KcActionUser {
  username: string;
  email?: string;
  portiaId: string;
}
export interface Reconciliation {
  _id: string;
  reconciliationId: string;
  courierId: string;
  workDate: string;
  payments: [Payment];
  totalAmount: string;
  updateReason: string;
  status: string;
  actionUser: KcActionUser;
}
export interface Vehicle {
  license: string;
  type: number;
  mileage: number;
}
export interface Pos {
  _id: string;
  posId: string;
  serial: string;
  hubId: string;
  courier: Courier;
  hub: Hub;
}

export interface Courier {
  _id: string;
  courierId: string;
  name: string;
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  tckn: string;
  email: string;
  mobile: string;
  photo: string;
  type: CourierType;
  state: string;
  hub: Hub;
  vehicle: Vehicle;
  pos: Pos;
  reconciliationParams?: ReconciliationParams;
  shiftStatusCode: string;
  numberOfActiveAssignedPackets: string;
  numberOfPackagesCarriedDaily: string;
  workingDays: {
    startTime: Date;
    finishTime: Date;
  };
  latestShift: string,
}

export interface ShiftStatus {
  code: string;
  timestamp: number;
}

export interface Shift {
  _id: string;
  courierId: string;
  vehicleId: string;
  shiftId: string;
  posId: string;
  startAt: number;
  endAt: number;
  reconciled: boolean;
  status: ShiftStatus;
  fsmState: any;
  posList: any[];
  processInstanceKey: string;
  courier: Courier;
  shifts: any;
}
export interface StatusHistory {
  id: string;
  courierId: string;
  shiftId: string;
  code: string;
  timestamp: number;
}

export interface Restaurant {
  _id: string;
  restaurantId: string;
  refRestaurantId: string;
  brandId: string;
  hubId: string;
  name: string;
  manager: string;
  location: Point;
  status: boolean;
  provinceId: string;
  districtId: string;
  hub: Hub;
  carryLimit : string;
  orderMergeDistance: number;
  assignedOrder: number;
  unAssignedOrder: number;
  totalOrder: number;
  numberOfOrderByCourier: string;
  checkRateGtLimit: boolean;
}

export interface Address {
  city: string;
  district: string;
  deliveryLocation: string;
}

export interface Payment {
  type: string;
  amount: string;
  price: string;
}
export enum PaymentPaidType {
  cash,
  creditCard,
  ticket,
  sodexo,
  paycell,
  setcard,
  multinet,
  metropolcard,
  payecard,
}
export interface PaymentType {
  _id: string;
  paymentId: string;
  type: PaymentPaidType;
}

export interface WorkingDay {
  _id: string;
  hubId: string;
  workingDayId: string;
  courierId: string;
  workingDate: string;
  startTime: string;
  finishTime: string;
  couriers: Courier;
  restaurants: Restaurant;
}

export interface Order {
  _id: string;
  orderId: string;
  refOrderId: string;
  refRestaurantId: string;
  orderNumber: string;
  orderDate: string;
  orderSummary: string;
  customerNote: string;
  orderSource: string;
  brand: string;
  address: Address;
  restaurantId: string;
  hubId: string;
  payments: [Payment];
  assignedCourierId: string;
  status: OrderStatus;
  orderDetail: any;
  fsmState: any;
  processInstanceKey: string;
  completedTime: number;
  assignedCourier: Courier;
  restaurant: Restaurant;
  location: Point;
  firstName: string;
  lastName: string;
  total: number;
  isPrePaid: boolean;
  courier: Courier;
  paymentColletions: any;
  hub: Hub;
}

export enum OrderStatus {
  RECEIVED = "RECEIVED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  ASSIGNED = "ASSIGNED",
  ASSIGNMENT_FAILED = "ASSIGNMENT_FAILED",
  PICKED = "PICKED",
  IN_DELIVERY = "IN_DELIVERY",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}

export interface DashboardCount {
  total: number;
  loginCourierCount: number;
  availableCourierCount: number;
  totalComplateOrders: number;
  notAssignedOrders: number;
  efficiency: number;
}

export interface UserHub {
  hubId: string;
  hubName?: string;
}

export interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  tckn: string;
  email: string;
  mobile: string;
  attributes: {
    mobile: Array<string>;
    phoneNumber: Array<string>;
    tckn: Array<string>;
    portiaId: Array<string>;
    hubId?: Array<string>;
    hubName?: Array<string>;
  };
  hubs: Array<UserHub>;
}
export interface Tenant {
  tenantId: string;
  id: string;
  name: string;
  description: string;
}
export interface Location {
  lat: number;
  long: number;
}
export interface Company {
  tenantId: string;
  companyId: string;
  id: string;
  name: string;
  description: string;
  tenant: Tenant;
  image: string | null | ArrayBuffer;
  location: Location;
}
export interface Brand {
  brandId: string;
  id: string;
  name: string;
  logo: string | null | ArrayBuffer;
}
export const ORDER_STATUS = {
  RECEIVED: "RECEIVED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  ASSIGNED: "ASSIGNED",
  ASSIGNMENT_FAILED: "ASSIGNMENT_FAILED", // atama işlemi hatalı
  PICKED: "PICKED", // sipariş hazır
  IN_DELIVERY: "IN_DELIVERY",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export interface CourierTypes {
  _id: string;
  courierType: number;
  courierTypeName: string;
  amountPerHour: number;
  amountPerPack: number;
  fixedAmount: number;
  courierTypeId: string;
  minAmount: number;
  minPackage: number;
}

export interface ReconciliationParams {
  amountPerHour: number;
  amountPerPack: number;
  fixedAmount: number;
  minAmount: number;
  minPackage: number;
}

export interface Announcement {
  announcementId: string;
  couriers: Array<string>;
  message: string;
  hubId: string;
  hub:Hub;
  restaurantId: string;
  restaurant: Restaurant;
  createdBy: string;
  actionUser: KcActionUser;
  updatedAt: string;
  deliveredTo: Array<string>;
  createdAt: string;
  endDate: number;
}
export interface HubBonuses {
  hubBonusId: string;
  hubId: string;
  minPackage: number;
  maxPackage: number;
  minPackagePerDaily: number;
  amount: number;
}
export interface OrderPayment {
  type: string;
  amount: number;
}
export interface OrderDetails {
  firstName: string;
  lastName: string;
  phone: string;
  refOrderId: string;
  refRestaurantId: string;
  address: string;
  directions: string;
  lat: number;
  lng: number;
  total: number;
  customerNote: string;
  orderSummary: string;
  orderSource: string;
  brand: string;
  refSystem: string;
  isPrepaid: boolean;
  payments: OrderPayment[];
}