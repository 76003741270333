import { Avatar, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { TableColumn } from "react-data-table-component";
import { Courier } from "../../api/types";
import { useTranslation } from "react-i18next";
import { PortiaTable } from "../../components/PortiaTable";
import { useState } from "react";
import { debounce } from "lodash-es";
import { CouriersList as CouriersDataList } from "../../api/dashboard";
import { useLocation } from 'react-router-dom';


export const DashboardDetail = () => {
    const { t } = useTranslation();
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState<string>("");
    const location = useLocation();
    const shiftStatus = location.state?.type || 'All';
    const brand = location.state?.brand;

    const columns: TableColumn<Courier>[] = [
        {
            cell: (row) => <Avatar src={row.photo} />,
            width: "64px",
        },
        {
            name: t('name'),
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: t('mobile'),
            selector: (row) => row.mobile,
            sortable: true,
        },
        {
            name: t('email'),
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: t('type'),
            selector: (row) => row.type,
            sortable: true,
        },
    ]

    const { data, isLoading, isError, refetch } = CouriersDataList(
        currentPage,
        perPage,
        shiftStatus == "All" ? undefined : shiftStatus,
        brand,
        search
    );

    const handleChange = (selectedRows: any) => {
        // console.log("Selected Rows: ", selectedRows);
    };

    const { data: couriers }: any = data || {};

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        refresh();
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setCurrentPage(page);
        setPerPage(newPerPage);
        refresh();
    };

    const handleSearchChange = (searchKey: string) => {
        setSearch(searchKey);
        (searchKey.length == 0 || searchKey.length > 2) && searchRefresh();
    };

    const refresh = debounce(() => {
        refetch();
    }, 50);

    const searchRefresh = debounce(() => {
        refetch();
    }, 500);

    return (
        <Box sx={{ m: 1 }}>
            <Grid item xs={6}>
                {couriers && (
                    <PortiaTable
                        loading={!isError && isLoading}
                        columns={columns}
                        data={couriers}
                        onSelect={handleChange}
                        onChangePage={handlePageChange}
                        onChangePerRows={handlePerRowsChange}
                        serverSideSearch
                        onSearchChange={handleSearchChange}
                    />
                )}
            </Grid>
        </Box>
    )
};

