export const SHIFT_STATUS = {
  OFFLINE: "OFFLINE",
  AVAILABLE: "AVAILABLE",
  ASSIGNED: "ASSIGNED",
  BREAK: "BREAK",
  ACCIDENT:"ACCIDENT",
};

export const COURIER_DETAIL_TYPE = {
  TOTAL: "All",
  LOGIN: "LOGIN"
}

export enum ReconciliationStatus {
  COURIER_DRAFT = 'COURIER_DRAFT',
  HUB_MANAGER_DRAFT = 'HUB_MANAGER_DRAFT',
  APPROVED = 'APPROVED',
}