import React, { memo, useCallback, useEffect, useState  } from "react";

import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import HubIcon from "@mui/icons-material/Hub";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import PaymentsIcon from "@mui/icons-material/Payments";
import AodIcon from "@mui/icons-material/Aod";
import EventIcon from "@mui/icons-material/Event";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import Leaderboard from "@mui/icons-material/Leaderboard";
import CampaignIcon from '@mui/icons-material/Campaign';

import { usePortia } from "../context";
import Button from "@mui/material/Button";
import { MenuItem, Select } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import i18n from "../translations";

import { useNavigate } from "react-router-dom";
import { BrowseGallery } from "@mui/icons-material";
import Authz from "./Authz";
import { GetImageByCompany } from "../api/companies";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const OrderSettingsDiv = styled("div")({
  flexDirection: "row",
  display: "flex",
  maxWidth: 25
})

const OrderSettingsIconWrapperDiv = styled("div")({
  display: "flex",
  width: "75%",
  alignSelf: "center",
  justifyContent: "flex-start",
  alignContent: "flex-start",
  marginLeft: -14,
})


const OrderSettingsMergedIcon = memo(() => (
  <OrderSettingsDiv>
    <OrderSettingsIconWrapperDiv>
      <SettingsIcon viewBox="-10 0 22 22" sx={{
        mr: 0.25
      }} />

      <ShoppingBagIcon viewBox="10 0 22 22" />
    </OrderSettingsIconWrapperDiv>
  </OrderSettingsDiv>
))

const MenuIcons: any = {
  couriers: <DeliveryDiningIcon />,
  pos: <AodIcon />,
  shifts: <BrowseGallery />,
  restaurants: <LunchDiningIcon />,
  hubs: <HubIcon />,
  paymenttypes: <PaymentsIcon />,
  orders: <ShoppingBagIcon />,
  mergesettings: <OrderSettingsMergedIcon />,
  workingdays: <EventIcon />,
  dashboard: <Leaderboard />,
  "": <HomeIcon />,
  definitions: <SettingsIcon />,
  announcement: <CampaignIcon />
};

const FirstGroupMenuItems = [
  { key: "", roles: [] },
  { key: "Hubs", roles: ["portia-admin", "portia-hub-manager"] },
  { key: "Couriers", roles: ["portia-admin", "portia-hub-manager"] },
  { key: "Orders", roles: ["portia-admin"] },
  { key: "mergesettings", roles: ["portia-admin", "portia-hub-manager"] },
  { key: "Definitions", roles: ["portia-admin"] },
  { key: "Dashboard", roles: ["portia-admin", "portia-hub-manager"] },
  { key: "Announcement", roles: ["portia-faz-2"] },
];

export default function AppFrame({ children }: any) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [lang] = React.useState("tr");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [image, setImage] = useState<string | null>(null);

  const { data: imageData } = GetImageByCompany();
  useEffect(() => {
    if (imageData?.data) {
      setImage(imageData?.data);
      
    }
  }, [imageData])

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const {
    store: { user, logout },
  } = usePortia();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const languageChange = (e: any) => {
    i18n.changeLanguage(e.target.value);
  };

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    targetNavigate: string
  ) => {
    setSelectedIndex(index);
    navigate(targetNavigate);
  };
  console.log("selected index", selectedIndex);

  const menuItemSelectedHover = useCallback((key: string) => {
    let urlKey = key.toLowerCase();
    let pathName: string = window.location.pathname;

    if (pathName.length == 1 && urlKey.length == 0) {
      //home page
      return true;
    } else if (urlKey.length > 0) {
      return pathName.includes(urlKey.toLowerCase());
    }
  }, []);

  const onLogout = () => {
    navigate("/", { replace: true });
    logout();
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          {
            image &&
            <img
              src={image}
              alt={t("☆Portia Backoffice☆") as string}
              height="40"
            />
          }
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 0, marginRight: 2 }}>
            <Select
              defaultValue={lang}
              onChange={languageChange}
              IconComponent={LanguageIcon}
              sx={{
                color: "white",
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
            >
              <MenuItem value="tr">{t("turkish")}</MenuItem>
              <MenuItem value="en">{t("english")}</MenuItem>
            </Select>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {!user && (
              <Button variant="contained" onClick={() => console.log("login")}>
                {" "}
                {t("login")}{" "}
              </Button>
            )}
            {!!user && (
              <>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleUserMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem>{user?.firstName}</MenuItem>
                  <MenuItem onClick={() => onLogout()}>{t("logout")}</MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {FirstGroupMenuItems.map((mi, index) => (
            <Authz key={mi.key} allowedRoles={mi.roles}>
              <ListItem key={mi.key} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuItemSelectedHover(mi.key)}
                  color="black"
                  onClick={(event) =>
                    handleListItemClick(
                      event,
                      index,
                      `/${mi.key.toLowerCase()}`
                    )
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {MenuIcons[mi.key.toLowerCase()]}
                  </ListItemIcon>
                  <ListItemText
                    primary={t(mi.key)}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Authz>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 5 }}>
        {children}
      </Box>
    </Box>
  );
}
