import { t } from "i18next";
import { PasswordElement } from "react-hook-form-mui";

export interface PasswordRepeatElement {
  name: string,
  label: any,
  passwordField: any,
}
export const PortiaPasswordRepeatElement = ({ 
  name,
  label,
  passwordField,
}: PasswordRepeatElement ) => {
  const errorMessage =  t('passwordError');
  return (
      <PasswordElement 
      name={name} 
      label={label} 
      sx={{ mr: 2 }} 
      validation={{
        validate: (value: string) => {
          return value === passwordField || errorMessage
        }
      }} />
  );
};
