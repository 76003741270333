
import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Toast = ({ alert }: any) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (alert && alert?.message) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [alert]);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };



    if (!open) return null;
    return (<Snackbar
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity={alert?.type} sx={{ width: '100%' }}>
            {alert?.message}
        </Alert>

    </Snackbar>)
}
