import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { useMutationWithDebounce } from "./debounce";
import {
  USERS,
  GET_USER_DETAILS
} from "./paths";

export function GetUser(userId: string) {
  return useQuery(["getuser", userId], {
    queryFn: () =>
      !!apiClient && apiClient.get(GET_USER_DETAILS + `/${userId}`),
    staleTime: 100,
    cacheTime: 100,
    retry: 0,
    enabled: false,
  });
}

export function PostUser(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (user: any) => apiClient.post(USERS, user),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    debounceTime,
  });
}

export function PatchUser(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (user: any) =>
      apiClient.patch(USERS + `/${user.id || user._id}`, user),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    debounceTime,
  });
}

export function Users() {
  return useQuery(["users "], {
    queryFn: () => !!apiClient && apiClient.get(USERS),
    retry: 0,
    onError(err) {
      console.log(err);
    },
  });
}
 