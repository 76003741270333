import { TableColumn } from "react-data-table-component";
import { ResponsivePie } from "@nivo/pie";
import { Courier } from "../../api/types";
import { PortiaTable } from "../../components/PortiaTable";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Grid, InputLabel, ListItemIcon, ListItemText, MenuItem, Select,TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { debounce, head, isEmpty } from "lodash-es";
import { HubByDistrictId, DashboardData } from "../../api/dashboard";
import { DistrictByProvince, Provinces } from "../../api/provinces";
import Authz from "../../components/Authz";
import { Hubs } from "../../api/hubs";
import { usePortia } from "../../context";
import * as XLSX from 'xlsx';
import general from "../../utils/general";
import { WorkingDaysByCourier } from "../../api/workingDay";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as DatePickerMui } from "@mui/x-date-pickers/DatePicker";
import { DatePicker } from "../../components/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from 'dayjs';
import { BrandsMap } from "../../api/brands";

export const Dashboard = () => {
  const { t } = useTranslation();
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState<string>("All");
  const [search, setSearch] = useState<string>("");
  const [selectedProvinceId, setSelectedProvinceId] = useState<string>("");
  const [districts, setDistricts] = useState([] as any);
  const [hubs, setHubs] = useState([] as any);
  const [hubList, setHubList] = useState([] as any);
 
  function checkShiftWorkingDay(courirer: Courier) {
    if (!courirer.workingDays) {
      return
    }
    const now = new Date();
    const timestampGMT = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
    );
    const finishLocalTimestamp = Date.parse(courirer?.workingDays?.finishTime?.toString());
    const finishUtcTimestamp = new Date(finishLocalTimestamp).getTime() + new Date(finishLocalTimestamp).getTimezoneOffset() * 60000;
    if (timestampGMT > finishUtcTimestamp) {
      return;
    }
    const startLocalTimestamp = Date.parse(courirer?.workingDays?.startTime?.toString());
    const startUtcTimestamp = new Date(startLocalTimestamp).getTime() + new Date(startLocalTimestamp).getTimezoneOffset() * 60000;
    if ((timestampGMT > startUtcTimestamp) && courirer?.latestShift === "OFFLINE") {
      return true;
    }
    return false;
  }
  const { dropdownAlert } = usePortia();

  function formatDate(dateString: string) {
    if (!dateString) {
      return
    }
    const dateObj = new Date(dateString);
    const formattedDate = `${dateObj.getUTCFullYear()}-${(dateObj.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dateObj
      .getUTCDate()
      .toString()
      .padStart(2, "0")} ${dateObj
      .getUTCHours()
      .toString()
      .padStart(2, "0")}:${dateObj.getUTCMinutes().toString().padStart(2, "0")}`;
    return formattedDate;
  }
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs(new Date()));
  const { data: brands } = BrandsMap();
  const pizzaHutBrand: any = brands?.data ? Object.values(brands.data).find((brand : any) => brand.name === "Pizza Hut") : null;
  const pizzaHutLogo = pizzaHutBrand?.logo;

  const shiftStateOptions = [
    { id: "All", label: t("all") },
    { id: "active", label: t("couriersActive") },
    { id: "login", label: t("couriersLogin") },
    { id: "logout", label: t("couriersLogout") },
    { id: "picked", label: t("couriersPicked") },
  ];

  const columns: TableColumn<Courier>[] = [
    {
      cell: (row) => <Avatar src={row.photo} />,
      width: "64px",
    },
    {
      name: t("name"),
      selector: (row) => row.name,
      sortable: true,
      sortField: "name",
    },
    {
      name: t("mobile"),
      selector: (row) => row.mobile,
      sortable: true,
      sortField: "mobile",
    },
    {
      name: t("hub"),
      selector: (row) => row?.hub?.name,
      sortable: true,
      sortField: "hub.name",
    },
    {
      name: t("shiftStatus"),
      sortable: true,
      sortField: "shiftStatusCode",
      cell: (row) => {
        const isOffline = checkShiftWorkingDay(row);
        return <div style={{ color: isOffline ? "white": "inherit" ,paddingTop: "11px", textAlign: "center", height: "40px", width: "100px", backgroundColor: isOffline ?  "red" : "inherit"}}>{ t(row?.shiftStatusCode).toString()}</div>
      } 
    },    
    {
      name: t("startDate"),
      selector: (row) =>  formatDate(row?.workingDays?.startTime.toString()) || '-',
      sortable: true,
      sortField: "shiftStatusCode",
    },
    {
      name: t("endDate"),
      selector: (row) => formatDate(row?.workingDays?.finishTime?.toString()) || '-',
      sortable: true,
      sortField: "shiftStatusCode",
    },
  ];

  const { data: provincesData, isLoading: provincesIsLoading } = Provinces();
  const {
    data: districtsData,
    isLoading: districtsIsLoading,
    refetch: getDistrict,
  } = DistrictByProvince(selectedProvinceId);

  const {
    mutate: getHubByDistrict,
    data: hubData,
  } = HubByDistrictId();

  const { data: hubDataByHubManager } = Hubs();
  const {
    store: { session: { roles } },
  } = usePortia();

  const hubManagerRole = roles?.find((role: string) => role?.includes("portia-hub-manager") === true)
  useEffect(() => {
    if (hubDataByHubManager) {
      hubManagerRole && setHubList(hubDataByHubManager?.data?.items)
    }
    if (hubData) {
      !hubManagerRole && setHubList(hubData?.data)
    }
  }, [hubDataByHubManager, hubData])

  const {
    data: dashboardData,
    isLoading: dashboardLoading,
    refetch,
  } = DashboardData(currentPage, perPage, search, hubs, status, selectedDate ? selectedDate?.unix()*1000 : dayjs(new Date()).unix()*1000);

  let { couriers, items, graphicData }: any = dashboardData?.data || {};

  useEffect(() => {
    if (selectedProvinceId) getDistrict();
  }, [selectedProvinceId]);

  const refresh = debounce(() => {
    refetch();
  }, 50);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refresh();
  };
  
  const handleSearchChange = (searchKey: string) => {
    setSearch(searchKey);
    (searchKey.length === 0 || searchKey.length > 2) && searchRefresh();
  };
  const searchRefresh = debounce(() => {
    refetch();
  }, 500);
  const faz2Role = roles?.find((role: string) => role?.includes("portia-faz-2") === true)
  const translatedData: any[] = [];

  if (graphicData) {
    graphicData.map((pt: any) => {
      if (!faz2Role) {
        if (pt.label === "AVAILABLE" || pt.label === "OFFLINE") {
          translatedData.push({
            id: t(pt.id),
            label: pt.label,
            value: pt.value,
          })
        }
      } else {
        translatedData.push({
          id: t(pt.id),
          label: pt.label,
          value: pt.value,
        })
      }
    }
    );
  }
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
    refresh();
  };

  const handleStatusChange = useCallback(
    (statusValue: any) => {
      setStatus(statusValue);
      refresh();
    },
    [refresh]
  );
  const changeDistrict = (event: any) => {
    const value = event.target.value;
    setDistricts(value);
    getHubByDistrict({ provinceId: selectedProvinceId, districts: value });
  };
  const changeHub = (event: any) => {
    const value = event.target.value;
    setHubs(value);
    refresh();
  };
  const createExcelFromData = (
    data: any[],
    headerRow: string[],
    sheetName: string,
    newSheetData: any[],
    newSheetHeaderRow: string[],
    newSheetname: string,
  ) => {
    const workbook = XLSX.utils.book_new();
  
    const mappedData = data.map(item => {
      const row: any[] = [];
      for (const key of headerRow) {
        row.push(item[key] || '');
      }
      return row;
    });
  
    const worksheetData: any[] = [headerRow, ...mappedData];
  
    const newMappedData = newSheetData.map(item => {
      const row: any[] = [];
      for (const key of newSheetHeaderRow) {
        row.push(item[key] || '');
      }
      return row;
    });
  
    const newWorksheetData: any[] = [newSheetHeaderRow, ...newMappedData];
  
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  
    const newWorksheet = XLSX.utils.aoa_to_sheet(newWorksheetData);
    XLSX.utils.book_append_sheet(workbook, newWorksheet, newSheetname);
  
    XLSX.writeFile(workbook, 'Dashboard_data.xlsx');
  };

const handleExcelButton = () => {
  if (couriers?.items.length !== 0) {
    const excelCourierData = couriers?.items?.map((courier: Courier) => {
      return {
        'Excel’in oluşturulma tarihi': general.formatDateForDisplay(Date.now()),
        'Kurye Adı Soyadı': courier?.name,
        'Kurye Telefon': courier?.mobile,
        'Kurye Bölge': courier?.hub?.name,
        'Kurye Vardiya Durumu': t(`${courier?.shiftStatusCode}`) ,
        'Başlangıç Tarihi': formatDate(courier?.workingDays?.startTime.toString()) ?? '-',
        'Bitiş Tarihi': formatDate(courier?.workingDays?.finishTime.toString()) ?? '-',
      };
    });
    
    const CourierHeaderRow = [
      'Excel’in oluşturulma tarihi',
      'Kurye Adı Soyadı',
      'Kurye Telefon',
      'Kurye Bölge',
      'Kurye Vardiya Durumu',
      'Başlangıç Tarihi',
      'Bitiş Tarihi'
    ];
     const brandHeaderRow= [
             'Excel’in oluşturulma tarihi',
             'KFC - Toplam Sipariş',
             'KFC - Atanmamış Sipariş',
             'KFC - Verimlilik',
             'Pizza Hut - Toplam Sipariş',
            'Pizza Hut - Atanmamış Sipariş',
            'Pizza Hut - Verimlilik',
            'Krispy Kreme - Toplam Sipariş',
            'Krispy Kreme - Atanmamış Sipariş',
            'Krispy Kreme - Verimlilik',
  ];
  const excelBrandData = [ {
     'Excel’in oluşturulma tarihi': general.formatDateForDisplay(Date.now()),
    'KFC - Toplam Sipariş': items?.efficiency?.totalComplateOrdersKrispyKreme,
    'KFC - Atanmamış Sipariş': items?.efficiency?.totalComplateOrdersKrispyKreme,
    'KFC - Verimlilik': items?.efficiency?.totalComplateOrdersKrispyKreme,
    'Pizza Hut - Toplam Sipariş': items?.efficiency?.totalComplateOrdersKrispyKreme,
    'Pizza Hut - Atanmamış Sipariş': items?.efficiency?.totalComplateOrdersKrispyKreme,
    'Pizza Hut - Verimlilik': items?.efficiency?.totalComplateOrdersKrispyKreme,
    'Krispy Kreme - Toplam Sipariş': items?.efficiency?.totalComplateOrdersKrispyKreme,
    'Krispy Kreme - Atanmamış Sipariş': items?.efficiency?.totalComplateOrdersKrispyKreme,
    'Krispy Kreme - Verimlilik': items?.efficiency?.totalComplateOrdersKrispyKreme,
  }];
    createExcelFromData(excelCourierData, CourierHeaderRow, 'Kuryeler', excelBrandData, brandHeaderRow, 'Markalar');
    dropdownAlert.current.alertWithType({ type: 'success', title: "OK", message: t('excelSuccess') });
  } else {
    dropdownAlert.current.alertWithType({ type: 'error', title: "Error", message: t('excelError') });
  }
};
  const changeSelectedDate = (value: any) => {
    setSelectedDate(value);
    refresh();
  }

  return (
    <Box sx={{ "& > :not(style)": { m: 6 } }}>
      <Grid container spacing={4}>
        <Grid sx={{ marginRight: 3 }}>
          <InputLabel id="select-status-label">
            {t("courierStatus")}
          </InputLabel>
          <Select
            variant="outlined"
            labelId="select-status-label"
            sx={{ width: 300 }}
            value={status}
            onChange={(e) => handleStatusChange(e.target.value)}
            label={t(status)}
          >
            {shiftStateOptions.map((val) => (
              <MenuItem key={val.id} value={val.id}>
                {val.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Authz allowedRoles={["portia-admin"]}>
          <Grid sx={{ marginRight: 3 }}>
            <InputLabel id="select-status-label">
              {t("province")}
            </InputLabel>
            <Select
              variant="outlined"
              labelId="select-status-label"
              sx={{ width: 300 }}
              value={selectedProvinceId}
              onChange={(e) => setSelectedProvinceId(e.target.value)}
              label={t("province")}
            >
              {provincesData?.data?.items.map((val: any) => (
                <MenuItem key={val._id} value={val.provinceId}>
                  {val.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid sx={{ marginRight: 3 }}>
            <InputLabel id="test-select-label">{t('district')}</InputLabel>
            <Select
              sx={{ width: 300 }}
              labelId="test-select-label"
              label={t('district')}
              multiple
              value={districts ?? {}}
              onChange={changeDistrict}
              renderValue={selected => {
                let names = [] as any;
                districtsData?.data?.items.map((option: any) => selected?.map((districtId: any) => (option.districtId === districtId) && names.push(option.name)))
                return names.toString();
              }}
            >
              {districtsData?.data?.items?.map((option: any) => (
                <MenuItem key={option.districtId} value={option.districtId}  >
                  <ListItemIcon>
                    <Checkbox checked={districts.indexOf(option.districtId) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Authz>
        <Grid>
          <InputLabel id="test-select-label">{t('hub')}</InputLabel>
          <Select
            sx={{ width: 300 }}
            labelId="test-select-label"
            label={t('hub')}
            multiple
            value={hubs ?? {}}
            onChange={changeHub}
            renderValue={selected => {
              let names = [] as any;
              hubList?.map((option: any) => selected?.map((hubId: any) => (option.hubId === hubId) && names.push(option.name)))
              return names.toString();
            }}
          >
            {hubList?.map((option: any) => (
              <MenuItem key={option.hubId} value={option.hubId}  >
                <ListItemIcon>
                  <Checkbox checked={hubs.indexOf(option.hubId) > -1} />
                </ListItemIcon>
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
         <Grid sx={{ marginLeft: 8 , marginTop: 3.5}}>
              <Button onClick={handleExcelButton} sx={{ border: '1px solid #046746' }}>{t('exportToExcel')}</Button>
            </Grid>
        <Grid sx={{marginLeft: 3, marginTop: 3}}>
              <LocalizationProvider locale={t("dateTimeLocale").toString()} dateAdapter={AdapterDayjs}>
              <DatePickerMui
                  renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                  label={t('date')}
                  value={selectedDate ? dayjs(selectedDate) : null}
                  inputFormat="DD-MM-YYYY"
                  onChange={changeSelectedDate}
              disableFuture={true}
            />
              </LocalizationProvider>
            </Grid>

      </Grid>

      {
        graphicData && items && (
          <Grid >
            <Grid
              container
              spacing={2}
              border={1}
            >
              <Grid item xs={6} md={8}>
                <Grid sx={{ margin: 20 }}>
                  <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                    {t("totalCourierCount")}
                    {"=" + items?.totalCourierCount}{" "}
                  </InputLabel>
                  <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                    {t("loginCourierCount")}
                    {"=" + items?.loginCourierCount}{" "}
                  </InputLabel>
                  <Authz allowedRoles={["portia-faz-2"]}>
                    <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                      {t("offlineCourierCount")}
                      {"=" + items?.offlineCourierCount}{" "}
                    </InputLabel>
                    <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                      {t("breakCourierCount")}
                      {"=" + items?.breakCourierCount}{" "}
                    </InputLabel>
                    <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                      {t("efficiency")}
                      {items?.efficiency?.efficiencyAll && "=" + '%' + items?.efficiency?.efficiencyAll}{" "}
                    </InputLabel>
                  </Authz>
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid style={pieBox}>
                  <ResponsivePie
                    data={translatedData}
                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                    valueFormat={function (e) {
                      return "%" + e;
                    }}
                    innerRadius={0.5}
                    colors={[
                      "lightslategray",
                      "darkseagreen",
                      "cornflowerblue",
                      "salmon",
                      "plum",
                      "blanchedalmond",
                      "limegreen",
                    ]}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                  />
                </Grid>
              </Grid>
              <Authz allowedRoles={["portia-faz-2"]}>
                <Grid item xs={6} md={8} container spacing={8} sx={{ marginLeft: 10, marginBottom: 5 }}>
                  <Grid item sx={{ mt: 4 }}>
                    <img
                      src="/kfc.jpg"
                      alt={t("☆Portia Backoffice☆") as string}
                      height="40"
                    />
                    <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                      {t("totalComplateOrders")}{" "}
                      {items?.efficiency?.totalComplateOrdersKFC && " = "+ items?.efficiency?.totalComplateOrdersKFC}
                    </InputLabel>
                    <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                      {t("approvedCourierOrder")}{" "}
                      {items?.efficiency?.totalApprovedOrdersKFC && " = " + items?.efficiency?.totalApprovedOrdersKFC}
                    </InputLabel>
                    <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                      {t("efficiency")}{" "}
                      {items?.efficiency?.efficiencyKFC && " = " + '%'  + items?.efficiency?.efficiencyKFC}
                    </InputLabel>
                  </Grid>
                  <Grid item sx={{ mt: 4 }}>
                    {pizzaHutLogo && (
                      <img
                        src={pizzaHutLogo}
                        alt="Pizza Hut Logo"
                        height="40"
                      />
                    )}
                    <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                      {t("totalComplateOrders")}{" "}
                      {items?.efficiency?.totalComplateOrdersPizzaHut && " = " + items?.efficiency?.totalComplateOrdersPizzaHut}
                    </InputLabel>
                    <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                      {t("approvedCourierOrder")}{" "}
                      {items?.efficiency?.totalApprovedOrdersPizzaHut && " = "+ items?.efficiency?.totalApprovedOrdersPizzaHut}
                    </InputLabel>
                    <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                      {t("efficiency")}{" "}
                      {items?.efficiency?.efficiencyPizzaHut && " = " + '%'  + items?.efficiency?.efficiencyPizzaHut}
                    </InputLabel>
                  </Grid>
                  <Grid item sx={{ mt: 4 }}>
                    <img
                      src="/krispyKreme.jpg"
                      alt={t("☆Portia Backoffice☆") as string}
                      height="40"
                    />
                    <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                      {t("totalComplateOrders")}{" "}
                      {items?.efficiency?.totalComplateOrdersKrispyKreme && " = "+ items?.efficiency?.totalComplateOrdersKrispyKreme}
                    </InputLabel>
                    <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                      {t("approvedCourierOrder")}{" "}
                      {items?.efficiency?.totalApprovedOrdersKrispyKreme && " = " + items?.efficiency?.totalApprovedOrdersKrispyKreme}
                    </InputLabel>
                    <InputLabel sx={{ fontWeight: 600, mt: 1 }}>
                      {t("efficiency")}{" "}
                      {items?.efficiency?.efficiencyKrispyKreme && " = " + '%' + items?.efficiency?.efficiencyKrispyKreme}
                    </InputLabel>
                  </Grid>
                </Grid>
              </Authz>
            </Grid>
          </Grid>
        )
      }
      {couriers && (
        <PortiaTable
          loading={dashboardLoading}
          columns={columns}
          data={couriers}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          onSearchChange={handleSearchChange}
          serverSideSearch
        />
      )}
    </Box>
  );
};
const pieBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 500,
};

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const selectStyle = {
  height: "30px",
  marginBottom: 15,
};
