import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Fab, Tab, Tabs, Modal, TextField, Button, Grid, InputLabel, Select, MenuItem, Switch, IconButton } from "@mui/material";
import { OrdersAdminList } from "./orders.list.admin";
import { AddCircleOutlined, Delete } from "@mui/icons-material";
import {  RestaurantsDropdown } from "../../api/restaurant";
import { OrderDetails, OrderPayment} from "../../api/types";
import { GetBrandList } from "../../api/brands";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { PaymentTypeListAll } from "../../api/paymentType";
import { CreateOrderMutation } from "../../api/orders";
import { usePortia } from "../../context";
import { MOBILEPATTERN } from "../../components/form/patterns";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const Orders = () => {
  const { dropdownAlert } = usePortia();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    firstName: "",
    lastName: "",
    phone: "",
    refRestaurantId: "",
    refOrderId: "",
    address: "",
    directions: "",
    lat: 0,
    lng: 0,
    total: 0,
    customerNote: "",
    orderSummary: "",
    orderSource: "",
    brand: "",
    refSystem: "CORS",
    isPrepaid: false,
    payments: [],
  });

  const tabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleFabClick = () => {
    setModalOpen(true);
  };
  const resetOrderDetails = () => {
    setOrderDetails({
      firstName: "",
      lastName: "",
      phone: "",
      refOrderId: "",
      refRestaurantId: "",
      address: "",
      directions: "",
      lat: 0,
      lng: 0,
      total: 0,
      customerNote: "",
      orderSummary: "",
      orderSource: "",
      brand: "",
      refSystem: "CORS",
      isPrepaid: false,
      payments: [],
    });
  };
  
  const handleCloseModal = () => {
    setModalOpen(false);
    resetOrderDetails();
  };
  function generateRandomOrderId() {
    return Math.floor(Math.random() * 10000000000000).toString();
  }
  
  const handleSaveOrder = async () => {
    const requiredFields: (keyof OrderDetails)[] = [
      'firstName',
      'lastName',
      'address',
      'lat',
      'lng',
      'total',
      'customerNote',
      'orderSummary',
      'orderSource',
      'brand',
    ];
  
    const isAnyFieldEmpty = requiredFields.some(field => !orderDetails[field]);
  
    if (isAnyFieldEmpty) {
      dropdownAlert.current.alertWithType({ type: 'error', title: "ERROR", message: t('FIELD_MISSING') });
      return;
    }
  
   
    const randomUUID = generateRandomOrderId();
    setModalOpen(false);
    const updatedPayments = orderDetails.payments.map(payment => ({
      ...payment,
      amount: Number(payment.amount), 
    }));
    const totalAmount = Number(orderDetails.total) * 100;
    
    setOrderDetails(prevDetails => ({
      ...prevDetails,
      refOrderId: randomUUID,
      total: totalAmount,
      payments: updatedPayments.map(payment => ({
        ...payment,
        amount: Number(payment.amount), 
      })),
    }));
  
    try {
      
      const newOrder = {
        ...orderDetails,
        total: totalAmount,
        refOrderId: randomUUID,
        payments: updatedPayments.map(payment => ({
          ...payment,
          amount: Number(payment.amount) * 100, 
        })),
      };

      await createOrder(newOrder);
    } catch (error) {
      console.error("An error occurred while creating the order: ", error);
      
    } finally {
      
      setModalOpen(false);
      
      resetOrderDetails();
    }
  };
  
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;    
    setOrderDetails((prevDetails) => ({
      ...prevDetails,
      [name]: name === 'lat' || name === 'lng' ? parseFloat(value) : value,
    }));
  };
  
  const handleAddPayment = () => {
    setOrderDetails((prevDetails) => ({
      ...prevDetails,
      payments: [
        ...prevDetails.payments,
        {
          type: '',
          amount: 0,
        }
      ]
    }));
  };
  
  const handleRemovePayment = (index: number) => {
    setOrderDetails((prevDetails) => {
      const updatedPayments = [...prevDetails.payments];
      updatedPayments.splice(index, 1);
      return { ...prevDetails, payments: updatedPayments };
    });
  };
  const handlePaymentChange = (index: number, key: keyof OrderPayment, value: string) => {
    const updatedPayments = [...orderDetails.payments];
    if (key === 'type') {
      updatedPayments[index].type = value;
      
    }else if (key === 'amount') {
      updatedPayments[index].amount = Number(value);
    }
      setOrderDetails((prevDetails) => ({
        ...prevDetails,
        payments: updatedPayments
      }));
  };


  const { data: brandData} = GetBrandList(
    1,
    1000,
    "",
    "",
    "",
  );
  const { data } = PaymentTypeListAll();
  const { data: paymentTypes }: any = data || {};
  
  const { data: brands }: any = brandData || {};

  const { data: restaurants } = RestaurantsDropdown();
  const { mutate: createOrder } =
  CreateOrderMutation();
  useEffect(() => {
  }, [])
  
  return (
    <Box sx={{ position: "relative" }}>
      <Tabs value={tab} onChange={tabChange}>
        <Tab label={t("tabActiveOrders")} />
        <Tab label={t("tabPastOrders")} />
      </Tabs>
      <Box>
        <TabPanel value={tab} index={0}>
          <Box sx={{ m: 2 }}>
            <OrdersAdminList
              dynamicStatus={[
                "RECEIVED",
                "APPROVED",
                "ASSIGNED",
                "PICKED",
                "IN_DELIVERY",
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Box sx={{ m: 2 }}>
            <OrdersAdminList
              dynamicStatus={[
                "REJECTED",
                "ASSIGNMENT_FAILED",
                "COMPLETED",
                "CANCELED",
              ]}
            />
          </Box>
        </TabPanel>
      </Box>
      <Fab
        sx={{
          marginX: 9,
          position: "fixed",
          bottom: 16,
          left: 8,
          zIndex: 9999,
        }}
        color="primary"
        aria-label="add"
        onClick={handleFabClick}
      >
        <AddCircleOutlined color="inherit" />
      </Fab>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            maxWidth: 800,
            bgcolor: "background.paper",
            border: "2px solid #21494D",
            boxShadow: 24,
            p: 4,
            overflowY: "auto",
            maxHeight: "90vh",
          }}
        >
          <h3 id="modal-modal-title" style={{ marginTop: 0, color: "#01494D" }}>
            Sipariş Oluştur
          </h3>
          <FormContainer
            values={orderDetails}
            defaultValues={{}}
            onSuccess={(data) => handleSaveOrder()}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label={t('customerName')}
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  value={orderDetails.firstName}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={t('customerLastName')}
                  variant="outlined"
                  fullWidth
                  name="lastName"
                  value={orderDetails.lastName}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>
              <Grid item xs={4}>
              <TextFieldElement
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                      maxLength: 10,
                    }}
                    placeholder="5#########"
                    validation={{
                      required: t("required").toString(),
                      pattern: MOBILEPATTERN,
                    }}
                    name={"mobile"}
                    label={t("mobile")}
                    fullWidth
                    required
                  />
                </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t('address')}
                  variant="outlined"
                  fullWidth
                  name="address"
                  multiline
                  rows={3}
                  value={orderDetails.address}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t('direction')}
                  variant="outlined"
                  fullWidth
                  name="directions"
                  multiline
                  required
                  rows={3}
                  value={orderDetails.directions}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t('latitude')}
                  variant="outlined"
                  fullWidth
                  name="lat"
                  value={orderDetails.lat}
                  onChange={handleTextFieldChange}
                  type="number"
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t('longitude')}
                  variant="outlined"
                  fullWidth
                  name="lng"
                  value={orderDetails.lng}
                  onChange={handleTextFieldChange}
                  type="number"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={t('totalAmount')}
                  variant="outlined"
                  fullWidth
                  name="total"
                  value={orderDetails.total}
                  onChange={handleTextFieldChange}
                  type="number"
                  required
                />
              </Grid>
              <Grid item xs={4} marginTop={-2.2}>
                <InputLabel id="restaurant-label">
                  {t("restaurant")}
                </InputLabel>
                <Select
                  labelId="restaurant-label"
                  id="restaurant"
                  value={orderDetails.refRestaurantId}
                  onChange={(e) => {                     
                    setOrderDetails({
                    ...orderDetails,
                    refRestaurantId: e.target.value as string,
                  })}
                   
                  }
                  fullWidth
                  required
                >
                  {restaurants &&
                    restaurants?.data?.map((restaurant: any) => (
                      <MenuItem
                        key={restaurant.id}
                        value={restaurant.refRestaurantId}
                      >
                        {restaurant.name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t('customerNote')}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  name="customerNote"
                  value={orderDetails.customerNote}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t('orderSummary')}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  name="orderSummary"
                  value={orderDetails.orderSummary}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={t('orderSource')}
                  variant="outlined"
                  fullWidth
                  name="orderSource"
                  value={orderDetails.orderSource}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>
              <Grid item xs={4} marginTop={-2.3}>
                <InputLabel id="brand-label">
                  {t("brand")}
                </InputLabel>
                <Select
                  labelId="brand-label"
                  id="brand"
                  value={orderDetails.brand}
                  onChange={(e) =>
                    setOrderDetails({
                      ...orderDetails,
                      brand: e.target.value as string,
                    })
                  }
                  fullWidth
                  required
                >
                  {brands &&
                    brands?.items?.map((brand: any) => (
                      <MenuItem key={brand.id} value={brand.name}>
                        {brand.name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label= {t("systemReference")}
                  variant="outlined"
                  fullWidth
                  name="refSystem"
                  value={orderDetails.refSystem}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>{t("isPrepaid")}</InputLabel>
                <Switch
                  checked={orderDetails.isPrepaid}
                  onChange={(e) =>
                    setOrderDetails({
                      ...orderDetails,
                      isPrepaid: e.target.checked,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
      </Grid>
      <Grid item marginY={2}>
      <Button onClick={handleAddPayment}>{t("addPayment")}</Button>

      </Grid>
            {orderDetails.payments.map((payment, index) => (
              <Grid container spacing={3} key={index}>
            <Grid item xs={5} marginBottom={2}>
          <InputLabel id={`payment-type-label-${index}`}>{t("paymentType")}</InputLabel>
          <Select
            labelId={`payment-type-label-${index}`}
            id={`payment-type-${index}`}
            value={payment.type}
            onChange={(e) => handlePaymentChange(index, 'type', e.target.value)}
            fullWidth
            required
          >
            {paymentTypes && paymentTypes.map((paymentType: any, typeIndex: number) => (
              <MenuItem key={typeIndex} value={paymentType.type}>
                {t(`${paymentType.type}`)}
              </MenuItem>
            ))}
          </Select>
      </Grid>

          <Grid item xs={5} marginTop={2.6}>
            <TextField
              label={t("paymentAmount")}
              variant="outlined"
              fullWidth
              type="number"
              required
              value={payment.amount}
              onChange={(e) => handlePaymentChange(index, 'amount', e.target.value)}
            />
          </Grid>
          <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={() => handleRemovePayment(index)} style={{color: "red"}}>
                    <Delete />
                  </IconButton>
                </Grid>

        </Grid>
      ))}
            <Grid item marginTop={3}>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                type={"submit"}
                color={"primary"}
              >
                {t("create")}
              </Button>
              <Button
                variant="outlined"
                color={"secondary"}
                onClick={() => handleCloseModal()}
              >
                {t("cancel")}
              </Button>
            </Grid>
          </FormContainer>
        </Box>
      </Modal>
    </Box>
  );
};
