import React from 'react';
import { StackStype, usePortia } from '../context';
import AppStack from './AppStack';
import { BrowserRouter } from "react-router-dom";

import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#00494d',
      contrastText: '#fff9c4',
    },
    secondary: {
      main: '#f50057',
    },
  },
};

const theme = createTheme(themeOptions);

const loading = false;
export const Router = () => {
  const {
    store: { stack },
  } = usePortia();



  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {stack === StackStype.App && <AppStack />}
        {stack === StackStype.Auth && <div style={{ textAlign: "center", position: "relative", top: "300px" }}><img alt="logo" src="/logo512.png"></img></div>}
      </BrowserRouter>
    </ThemeProvider>

  );
};
