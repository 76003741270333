import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import { LocationPicker } from '../components/PortiaMap';
import { useTranslation } from 'react-i18next';
import { GetCompany, GetCompanyList } from '../api/companies';

const Home = () => {
   const { t } = useTranslation();
   const [position, setPosition] = useState<google.maps.LatLngLiteral>({ lat: 41.10619322476262, lng: 29.096092016594394});
   
   const { refetch: getCompany, data: companyData } = GetCompany('-'); 
   // IGFAZ-120 deki gelistirmelerden sonra '-' degisecek simdilik companyId 
   // apideki AuthenticatedUser'dan geliyor

       useEffect(() => {
      if (companyData && companyData.data && companyData.data.location) {
        const companyLocation = companyData.data.location;
       setPosition({lat: companyLocation?.lat, lng: companyLocation?.long})
      }
    }, [companyData])
   
  return (
    <Container>
      <h1 className="text-green-800 text-4xl">{t('homeMesage')}</h1>
      <LocationPicker position={position} title="" onSave={(p)=>{console.log(p); setPosition(p)}} /> 
      
    </Container>
  );
};

export default Home;