import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import {
  AutocompleteElement,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import { CourierType, CourierTypes } from "../../api/types";
import { usePortia } from "../../context";
import { useTranslation } from "react-i18next";
import {
  CreateCourierTypes,
  GetCourierTypeDetail,
  PatchCourierTypes,
} from "../../api/courierType";
import { isEmpty } from "lodash-es";

export const CourierTypeDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const {
    dropdownAlert,
  } = usePortia();
  const [courierTypeDataState, setCourierTypeData] = useState<CourierTypes>();
  const [selectType, setTypeSelect] = useState<any>({});
  const courierTypeId: any = params && params["courierTypeId"];

  const {
    refetch: getCourierTypeDetailRefetch,
    data: courierTypeData,
    isLoading: isPosDeviceLoading,
  } = GetCourierTypeDetail(courierTypeId);
  const {
    mutate: create,
    data: cResult,
    error: cError,
  } = CreateCourierTypes();
  const {
    mutate: update,
    data: pResult,
    error: pError,
  } = PatchCourierTypes();

  const isNewPos = courierTypeId == "new";
  useEffect(() => {
    console.log("refresh", courierTypeId, isNewPos);
    if (courierTypeId && courierTypeId !== "new") {
      getCourierTypeDetailRefetch();
    }
  }, [params, courierTypeId]);

  useEffect(() => {
    setCourierTypeData(courierTypeData?.data);
  }, [courierTypeData]);

  useEffect(() => {}, [isPosDeviceLoading]);

  useEffect(() => {
    if (
      (cResult && cResult.status === 201) ||
      (pResult && pResult.status === 200)
    ) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
      navigate(-1);
    }
  }, [cResult, pResult]);

  useEffect(() => {
    if (
      (cError && cError?.response?.status === 404) ||
      (pError && pError?.response?.status === 404)
    ) {
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "ERROR",
        message:
          t(pError?.response?.data?.message) ||
          t(cError?.response?.data?.message),
      });
    }
  }, [cError, pError]);

  const saveCourierType = (courierTypeData: CourierTypes) => {
    let courierTypeDataNew;
    if (!isEmpty(selectType?.id)){
      courierTypeDataNew = {
        ...courierTypeData,
        courierType: selectType?.id,
        courierTypeName: selectType?.label,
      };

    }
    else if (isEmpty(selectType?.id)){
      courierTypeDataNew = {
        ...courierTypeData,
        courierTypeName: selectType?.label,
      };
    }

    if (isNewPos) {
      create(courierTypeDataNew);
    } else {
      update(courierTypeDataNew);
    }
  };

  const typeOptions = [
    { id: CourierType.POOL, label: "POOL" },
    { id: CourierType.DEDICATED, label: "DEDICATED" },
    { id: CourierType.STABLE, label: "STABLE" },
    { id: CourierType.PAYROLL, label: "PAYROLL" },
    { id: CourierType.JOKER, label: "JOKER" },
  ];

  let selectedTypeValueDefault = typeOptions.filter(
    (x) => x.id === courierTypeDataState?.courierType
  )[0]?.label;

  return (
    <Box sx={{ m: 2 }}>
      <FormContainer
        values={courierTypeDataState}
        onSuccess={(data) => saveCourierType(data)}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {isNewPos ? (
              <AutocompleteElement
                label={t("type")}
                name="courierType"
                matchId
                required
                textFieldProps={{
                  inputProps: {
                    form: {
                      autocomplete: "off",
                    },
                  },
                }}
                options={typeOptions}
                rules={{ required: t("typeRequiredMessage").toString() }}
                autocompleteProps={{
                  disabled: !isNewPos,
                  onChange: (e, value) => {
                    setTypeSelect(value);
                  },
                  getOptionLabel: (option) => t(option.label),
                }}
              />
            ) : (
              <TextField
                fullWidth
                id="outlined-required"
                label={t(selectedTypeValueDefault)}
                disabled
              />
            )}
          </Grid>
          {[
            CourierType.DEDICATED,
            CourierType.JOKER,
          ].includes(selectType?.id || courierTypeDataState?.courierType) && (
            <Grid item xs={4}>
              <TextFieldElement
                validation={{ required: t("required").toString() }}
                name={"amountPerHour"}
                label={t("amountPerHour")}
                fullWidth
                type={"number"}
              />
            </Grid>
          )}

          {[
            CourierType.POOL,
            CourierType.DEDICATED,
            CourierType.STABLE,
            CourierType.PAYROLL,
            CourierType.JOKER,
          ].includes(selectType?.id || courierTypeDataState?.courierType) && (
            <Grid item xs={4}>
              <TextFieldElement
                validation={{ required: t("required").toString() }}
                name={"amountPerPack"}
                label={t("amountPerPack")}
                fullWidth
                type={"number"}
              />
            </Grid>
          )}

          {[CourierType.PAYROLL].includes(
            selectType?.id || courierTypeDataState?.courierType
          ) && (
            <Grid item xs={4}>
              <TextFieldElement
                validation={{ required: t("required").toString() }}
                name={"fixedAmount"}
                label={t("fixedAmount")}
                fullWidth
                type={"number"}
              />
            </Grid>
          )}

          {[CourierType.STABLE].includes(
            selectType?.id || courierTypeDataState?.courierType
          ) && (
            <>
              <Grid item xs={4}>
                <TextFieldElement
                  validation={{ required: t("required").toString() }}
                  name={"minAmount"}
                  label={t("contractMinAmount")}
                  fullWidth
                  type={"number"}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldElement
                  validation={{ required: t("required").toString() }}
                  name={"minPackage"}
                  label={t("contractMinPackage")}
                  fullWidth
                  type={"number"}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              type={"submit"}
              color={"primary"}
            >
              {t("save")}
            </Button>
            <Button
              variant="outlined"
              color={"secondary"}
              onClick={() => navigate(-1)}
            >
              {t("cancel")}
            </Button>
          </Grid>
        </Grid>
      </FormContainer>
    </Box>
  );
};
