import React, { useEffect, useState } from 'react';
import { Button, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Hubs } from '../../api/hubs';
import { PatchPosInfo, Restaurants } from '../../api/restaurant';
import { PaymentTypeList } from '../../api/paymentType';
import { usePortia } from "../../context";

export const PosInfo = () => {
    const { t } = useTranslation();
    const { dropdownAlert } = usePortia();
    const patchPosInfo = PatchPosInfo();
    const { data: hubsData } = Hubs();
    const hubs = (hubsData && hubsData?.data?.items) || [];
    const [hubId, setHub] = useState('');
    const { data: restaurantData, isLoading, isError, refetch } = Restaurants(
        1,
        100,
        '',
        hubId,
        '_id',
        'desc',
    );
    const { refetch: getPaymentType, data: paymentTypeData } = PaymentTypeList(
        1,
        100,
    );

    const { data: paymentTypeList }: any = paymentTypeData || {};
    const [restaurants, setRestaurants] = useState<any[]>([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState('');
    const [selectedPaymentType, setSelectedPaymentType] = useState('');
    const [merchantId, setMerchantId] = useState('');
    const [terminalId, setTerminalId] = useState('');
    const [paymentInfo, setPaymentInfo] = useState({
        paymentType: '',
        merchantId: '',
        terminalId: '',
    });
    const isSaveButtonDisabled = !hubId || !selectedRestaurant || !selectedPaymentType || !merchantId || !terminalId;

    const changeHub = (event: any) => {
        const selectedHubId = event.target.value;
        setHub(selectedHubId);
    };

    const changeRestaurant = (event: any) => {
        const selectedRestaurantId = event.target.value;
        setSelectedRestaurant(selectedRestaurantId);
    };

    const changePaymentType = (event: any) => {
        const selectedPaymentTypeId = event.target.value;
        setSelectedPaymentType(selectedPaymentTypeId);
    };
    
    const handleSave = async () => {
        const restaurant = restaurants.find((restaurant: any) => restaurant.restaurantId === selectedRestaurant);
        const paymentType =  paymentTypeList?.items.find((paymentType: any) => paymentType.paymentId === selectedPaymentType);
        const consolidatedInfo = {
            paymentType: paymentTypeList?.items.find((paymentType: any) => paymentType.paymentId === selectedPaymentType) || '',
            merchantId,
            terminalId,
        };
        setPaymentInfo(consolidatedInfo);
        try {
            const response = await patchPosInfo.mutateAsync({ id: restaurant?.restaurantId, posInfo: consolidatedInfo, paymentType:  paymentType.type });
            if (response.success === true) {
                dropdownAlert.current.alertWithType({ type: 'success', title: "OK", message: t('successfulUpdatePosInfo') });
            }
        } catch (error) {
            dropdownAlert.current.alertWithType({ type: 'error', title: "ERROR", message: t('unSuccessfulUpdatePosInfo') });
        }
    };

    useEffect(() => {
        refetch();
    }, [hubId, refetch]);

    useEffect(() => {
        if (restaurantData && restaurantData?.data?.items) {
            setRestaurants(restaurantData.data.items);
        }
    }, [restaurantData]);

    useEffect(() => {
        getPaymentType();
    }, [getPaymentType]);

    const fetchPosInfoDetails = async () => {
        try {
            const restaurant = restaurants.find((restaurant: any) => restaurant.restaurantId === selectedRestaurant);
            const paymentType = paymentTypeList?.items.find((pt: any) => pt.paymentId === selectedPaymentType);
            const posInfo = restaurant?.posInfo.find((info: any) => info.paymentType.id === paymentType?.id);

            if (posInfo) {
                setMerchantId(posInfo.merchantId);
                setTerminalId(posInfo.terminalId);
            } else {
                setMerchantId('');
                setTerminalId('');
            }
        } catch (error) {
            console.error("Error fetching posInfo details:", error);
        }
    };

    useEffect(() => {
        fetchPosInfoDetails();
    }, [hubId, selectedRestaurant, selectedPaymentType]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={3} sx={{ marginRight: 3 }}>
                <InputLabel id="hub-select-label">{t('hub')}</InputLabel>
                <Select
                    sx={{ width: '100%' }}
                    labelId="hub-select-label"
                    label={t('hub')}
                    value={hubId}
                    onChange={changeHub}
                    renderValue={(selected) => {
                        const selectedHub = hubs.find((hub: any) => hub.hubId === selected);
                        return selectedHub ? selectedHub.name : '';
                    }}
                >
                    {hubs.map((option: any) => (
                        <MenuItem key={option.hubId} value={option.hubId}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={3} sx={{ marginRight: 3 }}>
                <InputLabel id="restaurant-select-label">{t('restaurant')}</InputLabel>
                <Select
                    onChange={changeRestaurant}
                    name={'restaurantId'}
                    sx={{ width: '100%' }}
                    labelId="restaurant-select-label"
                    label={t('restaurant')}
                    value={selectedRestaurant}
                    renderValue={(selected) => {
                        const selectedRestaurantItem = restaurants.find((restaurant: any) => restaurant.restaurantId === selected);
                        return selectedRestaurantItem ? selectedRestaurantItem.name : '';
                    }}
                >
                    {restaurants.map((option: any) => (
                        <MenuItem key={option?.restaurantId} value={option?.restaurantId}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={2} sx={{ marginRight: 3 }}>
                <InputLabel id="payment-type-select-label">{t('paymentType')}</InputLabel>
                <Select
                    onChange={changePaymentType}
                    name={'paymentTypeId'}
                    sx={{ width: '100%' }}
                    labelId="payment-type-select-label"
                    label={t('paymentType')}
                    value={selectedPaymentType}
                    renderValue={(selected) => {
                        const selectedPaymentTypeItem = paymentTypeList?.items.find((paymentType: any) => paymentType.paymentId === selected);
                        return selectedPaymentTypeItem ? selectedPaymentTypeItem.type : '';
                    }}
                >
                    {paymentTypeList?.items.map((option: any) => (
                        <MenuItem key={option?.paymentId} value={option?.paymentId}>
                            {option.type}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item container xs={4} spacing={2} sx={{ marginRight: 3 }}>
                <Grid item xs={6}>
                    <TextField
                        label={t('merchantId')}
                        type="number"
                        required
                        value={merchantId}
                        onChange={(e) => setMerchantId(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={t('terminalId')}
                        type="number"
                        required
                        value={terminalId}
                        onChange={(e) => setTerminalId(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} sx={{ marginRight: 3 }}>
                <Button
                    sx={{ mt: 1 }}
                    size="medium"
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={handleSave}
                    disabled={isSaveButtonDisabled}
                >
                    {t('save')}
                </Button>
            </Grid>
        </Grid>
    );
};
