import { Avatar, Box, debounce } from "@mui/material"
import { useTranslation } from "react-i18next";
import { RowActions } from "../../components/PortiaTable/row.actions";
import { PortiaTable } from "../../components/PortiaTable";
import Authz from "../../components/Authz";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect} from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";
import { Brand } from "../../api/types";
import { GetBrandList } from "../../api/brands";

export const BrandsList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [search, setSearch] = useState<string>("");
    const [sortField, setSortField] = useState<string>("_id");
    const [sortDirection, setSortDirection] = useState("desc");

    const columns: TableColumn<Brand>[] = [
        {
            cell: (row) => (
              <Avatar src={`${row.logo}`} />
            ),
            width: "64px",
          },
        {
            name: t('brandId'),
            selector: (row) => row?.brandId,
            sortable: true,
        },
        {
            name: t('firstName'),
            selector: (row) => row?.name,
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <RowActions
                        onEdit={(navigate: NavigateFunction) => {
                            navigate(`/brands/${row.brandId}`, { state: row });
                        }}
                    />
                </>
            ),
            allowOverflow: true,
            button: true,
            width: "128px",
        },
    ];
  
    const { data, isLoading, isError, refetch } = GetBrandList(
        currentPage,
        perPage,
        search,
        sortField,
        sortDirection
    );
        
    const { data: brands }: any = data || {};
        
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        refresh();
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setCurrentPage(page);
        setPerPage(newPerPage);
        refresh();
    };

    const handleChange = (selectedRows: any) => {
        console.log("Selected Rows: ", selectedRows);
    };

    const handleSort = async (column: any, sortDirection: any) => {
        setSortField(column?.sortField === undefined ? "_id" : column?.sortField);
        setSortDirection(sortDirection);
        refresh();
    };

    const handleSearchChange = (searchKey: string) => {
        setSearch(searchKey);
        (searchKey.length == 0 || searchKey.length > 2) && searchRefresh();
    };

    const searchRefresh = debounce(() => {
        refetch();
    }, 500);

    const refresh = debounce(() => {
        refetch();
    }, 50);

    return (
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
         {
                brands && (
                    <PortiaTable
                        loading={!isError && isLoading}
                        columns={columns}
                        data={brands}
                        onSelect={handleChange}
                        onChangePage={handlePageChange}
                        onChangePerRows={handlePerRowsChange}
                        serverSideSearch
                        onSearchChange={handleSearchChange}
                        onSort={handleSort}
                    />
                )
            }
            <Authz allowedRoles={["portia-admin"]}>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => navigate(`/brands/new`, { state: undefined })}
                >
                    <AddIcon />
                </Fab>
            </Authz>
        </Box>
    )
}