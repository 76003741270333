import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import {
  DASHBOARD_DETAIL,
  GET_HUBS_DISTRICT,
  DASHBOARD_DATA,
} from "./paths";
import { useMutationWithDebounce } from "./debounce";

export function CouriersList(
  page: number,
  limit: number,
  status: any,
  brand: any,
  search: string
) {
  return useQuery(["couriers"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        DASHBOARD_DETAIL + `?page=${page}&limit=${limit}&search=${search}`,
        { status, brand }
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}

export function HubByDistrictId(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (data: any) => apiClient.post(GET_HUBS_DISTRICT, data),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["HUBS_DISTRICT"] });
    },
    debounceTime,
  });
}
export function DashboardData(
  page: number,
  limit: number,
  search: string,
  hubs: string,
  status: any,
  selectedDate: number
) {
  return useQuery(["DASHBOARD_DATA"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        DASHBOARD_DATA +
          `?page=${page}&limit=${limit}&search=${search}`,
          hubs && status && { hubs: hubs, status: status , selectedDate: selectedDate },
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}