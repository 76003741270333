import { Box, Button, Grid, InputLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  GetOrder,
  GetPayment,
  SendOrderEvent,
} from "../../api/orders";
import { Order, Payment } from "../../api/types";
import { usePortia } from "../../context";
import { useTranslation } from "react-i18next";
import { CurrencyInput } from "../../components/CurrencyInput";
import { MultinetRollbackWithSign } from "../../api/multinet";
import { isEmpty } from "lodash-es";
interface AlertConfiguration {
  type: string;
  title: string;
  message: string;
}
export const PaymentList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const { dropdownAlert } = usePortia();
  const [order, setOrder] = useState<Order>(location?.state);
  const [totalAmountNumber, setTotalAmount] = useState(0);
  const [remainder, setRemainder] = useState(0);
  const [refundAmounts, setRefundAmounts] = useState<Record<PaymentType, string>>({
    multinet: "",
    creditcard: "",
  });
  const [multinetRefundAmount, setMultinetRefundAmounts] = useState("");
  const [isActiveHandleRefundAll, setIsActiveHandleRefundAll] = useState(true);
  const [isActiveHandleRefundPartial, setIsActiveHandleRefundPartial] = useState(true);
  
  type PaymentType = "multinet" | "creditcard";
  const paymentDefault: { [key: string]: any } = {
    cash: "",
    creditcard: "",
    metropolcard: "",
    multinet: "",
    paycell: "",
    payecard: "",
    setcard: "",
    sodexo: "",
    ticket: "",
    gastropay: "",
  };
  const [payment, setPayment] = useState(paymentDefault);

  const orderId: any = params && params["orderId"];
  const isPrePaid: any = location && location.state.isPrePaid;

  const { refetch: getOrder, data: orderData } = GetOrder(orderId);
  const {
    mutate: getOrderPayment,
    isSuccess: isPaymentSuccess,
    error: getPaymentError,
    data: _data
  } = GetPayment(500);
  const { mutate: sendOrderEvent, data: eventData } = SendOrderEvent(500);
  const { mutate: multinetRollBackWithSign, data: rollBackData } =
  MultinetRollbackWithSign();

  const [isMultinetPayment, setIsMultinetPayment] = useState<boolean>(false);

  const handleDayEndRefund = async (amount: number) => {
    try {
      await multinetRollBackWithSign({ orderId: orderId, amount: amount, reason: 9, refRestaurantId: orderData?.data?.refRestaurantId});
    } catch (error) {
      console.error("Error occurred during rollback:", error);
      dropdownAlert.current.alertWithType({ type: 'error', title: "Error", message: t("multinetRollbackError")});
    }
  };
  const handleRefundAll = async (paymentType: PaymentType, amount: number) => {
    if (paymentType === "multinet") {
      try {
        await multinetRollBackWithSign({ orderId: orderId, amount: amount, reason: 2, refRestaurantId: orderData?.data?.refRestaurantId});
       
      } catch (error) {
        console.error("Error occurred during rollback:", error);
        dropdownAlert.current.alertWithType({ type: 'error', title: "Error", message: t("multinetRollbackError")});
      }
    }
  };

  
  const alertConfigurations: Record<number, AlertConfiguration> = {
    0: { type: 'success', title: 'OK', message: t('multinetRollbackSuccess') },
    65510: { type: 'error', title: 'Error', message: t('multinetRollbackError') },
    20201: { type: 'error', title: 'Error', message: rollBackData?.data?.ResultMessage || '' },
    65512: { type: 'error', title: 'Error', message: rollBackData?.data?.ResultMessage || '' },
    25617: { type: 'error', title: 'Error', message: rollBackData?.data?.ResultMessage || '' },
    65525: { type: 'error', title: 'Error', message: rollBackData?.data?.ResultMessage || '' },
    22179: { type: 'error', title: 'Error', message: rollBackData?.data?.ResultMessage + t("endOftheDayRefundErrorMessage") || '' },
  };
  useEffect(() => {
    const resultCode = rollBackData?.data?.ResultCode;
  
    if (resultCode in alertConfigurations) {
      dropdownAlert.current.alertWithType(alertConfigurations[resultCode]);
    }
    if (rollBackData?.data?.ResultCode === 22179) {
      setIsActiveHandleRefundAll(false);
    }else if(rollBackData?.data?.ResultCode === 65525){
      setIsActiveHandleRefundPartial(false);
      setIsActiveHandleRefundAll(false);
    }
    else{
      setIsActiveHandleRefundAll(true);
      setIsActiveHandleRefundPartial(true)
    }
  }, [rollBackData]);

  
  useEffect(() => {
    if (orderId) {
      getOrder();
    }
  }, [params, orderId]);

  const availablePayments = orderData?.data?.restaurant?.paymentTypes
    ? Object.entries(orderData?.data?.restaurant?.paymentTypes)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key)
    : [];

  useEffect(() => {
    setTotalAmount(orderData?.data.total / 100);
    if (isPrePaid === true) {


      const newPayment = {
        online: orderData?.data.total / 100
      }
      setPayment(newPayment);
      setOrder(orderData?.data);      
    }
    else {
      const newPayment = {
        cash: "",
        creditcard: "",
        metropolcard: "",
        multinet: "",
        paycell: "",
        payecard: "",
        setcard: "",
        sodexo: "",
        ticket: "",
        gastropay: "",
      };

      setRemainder(orderData?.data.total / 100);

      orderData?.data?.payments.map((item: Payment) => {
        if (item.type === "cash") {
          newPayment.cash = parseFloat(
            (Number(item.amount) / 100).toString()
          ).toFixed(2);
        }
        if (item.type === "creditcard") {
          newPayment.creditcard = parseFloat(
            (Number(item.amount) / 100).toString()
          ).toFixed(2);
        }
        if (item.type === "sodexo") {
          newPayment.sodexo = parseFloat(
            (Number(item.amount) / 100).toString()
          ).toFixed(2);
        }
        if (item.type === "metropolcard") {
          newPayment.metropolcard = parseFloat(
            (Number(item.amount) / 100).toString()
          ).toFixed(2);
        }
        if (item.type === "multinet") {
          const multinetAmount = parseFloat(
            (Number(item.amount) / 100).toString()
          ).toFixed(2);
          newPayment.multinet = multinetAmount ;
          setMultinetRefundAmounts(multinetAmount)
        }
        if (item.type === "paycell") {
          newPayment.paycell = parseFloat(
            (Number(item.amount) / 100).toString()
          ).toFixed(2);
        }
        if (item.type === "setcard") {
          newPayment.setcard = parseFloat(
            (Number(item.amount) / 100).toString()
          ).toFixed(2);
        }
        if (item.type === "ticket") {
          newPayment.ticket = parseFloat(
            (Number(item.amount) / 100).toString()
          ).toFixed(2);
        }
        if (item.type === "payecard") {
          newPayment.payecard = parseFloat(
            (Number(item.amount) / 100).toString()
          ).toFixed(2);
        }
        if (item.type === "gastropay") {
          newPayment.gastropay = parseFloat(
            (Number(item.amount) / 100).toString()
          ).toFixed(2);
        }
      });
      const calculatedRemainder =
        orderData?.data.total / 100 -
        parseFloat(newPayment?.creditcard) -
        parseFloat(newPayment?.ticket) -
        parseFloat(newPayment?.cash) -
        parseFloat(newPayment?.sodexo) -
        parseFloat(newPayment?.paycell) -
        parseFloat(newPayment?.setcard) -
        parseFloat(newPayment?.metropolcard) -
        parseFloat(newPayment?.payecard) -
        parseFloat(newPayment?.gastropay) -
        parseFloat(newPayment?.multinet);

      setRemainder(parseFloat(calculatedRemainder.toFixed(2)));
      setPayment(newPayment);
      setOrder(orderData?.data);
      if (!isEmpty(orderData)) {
        setIsMultinetPayment(orderData.data.payments.some((payment: Payment) => payment.type === "multinet"));
      }
    }
  }, [orderData]);

  useEffect(() => {
    if (isPaymentSuccess) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: "Ödeme işlemi başarılı",
      });
      navigate(-1);
    }
    if (getPaymentError) {
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "ERROR",
        message: "Ödeme işlemi başarısız",
      });
    }
  }, [isPaymentSuccess, getPaymentError]);

  useEffect(() => {
    if (eventData?.data?.isCompleted === true) {
      let payments: { type: string; amount: number; }[] = [];
      Object.keys(payment).map((key: string) => {
        payments.push({ type: key, amount: Math.round(parseFloat(payment[key]) * 100), })
      });
      const paymentInfo = {
        courierId: order?.assignedCourierId,
        payments: payments,
        order: order,
      };
      getOrderPayment(paymentInfo);
    }
    if(eventData?.data?.isCompleted === false) {
      dropdownAlert.current.alertWithType({
        type: 'error',
        title: 'ERROR',
        message: "Siparişi teslim almanız üzerinden 3 dakika geçmezse ödeme alamazsınız."
      });
    }

  }, [eventData]);

  function remainderCalcute() {
    const calculatedRemainder =
      orderData?.data.total / 100 -
      (payment?.creditcard !== "" ? parseFloat(payment?.creditcard) : 0) -
      (payment?.ticket !== "" ? parseFloat(payment?.ticket) : 0) -
      (payment?.cash !== "" ? parseFloat(payment?.cash) : 0) -
      (payment?.sodexo !== "" ? parseFloat(payment?.sodexo) : 0) -
      (payment?.paycell !== "" ? parseFloat(payment?.paycell) : 0) -
      (payment?.setcard !== "" ? parseFloat(payment?.setcard) : 0) -
      (payment?.metropolcard !== "" ? parseFloat(payment?.metropolcard) : 0) -
      (payment?.payecard !== "" ? parseFloat(payment?.payecard) : 0) -
      (payment?.gastropay !== "" ? parseFloat(payment?.gastropay) : 0) -
      (payment?.multinet !== "" ? parseFloat(payment?.multinet) : 0);

    setRemainder(parseFloat(calculatedRemainder.toFixed(2)));
  }
  useEffect(() => {
    remainderCalcute();
  }, [payment]);

  function getPayment() {
    const event = "complete";
    sendOrderEvent({
      orderId: order.orderId,
      data: {},
      event,
    });
  }
  useEffect(() => {

    setRefundAmounts((prevRefundAmounts) => ({
      ...prevRefundAmounts,
      multinet: multinetRefundAmount,
    }));
  }, [totalAmountNumber]);
  return order?.isPrePaid === true ? (
    <Box sx={{ m: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="total"
            label={t("totalAmount")}
            value={totalAmountNumber}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled
            fullWidth
            key={'online'}
            label={t("onlinePayment")}
            value={parseFloat(
              (Number(order.total) / 100).toString()
            ).toFixed(2)}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="outlined"
            color={"secondary"}
            onClick={() => getPayment()}
          >
            {t("delivery")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box sx={{ m: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputLabel>{t("totalAmount")}</InputLabel>
          <CurrencyInput value={totalAmountNumber} disabled={true} />
        </Grid>
        <Grid item xs={6}>
          <InputLabel>{t("remainingAmount")}</InputLabel>
          <CurrencyInput value={remainder} disabled={true} />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t("cash")}</InputLabel>
          <CurrencyInput
            value={payment?.cash}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              !isNaN(Number(event.target.value)) &&
                setPayment({
                  ...payment,
                  cash: event.target.value,
                });
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ display: !availablePayments.includes("creditCard") ? "none" : "block" }}>
          <InputLabel>{t("creditCard")}</InputLabel>
          <CurrencyInput
            value={payment?.creditcard}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              !isNaN(Number(event.target.value)) &&
                setPayment({
                  ...payment,
                  creditcard: event.target.value,
                });
            }}
            disabled={availablePayments.includes("creditCard") ? false : true}
          />
        </Grid>
        <Grid item xs={12} style={{ display: !availablePayments.includes("metropolcard") ? "none" : "block" }}>
          <InputLabel>{t("metropolcard")}</InputLabel>
          <CurrencyInput
            value={payment?.metropolcard}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              !isNaN(Number(event.target.value)) &&
                setPayment({
                  ...payment,
                  metropolcard: event.target.value,
                });
            }}
            disabled={availablePayments.includes("metropolcard") ? false : true}
          />
        </Grid>
        <Grid item xs={12} style={{ display: !availablePayments.includes("multinet") ? "none" : "block" }}>
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <InputLabel>{t("Multinet")}</InputLabel>
      <CurrencyInput
        value={payment?.multinet}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          !isNaN(Number(event.target.value)) &&
            setPayment({
              ...payment,
              multinet: event.target.value,
            });
        }}
        disabled={availablePayments.includes("multinet") ? false : true}
      />
      </Grid>
            <Grid item xs={4}>
              <InputLabel>{t("multinetRefundAmount")}</InputLabel>
              <CurrencyInput
          value={refundAmounts.multinet}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const enteredValue = event.target.value;
              setRefundAmounts({ ...refundAmounts, multinet: enteredValue });
          }}
          fullWidth
          style={{ marginTop: 10 }}
        />

            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                 disabled={!isMultinetPayment || !isActiveHandleRefundAll}
                variant="outlined"
                color="secondary"
                onClick={() => handleRefundAll("multinet",parseFloat(refundAmounts.multinet))}
                style={{ marginTop: 10, marginRight: 10 }}
              >
                {t("refund")}
              </Button>
              <Button
              disabled={!isMultinetPayment || !isActiveHandleRefundPartial}
              style={{ marginTop: 10}}
              variant="outlined"
              color="secondary"
              onClick={() => handleDayEndRefund(parseFloat(refundAmounts.multinet))}
            >
              {t("dayEndRefund")}
            </Button>
            </Grid>
          </Grid>
        </Grid>


        <Grid item xs={12} style={{ display: !availablePayments.includes("creditCard") ? "none" : "block" }}>
          <InputLabel>{t("Paycell")}</InputLabel>
          <CurrencyInput
            value={payment?.paycell}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              !isNaN(Number(event.target.value)) &&
                setPayment({
                  ...payment,
                  paycell: event.target.value,
                });
            }}
            disabled={availablePayments.includes("paycell") ? false : true}
          />
        </Grid>
        <Grid item xs={12} style={{ display: !availablePayments.includes("payecard") ? "none" : "block" }}>
          <InputLabel>{t("Payecard")}</InputLabel>
          <CurrencyInput
            value={payment?.payecard}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              !isNaN(Number(event.target.value)) &&
                setPayment({
                  ...payment,
                  payecard: event.target.value,
                });
            }}
            disabled={availablePayments.includes("payecard") ? false : true}
          />
        </Grid>
        <Grid item xs={12} style={{ display: !availablePayments.includes("setcard") ? "none" : "block" }}>
          <InputLabel>{t("SetCard")}</InputLabel>
          <CurrencyInput
            value={payment?.setcard}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              !isNaN(Number(event.target.value)) &&
                setPayment({
                  ...payment,
                  setcard: event.target.value,
                });
            }}
            disabled={availablePayments.includes("setcard") ? false : true}
          />
        </Grid>
        <Grid item xs={12} style={{ display: !availablePayments.includes("sodexo") ? "none" : "block" }}>
          <InputLabel>{t("Sodexo")}</InputLabel>
          <CurrencyInput
            value={payment?.sodexo}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              !isNaN(Number(event.target.value)) &&
                setPayment({
                  ...payment,
                  sodexo: event.target.value,
                });
            }}
            disabled={availablePayments.includes("sodexo") ? false : true}
          />
        </Grid>
        <Grid item xs={12} style={{ display: !availablePayments.includes("ticket") ? "none" : "block" }}>
          <InputLabel>{t("Ticket")}</InputLabel>
          <CurrencyInput
            value={payment?.ticket}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              !isNaN(Number(event.target.value)) &&
                setPayment({
                  ...payment,
                  ticket: event.target.value,
                });
            }}
            disabled={availablePayments.includes("ticket") ? false : true}
          />
        </Grid>
        <Grid item xs={12} style={{ display: !availablePayments.includes("gastropay") ? "none" : "block" }}>
          <InputLabel>{t("gastropay")}</InputLabel>
          <CurrencyInput
            value={payment?.gastropay}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              !isNaN(Number(event.target.value)) &&
                setPayment({
                  ...payment,
                  gastropay: event.target.value,
                });
            }}
            disabled={availablePayments.includes("gastropay") ? false : true}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            color={"secondary"}
            onClick={() => getPayment()}
            disabled={Number(remainder) !== 0}
          >
            {t("save")}
          </Button>
          <Button
            variant="outlined"
            color={"secondary"}
            onClick={() => navigate(-1)}
          >
            {t("cancel")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
