import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { useMutationWithDebounce } from "./debounce";
import { PROVINCES } from "./paths";

export function Provinces() {
  return useQuery(["provinces"], {
    queryFn: () =>
      !!apiClient && apiClient.get(PROVINCES + `?page=1&limit=1000`),
    retry: 0,
    enabled: true,
  });
}

export function DistrictByProvince(provinceId: string) {
  return useQuery(["districtbyprovince", provinceId], {
    queryFn: () =>
      !!apiClient &&
      apiClient.get(PROVINCES + `/${provinceId}?page=1&limit=100000&search=`),
    retry: 0,
    enabled: false,
  });
}

export function PatchProvince(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (province: any) =>
      apiClient.patch(
        PROVINCES +'/updateProvinceParameters',
        province
      ),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["updateProvinceParameters"] });
    },
    debounceTime,
  });
}

export function PatchDistrict(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (district: any) =>
      apiClient.patch(
        PROVINCES +'/updateDistrictParameters',
        district
      ),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["updateDistrictParameters"] });
    },
    debounceTime,
  });
  
}

export function PatchAllDistrict(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (district: any) =>
      apiClient.patch(
        PROVINCES +'/updateAllDistrict',
        district
      ),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["updateAllDistrict"] });
    },
    debounceTime,
  });
  
}

