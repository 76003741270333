import { TableColumn } from "react-data-table-component";
import { useNavigate, NavigateFunction, useParams } from "react-router-dom";
import { CouriersList as CouriersDataList } from "../../api/courier";
import { Courier, CourierType } from "../../api/types";
import { PortiaTable } from "../../components/PortiaTable";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { RowActions } from "../../components/PortiaTable/row.actions";
import Avatar from "@mui/material/Avatar";
import { useCallback, useState, useEffect} from "react";
import { CourierCustomRowActions } from "./components/courier.row.actions";
import { Grid, InputLabel, MenuItem, Select, Tooltip, Button } from "@mui/material";
import { SHIFT_STATUS } from "../../utils/enums";
import { useTranslation } from "react-i18next";
import { debounce, isEmpty } from "lodash-es";
import { Hubs } from "../../api/hubs";
import Authz from "../../components/Authz";
import { CourierStatusChange } from "./courier.status.change";
import { CourierShiftEnd } from "./courier.shift.end";
import general from "../../utils/general";
import { usePortia } from "../../context";

export const CouriersList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const {
    store: { user },
  } = usePortia();
  const shiftStateOptions = [
    { id: "All", label: t("all") },
    { id: SHIFT_STATUS.ASSIGNED, label: t(SHIFT_STATUS.ASSIGNED) },
    { id: SHIFT_STATUS.AVAILABLE, label: t(SHIFT_STATUS.AVAILABLE) },
    { id: SHIFT_STATUS.OFFLINE, label: t(SHIFT_STATUS.OFFLINE) },
    { id: SHIFT_STATUS.BREAK, label: t(SHIFT_STATUS.BREAK) },
    { id: SHIFT_STATUS.ACCIDENT, label: t(SHIFT_STATUS.ACCIDENT) },
  ];
  const stateOptions = [
    { id: "All", label: t("all") },
    { id: "ACTIVE", label: t("active") },
    { id: "BLOCKED", label: t("blocked") },
    { id: "INACTIVE", label: t("inactive") },
    { id: "DELETED", label: t("deleted") },
  ];
  const { dropdownAlert } = usePortia();
  function checkShiftWorkingDay(courirer: Courier) {
    if (!courirer.workingDays || isEmpty(courirer.workingDays)) {
      return
    }
    const now = new Date();
    const timestampGMT = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
    );
    const finishLocalTimestamp = Date.parse(courirer?.workingDays?.finishTime?.toString());
    const finishUtcTimestamp = new Date(finishLocalTimestamp).getTime() + new Date(finishLocalTimestamp).getTimezoneOffset() * 60000;
    if (timestampGMT > finishUtcTimestamp) {
      return;
    }
    const startLocalTimestamp = Date.parse(courirer?.workingDays?.startTime?.toString());
    const startUtcTimestamp = new Date(startLocalTimestamp).getTime() + new Date(startLocalTimestamp).getTimezoneOffset() * 60000;
    if ((timestampGMT > startUtcTimestamp) && courirer?.shiftStatusCode === "OFFLINE") {
      return true;
    }
    return false;
  }
  const columns: TableColumn<Courier>[] = [
    {
      cell: (row) => <Avatar src={row.photo} />,
      width: "64px",
    },
    {
      name: t("name"),
      selector: (row) => row.name,
      sortable: true,
      sortField: "name",
    },
    {
      name: t("mobile"),
      selector: (row) => row.mobile,
      sortable: true,
      sortField: "mobile",
    },
    {
      name: t("email"),
      sortable: true,
      sortField: "email",
      cell: (row) => <Tooltip title={row.email}>
        <div style={{
          whiteSpace: "nowrap", overflow: 'hidden',
          textOverflow: 'ellipsis', maxWidth: '200px'
        }}>{row.email}</div>
      </Tooltip>
    },
    {
      name: t("type"),
      selector: (row) => t(CourierType[row.type]).toString(),
      sortable: true,
      sortField: "type",
    },
    {
      name: t("hub"),
      selector: (row) => row.hub.name,
      sortable: true,
      sortField: "hub",
    },
    {
      name: t("shiftStatus"),
      sortable: true,
      sortField: "shiftStatusCode",
      cell: (row) => {
        const isOffline = checkShiftWorkingDay(row);
        return <div style={{ color: isOffline ? "white": "inherit" ,paddingTop: "11px", textAlign: "center", height: "40px", width: "100px", backgroundColor: isOffline ?  "red" : "inherit"}}>{ t(row?.shiftStatusCode).toString()}</div>
      } 
    }, 
    {
      name: t("numberOfActiveAssignedPackets"),
      selector: (row) => row?.numberOfActiveAssignedPackets,
      sortable: true,
      sortField: "numberOfActiveAssignedPackets",
    },
    {
      name: t("numberOfPackagesCarriedDaily"),
      selector: (row) => row?.numberOfPackagesCarriedDaily,
      sortable: true,
      sortField: "numberOfPackagesCarriedDaily",
    },
    {
      cell: (row) => (
        <Authz allowedRoles={["portia-admin", "portia-hub-manager"]}>
          <CourierCustomRowActions
            {...(row?.state === "ACTIVE" &&
              row?.courierId && {
              onhandleShiftEnd: () => handleShiftEnd(row?.courierId),
            })}
            {...(row?.state === "ACTIVE" &&
              row?.courierId && {
              onChangeStatus: () => handleChangeOrderStatus(row?.courierId),
            })}
          />
          <RowActions
            onEdit={(navigate: NavigateFunction) => {
              navigate(`/couriers/${row.courierId}`, { state: row });
            }}
          />
        </Authz>
      ),
      allowOverflow: true,
      button: true,
      width: "128px",
    },
  ];
  const hubId: any = params && params["hubid"];

  const [shiftStatus, setShiftStatus] = useState<string>("All");
  const [courierStatus, setCourierStatus] = useState<string>("All");
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [selectedHub, setSelectedHub] = useState<string>('all');
  const [sortField, setSortField] = useState<string>("_id");
  const [sortDirection, setSortDirection] = useState("desc");

  const { data: hubs } = Hubs();
  const hubsData = (hubs && hubs?.data?.items) || [];


  const { data, isLoading, isError, refetch } = CouriersDataList(
    currentPage,
    perPage,
    shiftStatus === "All" ? undefined : shiftStatus,
    courierStatus === "All" ? undefined : courierStatus,
    search,
    hubId !== undefined ? hubId : selectedHub,
    sortField,
    sortDirection
  );
  const handleChange = () => {
    // console.log("Selected Rows: ", selectedRows);
  };
  const { data: couriers }: any = data || {};
  const refresh = debounce(() => {
    refetch();
  }, 50);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refresh();
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
    refresh();
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(column?.sortField === undefined ? "_id" : column?.sortField);
    setSortDirection(sortDirection);
    refresh();
  };

  const handleShiftStatusChange = useCallback(
    (statusValue: any) => {
      setShiftStatus(statusValue);
      refresh();
    },
    [refresh]
  );

  const handleCourierStatusChange = useCallback(
    (statusValue: any) => {
      setCourierStatus(statusValue);
      refresh();
    },
    [refresh]
  );

  const handleHubChange = useCallback(
    (statusValue: any) => {
      setSelectedHub(statusValue);
      refresh();
    },
    [refresh]
  );

  const handleSearchChange = (searchKey: string) => {
    setSearch(searchKey);
    (searchKey.length === 0 || searchKey.length > 2) && searchRefresh();
  };

  const searchRefresh = debounce(() => {
    refetch();
  }, 500);

  const [openStatusChangeModal, setStatusChangeModal] = useState(false);
  const [openShiftEndModal, setShiftEndModal] = useState(false);

  const [statusChangeData, setStatusChangeData] = useState<Object>();
  const handleChangeOrderStatus = useCallback((courierId: any) => {
    setStatusChangeData({ courierId: courierId, time: new Date().getTime() });
    setTimeout(() => {
      setStatusChangeModal(true);
    }, 150);
  }, []);
  const [shiftEndData, setShiftEndData] = useState<Object>();
  const handleShiftEnd = useCallback((courierId: any) => {
    setShiftEndData({ courierId: courierId });
    setTimeout(() => {
      setShiftEndModal(true);
    }, 150);
  }, []);

  const handleExcelButton = () => {
    const excelCourierData = data?.data?.items?.map((courier: Courier) => {
      const activePackets = courier?.numberOfActiveAssignedPackets || 0;
      const dailyCarriedPackets = courier?.numberOfPackagesCarriedDaily || 0;
    
      return {
        'Excel’in oluşturulma tarihi': general.formatDateForDisplay(Date.now()),
        'Kurye Adı Soyadı': courier?.name,
        'Kurye Telefon': courier?.mobile,
        "Kurye Email": courier?.email,
        "Kurye Tip": courier.type,
        'Kurye Bölge': courier?.hub?.name,
        'Kurye Vardiya Durumu': t(`${courier?.shiftStatusCode}`) ,
        'Aktif Atanan Paket Sayısı': activePackets,
        'Günlük Taşınan Paket Sayısı': dailyCarriedPackets,
      };
    });
    const headerRow= [
    'Excel’in oluşturulma tarihi',
     'Kurye Adı Soyadı',
     'Kurye Telefon',
     'Kurye Email',
     'Kurye Tip',
     'Kurye Bölge',
     'Kurye Vardiya Durumu',
     'Aktif Atanan Paket Sayısı',
     'Günlük Taşınan Paket Sayısı',
];
    general.createExcelFromData(excelCourierData, headerRow, 'Kurye');
    dropdownAlert.current.alertWithType({ type: 'success', title: "OK", message: t('excelSuccess') });
  }
  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      {couriers && (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns}
          data={couriers}
          customFilter={
            <Grid container spacing={1}>
              {!hubId && (
                <Grid item xs={2} mt={-3}>
                  <InputLabel id="select-hub">{t("hubs")}</InputLabel>
                  <Select
                    labelId="select-hub"
                    variant="outlined"
                    sx={{ width: 200 }}
                    value={selectedHub}
                    onChange={(e) => handleHubChange(e.target.value)}
                    label={t("hubs")}
                  >
                    <MenuItem key={"all"} value={"all"}>
                      {t("all")}
                    </MenuItem>
                    {hubsData?.map((val: any) => (
                      <MenuItem key={val?.hubId} value={val?.hubId}>
                        {val?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
                 
              <Grid item xs={2} ml={15} mt={-3}>
                <InputLabel id="select-status-label">
                  {t("shiftStatus")}
                </InputLabel>
                <Select
                  variant="outlined"
                  labelId="select-status-label"
                  sx={{ width: 200 }}
                  value={shiftStatus}
                  onChange={(e) => handleShiftStatusChange(e.target.value)}
                  label={t(shiftStatus)}
                >
                  {shiftStateOptions.map((val) => (
                    <MenuItem key={val.id} value={val.id}>
                      {val.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid >
              <Grid item xs={1} ml={10} mt={-3}>
                <InputLabel id="select-courier-status-label">
                  {t("courierStatus")}
                </InputLabel>
                <Select
                  variant="outlined"
                  labelId="select-courier-status-label"
                  sx={{ width: 200 }}
                  value={courierStatus}
                  onChange={(e) => handleCourierStatusChange(e.target.value)}
                  label={t(courierStatus)}
                >
                  {stateOptions.map((val) => (
                    <MenuItem key={val.id} value={val.id}>
                      {val.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid >
              <Grid item xs={2} ml={20} mt={1}>
              <Button onClick={handleExcelButton} sx={{ border: '1px solid #046746' }}>{t('exportToExcel')}</Button>
            </Grid>
            </Grid>
          }
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          serverSideSearch
          onSearchChange={handleSearchChange}
          onSort={handleSort}
        />
      )}
      {statusChangeData?.hasOwnProperty("courierId") && (
        <CourierStatusChange
          openDialog={openStatusChangeModal}
          setOpenDialog={setStatusChangeModal}
          data={statusChangeData}
          onListingRefresh={(e: any) => refetch()}
        />
      )}
      {shiftEndData?.hasOwnProperty("courierId") && (
        <CourierShiftEnd
          openDialog={openShiftEndModal}
          setOpenDialog={setShiftEndModal}
          data={shiftEndData}
          onListingRefresh={(e: any) => refetch()}
        />
      )}
      <Authz allowedRoles={["portia-admin", "portia-hub-manager"]}>
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => {
            let navRoute = `/couriers/new`;
            if (hubId && hubId !== "all") {
              navRoute = `/couriers/new?hubId=${hubId}`;
            }
            navigate(navRoute, { state: undefined });
          }}
        >
          <AddIcon />
        </Fab>
      </Authz>
    </Box>
  );
};
