import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { useMutationWithDebounce } from "./debounce";
import { PaymentType } from "./types";
import { GET_CANCEL_PAYMENTS } from "./paths";

export function PaymentTypeList(page: number, limit: number) {
    return useQuery(["paymentTypes", page, limit], {
        queryFn: () =>
            !!apiClient &&
            apiClient.post(`/api/payment-types/list?page=${page}&limit=${limit}`),
        retry: 0,
        refetchOnWindowFocus: false,
        onError(err) {
            console.log(err);
        },
    });
}
export function PaymentTypeListAll() {
    return useQuery(["paymentTypesAll"], {
        queryFn: () =>
            !!apiClient &&
            apiClient.get(`/api/payment-types`),
        retry: 0,
        refetchOnWindowFocus: false,
        onError(err) {
            console.log(err);
        },
    });
}
export function GetPaymentType(paymentId: string) {
    return useQuery(["getpaymenttype", paymentId], {
        queryFn: () =>
            !!apiClient && apiClient.get(`/api/payment-types/${paymentId}`),
        staleTime: 100,
        cacheTime: 100,
        retry: 0,
        enabled: false,
    });
}
export function CreatePaymentType(debounceTime: number = 500) {
    const queryClient = useQueryClient();
    return useMutationWithDebounce({
        mutationFn: (payment: PaymentType) => apiClient.post("/api/payment-types", payment),
        onSuccess: (data: any, variables: { id: any }) => {
            queryClient.invalidateQueries({ queryKey: ["payment"] });
        },
        debounceTime,
    });
}
export function GetCancelPayments(orderId: string, paymentType: string) {
    return useQuery(["get-cancel-payments", orderId, paymentType], {
      queryFn: () =>
        !!apiClient && apiClient.get(`${GET_CANCEL_PAYMENTS}/${paymentType}/${orderId}`),
      staleTime: 100,
      cacheTime: 100,
      retry: 0,
      enabled: false,
    });
  }
  
export function PatchPaymentType(debounceTime: number = 500) {
    const queryClient = useQueryClient();
    return useMutationWithDebounce({
        mutationFn: (payment: any) =>
            apiClient.patch(`/api/payment-types/${payment.paymentId}`, payment),
        onSuccess: (data: any, variables: { id: any }) => {
            queryClient.invalidateQueries({ queryKey: ["payment"] });
        },
        debounceTime,
    });
}
export function DeletePaymentType(debounceTime: number = 500) {
    const queryClient = useQueryClient();
    return useMutationWithDebounce({
      mutationFn: (payment: any) =>
        apiClient.delete(`/api/payment-types/${payment}`),
      onSuccess: (data: any, variables: { id: any }) => {
        queryClient.invalidateQueries({ queryKey: ["payment"] });
      },
      debounceTime,
    });
}



