
import { List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Timestamp } from "../../components/Timestamp";
import { ListShiftHistory } from "../../api/shifts";
import { useParams } from "react-router-dom";

export const StatusHistoryList = () => {


  const { t } = useTranslation();

  const params = useParams();
  const shiftid: any = params && params["shiftid"];


  const {
    data
  } = ListShiftHistory(shiftid);

  const renderItem = (item: any) => {
    return (
        <ListItem key={item.id}>
            <ListItemAvatar>
                <Timestamp timestamp={item.timestamp} card date time />
            </ListItemAvatar>
            <ListItemText primary={t("status")} secondary={t(item?.code)} />
        </ListItem>
    );
};
const history: any = data?.data || [];

return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {history.map((item: any) => renderItem(item))}
    </List>
);
};
