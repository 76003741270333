import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { TENANTS } from "./paths";
import { useMutationWithDebounce } from "./debounce";
import { Tenant } from "./types";

export function GetTenantList(
  page: number,
  limit: number,
  search: string,
  sortField: string,
  sortDirection: string
) {
  return useQuery(["tenantspagination"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        TENANTS +
        `/list?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}`
      ),
    retry: 0,
    enabled: true,
  });
}

export function PostTenant(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (tenant: Tenant) => apiClient.post(TENANTS, tenant),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["tenants"] });
    },
    debounceTime,
  });
}

export function PatchTenant(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (tenant: any) =>
      apiClient.patch(TENANTS + `/${tenant.id || tenant._id}`, tenant),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["tenants"] });
    },
    debounceTime,
  });
}

export function GetTenantDetail(tenantId: string) {
  return useQuery(["getTenantDetails", tenantId], {
    queryFn: () => !!apiClient && apiClient.get(TENANTS + `/details/${tenantId}`),
    retry: 0,
    enabled: true,
  });
}
export function GetTenant() {
  return useQuery(["getTenant"], {
    queryFn: () => !!apiClient && apiClient.get(TENANTS ),
    retry: 0,
    enabled: true,
  });
}