import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';
import { MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CourierType } from "../../api/types";
import { ProceedSave, ProceedSaveDraft } from "../../api/proceeds";
import { GetCourier } from "../../api/courier";
import { GetReconciliationByParams } from "../../api/reconciliation";
import Authz from "../../components/Authz";
import { usePortia } from "../../context";

export const Earning = () => {
  const params = useParams();
  const {
    dropdownAlert,
    store: { setLoading },
  } = usePortia();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const location = useLocation();
  const courierId: any = location?.state?.courierId;

  const isNewProceed = location.state.row != null ? false : true;
  const [reconciliationDate, setReconciliationDate] = useState<any>(null);

  const { mutate: GetReconciliation, data: reconciliationData, isLoading: isLoading } = GetReconciliationByParams(500);
  const [proceeds, setProceed] = useState<any>(location?.state?.row != null ? location?.state?.row : {});
  const [addButton, setAddButton] = useState<any>(false)
  const [saveDraftButton, setSaveDraftButton] = useState<any>(false)
  const [saveButton, setSaveButton] = useState<any>(false)
  const [numberOfPackages, setNumberOfPackages] = useState<any>(false)
  const [workingHour, setWorkingHour] = useState<any>(false)
  const [workingDay, setWorkingDay] = useState<any>(false)



  useEffect(() => {
    if (proceeds && !isNewProceed) {
      setReconciliationDate(proceeds?.reconciliationDate)
      GetReconciliation({ courierId: courierId, proceedsDate: proceeds?.reconciliationDate });
    }
  }, [proceeds, proceeds?.reconciliationDate])

  useEffect(() => {
    const motorCycleRent = proceeds?.earningMetaData?.motorCycleRent;
    const workingDay = proceeds?.earningMetaData?.numberOfDaysWorked;
    const motorRentalDay = motorCycleRent / 30 * workingDay;

    setProceed((prevState: any) => ({
      ...prevState,
      earningMetaData: {
        ...prevState.earningMetaData,
        motorCycleRentalDay: motorRentalDay,
      },
    }));
  }, [proceeds?.earningMetaData?.motorCycleRent, proceeds?.earningMetaData?.numberOfDaysWorked]);

  useEffect(() => {
    const kdvValue = proceeds?.earningMetaData?.base * 0.2;

    setProceed((prevState: any) => ({
      ...prevState,
      earningMetaData: {
        ...prevState.earningMetaData,
        kdv: kdvValue,
      },
    }));
  }, [proceeds?.earningMetaData?.base]);
  useEffect(() => {
    const vat = proceeds?.earningMetaData?.kdv * 0.2;

    setProceed((prevState: any) => ({
      ...prevState,
      earningMetaData: {
        ...prevState.earningMetaData,
        kdvWithholding: vat
      },
    }));
  }, [proceeds?.earningMetaData?.kdv]);
  const {
    mutate: saveDraft,
    data: cResultSaveDraft,
    error: cErrorSaveDraft,
  } = ProceedSaveDraft(500);
  
  const {
    mutate: save,
    data: cResult,
    error: cError,
  } = ProceedSave(500);
  const { refetch: getCourier, data: courierData } = GetCourier(location?.state?.courierId);

  const typeOptions = [
    { id: CourierType.POOL, label: t("POOL") },
    { id: CourierType.DEDICATED, label: t("DEDICATED") },
    { id: CourierType.STABLE, label: t("STABLE") },
    { id: CourierType.PAYROLL, label: t("PAYROLL") },
    { id: CourierType.JOKER, label: t("JOKER") },
  ];
  function getDateOptions() {
    let now = new Date();

    let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    let fifteenthDay = new Date(now.getFullYear(), now.getMonth(), 15);
    let sixteenthDay = new Date(now.getFullYear(), now.getMonth(), 16);
    let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    let sixteenthDayLasthMonth = new Date(now.getFullYear(), now.getMonth() - 1, 16);
    let lastDayLasthMonth = new Date(now.getFullYear(), now.getMonth(), 0);

    function formatDate(date: Date) {
      let day = date.getDate().toString().padStart(2, "0");
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let year = date.getFullYear();
      return `${day}.${month}.${year}`;
    }

    let first = formatDate(firstDay) + "-" + formatDate(fifteenthDay);
    let second = formatDate(sixteenthDay) + "-" + formatDate(lastDay);
    let last = formatDate(sixteenthDayLasthMonth) + "-" + formatDate(lastDayLasthMonth);


    const lastOption = last;
    const firstOption = first;
    const secondOption = second;
    return [lastOption, firstOption, secondOption];
  }

  useEffect(() => {
    if (isNewProceed) {
      setReconciliationDate(getDateOptions()[1])
      GetReconciliation({ courierId: courierId, proceedsDate: getDateOptions()[1] });
    }
  }, [])

  const saveSelectedButton = (data: any) => {
    data.earningMetaData.packageMultiplier = courierData?.data?.reconciliationParams?.amountPerPack || null;
    data.earningMetaData.amountPerHour = courierData?.data?.reconciliationParams?.amountPerHour || null;
    data.earningMetaData.fixedAmount = courierData?.data?.reconciliationParams?.fixedAmount || null;
    data.earningMetaData.contractFixedAmount = courierData?.data?.reconciliationParams?.minAmount || null;
    data.earningMetaData.contractedCourierPackages = courierData?.data?.reconciliationParams?.minPackage || null;
    data.reconciliationDate = reconciliationDate;
    data.earningMetaData.bonus = reconciliationData?.data?.bonus || 0
    if (isNewProceed) {
      data.earningMetaData.numberOfPackages = numberOfPackages;
      data.earningMetaData.workingHour = workingHour;
      data.earningMetaData.numberOfDaysWorked = workingDay;
    }
  const motorCycleRent = proceeds?.earningMetaData?.motorCycleRentalDay
  const accountingDeduction = proceeds?.earningMetaData?.accountingDeduction || 0;
  const fuel = proceeds?.earningMetaData?.fuel || 0;
  const clothes = proceeds?.earningMetaData?.clothes || 0;
  const gross = data?.grossProfit || 0;
  const kdvWithholding = proceeds?.earningMetaData?.kdvWithholding || 0;
  const otherDeduction = proceeds?.earningMetaData?.otherDeduction || 0;

  const transferAmount = gross - kdvWithholding - motorCycleRent - accountingDeduction - fuel - clothes - otherDeduction

  setProceed((prevState: any) => ({
    ...prevState,
    earningMetaData: {
      ...prevState.earningMetaData,
      transferAmount: transferAmount,
    },
  }));
  data.earningMetaData.transferAmount = transferAmount;
  data.earningMetaData.bonus = proceeds?.earningMetaData?.bonus;
  
    if (addButton) {
      save(Object.assign(data, { courierId: courierId, status: "draft", courierType: courierData?.data.type }));
    }
    if (saveDraftButton) {
      saveDraft(Object.assign(data, { courierId: courierId, status: "draft", courierType: courierData?.data.type }));
    }
    if (saveButton) {
      saveDraft(Object.assign(data, { courierId: courierId, status: "saved", courierType: courierData?.data.type }));
    }
  }

  function findLabelById() {
    let obj = typeOptions.find(item => item.id === courierData?.data.type);
    return obj ? obj.label : undefined;
  }
  useEffect(() => {
    if (
      (cResultSaveDraft && (cResultSaveDraft.status === 200 || cResultSaveDraft.status === 201)) ||
      (cResult && (cResult.status === 200 || cResult.status === 201))
    ) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }

    if (cErrorSaveDraft || cError)
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "Error",
        message:
          t(cErrorSaveDraft?.response?.data?.message) ||
          t(cError?.response?.data?.message),
      });
  }, [cResultSaveDraft, cResult, cError, cErrorSaveDraft]);

  useEffect(() => {
    if (reconciliationData) {
      setNumberOfPackages(reconciliationData?.data?.orderCount);
      setWorkingHour(reconciliationData?.data?.workingHour);
      setWorkingDay(reconciliationData?.data?.workingDay);
    }
  }, [reconciliationData])

  const handleChange = (event: SelectChangeEvent) => {
    setReconciliationDate(event.target.value);
    GetReconciliation({ courierId: courierId, proceedsDate: event.target.value });
  };
  const handlePaste = (event: any) => {
    const pastedText = event.clipboardData.getData("text");
    if (pastedText.includes("e")) {
      event.preventDefault();
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "e") {
      event.preventDefault();
    }
  };

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      <Box sx={{ m: 2 }}>
        <FormContainer
          values={proceeds}
          onSuccess={(data) => saveSelectedButton(data)}
        >
          <Grid container spacing={10} sx={{ mb: 2 }} alignItems={"center"}>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("courierId")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  disabled
                  id="outlined-disabled"
                  name={"courierId"}
                  InputProps={{
                    value: courierId
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={3}>
                <Typography>{t("courierFullName")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  disabled
                  id="outlined-disabled"
                  label={courierData?.data?.name}
                />
              </Grid>
            </Grid>
            <Grid item xs={4} />
          </Grid>
          <Grid container spacing={10} sx={{ mb: 10 }} alignItems={"center"}>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("courierType")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  disabled
                  label={t("type")}
                  defaultValue={findLabelById()}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={3}>
                <Typography>{t("earning_period")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Select
                  label="earning_period"
                  variant="outlined"
                  sx={{ width: 300 }}
                  onChange={handleChange}
                  value={reconciliationDate}
                  disabled={!isNewProceed}
                >
                  <MenuItem value="">
                  </MenuItem>
                  <MenuItem value={getDateOptions()[0]}>{getDateOptions()[0]}</MenuItem>
                  <MenuItem value={getDateOptions()[1]}>{getDateOptions()[1]}</MenuItem>
                  <MenuItem value={getDateOptions()[2]}>{getDateOptions()[2]}</MenuItem>

                </Select>
              </Grid>
            </Grid>
            <Grid item xs={4} />
          </Grid>

          <Grid container alignItems={"center"} spacing={10}>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("per_package_multiplier")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={"earningMetaData.packageMultiplier"}
                  label={t("per_package_multiplier")}
                  fullWidth
                  disabled={true}
                  //type={"number"}
                  InputProps={{
                    value: courierData?.data?.reconciliationParams?.amountPerPack || "-",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={3}>
                <Typography>{t("vat_deduction_will_be_applied")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextFieldElement
                  name={"earningMetaData.kdvWithholding"}
                  label={t("vat_deduction_will_be_applied")}
                  fullWidth
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  InputProps={{
                    readOnly: true,
                    value: proceeds?.earningMetaData?.kdvWithholding?.toFixed(2) || 0,
                    inputProps: {
                      min: 0,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("transfer_amount")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={"earningMetaData.transferAmount"}
                  label={t("transfer_amount")}
                  fullWidth
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  InputProps={{
                    readOnly: true,
                    value: proceeds?.earningMetaData?.transferAmount?.toFixed(2) || 0,
                    inputProps: {
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>


          <Grid container alignItems={"center"} spacing={10} paddingTop={5}>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("fixed_amount")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={"earningMetaData.fixedAmount"}
                  label={t("fixed_amount")}
                  fullWidth
                  disabled={true}
                  //type={"number"}
                  InputProps={{
                    value: courierData?.data?.reconciliationParams?.fixedAmount || "-",
                    onChange: (e: any) => {
                      setProceed((prevState: any) => ({
                        ...prevState,
                        earningMetaData: {
                          ...prevState.earningMetaData,
                          fixedAmount: parseInt(e.target.value) || null,
                        },
                      }));
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={3}>
                <Typography>{t("advance")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextFieldElement
                  name={"earningMetaData.advance"}
                  label={t("advance")}
                  fullWidth
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  InputProps={{
                    value: proceeds?.earningMetaData?.advance,
                    inputProps: {
                      min: 0,
                    },
                    onChange: (e: any) => {
                      setProceed((prevState: any) => ({
                        ...prevState,
                        earningMetaData: {
                          ...prevState.earningMetaData,
                          advance: parseInt(e.target.value) || null,
                        },
                      }));
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("bonus")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={"earningMetaData.bonus"}
                  label={t("bonus")}
                  fullWidth
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  InputProps={{
                    value: proceeds?.earningMetaData?.bonus,
                    inputProps: {
                      min: 0,
                    },
                    onChange: (e: any) => {
                      setProceed((prevState: any) => ({
                        ...prevState,
                        earningMetaData: {
                          ...prevState.earningMetaData,
                          bonus: parseInt(e.target.value) || null,
                        },
                      }));
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>


          <Grid container alignItems={"center"} spacing={10} paddingTop={5}>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("hourly_rate")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={"earningMetaData.amountPerHour"}
                  label={t("hourly_rate")}
                  fullWidth
                  disabled
                  InputProps={{
                    value: courierData?.data?.reconciliationParams?.amountPerHour || "-",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={3}>
                <Typography>{t("motor_installment_rent")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextFieldElement
                  name={"earningMetaData.motorCycleRent"}
                  label={t("motor_installment_rent")}
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  fullWidth
                  InputProps={{
                    value: proceeds?.earningMetaData?.motorCycleRent,
                    inputProps: {
                      min: 0,
                    },
                    onChange: (e: any) => {
                      setProceed((prevState: any) => ({
                        ...prevState,
                        earningMetaData: {
                          ...prevState.earningMetaData,
                          motorCycleRent: parseInt(e.target.value) || null,
                        },
                      }));
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("other_deductions")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={"earningMetaData.otherDeduction"}
                  label={t("other_deductions")}
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  fullWidth
                  InputProps={{
                    value: proceeds?.earningMetaData?.otherDeduction,
                    inputProps: {
                      min: 0,
                    },
                    onChange: (e: any) => {
                      setProceed((prevState: any) => ({
                        ...prevState,
                        earningMetaData: {
                          ...prevState.earningMetaData,
                          otherDeduction: parseInt(e.target.value) || null,
                        },
                      }));
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignItems={"center"} spacing={10} paddingTop={5}>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("contracted_fixed_amount")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={"earningMetaData.contractFixedAmount"}
                  label={t("contracted_fixed_amount")}
                  disabled
                  fullWidth
                  InputProps={{
                    value: courierData?.data?.reconciliationParams?.minAmount || "-",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={3}>
                <Typography>{t("motor_rental_day")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextFieldElement
                  name={"earningMetaData.motorCycleRentalDay"}
                  label={t("motor_rental_day")}
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    value: proceeds?.earningMetaData?.motorCycleRentalDay?.toFixed(2) || 0,
                    inputProps: {
                      min: 0,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("vat")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={""}
                  label={t("vat")}
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    value: proceeds?.earningMetaData?.kdv?.toFixed(2) || 0,
                    inputProps: {
                      min: 0,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignItems={"center"} spacing={10} paddingTop={5}>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("contracted_courier_minpackage_quantity")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={"earningMetaData.contractedCourierPackages"}
                  label={t("contracted_courier_minpackage_quantity")}
                  fullWidth
                  disabled
                  InputProps={{
                    value: courierData?.data?.reconciliationParams?.minPackage || "-",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={3}>
                <Typography>{t("accounting_deduction")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextFieldElement
                  name={"earningMetaData.accountingDeduction"}
                  label={t("accounting_deduction")}
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  fullWidth
                  InputProps={{
                    value: proceeds?.earningMetaData?.accountingDeduction,
                    inputProps: {
                      min: 0,
                    },
                    onChange: (e: any) => {
                      setProceed((prevState: any) => ({
                        ...prevState,
                        earningMetaData: {
                          ...prevState.earningMetaData,
                          accountingDeduction: parseInt(e.target.value) || null,
                        },
                      }));
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"} />
          </Grid>

          <Grid container alignItems={"center"} spacing={10} paddingTop={5}>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("number_of_packages")}</Typography>
              </Grid>
              <Grid item xs={8}>
                {
                  isNewProceed ? <TextFieldElement
                    name={"earningMetaData.numberOfPackages"}
                    label={t("number_of_packages")}//Paket sayısı
                    type={"number"}
                    onKeyDown={handleKeyDown}
                    onPaste={handlePaste}
                    fullWidth
                    InputProps={{
                      value: numberOfPackages,
                      inputProps: {
                        min: 0,
                      },
                      onChange: (e: any) => {
                        setNumberOfPackages(parseInt(e.target.value) || null);
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> :
                    <TextFieldElement
                      name={"earningMetaData.numberOfPackages"}
                      label={t("number_of_packages")}//Paket sayısı
                      fullWidth
                      type={"number"}
                      onKeyDown={handleKeyDown}
                      onPaste={handlePaste}
                      InputProps={{
                        value: proceeds?.earningMetaData?.numberOfPackages,
                        inputProps: {
                          min: 0,
                        },
                        onChange: (e: any) => {
                          setProceed((prevState: any) => ({
                            ...prevState,
                            earningMetaData: {
                              ...prevState.earningMetaData,
                              numberOfPackages: parseInt(e.target.value) || null,
                            },
                          }));
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                }
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={3}>
                <Typography>{t("fuel")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextFieldElement
                  name={"earningMetaData.fuel"}
                  label={t("fuel")}
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  fullWidth
                  InputProps={{
                    value: proceeds?.earningMetaData?.fuel,
                    inputProps: {
                      min: 0,
                    },
                    onChange: (e: any) => {
                      setProceed((prevState: any) => ({
                        ...prevState,
                        earningMetaData: {
                          ...prevState.earningMetaData,
                          fuel: parseInt(e.target.value) || null,
                        },
                      }));
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={4} alignItems={"center"} />

          </Grid>

          <Grid container alignItems={"center"} spacing={10} paddingTop={5}>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("number_of_days_worked")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={"numberOfDaysWorked"}
                  label={t("number_of_days_worked")}
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  fullWidth
                  InputProps={{
                    value: isNewProceed ? workingDay : proceeds?.earningMetaData?.numberOfDaysWorked,
                    inputProps: {
                      min: 0,
                    },
                    onChange: (e: any) => {
                      isNewProceed ? setWorkingDay(parseInt(e.target.value) || null) : setProceed((prevState: any) => ({
                        ...prevState,
                        earningMetaData: {
                          ...prevState.earningMetaData,
                          numberOfDaysWorked: parseInt(e.target.value) || null,
                        },
                      }));
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={3}>
                <Typography>{t("clothing")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextFieldElement
                  name={"earningMetaData.clothes"}
                  label={t("clothing")}
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  fullWidth
                  InputProps={{
                    value: proceeds?.earningMetaData?.clothes,
                    inputProps: {
                      min: 0,
                    },
                    onChange: (e: any) => {
                      setProceed((prevState: any) => ({
                        ...prevState,
                        earningMetaData: {
                          ...prevState.earningMetaData,
                          clothes: parseInt(e.target.value) || null,
                        },
                      }));
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("gross_earnings")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={"grossProfit"}
                  label={t("gross_earnings")}
                  type={"number"}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>

          </Grid>

          <Grid container alignItems={"center"} spacing={10} paddingTop={5}>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("working_hours")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={"earningMetaData.workingHour"}
                  label={t("working_hours")}
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  fullWidth
                  InputProps={{
                    value: isNewProceed ? workingHour : proceeds?.earningMetaData?.workingHour,
                    inputProps: {
                      min: 0,
                    },
                    onChange: (e: any) => {
                      isNewProceed ? setWorkingHour(parseInt(e.target.value) || null) : setProceed((prevState: any) => ({
                        ...prevState,
                        earningMetaData: {
                          ...prevState.earningMetaData,
                          workingHour: parseInt(e.target.value) || null,
                        },
                      }));
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={3}>
                <Typography>{t("tax_base")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextFieldElement
                  name={"earningMetaData.base"}
                  label={t("tax_base")}
                  type={"number"}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  fullWidth
                  InputProps={{
                    value: proceeds?.earningMetaData?.base,
                    inputProps: {
                      min: 0,
                    },
                    onChange: (e: any) => {
                      setProceed((prevState: any) => ({
                        ...prevState,
                        earningMetaData: {
                          ...prevState.earningMetaData,
                          base: parseInt(e.target.value) || null,
                        },
                      }));
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{t("net_earnings")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement
                  name={"netProfit"}
                  label={t("net_earnings")}
                  fullWidth
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>

          {proceeds?.status !== "saved" ? (
            <Grid container item margin={5}>
              <Grid item xs={5} />
              <Grid item xs={2}>
              </Grid>
              <Authz allowedRoles={['portia-admin', 'portia-hub-manager']}>
                <Grid item xs={3}>
                  <Button
                    sx={{ mr: 2 }}
                    size="large"
                    variant="contained"
                    type={"submit"}
                    color={"primary"}
                    onClick={() => {
                      setSaveDraftButton(true);
                      setSaveButton(false);
                    }}
                  >
                    {t("save_as_draft")}
                  </Button>
                </Grid>
              </Authz>
              <Authz allowedRoles={['portia-admin']}>
                <Grid item xs={2}>
                  <Button
                    sx={{ mr: 2 }}
                    size="large"
                    variant="contained"
                    type={"submit"}
                    color={"primary"}
                    onClick={() => {
                      setSaveDraftButton(false);
                      setSaveButton(true);
                    }}
                  >
                    {t("save")}
                  </Button>
                </Grid>
              </Authz>
            </Grid>
          ) : null}
        </FormContainer>
      </Box>
    </Box>
  );
};