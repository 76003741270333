import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  AutocompleteElement,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import { Point } from "geojson";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { Hub, User } from "../../api/types";
import { PatchHub, PostHub, GetHub, SaveKmlFile, GetKmlFile, DeleteKMLFile } from "../../api/hubs";
import { usePortia } from "../../context";
import { useEffect, useRef, useState } from "react";
import { LocationPicker } from "../../components/PortiaMap";
import { Polygon } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { Users } from "../../api/users";
import Authz from "../../components/Authz";
import { DistrictByProvince, Provinces } from "../../api/provinces";
import { useRoles } from "../../hooks/useRoles";
import { GetTenant } from "../../api/tenants";
import { GetCompanyByTenantId } from "../../api/companies";
import { kml } from "@tmcw/togeojson";
import { TrackHubCouriers } from "../../api/courier";
import { GetRestaurantByHubIdBrandList } from "../../api/restaurant";

const DOMParser = require("xmldom").DOMParser;

export const HubDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const {
    dropdownAlert,
    store: { user, setLoading },
  } = usePortia();
  const hasPortiaSuperAdmin = useRoles({ allowedRoles: ["portia-super-admin"] });
  const [hub, setHub] = useState<Hub>(location?.state);
  const [position, setPosition] = useState<google.maps.LatLngLiteral>();
  const hubId: any = params && params["hubid"];
  const [kmlDataGeoJson, setKmlDataGeoJson] = useState<any>(null);
  const { data: kmlData, refetch: getKmlFile } = GetKmlFile(hubId || "", "hub");
  const { data: kmlResponse, refetch: saveKmlFile } = SaveKmlFile(kmlDataGeoJson, hubId || "", null, position);
  const { mutate: kmlDelete, data: kmlDeleteData, error: kmlDeleteError } = DeleteKMLFile(500);
  const couriersPosition: any[] = [];
  const restaurantPosition: any[] = [];
  const [path, setPath] = useState<google.maps.LatLngLiteral[]>([]);
  const [restaurantPath, setRestaurantPath] = useState<google.maps.LatLngLiteral[]>([]);
  const inputFile = useRef<HTMLInputElement>(null);
  const { refetch: getTrack, data: trackData } = TrackHubCouriers(hubId);
  const {
    mutate: create,
    data: cResult,
    error: cError,
    isLoading: isPostLoading,
  } = PostHub();
  const {
    mutate: save,
    data: pResult,
    error: sError,
    isLoading: isPatchLaoding,
  } = PatchHub();
  const { refetch: getHub, data: hubData } = GetHub(hubId);
  const { refetch: getRestaurantList, data: restaurantList} = GetRestaurantByHubIdBrandList(hubId);
  const [selectedProvinceId, setSelectedProvinceId] = useState<string>("");
  const [selectedTenantId, setSelectedTenantId] = useState<string>("null");
  const { data: provincesData, isLoading: provincesIsLoading } = Provinces();
  const {
    data: districtsData,
    isLoading: districtsIsLoading,
    refetch: getDistrict,
  } = DistrictByProvince(selectedProvinceId);

  useEffect(() => {
    if (selectedProvinceId) getDistrict();
  }, [selectedProvinceId]);

  const {
    data: companyData,
    refetch: getCompany,
  } = GetCompanyByTenantId(selectedTenantId);

  const { data: tenantData } = GetTenant();


  useEffect(() => {
    if (selectedTenantId) {
      getCompany();
    }
  }, [selectedTenantId]);

  const getRegions = (data: any) => {
    if (data) {
      try {
        return data?.features.map((regionFeature: any) => {
          if (regionFeature.geometry.type == "Polygon") {
            let coordinates = regionFeature.geometry.coordinates[0];
            let coordArr: { lat: any; lng: any; }[] = [];
            coordinates.map((coordinate: any[]) => {
              return coordArr.push({
                lat: coordinate[1], lng: coordinate[0],
              });
            });
            const newColor = "#" + Math.floor(Math.random() * 1000);
            return (
              <Polygon
                path={coordArr}
                options={{
                  fillColor: newColor,
                  strokeColor: newColor,
                  strokeWeight: 1,
                  strokeOpacity: 0
                }}
                key={newColor} />
            )
          }
        });
      }
      catch (e) {
        dropdownAlert.current.alertWithType({
          type: "error",
          title: "Error",
          message: t("kmlError")
        });
      }
    } else {
      return undefined;
    }
  };

  const isNewHub = !!!location.state && hubId == "new";

  const getCurrentPositionOrDefault = async () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      },
      (err) =>
        setPosition({
          lat: 41.10551877478612,
          lng: 29.02610099753676,
        })
    );
  };

  const openKmlFile = async () => {
    if (inputFile.current != null) {
      inputFile.current?.click();
    }
  };
  const deleteKmlFile = async () => {
    kmlDelete({ hubId: hubId });
  }

  const handleFileUpload = (e: any) => {
    const { files } = e.target;
    const reader = new FileReader();
    e.preventDefault();
    reader.readAsText(files[0], "utf-8");
    reader.onload = function () {
      if (reader.result != null) {
        const data = reader.result;
        const kmlData = new DOMParser().parseFromString(data);
        const converted = kml(kmlData);
        setKmlDataGeoJson(converted);
      }
      reader.onerror = function () {
        console.log(reader.error);
      };
    }
  };

  useEffect(() => {
    if (hubId && hubId !== "new") {
      getHub();
      getKmlFile(hubId);
      getTrack();
      getRestaurantList();
    } else {
      getCurrentPositionOrDefault();
    }
  }, [params, hubId]);

  useEffect(() => {
    let msg = t("kmlSuccess");
    if (kmlResponse) {
      if (kmlResponse.data.status === "NOTCREATED") {
        msg = t("kmlFail");
        dropdownAlert.current.alertWithType({
          type: "error",
          title: "Error",
          message: msg
        });
      } else if (kmlResponse.data.status === "ERROR") {
        msg = t("kmlError");
        dropdownAlert.current.alertWithType({
          type: "error",
          title: "Error",
          message: msg
        });
      } else if (kmlResponse.data.status === "CREATED") {
        dropdownAlert.current.alertWithType({
          type: "success",
          title: "Success",
          message: msg
        });
      }
    }
  }, [kmlResponse]);

  useEffect(() => {
    setHub(hubData?.data);
    if (hubData?.data?.provinceId) {
      setSelectedProvinceId(hubData?.data?.provinceId);
      setSelectedTenantId(hubData?.data?.tenantId);
      getDistrict();
      getCompany();
    }

    const { data: r } = hubData || {};
    if (r) {
      const { location } = r;
      if (location !== undefined) {
        setPosition({
          lng: location?.coordinates[0],
          lat: location?.coordinates[1],
        });
      }
    }
  }, [hubData]);

  useEffect(() => {
    setLoading(isPostLoading || isPatchLaoding);
  }, [isPostLoading, isPatchLaoding]);

  useEffect(() => {
    if (
      (pResult && (pResult.status === 200 || pResult.status === 201)) ||
      (cResult && (cResult.status === 200 || cResult.status === 201))
    ) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }

    if (sError || cError)
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "Error",
        message:
          t(sError?.response?.data?.message) ||
          t(cError?.response?.data?.message),
      });
  }, [pResult, cResult, cError, sError]);

  useEffect(() => {
    if (kmlDeleteData || kmlDeleteError) {
      navigate(-1);
    }
  }, [kmlDeleteData, kmlDeleteError])

  useEffect(() => {
    if (trackData?.data?.data) {
      trackData?.data?.data.map((pt: any) =>
        couriersPosition.push({
          lat: pt.lat,
          lng: pt.lon,
          courierId: pt.courier,
          courierName: pt.courierName,
          speed: pt.speed,
          licensePlate: pt.licensePlate,
        })
      );
      if (couriersPosition?.length > 0) {
        setPath(couriersPosition);
      }
    }
  }, [trackData]);

  useEffect(()=>{
    if(restaurantList?.data?.items) {
      restaurantList?.data?.items.map((pt: any) =>
      restaurantPosition.push({
          lat: pt?.location?.coordinates?.[1],
          lng: pt?.location?.coordinates?.[0],
          restaurantName: pt?.name,
          brandName: pt?.brand?.name,
          brandLogo: pt?.brand?.logo
        })
      );
      if (restaurantPosition?.length > 0) {
        setRestaurantPath(restaurantPosition);
      }
    }
  },[restaurantList])

  const getLastPositionCurier = () => {
    getTrack();
    if (trackData?.data?.data) {
      trackData?.data?.data.map((pt: any) =>
        couriersPosition.push({
          lat: pt.lat,
          lng: pt.lon,
          courierId: pt.courier,
          courierName: pt.courierName,
          speed: pt.speed,
        })
      );
    }
  };

  const saveHub = (c: Hub) => {
    const l = position && {
      type: "Point",
      coordinates: [position.lng || 0, position.lat || 0],
    };
    c.location = l as Point;
    if (isNewHub) {
      create(c);
    } else {
      saveKmlFile();
      save(c);
    }
  };
  let userOptions = null;
  if (!user?.attributes?.hubId) {
    const { data: usersData } = Users();
    userOptions = (usersData && usersData.data) || [];
  }

  return (
    <Box sx={{ m: 2 }}>
      <FormContainer values={hub} onSuccess={(data) => saveHub(data)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextFieldElement
              sx={{ mr: 2 }}
              validation={{ required: t("required").toString() }}
              name={"name"}
              label={t("firstName")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            {!user?.attributes?.hubId && (
              <Authz allowedRoles={["portia-admin"]}>
                <AutocompleteElement
                  label={t("manager")}
                  name="managersArr"
                  matchId
                  loading={false}
                  multiple
                  options={userOptions.map((e: User) => {
                    return {
                      id: e?.username,
                      label: e?.email,
                    };
                  })}
                />
              </Authz>
            )}
            {user?.attributes?.hubId && hubData?.data?.managers && (
              <AutocompleteElement
                label={t("manager")}
                name="managersArr"
                matchId
                loading={false}
                multiple
                textFieldProps={{
                  InputProps: {
                    readOnly: true,
                  },
                }}
                options={hubData?.data?.managers.map((e: User) => {
                  return {
                    id: e?.username,
                    label: e?.email,
                  };
                })}
              />
            )}
          </Grid>

          {provincesData?.data?.items && (
            <Grid item xs={6}>
              <AutocompleteElement
                label={t("province")}
                name="provinceId"
                matchId
                required
                loading={provincesIsLoading}
                textFieldProps={{
                  inputProps: {
                    form: {
                      autocomplete: "off",
                    },
                  },
                }}
                options={provincesData?.data?.items.map((h: any) => {
                  return {
                    id: h.provinceId,
                    label: h.name,
                  };
                })}
                rules={{ required: t("hubRequiredMessage").toString() }}
                autocompleteProps={{
                  onChange: (event, value) => {
                    setSelectedProvinceId(value?.id);
                  },
                }}
              />
            </Grid>
          )}

          {districtsData?.data?.items && (
            <Grid item xs={6}>
              <AutocompleteElement
                label={t("district")}
                name="districtId"
                matchId
                required
                loading={provincesIsLoading}
                textFieldProps={{
                  inputProps: {
                    form: {
                      autocomplete: "off",
                    },
                  },
                }}
                options={districtsData?.data?.items.map((h: any) => {
                  return {
                    id: h.districtId,
                    label: h.name,
                  };
                })}
                rules={{ required: t("hubRequiredMessage").toString() }}
                autocompleteProps={{
                  disabled: !selectedProvinceId ? true : false,
                }}
              />
            </Grid>
          )}
          {
            hasPortiaSuperAdmin && (
              <Grid item xs={6}>
                {
                  tenantData?.data && (
                    <AutocompleteElement
                      label={t("tenant")}
                      name="tenantId"
                      matchId
                      required
                      options={tenantData?.data.map((h: any) => {
                        return { id: h.tenantId, label: h.name };
                      })}
                      rules={{
                        required: t("tenantRequiredMessage").toString(),
                      }}
                      autocompleteProps={{
                        onChange: (event, value) => {
                          setSelectedTenantId(value?.id);
                        },
                      }}
                    />
                  )
                }
              </Grid>

            )
          }
          {
            hasPortiaSuperAdmin && (
              <Grid item xs={6}>
                {
                  companyData?.data && (
                    <AutocompleteElement
                      label={t("company")}
                      name="companyId"
                      matchId
                      required
                      textFieldProps={{
                        inputProps: {
                          form: {
                            autocomplete: "off",
                          },
                        },
                      }}
                      options={companyData?.data?.map((h: any) => {
                        return {
                          id: h.companyId,
                          label: h.name,
                        };
                      })}
                      rules={{ required: t("hubRequiredMessage").toString() }}
                      autocompleteProps={{
                        disabled: !selectedTenantId ? true : false,
                      }}
                    />
                  )
                }
              </Grid>
            )
          }
          {!isNewHub ?
            (<Grid item xs={12} style={{ paddingLeft: "18px", paddingTop: "10px" }}>
              <Button
                sx={{ height: 25 }}
                variant="contained"
                onClick={() => openKmlFile()}
                color={"primary"}
              >
                {t("fileImport")}
              </Button>
              {
                kmlData?.data?.length > 0 && kmlData?.data[0]?.id && <Button
                  sx={{ height: 25, marginLeft: 5 }}
                  variant="contained"
                  onClick={() => deleteKmlFile()}
                  color={"primary"}
                >
                  {t("kmlDelete")}
                </Button>
              }
            </Grid>) : undefined
          }
          <Grid item xs={12}>
            {position && (
              <LocationPicker
                position={position}
                title={hub?.name}
                children={(kmlData ? getRegions(kmlData.data[0]) : undefined)}
                onSave={(p) => {
                  console.log(p);
                  setPosition(p);
                }}
                couriersPosition={path}
                restaurantsPosition={restaurantPath}
                onRefresh={() => getLastPositionCurier()}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {!user?.attributes?.hubId && (
              <Authz allowedRoles={["portia-admin"]}>
                <Button
                  sx={{ mr: 2 }}
                  variant="contained"
                  type={"submit"}
                  color={"primary"}
                >
                  {t("save")}
                </Button>
              </Authz>
            )}
            <Button
              variant="outlined"
              color={"secondary"}
              onClick={() => navigate(-1)}
            >
              {t("back")}
            </Button>
          </Grid>
        </Grid>
        <input
          style={{ display: "none" }}
          accept=".kml"
          ref={inputFile}
          onChange={(e) => handleFileUpload(e)}
          type="file"
        />
      </FormContainer>
    </Box>
  );
};