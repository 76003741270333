import { TableColumn } from "react-data-table-component";
import { useNavigate, NavigateFunction, useParams } from "react-router-dom";

import { ChangeStatusRestaurant, Restaurants } from "../../api/restaurant";
import { Restaurant } from "../../api/types";
import { PortiaTable } from "../../components/PortiaTable";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { RowActions } from "../../components/PortiaTable/row.actions";

import { HubsMap } from "../../api/hubs";
import { BrandsMap } from "../../api/brands";
import Avatar from "@mui/material/Avatar";
import { useCallback, useEffect, useState } from "react";
import { usePortia } from "../../context";
import { Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";
import Authz from "../../components/Authz";

export const RestaurantsList = ({ hub }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const {
    dropdownAlert,
  } = usePortia();
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [sortField, setSortField] = useState<string>("_id");
  const [sortDirection, setSortDirection] = useState("desc");

  const columns = (hubs: any, brands: any) => {
    const c: TableColumn<Restaurant>[] = [
      {
        cell: (row) => (
          <Avatar src={brands && brands?.data[row.brandId]?.logo} />
        ),
        width: "64px",
      },
      {
        name: t('firstName'),
        selector: (row) => row.name,
        sortable: true,
        sortField: 'name'
      },
      {
        name: t('brand'),
        selector: (row) => {
          const b = brands && brands.data[row.brandId];
          return b?.name;
        },
        sortable: true,
        sortField: 'brand'
      },
      {
        name: t('hub'),
        selector: (row) => {
          const h = hubs && hubs.data[row.hubId];
          return h?.name;
        },
        sortable: false,
      },
      {
        name: t('state'),
        cell: (row) => (
          <Authz allowedRoles={['portia-admin']}>
            <Switch
              checked={row?.status}
              onChange={() => {
                handleChangeStatusRestaurant(row?.restaurantId);
              }}
            />
          </Authz>
        ),
        button: true,
      },
      {
        cell: (row) => (
          <Authz allowedRoles={['portia-admin']}>
            <RowActions
              onPaymentType={(navigate: NavigateFunction) => {
                navigate(`/hubs/restaurants/payment/${row.restaurantId}`, {
                  state: row,
                });
              }}
              onEdit={(navigate: NavigateFunction) => {
                navigate(`/hubs/restaurants/${row.restaurantId}`, { state: row });
              }}
            />
          </Authz>
        ),
        allowOverflow: true,
        button: true,
        width: "128px",
      },
    ];
    return c;
  };
  const hubId: any = params && params["hubid"];

  const { data, isLoading, isError, refetch } = Restaurants(
    currentPage,
    perPage,
    search,
    hubId,
    sortField,
    sortDirection,
  );
  const { data: hubs } = HubsMap();
  const { data: brands } = BrandsMap();
  const { data: restaurants }: any = data || {};

  const {
    mutate: changeStatusRestaurant,
    data: rResult,
    error: rError,
  } = ChangeStatusRestaurant();

  const handleChangeStatusRestaurant = useCallback((id: any) => {
    changeStatusRestaurant(id);
  }, []);

  useEffect(() => {
    if (rResult && rResult.status === 200) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t('statusUpdate'),
      });
      refresh();
    }
  }, [rResult]);

  useEffect(() => {
    if (rError) {
      dropdownAlert.current.alertWithType({
        type: "warning",
        title: "OK",
        message: rError && t(rError?.response?.data.message),
      });
    }
  }, [rError]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refresh();
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
    refresh();
  };

  const handleChange = (selectedRows: any) => {
    console.log("Selected Rows: ", selectedRows);
  };

  const handleSearchChange = (searchKey: string) => {
    setSearch(searchKey);
    (searchKey.length == 0 || searchKey.length > 2) && searchRefresh();
  };
  
  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(column?.sortField === undefined ? "_id" : column?.sortField );
    setSortDirection(sortDirection);
    refresh();
  };

  const searchRefresh = debounce(() => {
    refetch();
  }, 500);

  const refresh = debounce(() => {
    refetch();
  }, 50);
  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      {restaurants && (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns(hubs, brands)}
          data={restaurants}
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          serverSideSearch
          onSearchChange={handleSearchChange}
          onSort={handleSort}
        />
      )}
      <Authz allowedRoles={['portia-admin']}>

        <Fab
          color="primary"
          aria-label="add"
          onClick={() => {
            let navRoute = `/hubs/restaurants/new`;
            if (hubId && hubId !== "all") {
              navRoute = `/hubs/restaurants/new?hubId=${hubId}`;
            }
            navigate(navRoute, { state: undefined });
          }}>
          <AddIcon />
        </Fab>
      </Authz>
    </Box>
  );
};
