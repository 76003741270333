import React from "react";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import WhatshotIcon from "@mui/icons-material/Whatshot";

import { NavLink, useNavigate } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import i18n from "../../translations";
import { usePortia } from "../../context";

export interface BreadcrumbsProps {
  routes: any[];
}

export function PortiaBreadcrumbs({ routes }: BreadcrumbsProps) {
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs(routes);
  const {
    store: { session: {roles} },
  } = usePortia();

  const adminRole = roles?.find((role: string) => role?.includes("portia-admin") === true)

  const handleNavClick = (pathname: string) => { 
    if(pathname === "/hubs/restaurants") {
      navigate(-1);
      return;
    }

    if (pathname === "/orders" && adminRole !== "portia-admin") {
      navigate(-1);
      return;
    }

    if (pathname === "/pos" && adminRole !== "portia-admin") {
      navigate(-1);
      return;
    }

    if (pathname?.includes("/hubBonus")) {
      navigate(-1);
      return;
    }

    return null
  }

  const decidePathWhenNavigating = (pathname: string) => { 
    if (pathname === "/hubs/restaurants") 
      return true;
    if (pathname === "/orders" && adminRole !== "portia-admin") {
      return true;
    }
    if (pathname === "/pos" && adminRole !== "portia-admin") {
      return true;
    }
    if (pathname?.includes("/hubBonus")) {
      return true;
    }
    return false
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map(({ match, breadcrumb }: any) => (
        <NavLink key={match.pathname} color="inherit" to={decidePathWhenNavigating(match.pathname) ? null : match.pathname}
          onClick={() =>  handleNavClick( match.pathname)}
        >
          {breadcrumb?.key === "/" ? (
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          ) : (
            <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          )}
          {breadcrumb?.key.includes("new") ? i18n.t("New") : breadcrumb}
        </NavLink>
      ))}
    </Breadcrumbs>
  );
}
