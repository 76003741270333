import DataTable, { TableColumn } from "react-data-table-component";
import { useNavigate, NavigateFunction } from "react-router-dom";

import { User } from "../../api/types";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { RowActions } from "../../components/PortiaTable/row.actions";
import { useState } from "react";
import {
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Users } from "../../api/users";

export const UsersList = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns: TableColumn<User>[] = [
    {
      name: t("keycloakId"),
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: t("username"),
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: t("name"),
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: true,
    },
    {
      name: t("mobile"),
      selector: (row) => {
        const m = row.attributes?.mobile?.length > 0 && row.attributes.mobile[0];
        return m || 'N/A (KEYCLOAK)';
      },
      sortable: true,
    },
    {
      name: t("email"),
      selector: (row) => row.email,
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <RowActions
            onEdit={(navigate: NavigateFunction) => {
              navigate(`/settings/${row.id}`, { state: row });
            }}
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "128px",
    },
  ];

  const { data, isLoading } = Users();
 
  const { data: users }: any = data || {};

  const [filterText, setFilterText] = useState<string>("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const onClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const onFilter = (e: any) => {
    setFilterText(e.target.value);
  };

  const filter: any = (item: User, search: string) => {
    console.log("item", item.username, search);
    const usernameHas =
      item.username &&
      item.username.toLowerCase().includes(search.toLowerCase());
    const firstNameHas =
      item.firstName &&
      item.firstName.toLowerCase().includes(search.toLowerCase());
    const lastNameHas =
      item.lastName &&
      item.lastName.toLowerCase().includes(search.toLowerCase());
    const emailHas =
      item.email && item.email.toLowerCase().includes(search.toLowerCase());

    return usernameHas || firstNameHas || lastNameHas || emailHas;
  };

  const filteredItems =
    (!!users &&
      !!filterText &&
      users?.filter((item: any) => filter(item, filterText))) ||
    users;

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      {users && (
        <DataTable
          columns={columns}
          data={filteredItems}
          progressPending={isLoading}
          progressComponent={<CircularProgress />}
          pagination
          subHeader
          subHeaderComponent={
            <Box sx={{ m: 2, p: 1 }}>
              <TextField
                id="search"
                variant="outlined"
                label={t("search").toString()}
                type="text"
                placeholder={t("search").toString()}
                aria-label={t("search").toString()}
                value={filterText}
                onChange={onFilter}
              />
              <Button sx={{ height: 55 }} variant="contained" onClick={onClear}>
                X
              </Button>
            </Box>
          }
          noDataComponent={t("dataNotFound")}
        />
      )}
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => navigate(`/settings/new`, { state: undefined })}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
};
