import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { useMutationWithDebounce } from "./debounce";
import {
  GET_HUBS_DETAILS,
  GET_HUBS_MAP,
  HUBS,
  HUBS_CHANGE_STATUS,
  HUB_BONUSES,
} from "./paths";
import { Hub, HubBonuses } from "./types";
import { isEmpty } from "lodash-es";

export function Hubs() {
  return useQuery(["hubs"], {
    queryFn: () =>
      !!apiClient && apiClient.post(HUBS + `/list?page=1&limit=100000&search=`),
    retry: 0,
    enabled: true,
  });
}

export function HubsPagination(
  page: number,
  limit: number,
  search: string,
  sortField: string,
  sortDirection: string
) {
  return useQuery(["hubspagination"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        HUBS +
          `/list?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}`
      ),
    retry: 0,
    enabled: true,
  });
}

export function HubsMap() {
  return useQuery(["hubmap"], {
    queryFn: () => !!apiClient && apiClient.get(GET_HUBS_MAP),
    retry: 0,
    enabled: true,
  });
}

export function GetHub(hubId: string) {
  return useQuery(["gethub", hubId], {
    queryFn: () => !!apiClient && apiClient.get(GET_HUBS_DETAILS + `/${hubId}`),
    retry: 0,
    enabled: true,
  });
}

export function PostHub(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (hub: Hub) => apiClient.post(HUBS, hub),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["hubs"] });
    },
    debounceTime,
  });
}

export function PatchHub(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (hub: any) =>
      apiClient.patch(HUBS + `/${hub.id || hub._id}`, hub),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["hubs"] });
    },
    debounceTime,
  });
}

export function ChangeStatusHub(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (id: any) => apiClient.patch(HUBS_CHANGE_STATUS + `/${id}`),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["hubchangestatus"] });
    },
    debounceTime,
  });
}

export function PatchHubParameters(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (hub: any) =>
      apiClient.patch(
        HUBS +'/updateParameters',
        hub
      ),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["updateParameters"] });
    },
    debounceTime,
  });
}


export function DeleteHub(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (hubId: any) => apiClient.delete(HUBS + `/${hubId}`),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["hubdelete"] });
    },
    debounceTime,
  });
}

export function GetHubBonuses(
  hubId: string,
  page: number,
  limit: number,

) {
  return useQuery(["gethubbonuses"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.get(
        HUB_BONUSES +
          `/list/${hubId}?page=${page}&limit=${limit}`
      ),
    retry: 0,
    enabled: true,
  });
}

export function PostHubBonuses(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (hubBonus: HubBonuses) =>
      apiClient.post(HUB_BONUSES + `/${hubBonus.hubId}`, hubBonus),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["hubbonuses"] });
    },
    debounceTime,
  });
}

export function DeleteHubBonuses(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (hubBonusId: any) =>
      apiClient.delete(HUB_BONUSES + `/${hubBonusId}`),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["deletehubbonuses"] });
    },
    debounceTime,
  });
}

export function GetHubBonusDetail(hubBonusId: string) {
  return useQuery(["gethubbonusdetail", hubBonusId], {
    queryFn: () => !!apiClient && apiClient.get(HUB_BONUSES + `/${hubBonusId}`),
    retry: 0,
    enabled: true,
  });
}

export function PatchHubBonus(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (hubBonus: any) =>
      apiClient.patch(HUB_BONUSES + `/${hubBonus.hubBonusId}`, hubBonus),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["hubs"] });
    },
    debounceTime,
  });
}

export function HubsByProvince(provinceId: string) {
  return useQuery(["byProvince", provinceId], {
    queryFn() {
      return !!apiClient && apiClient.get(`${HUBS}/byProvince/${provinceId}`)
    },
    enabled: !isEmpty(provinceId)
  })
}

export function HubsByDistrict(districtId: string) {
  return useQuery(["byDistrict", districtId], {
    queryFn() {
      return !!apiClient && apiClient.get(`${HUBS}/byDistrict/${districtId}`)
    },
    enabled: !isEmpty(districtId)
  })
}

export function HubsWithRestaurant(hubId: string) {
  return useQuery(["hubsWithRestaurant", hubId], {
    queryFn() {
      return !!apiClient && apiClient.get(`${HUBS}/hubsWithRestaurant/${hubId}`)
    },
    enabled: !isEmpty(hubId),
  })
}
export function OrderCountByHub(hubId: string, brandId: string , restaurantId: string) {
  return useQuery(["orderCountByHubId", hubId], {
    queryFn: () => !!apiClient && apiClient.get(`${HUBS}/orderCountByHubId/${hubId}?brandId=${brandId}&restaurantId=${restaurantId}`),
    retry: 0,
    enabled: true,
  });
}

export function GetKmlFile(hubId: string, type: string) {
  return useQuery(["getKmlFile", hubId], {
    queryFn: () => !!apiClient && apiClient.get(`${HUBS}/getKmlFile/${hubId}/${type}`),
    retry: 0,
    enabled: true,
  });
}

export function SaveKmlFile(kmlData: any, hubId: string, restaurantId: any, position: any) {
  if(kmlData) {
    kmlData.hubId = hubId;
    kmlData.position = position;
    if(restaurantId != null && restaurantId != undefined) {
      kmlData.restaurantId = restaurantId;
    }
  }
    return useQuery(["saveKmlFile", kmlData], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(`${HUBS}/saveKmlFile`, kmlData && {kmlData: kmlData}),
    retry: 0,
    enabled: false,
    onError(err: any) {
      console.log(err);
    },
  });
}
export function DeleteKMLFile(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: ({ hubId, restaurantId }: any) => {
      return apiClient.post(`${HUBS}/deleteKmlFile`, {
        hubId,restaurantId
      });
    },
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["delete-kml-file"] });
    },
    debounceTime,
  });
}

export function PatchAllHub(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (hub: any) =>
      apiClient.patch(
        HUBS +'/updateAllHubs',
        hub
      ),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["updateAllHubs"] });
    },
    debounceTime,
  });
}
