import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { COMPANIES } from "./paths";
import { useMutationWithDebounce } from "./debounce";
import { Tenant } from "./types";

export function GetCompanyList(
  page: number,
  limit: number,
  search: string,
  sortField: string,
  sortDirection: string
) {
  return useQuery(["companiespagination"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        COMPANIES +
        `/list?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}`
      ),
    retry: 0,
    enabled: true,
  });
}

export function PostCompany(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (company: Tenant) => apiClient.post(COMPANIES, company),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
    },
    debounceTime,
  });
}

export function PatchCompany(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (company: any) =>
      apiClient.patch(COMPANIES + `/${company.id || company._id}`, company),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
    },
    debounceTime,
  });
}

export function GetCompany(companyId: string) {
  return useQuery(["getCompany", companyId], {
    queryFn: () => !!apiClient && apiClient.get(COMPANIES + `/details/${companyId}`),
    retry: 0,
    enabled: true,
  });
}

export function GetCompanyByTenantId(tenantId: string) {
  return useQuery(["getCompanyByTenantId", tenantId], {
    queryFn: () => !!apiClient && apiClient.get(COMPANIES + `/byTenantId/${tenantId}`),
    retry: 0,
    enabled: false,
  });
}

export function GetImageByCompany() {
  return useQuery(["imagebycompany"], {
    queryFn: () =>
      !!apiClient && apiClient.get(COMPANIES +`/imageByCompany`),
    retry: 0,
    enabled: true,
  });
}