// BU URL'e dokunmayın. .env verilen src/context/PortiaContext.ts  REACT_APP_API_URL  olarak
// apiClient da set edilmektedir. BURADA SADECE PREFIX olmalıdır.
const BASE_URL = `/api`;

export const WEATHER = BASE_URL + '/weather';
export const AUTH_LOGIN = '/auth/login';
export const AUTH_OTP = '/auth/otp'
export const GET_BRANDS = BASE_URL + '/brands';
export const GET_BRANDS_MAP= BASE_URL + '/brands/map';
export const GET_BRANDS_AUTH_LIST = BASE_URL + '/brands/authlist';
export const GET_COURIER = BASE_URL + '/couriers/user';
export const GET_COURIER_DETAILS = BASE_URL + '/couriers/details';
export const COURIERS = BASE_URL + '/couriers';
export const GET_COURIER_AVAILABLE = BASE_URL + '/couriers/available';
export const GET_COURIER_TRACK = BASE_URL + '/couriers/track';
export const GET_HUB_COURIER_TRACK = BASE_URL + '/couriers/hubtracks';
export const GET_COURIER_TRACK_INFO = BASE_URL + '/couriers/trackinfo';
export const GET_COURIER_TRACK_AVG_SPEED = BASE_URL + '/couriers/trackavgspeed';
export const GET_COURIER_TRACK_MAX_SPEED = BASE_URL + '/couriers/trackmaxspeed';
export const GET_COURIER_TRACK_ODOMETER = BASE_URL + '/couriers/trackodometer';
export const GET_COURIERS_HUBS_LIST = BASE_URL + '/couriers/hub-couriers-list';
export const GET_COURIERS_HUBS = BASE_URL + '/couriers/hub-couriers';
export const HUBS = BASE_URL + '/hubs';
export const GET_HUBS_MAP = BASE_URL + '/hubs/map';
export const GET_HUBS_DETAILS = BASE_URL + '/hubs/details';
export const HUBS_CHANGE_STATUS = BASE_URL + '/hubs/changeStatus';
export const GET_HUBS_DISTRICT = BASE_URL + '/hubs/district';
export const AUTH_FAIL = BASE_URL + '/fail-with-403';
export const POS = BASE_URL + '/pos';
export const RESTAURANT = BASE_URL + '/restaurants';
export const RESTAURANT_CHANGE_STATUS = BASE_URL + '/restaurants/changeStatus';
export const RESTAURANT_DETAILS = BASE_URL + '/restaurants/details';
export const ORDERS = BASE_URL + '/orders';
export const ORDERS_CHANGE_COURIER = BASE_URL + '/orders/change/courier';
export const ORDERS_START = BASE_URL + '/orders/start';
export const ORDERS_CHANGE_STATUS = BASE_URL + '/orders/change/status';
export const ORDERS_ASSIGN_COURIER = BASE_URL + '/orders/assign-courier-cors';

export const ORDERS_ACTIVE_ORDER = BASE_URL + '/orders/active-orders';
export const ORDERS_EVENT = BASE_URL + '/orders/event';
export const GET_PAYMENT = BASE_URL + '/orders/payment';
export const PAYMENT_DELIVERY = BASE_URL + '/payments/delivery';
export const SHIFTS_USER = BASE_URL + '/shifts/user';
export const SHIFTS_CURRENT = BASE_URL + '/shifts/current';
export const SHIFTS_START = BASE_URL + '/shifts/start';
export const SHIFTS_EVENT = BASE_URL + '/shifts/event';
export const SHIFTS_END = BASE_URL + '/shifts/end';
export const FORCE_SHIFTS_END= BASE_URL + '/shifts/end/force';
export const SHIFTS_LIST = BASE_URL + '/shifts/list';
export const SHIFTS_HISTORY = BASE_URL + '/shifts/history';
export const WORKING_DAYS = BASE_URL + '/working-days';
export const FILTER_WORKING_DAYS = BASE_URL + '/working-days/filter';
export const WORKING_DAYS_BY_COURIER = BASE_URL + '/working-days/courier';
export const DASHBOARD = BASE_URL + '/dashboard';
export const DASHBOARD_DATA = BASE_URL + '/dashboard/data';
export const DASHBOARD_DETAIL = DASHBOARD + '/list';
export const RESTAURANTS_DROPDOWN = BASE_URL + '/restaurants/dropdown';
export const USERS = BASE_URL + '/users';
export const GET_USER_DETAILS = BASE_URL + '/users/details';
export const RECONCILIATIONS = BASE_URL + '/reconciliation';
export const RECONCILIATIONS_DETAIL = BASE_URL + '/reconciliation/details';

export const COURIER_TYPES = BASE_URL + '/courier-types';
export const COURIER_TYPES_DETAIL = BASE_URL + '/courier-types/details';

export const HUB_BONUSES = BASE_URL + '/hub-bonuses';

export const PROCEEDS = BASE_URL + '/proceeds';



export const PROVINCES = BASE_URL + '/provinces';


export const ANNOUNCEMENTS = BASE_URL + '/announcements';
export const GET_ANNOUNCEMENT_DETAILS = BASE_URL + '/announcements/details';
export const SEND_AGAIN = BASE_URL + '/announcements/again';
export const ANNOUNCEMENTS_COURIERS = BASE_URL + '/announcements/courierlist';
export const MULTINET_ROLLBACK_WITH_SIGN =
  BASE_URL + '/multinet/rollbackWithSign';
  export const GET_CANCEL_PAYMENTS =
  BASE_URL + '/payments/canceled-payments/list';
export const TENANTS = BASE_URL + '/tenants';
export const COMPANIES = BASE_URL + '/companies';
export const BRANDS = BASE_URL + '/brands';




















