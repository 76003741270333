import {
  Box,
  Button,
  Chip,
  Fab,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { OrderReload, OrdersDynamicStatus, OrderStart } from "../../api/orders";
import { Order, Payment, OrderStatus } from "../../api/types";
import { PortiaTable } from "../../components/PortiaTable";
import { OrderRowActions } from "./components/order.row.actions";
import { OrderStatusChange } from "./order.status.change";
import { OrderCourierChange } from "./order.courier.change";
import general from "../../utils/general";
import { usePortia } from "../../context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { debounce, isEmpty } from "lodash-es";
import Authz from "../../components/Authz";
import i18n, { tr } from "../../translations";
import { Hubs } from "../../api/hubs";
import { DatePicker } from "../../components/DateRangePicker";
import { Refresh, Payment as PaymentIcon } from "@mui/icons-material";
import { addDays, startOfDay, endOfDay } from "date-fns";

export const OrdersAdminList = ({
  restaurantId,
  dynamicStatus,
}: {
  restaurantId?: any;
  dynamicStatus: Array<string>;
}) => {
  const { dropdownAlert } = usePortia();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const paymentMethodsRender = useCallback((row: any) => {
    return (
      <Box>
        {row.map((item: Payment) => {
          const strItem = item.amount.toString();
          const itemAmountLen = strItem.length;
          const subStringItem = strItem.substring(0, itemAmountLen - 2);
          const pennyAmount = "." + strItem.substring(itemAmountLen - 2);
          return (
            <Chip
              key={item.type + ": " + item.amount + ""}
              label={t(item.type) + ": " + subStringItem + pennyAmount}
              style={{ marginTop: 4 }}
              variant="outlined"
            />
          );
        })}
      </Box>
    );
  }, []);

  const columns: TableColumn<Order>[] = [
    {
      name: t("orderId"),
      selector: (row) => row.orderId,
      sortable: false,
      width: "200px",
      cell: (row) => (
        <Tooltip title={t("orderDetail")} arrow>
          <Button
            sx={{ textAlign: "left" }}
            style={{ fontSize: "12px" }}
            aria-label="detail"
            onClick={() => {
              navigate(`/orders/${row?.orderId}`);
            }}
          >
            {row.orderId}
          </Button>
        </Tooltip>
      ),
    },
    {
      name: t("refOrderId"),
      selector: (row) => row?.refOrderId || "-",
      cell: (row) => (
        <Tooltip title={row?.refOrderId || "-"}>
          <p> {row?.refOrderId || "-"} </p>
        </Tooltip>
      ),

      sortable: true,
      width: "10%",
      sortField: "refOrderId",
    },
    {
      name: t("Courier"),
      selector: (row) => row?.courier?.name,
      sortable: true,
      sortField: "courier.name",
      width: "125px",
      cell: (row) => (
        <Tooltip title={t("courierDetail")}>
          {row?.courier?.name ? (
            <Button
              sx={{ textAlign: "left" }}
              style={{ fontSize: "12px" }}
              aria-label="detail"
              onClick={() => {
                navigate(`/couriers/${row?.courier?.courierId}`);
              }}
            >
              {row?.courier?.name}
            </Button>
          ) : (
            <></>
          )}
        </Tooltip>
      ),
    },
    {
      name: t("date"),
      selector: (row) => row?.orderDate || "-",
      cell: (row) =>
        row?.orderDate &&
        new Date(row?.orderDate).toLocaleString(i18n.language),
      sortable: true,
      sortField: "orderDate",
    },
    {
      name: t("state"),
      selector: (row) => t(row?.status) || "-",
      cell: (row) => (
        <Tooltip title={t(row?.status) || "-"}>
          <div>
            <p>{t(row?.status) || "-"} </p>

            {row?.status == OrderStatus.CANCELED &&
              row?.fsmState?._event?.data?.cancelReason && (
                <p
                  style={{ background: "#feacac", borderRadius: 8, padding: 4 }}
                >
                  {t("cancelReason")}
                  {": "}
                  {row?.fsmState?._event?.data?.cancelReason}
                </p>
              )}
          </div>
        </Tooltip>
      ),
      sortable: true,
      sortField: "status",
    },
    {
      name: t("brand"),
      selector: (row) => row?.brand || "-",
      sortable: true,
      sortField: "brand",
    },
    {
      name: t("hub"),
      selector: (row) => row?.hub?.name || "-",
      sortable: true,
      sortField: "hub.name",
      width: "200px",
      cell: (row) => (
        <Tooltip title={t("hubDetail")}>
          <Button
            style={{ fontSize: "12px" }}
            sx={{ textAlign: "left" }}
            aria-label="detail"
            onClick={() => {
              navigate(`/hubs/${row?.hubId}`);
            }}
          >
            {row?.hub?.name}
          </Button>
        </Tooltip>
      ),
    },
    {
      name: t("restaurant"),
      selector: (row) => row?.restaurant?.name || "-",
      cell: (row) => (
        <Tooltip title={row?.restaurant?.name || "-"}>
          <p> {row?.restaurant?.name || "-"} </p>
        </Tooltip>
      ),
      width: "200px",
      sortable: true,
      sortField: "restaurant.name",
    },
    {
      name: t("channel"),
      selector: (row) => row?.orderSource || "-",
      sortable: true,
      sortField: "orderSource",
    },
    {
      name: t("customer"),
      selector: (row) => row?.firstName + " " + row?.lastName || "-",
      cell: (row) => (
        <Tooltip title={row?.firstName + " " + row?.lastName || "-"}>
          <p> {row?.firstName + " " + row?.lastName || "-"} </p>
        </Tooltip>
      ),
      sortable: true,
      sortField: "firstName",
    },
    {
      name: t("total"),
      selector: (row) =>
        parseFloat((Number(row?.total) / 100).toString()).toFixed(2) || "-",
      sortable: true,
      sortField: "total",
    },
    {
      name: t("paymentInfo"),
      selector: (row) => row?.orderId,
      cell: (row) =>
        row?.paymentColletions && row?.status === "COMPLETED"
          ? paymentMethodsRender(row?.paymentColletions?.payments)
          : "-",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "120px",
    },
    {
      cell: (row) => (
        <Authz allowedRoles={["portia-admin"]}>
          <OrderRowActions
            {...(row?.fsmState &&
              row?.assignedCourierId &&
              row?.fsmState?.nextEvents?.length > 0 &&
              row?.status != OrderStatus.COMPLETED &&
              row?.status != OrderStatus.CANCELED &&
              row?.status != OrderStatus.REJECTED && {
              onChangeStatus: () =>
                handleChangeOrderStatus(
                  row?.orderId,
                  row?.fsmState?.nextEvents,
                  row?.isPrePaid
                ),
            })}
            {...(row?.orderId &&
              row?.status != OrderStatus.COMPLETED &&
              row?.status != OrderStatus.CANCELED &&
              row?.status != OrderStatus.REJECTED && {
              onChangeCourier: () =>
                handleChangeOrderCourier(
                  row?.orderId,
                  row?.restaurantId,
                  row?.assignedCourierId
                ),
            })}
            {...((row?.status == OrderStatus.RECEIVED ||
              row?.status == OrderStatus.ASSIGNMENT_FAILED) && {
              onStartOrder: () => orderStart(row?.orderId),
            })}
            {...(row?.fsmState &&
              row?.assignedCourierId && !row?.fsmState?.nextEvents && {
              onOrderReload: () => orderReload(row?.orderId),
            })}

          // onDeliver={() => {
          //   console.log("onDeliver", row);
          // }}
          />
          <>
            {(row?.status === "PICKED" || (row?.status === "COMPLETED" && row?.payments.some((payment: Payment) => payment.type === "multinet"))) && row?.isPrePaid === false && (
              <Tooltip title={t("payment")} arrow>
                <Fab
                  sx={{ width: 32, height: 32, mr: 1 }}
                  color="secondary"
                  aria-label="edit"
                  onClick={() => navigate(`/payment/${row.orderId}`, { state: { isPrePaid: row?.isPrePaid } })}
                >
                  <PaymentIcon />
                </Fab>
              </Tooltip>
            )}
          </>
        </Authz>
      ),
      allowOverflow: true,
      button: true,
      width: "200px",
    },
  ];

  const handleChange = (selectedRows: any) => {
    // console.log("Selected Rows: ", selectedRows);
  };

  const handleChangeOrderStatus = useCallback(
    (orderId: any, nextEvents: any, isPrePaid: any) => {
      setStatusChangeData({ orderId: orderId, nextEvents: nextEvents, isPrePaid: isPrePaid });
      setStatusChangeModal(true);
    },
    []
  );

  const handleChangeOrderCourier = useCallback(
    (orderId: string, restaurantId: string, assignedCourierId: string) => {
      setCourierChangeData({
        orderId: orderId,
        restaurantId: restaurantId,
        assignedCourierId: assignedCourierId,
      });
      setCourierChangeModal(true);
    },
    []
  );

  //tab change reset
  useEffect(() => {
    setSearch("");
    setPerPage(50);
    setCurrentPage(1);
  }, [dynamicStatus]);

  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [selectedRestaurant, setSelectedRestaurant] = useState<string>(
    restaurantId || "all"
  );

  const [sortField, setSortField] = useState<string>("orderDate");
  const [sortDirection, setSortDirection] = useState("desc");

  const [openStatusChangeModal, setStatusChangeModal] = useState(false);
  const [openCourierChangeModal, setCourierChangeModal] = useState(false);
  const [showSelectedDays, setShowSelectedDays] = useState(true);

  const [statusChangeData, setStatusChangeData] = useState<Object>();
  const [courierChangeData, setCourierChangeData] = useState<Object>();

  const [selectDays, setSelectDays] = useState([
    {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);
  const [startDate, setStartDate] = useState(new Date(selectDays[0]?.startDate).getTime().toString());
  const [finishDate, setFinishDate] = useState(new Date(selectDays[0]?.endDate).getTime().toString());

  const [hubId, setHubId] = useState<string>("all");

  const { data, isLoading, isError, refetch } = OrdersDynamicStatus(
    selectedRestaurant || restaurantId,
    currentPage,
    perPage,
    search,
    hubId,
    startDate,
    finishDate,
    dynamicStatus,
    sortField,
    sortDirection
  );

  const { mutate: orderStart, data: orderStartData } = OrderStart();

  const { data: orders }: any = data || {};

  const { mutate: orderReloadMutate, data: orderReloadData } = OrderReload();
  const orderReload = (orderId: any) => {
    orderReloadMutate(orderId);
  }
  useEffect(() => {
    if (orderReloadData && orderReloadData?.status === 200) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("Sipariş Durumu Güncellenmiştir"),
      });
      refresh();
    }

  }, [orderReloadData])

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refresh();
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
    refresh();
  };

  useEffect(() => {
    if (!general.isNullOrEmpty(orderStartData)) {
      if (orderStartData && orderStartData.status === 200)
        dropdownAlert.current.alertWithType({
          type: "success",
          title: "OK",
          message: t("START_ORDER"),
        });
      refresh();
    }
  }, [orderStartData?.status]);

  const handleSearchChange = (searchKey: string) => {
    setSearch(searchKey);
    (searchKey.length == 0 || searchKey.length > 2) && searchRefresh();
  };

  const handleHubChange = useCallback((id: string) => {
    setHubId(id);
    refresh();
  }, []);

  const searchRefresh = debounce(() => {
    refetch();
  }, 500);

  const refresh = debounce(() => {
    refetch();
  }, 50);
  const handleOnChangeDate = (ranges: any) => {
    const { selection } = ranges;
    setSelectDays([selection]);
    setShowSelectedDays(true)
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(
      column?.sortField === undefined ? "orderDate" : column?.sortField
    );
    setSortDirection(sortDirection);
    refresh();
  };

  const showAllTime = () => {
    setShowSelectedDays(false);
    setStartDate("");
    setFinishDate("");
    refresh();
  };

  const orderFilter = () => {
    setShowSelectedDays(true)
    setStartDate(new Date(selectDays[0]?.startDate).getTime().toString());
    setFinishDate(new Date(selectDays[0]?.endDate).getTime().toString());
    refresh();
  };
  const orderTableRefresh = () => {
    refresh();
  };

  const { data: dataHub } = Hubs();
  const hubs = (dataHub && dataHub?.data?.items) || [];

 

  const handleExcelButton = () => {
    if (isEmpty(data?.data?.items)) {
      dropdownAlert.current.alertWithType({ type: 'error', title: "error", message: t('excelError') });
    }
    else{
      const excelCourierData = data?.data?.items?.map((order: Order) => {
        return {
          'Excel Oluşturma Tarihi': general.formatDateForDisplay(Date.now()),
          'Sipariş ID': order?.orderId,
          'Referans Sipariş ID': order?.refOrderId,
          'Kurye': order?.courier?.name,
          'Tarih': general.formatDateForDisplay(Number(order?.orderDate)),
          'Durum': t(`${order?.status}`),
          'Marka': order?.brand,
          'Bölge': order?.hub?.name,
          'Restoran': order?.restaurant?.name,
          'Kanal Adı': order?.orderSource,
          'Müşteri': order?.firstName + order?.lastName,
          'Toplam': order?.total / 100,
          'Ödeme Bilgileri': '-',
        };
      });
      const headerRow= [
      'Excel Oluşturma Tarihi',
      'Sipariş ID',
      'Referans Sipariş ID',
      'Kurye',
      'Tarih',
      'Durum',
      'Marka',
      'Bölge',
      'Restoran',
      'Kanal Adı',
      'Müşteri',
      'Toplam',
      'Ödeme Bilgileri',
      ];
      general.createExcelFromData(excelCourierData, headerRow,'Sipariş');
      dropdownAlert.current.alertWithType({ type: 'success', title: "OK", message: t('excelSuccess') });
    }
    }
   
  return (
    <>
      {orders && (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns}
          customFilter={
            <Grid container spacing={2}>
              <Authz allowedRoles={["portia-admin"]}>
                <Grid item lg={2} mt={-3}>
                  <InputLabel id="select-hub">{t("hub")}</InputLabel>
                  <Select
                    labelId="select-hub"
                    variant="outlined"
                    autoWidth
                    value={hubId}
                    onChange={(e) => handleHubChange(e.target.value)}
                    label={t("hub")}
                  >
                   
                        <MenuItem key="all" value={"all"}>
                          {t("all")}
                        </MenuItem>
                    
                    {hubs?.map((val: any) => (
                      <MenuItem key={val?.hubId} value={val?.hubId}>
                        {val?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item ml={1} mr={1} lg={1}>
                  <DatePicker
                    selectDays={selectDays}
                    handleOnChange={handleOnChangeDate}
                  />
                </Grid>

                <Grid item container spacing={2} lg={2}>
                 {showSelectedDays && (<>
                  <span style={{color:"#046746"}}>{t("startDate")+ ":"}</span>
                  <InputLabel style={{color:"black"}} id="select-days">{general.formatDateForDisplay(Number(selectDays[0].startDate))}</InputLabel>
                  <span style={{color:"#046746"}}>{t("endDate")+ ":"}</span>
                  <InputLabel style={{color:"black"}} id="select-days">{general.formatDateForDisplay(Number(selectDays[0].endDate))}</InputLabel>
                  </>)}
                </Grid>

                <Grid item lg={1}>
                  <Button
                    sx={{ mr: 2 }}
                    variant="contained"
                    style={{ float: "right" }}
                    onClick={() => orderFilter()}
                  >
                    {t("filter")}
                  </Button>
                </Grid>
                <Grid item ml={2} lg={1}>
                  <Button
                    variant="contained"
                    style={{ float: "right" }}
                    onClick={() => showAllTime()}
                  >
                    {t("showAllOrders")}
                  </Button>
                </Grid>
                <Grid item lg={2}>
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="refresh"
                    style={{ float: "right" }}
                    onClick={() => orderTableRefresh()}
                  >
                    <Refresh />
                  </Fab>
                </Grid>
                <Grid item xs={2} ml={6} mt={0}>
              <Button onClick={handleExcelButton} sx={{ border: '1px solid #046746' }}>{t('exportToExcel')}</Button>
            </Grid>
              </Authz>
            </Grid>
          }
          data={orders}
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          serverSideSearch
          onSearchChange={handleSearchChange}
          onSort={handleSort}
        />
      )}

      {statusChangeData?.hasOwnProperty("orderId") && (
        <OrderStatusChange
          openDialog={openStatusChangeModal}
          setOpenDialog={setStatusChangeModal}
          data={statusChangeData}
          onListingRefresh={(e: any) => refetch()}
        />
      )}

      {courierChangeData?.hasOwnProperty("orderId") && (
        <OrderCourierChange
          openDialog={openCourierChangeModal}
          setOpenDialog={setCourierChangeModal}
          data={courierChangeData}
          onListingRefresh={(e: any) => refetch()}
        />
      )}
    </>
  );
};
