// [START maps_react_map]
import React, { useState, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  GoogleMap,
  InfoWindow,
  MarkerF,
  OverlayView,
  useJsApiLoader,
} from "@react-google-maps/api";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import { CircularProgress, Fab, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Refresh from "@mui/icons-material/Refresh";
import MotorIcon from "./icons/motorcycle.png";
import './index.css';
import dayjs from "dayjs";
const containerStyle = {
  width: "100%",
  height: "600px",
};

const infoWindowStyle = {
  background: `white`,
  border: `1px solid ##CCC`,
  padding: 5,
  fontSize: 7,
};

export interface MapProps extends google.maps.MapOptions {
  center: google.maps.LatLngLiteral;
  zoom: number;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
  path?: google.maps.LatLngLiteral[];
  style?: { [key: string]: string };
}

const GoogleMapWrapper = ({
  center,
  zoom,
  onClick,
  onIdle,
  children,
  path,
}: MapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAIzuJqbrQz2_YVebyZfebfm1e0ItQrJ3w",
  });
  const [map, setMap] = useState<google.maps.Map>();
  const onLoad = useCallback((m: google.maps.Map) => {
    setMap(m);
  }, []);
  const onUnmount = useCallback(() => {
    setMap(undefined);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={onClick}
      onIdle={() => onIdle && onIdle(map as google.maps.Map)}
    >
      {children}
    </GoogleMap>
  ) : (
    <CircularProgress />
  );
};

export const PortiaMap = React.memo(GoogleMapWrapper);

export interface LocationProps {
  position: google.maps.LatLngLiteral;
  children?: React.ReactNode;
  title: string;
  title1?: string;
  path?: google.maps.LatLngLiteral[];
  couriersPosition?: any[];
  restaurantsPosition?: any[];
  onSave?: (map: google.maps.LatLngLiteral) => void;
  onRefresh?: any;
  isDisablePlate?: boolean;
}

export const LocationPicker = ({
  title,
  position,
  children,
  title1,
  path,
  onSave,
  couriersPosition,
  restaurantsPosition,
  onRefresh,
  isDisablePlate,
}: LocationProps) => {
  const { t } = useTranslation();
  const [zoom, setZoom] = useState(15); // initial zoom
  const [toggle, setToggle] = useState(false); // initial zoom
  const [editable, setEditable] = useState(false); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(position);
  const [latLng, setLatLng] = useState<google.maps.LatLngLiteral>(position);
  const [childrenData, setChildrenData] = useState<React.ReactNode>(children);
  const [isOpenedPositionInfo, setIsOpenedPositionInfo] = useState<boolean>(false);
  const [positionInfo, setPositionInfo] = useState<any>();
  const navigate = useNavigate();
  const onClick = (e: google.maps.MapMouseEvent) => {
    setLatLng(e.latLng!.toJSON());
    console.log(e.latLng!.toJSON());
  };

  const startRefreshInterval = useCallback(() => {
    const interval = setInterval(() => {
      onRefresh && onRefresh();
    }, 60000);
    return interval;
  }, [onRefresh]);

  const getCourierPositionInfo = (p: any) => {
    setPositionInfo(p);
    setIsOpenedPositionInfo(true);
  }

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (onRefresh) {
      intervalId = startRefreshInterval();

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }
  }, [onRefresh, startRefreshInterval]);

  const onIdle = (m: google.maps.Map) => {
    if (m) {
      setZoom(m.getZoom()!);
    }
  };
  useEffect(() => {
    if (position?.lat !== undefined) {
      setLatLng(position);
      setCenter(position);
    }
  }, [position]);

  useEffect(() => {
    if (children !== undefined) {
      setChildrenData(children);
    }
  }, [children]);

  useEffect(() => {
    setIsOpenedPositionInfo(false);
  }, [path]);

  return (
    <Box>
      <PortiaMap
        onClick={onClick}
        onIdle={onIdle}
        center={center}
        zoom={zoom}
        path={path}
      >
        {path?.map((p, i) => (
          <MarkerF
            key={"k" + p.lat + "-" + p.lng + "_" + i}
            position={p}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              strokeColor: "#8bc34a",
              scale: 4.0,
            }}
            onClick={() => getCourierPositionInfo(p)}
          />
        ))}
        {isOpenedPositionInfo && (
          <OverlayView
            position={positionInfo}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div>
              <div className="card">
                <span>
                  {positionInfo?.lat}, {positionInfo?.lng} <br />
                  <span>
                    {t("date")} = {dayjs(new Date(positionInfo?.time)).format("YYYY-MM-DD HH:mm:ss")}
                  </span>
                </span>
              </div>
            </div>
          </OverlayView>
        )}
        {restaurantsPosition?.map((p, i) => (
          <MarkerF
            key={"k" + p.lat + "-" + p.lng + "_" + i}
            position={p}
            icon={{
              url: p?.brandLogo,
            }}
            title= {p?.brandName}
          />
        ))}
        {couriersPosition?.map((p) => (
          <OverlayView
            position={p}
            key={
              "k" + p.lat + "-" + p.lng + p.courierId + p.courierName
            }
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          ><>
              <MarkerF
                title={
                  ""
                }
                key={
                  "k" + p.lat + "-" + p.lng + p.courierId + p.courierName
                }
                onLoad={() => { }}
                onClick={() =>
                  navigate(`/couriers/${p.courierId}`, { state: undefined })
                }
                position={p}
                icon={MotorIcon}
              >
              </MarkerF>
              {!isDisablePlate ? (
                <div>
                  <div className="card">
                    <span>
                      {p.courierName} <br />
                      <span>
                        {t("licensePlate")} = {p.licensePlate}
                      </span>
                    </span>
                  </div>
                </div>
              ) : null}
            </>
          </OverlayView>
        ))}
        <MarkerF
          position={latLng}
          draggable={editable}
          onDragEnd={(e) => {
            console.log(e.latLng!.toJSON());
            setLatLng(e.latLng!.toJSON());
          }}
          onLoad={() => { }}
          onClick={(e) => { setToggle(true); setLatLng(e.latLng!.toJSON()); }}
        >
          {toggle && (
            <InfoWindow position={center} onCloseClick={() => setToggle(false)}>
              <div style={infoWindowStyle}>
                <h1>{title}</h1>
                <h1>{latLng?.lat + ' - ' + latLng?.lng}</h1>
              </div>
            </InfoWindow>
          )}
        </MarkerF>
        {childrenData}
      </PortiaMap>
      {onRefresh ? (
        <FormGroup>
          <Box sx={{ m: 1 }}>
            <Fab
              size="small"
              color="primary"
              aria-label="refresh"
              onClick={() => onRefresh()}
            >
              <Refresh />
            </Fab>
          </Box>
        </FormGroup>
      ) : null}
      {onSave ?
        (
          <FormGroup>
            <Box sx={{ m: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editable}
                    onChange={(e) => setEditable(e.target.checked)}
                  />
                }
                label={t("editLocation")}
              />
              {
                editable ? (
                  <FormControl>
                    <TextField
                      sx={{ mr: 2 }}
                      size="small"
                      label={t("latitude")}
                      defaultValue={position?.lat}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { value }: any = e?.target;
                        setLatLng({
                          ...latLng,
                          lat: parseFloat(value.replace(/,/g, '.')),
                        });
                        setCenter({
                          ...center,
                          lat: parseFloat(value.replace(/,/g, '.'))
                        });
                      }}
                    />
                  </FormControl>
                ) : null
              }
              {
                editable ? (
                  <FormControl>
                    <TextField
                      sx={{ mr: 2 }}
                      size="small"
                      label={t("longtitude")}
                      defaultValue={position?.lng}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { value }: any = e?.target;
                        setLatLng({
                          ...latLng,
                          lng: parseFloat(value.replace(/,/g, '.')),
                        });
                        setCenter({
                          ...center,
                          lng: parseFloat(value.replace(/,/g, '.')),
                        });
                      }}
                    />
                  </FormControl>
                ) : null
              }
              {editable ? (
                <FormControl>
                  <Button
                    sx={{ mr: 2 }}
                    variant="contained"
                    onClick={() => {
                      onSave && onSave(latLng as google.maps.LatLngLiteral);
                      setEditable(false);
                      onSave && onSave(latLng);
                    }}
                  >
                    {t("save")}
                  </Button>
                </FormControl>
              ) : null}
              {editable ? (
                <FormControl>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setLatLng(position);
                      setCenter(position);
                      setEditable(false);
                    }}
                  >
                    {t("cancel")}
                  </Button>
                </FormControl>
              ) : null}
            </Box>
          </FormGroup>
        ) : null
      }
    </Box>
  );
};
