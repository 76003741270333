import { useQuery, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '../context';
import { useMutationWithDebounce } from './debounce';
import { POS } from './paths';
import { Pos } from './types';

export function PosList(page: number, limit: number,  hubId: string, sortDirection: string, sortField: string) {
  return useQuery(["couriers", page, limit], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(POS + `/list/${hubId}?page=${page}&limit=${limit}&sortField=${sortField}&sortDirection=${sortDirection}`),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function GetPosDevice(posId: string) {
  return useQuery(["getposdevice", posId], {
    queryFn: () =>
      !!apiClient && apiClient.get(POS + `/${posId}`),
    staleTime: 100,
    cacheTime: 100,
    retry: 0,
    enabled: false,
  });
}
export function CreatePosDevice(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (pos: Pos) => apiClient.post(POS, pos),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["pos"] });
    },
    debounceTime,
  });
}
export function PatchPosDevice(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (pos: any) =>
      apiClient.patch(POS+`/${pos.posId}`, pos),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["pos"] });
    },
    debounceTime,
  });
}
export function DeletePosDevice(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (pos: any) =>
      apiClient.delete(POS+`/${pos}`),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["pos"] });
    },
    debounceTime,
  });
}
