import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  AutocompleteElement,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import general from "../../utils/general";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { Order, OrderStatus, Payment } from "../../api/types";
import { usePortia } from "../../context";
import { useEffect, useState } from "react";
import { AssignOrderCors, GetOrder, OrderChangeStatus } from "../../api/orders";
import { Chip, Divider, Tab, Tabs, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OrderLocation } from "./orders.track";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
export const OrderDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const params = useParams();
  const {
    dropdownAlert,
  } = usePortia();
  const [tab, setTab] = useState(0);

  const [order, setOrder] = useState<Order>(location?.state);
  const orderId: any = params && params["orderId"];
  const {
    refetch: getCourier,
    data: orderData,
  } = GetOrder(orderId);

  useEffect(() => {
    if (orderId) {
      getCourier();
    }
  }, [params, orderId]);

  useEffect(() => {
    setOrder(orderData?.data);
  }, [orderData]);

  const {
    mutate: change,
    data: cResult,
  } = OrderChangeStatus();
  const { mutate: assignOrder, data: assignOrderData } = AssignOrderCors();

  const tabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const [cancelReasonElement, setCancelReasonElement] = useState(false);
  const changeStatus = (c: any) => {
    if (
      !general.isNullOrEmpty(orderData?.data?.orderId) &&
      !general.isNullOrEmpty(c?.status)
    )
      if (c?.status === 'complete') {
        navigate(`/payment/${orderData?.data?.orderId}`, {state:{isPrePaid: orderData?.data.isPrePaid}})
      } else {
        change({
          orderId: orderData?.data?.orderId,
          status: c?.status,
          ...(c?.cancelReason && { cancelReason: c?.cancelReason }),
        });
      }
  }
  useEffect(() => {
    if (!general.isNullOrEmpty(orderData?.data?.orderId)) {

      if (cResult && cResult.data.isSuccess) {
        dropdownAlert.current.alertWithType({
          type: "success",
          title: "OK",
          message: t("Sipariş Durumu Güncellenmiştir"),
        });
      }
    }


  }, [cResult]);
  console.log(orderData);
  const assignedCourier = () => {
    assignOrder(orderData?.data?.orderId)
  }
  useEffect(() => {
    if (!general.isNullOrEmpty(orderData?.data?.orderId)) {
      if (assignOrderData && assignOrderData.status === 200) {
        dropdownAlert.current.alertWithType({
          type: "success",
          title: "OK",
          message: t("success"),
        });
      }
    }
  }, [assignOrderData]);

  return (
    <Box sx={{ m: 2 }}>
      <Tabs value={tab} onChange={tabChange}>
        <Tab label={t('general')} />
        <Tab label={t('actions')} />
        <Tab label={t('courierTracing')} />
      </Tabs>
      <Box sx={{ border: 1, borderColor: "#eee" }}>
        <TabPanel value={tab} index={0}>
          <FormContainer values={order} >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  value={t(order?.status)}
                  label={t("orderStatus")}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <TextFieldElement
                  name={"firstName"}
                  label={t("customerName")}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <TextFieldElement
                  name={"lastName"}
                  label={t("lastName")}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              {
                [OrderStatus.CANCELED].includes(order?.status) && (
                  <Grid item xs={12}>
                    <TextFieldElement
                      name={"cancelReason"}
                      label={t("cancelReason")}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                )
              }
              <Grid item xs={4}>
                <TextFieldElement
                  name={"brand"}
                  label={t("brand")}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <TextFieldElement
                  name={"orderSource"}
                  label={t("orderSource")}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <TextFieldElement
                  name={"refOrderId"}
                  label={t("orderNumber")}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldElement
                  name={"refRestaurantId"}
                  label={t("restaurantRef")}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <TextFieldElement
                  name={"total"}
                  label={t("total")}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                {order?.payments && (
                  <TextField
                    label={t("paymentPreference")}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <>
                          {order.payments.map((item: Payment) => {
                            return (
                              <Chip
                                key={item.type}
                                label={t(item.type) + ": " + (Number(item.amount) / 100).toString()}
                                sx={{ mr: 2 }}
                                variant="outlined"
                              />
                            );
                          })}
                        </>
                      ),
                    }}
                  />
                )}
              </Grid>
              {
                [OrderStatus.COMPLETED].includes(order?.status) && (
                  <Grid item xs={12}>
                    {order?.paymentColletions?.payments && (
                      <TextField
                        label={t("paid")}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <>
                              {order?.paymentColletions?.payments?.map((item: Payment) => {
                                return (
                                  <Chip
                                    key={item.type}
                                    label={t(item.type) + ": " + (Number(item.amount) / 100).toString()}
                                    sx={{ mr: 2 }}
                                    variant="outlined"
                                  />
                                );
                              })}
                            </>
                          ),
                        }}
                      />
                    )}
                  </Grid>
                )
              }
              <Grid item xs={12}>
                <TextFieldElement
                  name={"customerNote"}
                  label={t("customerNote")}
                  InputProps={{
                    readOnly: true,
                  }}
                  multiline
                  rows={2}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldElement
                  name={"orderSummary"}
                  label={t("orderSummary")}
                  InputProps={{
                    readOnly: true,
                  }}
                  multiline
                  rows={3}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldElement
                  name={"address"}
                  label={t("address")}
                  InputProps={{
                    readOnly: true,
                  }}
                  multiline
                  rows={3}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color={"secondary"}
                  onClick={() => navigate(-1)}
                >
                  {t("back")}
                </Button>
              </Grid>

            </Grid>
          </FormContainer>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Box sx={{ m: 2 }}>
            {
              orderData?.data?.assignedCourierId && (
                <FormContainer onSuccess={(data) => changeStatus(data)}>
                  <Grid container spacing={2} columnSpacing={1} >
                    <Grid item xs={8} >
                      <AutocompleteElement
                        required
                        label={t("changeOrderStatus")}
                        name="status"
                        textFieldProps={{}}
                        options={orderData?.data?.fsmState?.nextEvents || []}
                        autocompleteProps={{
                          getOptionLabel: (option) => t(option),
                          isOptionEqualToValue: (option, value) => option === value,
                          onChange: (event, value) =>
                            setCancelReasonElement(value === "cancel" ? true : false),
                        }}
                        rules={{ required: t("required").toString() }}
                      />
                    </Grid>
                    <Grid item xs={8} >
                      {cancelReasonElement && (
                        <TextFieldElement
                          required
                          label={t("cancelReason")}
                          name="cancelReason"
                          fullWidth
                          validation={{ required: t("required").toString() }}
                        />
                      )}

                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        sx={{ mr: 2 }}
                        variant="contained"
                        type={"submit"}
                        color={"primary"}
                        disabled={orderData?.data?.status === OrderStatus.COMPLETED && orderData?.data?.status === OrderStatus.CANCELED && orderData?.data?.status === OrderStatus.REJECTED}
                      >
                        {t("save")}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </FormContainer>
              )
            }
            <Grid item xs={12} mt={2}>
              {
                !orderData?.data?.refSystem || orderData?.data?.refSystem === "CORS" ? (
                  <Button
                    sx={{ mr: 2 }}
                    variant="contained"
                    type={"submit"}
                    color={"primary"}
                    disabled={orderData?.data?.status !== OrderStatus.ASSIGNED}
                    onClick={() => assignedCourier()}
                  >
                    {t("sendCourierCors")}
                  </Button>
                ) : null
              }
            </Grid>
          </Box>
        </TabPanel>
      <TabPanel value={tab} index={2}>
        <OrderLocation assignedCourierId={orderData?.data?.assignedCourierId} />
        </TabPanel>
      </Box>

    </Box>
  );
};
