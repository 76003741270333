import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const en = {
  translation: {
    "☆Portia Backoffice☆": "☆Portia Backoffice☆",
    abort: "Cancel",
    ACCIDENT: "Accident",
    accounting_deduction: "Accounting Deduction",
    actions: "actions",
    active: "Active",
    activeCourierPercent: "Active Courier %",
    activeOrders: "Active Orders",
    add: "Add",
    addPayment: 'Add Payment',
    create: 'Create',
    add_update: "Add / Update",
    advance: "Advance",
    address: 'Customer Address',
    direction: 'Direction',
    latitude: 'Latitude',
    longitude: 'Longitude',
    adminManager: 'Admin Manager',
    all: "All",
    amount: "Amount",
    amountPerHour: "Amount Per Hour",
    amountPerPack: "Amount Per Pack",
    Announcement: "Announcement",
    announcementId: "Announcement ID",
    announcementDate: "Announcement Date",
    announcementEndDate: "Announcement End Date",
    announcementResendDate: 'Resend Date', 
    announcementDetail:"Announcement Detail",
    announcementFail: "Announcement Failed",
    announcementSent:"Announcement Sent",
    announcementSendCheck:"Are you sure you want to send the announcement?" ,
    announcementMessageAlert:"Please enter a message",
    announcementCourierAlert:"Please select a courier",
    announcementSendAgainCheck: "Are you sure you want to send the announcement again?",
    announcementDeliveryStatus: "Announcement Delivery",
    announcementText: "Announcement Text: ",
    announcementHub: "Send to all couriers of the selected hub",
    ASSIGNED: "Assigned",
    Approve: "Approve",
    approvedCourierOrder: 'Courier Unassigned Order',
    assignedOrders: "Assigned Orders",
    ATTACHED_TO_HUB_RESTAURANT:
      "Attached to hub restaurant. Cannot be Status False",
    AVAILABLE: "Available",
    availableCourier: "Available Courier",
    back: "Back",
    blocked: "Blocked",
    BLOCKED: "Blocked",
    blockReason: 'Block Reason',
    bonus: "Bonus",
    brand: "Brand",
    brandId: "Brand Id",
    brands: "Brands",
    break: "Break",
    BREAK: "Break",
    breakCourierCount: "Number of Couriers with Break",
    cancel: "Cancel",
    cancelReason: "Cancel Reason",
    cash: "Cash",
    carryLimit: "Carry Limit",
    changeCourier: "Change Courier",
    changeCourierError: "Failed to change courier!",
    changeCourierStatus: "Change courier status",
    changeCourierSuccess: "Courier changed.",
    changeOrderStatus: "Change order status",
    changeStatus: "Change Status",
    channel: "Channel Name",
    chooseDifferentDate: "Choose a different date!",
    clothing: "Clothing",
    CONTRACT: "Contract",
    contracted_courier_minpackage_quantity:
      "Contracted Courier Min Package Quantity",
    contracted_fixed_amount: "Contracted Fixed Amount",
    contractMinAmount: "Contract Min Amount",
    contractMinPackage: "Contract Min Package",
    COURIER_EMAIL_IN_USED: "Another use exists with the same email",
    COURIER_IN_USED: "Another use exists with the same user",
    COURIER_MOBILE_IN_USED: "Another use exists with the same  mobile",
    COURIER_TCKN_IN_USED: "Another use exists with the same tc",
    COURIER_TYPE_IS_USED: "Another use exists with the same courier type",
    COURIER_USERNAME_IN_USED: "Another use exists with the same username",
    Courier: "Courier",
    CourierActiveOrder: "Courier Active Orders",
    courierDetail: "Courier Detail",
    CourierDetailList: "Courier Detail List",
    courierFullName: "Courier Full Name",
    courierId: "CourierId Id",
    courierRequiredMessage: "Please select a courier.",
    Couriers: "Couriers",
    courierStatus: "Courier Status",
    courierTracing: "Courier Tracing",
    courierType: "Courier Type",
    courierTypeParameters: "Courier Type Parameters",
    CourierTypes: "Courier Type Parameter",
    CourierTypesDetail: "Courier Type Detail",
    company: "Company",
    companies: "Companies",
    companyId: "Company Id",
    creditcard: "Credit Card",
    creditCard: "Credit Card",
    createdBy:"Created By",
    couriersActive: "Active Couriers",
    couriersLogin: "Login Couriers",
    couriersLogout: "Logout Couriers ",
    couriersPicked: "Picked Couriers",
    customer: "Customer",
    customerName: "Customer Name",
    customerLastName: 'Customer Last Name',
    customerNote: "Customer Note",
    Dashboard: 'Control Panel',
    dataNotFound: "There are no records to display",
    date: "Date",
    dateTimeLocale: "en",
    daysStartingToday: "days starting today",
    daysUpToToday: "days up to today",
    DEDICATED: "Dedicated",
    Definitions: "Definitions",
    delete: "deleted.",
    deleteButton: "Delete",
    deleted: "Deleted",
    DELETED: "Deleted",
    deleteHub: "Do you want to delete the hub?",
    delivery: "Delivery",
    description: "Description", 
    district: "District",
    distance: "Distance",
    earning: "Earning",
    edit: "Edit",
    editLocation: "Edit Location",
    efficiency: "Efficiency",
    email: "E-Mail",
    end: "Shift End",
    endAt: "End At",
    endDate: "End Date",
    endCourierShift: "Do you want to end the courier's shift?",
    english: "English",
    earning_period: "Earning Period",
    FIELD_MISSING: "Required fields are missing",
    fileImport: "Import Kml file",
    filter: "Filter",
    finishDate: "Finish DateTime",
    finishTime: "Finish Time",
    firstName: "Name",
    fixed_amount: "Fixed amount",
    fixedAmount: "Fixed Amount",
    FREELANCE: "Freelance",
    firstStageDistance: "Stage 1",
    fourthStageDistance: "Stage 4",
    fifthStageDistance : "Stage 5",
    fuel: "Fuel",
    gastropay: "Gastropay",
    general: "General",
    gross_earnings: "Gross Earnings",
    helmet: "Helmet",
    homeMesage: "Welcome to the Homepage",
    homeTitle: "Home",
    hourly_rate: "Hourly rate",
    HUB_BONUS_NOT_FOUND: "Hub bonus not found",
    HUB_IN_USED_BY_RESTAURANT:
      "There are restaurants attached to the hub. You can not delete",
    HUB_NAME_IN_USED: "Another use exists with the same name",
    hub: "Zone",
    HubBonus: 'Hub Bonus',
    hubBonusDetail: "Bonus Detail",
    hubDetail: "Zone Detail",
    hubRequiredMessage: "Please select a zone .",
    hubs: "Zones",
    Hubs: "Zones",
    HUB_MANAGER_DRAFT: "Hub manager draft",
    inactive: "Inactive",
    jacket: "Jacket",
    JOKER: "Joker",
    keycloakId: 'Keycloak ID',
    kmlDelete : 'Kml File Delete',
    kmlFail: "Drawing detected that exceeds your region limits.",
    kmlError: "Error loading kml.",
    kmlSuccess: "Kml was installed successfully.",
    lastActionUser: "Last Action User",
    lastMonth: "Last Month",
    lastName: "Last Name",
    lastWeek: "Last Week",
    licensePlate: "License Plate",
    licensePlateMessage: "Invalid license plate",
    login: "login",
    loginCourier: "Login Courier",
    loginCourierCount: "Number of Couriers with Login",
    logout: "Log Out",
    makeaccident: "Accident",
    makeavailable: "Make Available",
    manager: "Manager",
    message: "Announcement Text",
    maxSpeed: "Maximum Speed",
    maxPackage: "Maximum Package",
    maxPackageRange: "Package Range Maximum Value",
    metropolcard: "Metropol Card",
    mergesettings: "Merge Settings",
    MIN_PACKAGE_COUNT_CANNOT_BE_GREATER_THAN_MAX:
      "Minimum package count cannot be greater than maximum package count !",
    mileage: "Mileage",
    minPackage: "Minimum Package",
    minPackageRange: "Package Range Minimum Value",
    minPackagePerDaily: "Min. Package Per Daily",
    mobile: "Mobile",
    mobileMessage: "Invalid mobile phone 05#######",
    motor_installment_rent: "Motor Installment / Rent",
    motor_rental_day: "Motor Rental Day",
    motorcycle: "Motorcycles",
    refund: 'Refund All Amount',
    multinet: "Multinet",
    endOftheDayRefundErrorMessage: 'Please try partial refund at the end of the day.',
    multinetRefundAmount: 'Multinet Refund Amonut',
    multinetRollbackSuccess: 'The multinet refund process was completed successfully.',
    multinetRollbackError: 'The multinet refund operation failed.',
    dayEndRefund: 'Refund it at the end of the day',
    name: "Name",
    nameMessage:
      "It contains invalid characters and must not contain special characters or numbers",
    net_earnings: "Net Earnings",
    noCourierFound: "No courier found",
    NOT_FOUND_COURIER: "Not found courier",
    NOT_FOUND_HUB: "Not found hub",
    NOT_TAKE_A_BREAK: "You can't go on a break until the other couriers' break is over",
    notAssignedOrders: "Not Assigned Orders",
    numberOfActiveAssignedPackets: 'Number Of Active Assigned Packets',
    number_of_courier: "Number of Selected Couriers: ",
    number_of_days_worked: "Number of days worked",
    number_of_packages: "Number of packages",
    numberOfPackagesCarriedDaily: 'Number Of Packages Carried Daily',
    OFFLINE: "Offline",
    offlineCourierCount: "Number of Couriers with Logout",
    online:"Online",
    onlinePayment: "Online Payment",
    Order: "Order",
    orderDetail: "Order Detail",
    OrderGetPayment: "Order Get Payment",
    orderId: "Order ID",
    orderNumber: "Order Number",
    Orders: "Orders",
    orderSource: "Order Channel",
    orderStatus: "Order Status",
    orderSummary: "Order Summary",
    other_deductions: "Other Deductions",
    OTHER: "Other",
    PASSIVE: "Passive",
    paid: "Paid",
    isPrepaid: 'Is Prepaid?',
    pant: "Pant",
    password: "Password",
    passwordError: "Passwords are not the same",
    passwordMessage:
      "Must be at least six characters, contain numbers and special characters",
    passwordRepeat: "Password Repeat",
    pastOrders: "Past Orders",
    parameterToUse: "Parameter To Use",
    paycell: "Paycell",
    payecard: "Paye Card",
    PAYMENT_TYPE_IS_USED: "Another use exists with the same payment type",
    payment: "Payment",
    paymentId: "Payment ID",
    paymentInfo: "Payment Info",
    paymentMethods: "Payment Methods",
    payments: "Payments",
    paymentType: "Payment Type",
    paymentAmount: 'Payment Amount',
    paymentTypeDefinition: "Payment Type Definitions",
    PaymentTypes: "Payment Types",
    PAYROLL: "Payroll",
    pdfDowloand: "PDF Dowloand",
    per_package_multiplier: "Per package multiplier",
    pickup: "Picked Up",
    POOL: "Pool",
    POS_IN_USED: "Pos device is used",
    Pos: "Pos Devices",
    posDefinition: "Pos Device Definitions",
    posId: "Pos ID",
    PosList: "Pos Device List",
    posSerial: "Pos Serial",
    proceeds_draft:'Saved By Draft',
    proceeds_saved:'Saved',
    previousAnnouncement: "Previous Announcements",
    receiptNo: "Receipt No",
    receiver: "Receivers",
    reconciliation_date: "Reconciliation Date",
    reconciliation: "Reconciliation",
    reconciliationId: "Reconciliation Id",
    remainingAmount: "Remaining Amount",
    required: "Required",
    RESTAURANT_NOT_FOUND: "Restaurant not found",
    RESTAURANT_REFID_USED: "Another use exists with the same refRestaurantId",
    restaurant: "Restaurant",
    Restaurant: "Restaurant",
    restaurantRef: "Restaurant Reference ID",
    Restaurants: "Restaurants",
    rowsPerPageText: "Rows per page",
    SAME_HUB_BONUS_EXISTS: "Same hub bonus exists !",
    save_as_draft: "Save as Draft",
    save: "Save",
    saved: "Saved",
    paymentPreference: "Payment Preference",
    search: "Search",
    send: "Send",
    sendAgain:"Send Again",
    selectDate: "Choose Date",
    sendCourierCors: "Send Courier to CORS",
    systemReference: 'System Reference',
    SERIAL_IS_USED: "Another use exists with the same pos serial",
    setcard: "Set Card",
    secondStageDistance: "Stage 2",
    shiftDetail: "ShiftDetail",
    shiftInfo: "Shift Info",
    Shifts: "Shifts",
    shiftStatus: "Shift Status",
    shirt: "Shirt",
    showAllOrders: "Show All Orders",
    sodexo: "Sodexo",
    STABLE: "Stable",
    START_ORDER: "Start order",
    startAt: "Start At",
    startDate: "Start DateTime",
    startOrder: "Start Order",
    startOvertime: "Start Overtime",
    startTime: "Start Time",
    state: "State",
    stateRequiredMessage: "Please select a state.",
    statistics: "Statistics",
    status: "Status",
    statusUpdate: "Status Updated.",
    success: "Successfully Done",
    summary: "Summary",
    tabActiveOrders: "ACTIVE ORDERS",
    tabAssignedOrders: "ASSIGNED ORDERS",
    tabHubBonus: "BONUSES",
    tabOrders: "ORDERS",
    tabPastOrders: "PAST ORDERS",
    tabPaymentType: "PAYMENT TYPES",
    tabPos: "POS DEVICES",
    tabShift: "SHIFT INFOS",
    tabStatistics: "STATISTICS",
    tabTracing: "TRACING",
    tabWorkingDay: "KURYE ÇALIŞMA GÜNLERİ",
    tabMergeSettings : "MERGE SETTINGS",
    takebreak: "Take Break",
    tax_base: "Tax base",
    tckn: "TCKN",
    tcknMessage: "Invalid TC",
    tenantRequiredMessage: "Please select a tenant.",
    THERE_IS_ORDER:
      "There is an order in this restaurant. Cannot be Status False",
    thisMonth: "This Month",
    thisWeek: "This Week",
    thirdStageDistance: "Stage 3",
    ticket: "Ticket",
    today: "Today",
    before15minutes: "15 minutes before",
    before30minutes: "30 minutes before",
    beforeAnHour: "1 hour before",
    before6Hour: "6 minutes before",
    tenant: "Tenant",
    tenants: "Tenants",
    tenantId: "TenantId Id",
    total: "Total",
    totalAmount: "Total Amount",
    totalComplateOrders: "Total Orders Delivered",
    totalCourier: "Total Courier",
    totalCourierCount: "Total Number of Couriers Using the App",
    transfer_amount: "Transfer Amount",
    turkish: "Turkish",
    type: "Type",
    typeRequiredMessage: "Please select the type of the courier",
    update: "Update",
    updateReason: "Update Reason",
    USER_IN_USED: "Another use exists with the same username, email",
    USER_NOT_UPDATE: "User Update Not Working",
    User: "User",
    userDefinition: "User Definitions",
    userMail: "Invalid E-mail",
    username: "User Name",
    userNameMessage: "Invalid user name",
    Users: "Users",
    userType: "User Type",
    userTypeRequiredMessage: "Please select the type of the user",
    vat_deduction_will_be_applied: "VAT Deduction will be applied",
    vat: "VAT",
    vehicleType: "Vehicle Type",
    working_hours: "Working hours",
    workingDate: "Working Date",
    WorkingDay: "Courier Working Day",
    WorkingDays: "Working Days",
    exportToExcel: 'Export to Excel',
    excelError: 
    'The Excel file cannot be created because there is no data.',
    excelSuccess: 'The Excel file has been created successfully.',
    yesterday: "Yesterday",
    All: 'All',
    zoneManager: "Zone Manager",
    orderStatusEvent: "You cannot delivery of the order before it's ready.",
    activeCourierCount: "Number Of Active Couriers",
    activeOrderCount: "Number Of Active Orders",
    numberOfOrderByCourier: "Number Of Orders By Courier",
    assignedOrderToCourier: "Assigned Order To Courier",
    unAssignedOrderToCourier: "Unassigned Order To Courier",
    numberOfOrder: "Total Order",
    orderMergeDistance: "Order Merge Distance (Meter)",
    vknNumber: 'VKN (Tax Identification Number)',
    vknValidationMessage:  
    'Please enter a valid VKN number. VKN number must be 10 digits',
    terminalId: 'Terminal No',
    merchantId: 'Merchant No',
    posInfo: 'Pos Information',
    successfulUpdatePosInfo: 'Pos information was successfully updated.',
    unSuccessfulUpdatePosInfo: 'An error occurred while updating pos information.',
  },
};
export const tr = {
  translation: {
    "☆Portia Backoffice☆": "☆Portia Backoffice☆",
    abort: "Vazgeç",
    ACCIDENT: "Kaza",
    accounting_deduction: "Muhasebe Kesintisi",
    actions: "AKSİYONLAR",
    active: "Aktif",
    activeCourierPercent: "Aktif Kurye %",
    activeOrders: "Aktif Siparişler",
    add: "Ekle",
    addPayment: 'Ödeme Ekle',
    create: 'Oluştur',
    add_update: "Ekle / Güncelle",
    advance: "Avans",
    address: 'Müşteri Adresi',
    direction: 'Yön',
    latitude: 'Enlem',
    longitude: 'Boylam',
    adminManager: 'Admin Yönetici',
    all: "Tümü",
    amount: "Tutar",
    amountPerHour: "Saat Başına Ücret",
    amountPerPack: "Paket Başına Ücret",
    Announcement: "Duyurular",
    announcementId: "Duyuru Id",
    announcementDate: "Duyuru Tarih",
    announcementEndDate: "Duyuru Bitiş Tarih",
    announcementResendDate: 'Tekrarlanma Tarihi', 
    announcementDetail:"Duyuru Detay",
    announcementFail: "Duyuru Gönderilemedi",
    announcementSent:"Duyuru Gönderildi",
    announcementSendCheck: "Duyuruyu göndermek istediğinize emin misiniz?",
    announcementMessageAlert:"Lütfen bir mesaj giriniz",
    announcementCourierAlert: "Lütfen bir kurye seçiniz",
    announcementSendAgainCheck: "Duyuruyu tekrar göndermek istediğinize emin misiniz?",
    announcementDeliveryStatus: "Duyuru Durumu",
    announcementText: "Duyuru Metni: ",
    announcementHub: "Seçilen bölgedeki bütün kuryelere gönder",
    APPROVED: "Onaylandı",
    Approve: "Onayla",
    approvedCourierOrder: 'Kurye Atanmamış Sipariş',
    assign: "Atandı",
    ASSIGNED: "Sipariş Atandı",
    assignedOrders: "Atanmış Siparişler",
    assignFail: "Atama başarısız",
    ASSIGNMENT_FAILED: "Atama Başarısız",
    assignwork: "İş Ata",
    ATTACHED_TO_HUB_RESTAURANT:
      "Bölgeye bağlı restoran bulunmaktadır.Durumunu değiştiremessiniz.",
    AVAILABLE: "Müsait",
    availableCourier: "Boşta Kurye",
    averageSpeed: "Ortalama Hız",
    back: "Geri Dön",
    blocked: "Engellenmiş",
    BLOCKED: "Engellenmiş",
    blockReason: 'Engellenme Sebebi',
    bonus: "Bonus",
    brand: "Marka",
    brandId: "Marka Id",
    brands: "Markalar",
    brandRequiredMessage: "Lütfen marka seçiniz.",
    break: "Mola",
    BREAK: "Mola",
    breakCourierCount: "Molada Olan Kurye Sayısı ",
    cancel: "İptal",
    CANCELED: "İptal Edildi",
    cancelReason: "İptal Nedeni",
    cash: "Nakit",
    carryLimit: "Taşınabilecek Paket Sayısı",
    changeCourier: "Kurye Değiştir",
    changeCourierError: "Kurye değişikliği yapılamamıştır!",
    changeCourierStatus: "Kuryenin durumunu değiştir",
    changeCourierSuccess: "Kurye değişikliği yapılmıştır.",
    changeOrderStatus: "Sipariş durum değiştir",
    changeStatus: "Durum Değiştir",
    channel: "Kanal Adı",
    chooseDifferentDate: "Farklı bir tarih seçin!",
    clothing: "Elbise",
    complete: "Tamamla",
    COMPLETED: "Tamamlandı",
    CONTRACT: "Sözleşmeli",
    contracted_courier_minpackage_quantity: "Sözleşmeli Kurye Min. Paket Adedi",
    contracted_fixed_amount: "Sözleşmeli Sabit Tutar",
    contractMinAmount: "Sabit Minimum Ücret",
    contractMinPackage: "Sabit Minimum Paket",
    COURIER_EMAIL_IN_USED: "Girdiğiniz email adresi kullanılmaktadır.",
    COURIER_IN_USED: "Girdiğiniz kullanıcı kullanılmaktadır.",
    COURIER_MOBILE_IN_USED: "Girdiğiniz telefon kullanılmaktadır.",
    COURIER_TCKN_IN_USED: "Girdiğiniz TC kullanılmaktadır.",
    COURIER_TYPE_IS_USED: "Girdiğiniz kurye tipi sistemde kayıtlıdır",
    COURIER_USERNAME_IN_USED: "Girdiğiniz kullanıcı adı kullanılmaktadır.",
    courier: "Kurye",
    Courier: "Kurye",
    CourierActiveOrder: "Kurye Aktif Siparişler",
    courierDetail: "Kurye Detay",
    CourierDetailList: "Kurye Detay Listesi",
    courierFullName: "Kurye Adı Soyadı",
    courierId: "Kurye Id",
    courierRequiredMessage: "Lütfen kurye seçiniz.",
    Couriers: "Kuryeler",
    courierTracing: "izleme",
    courierType: "Kurye Tipi",
    courierTypeParameters: "Kurye Tip Parametreleri",
    CourierTypes: "Kurye Tip Parametresi",
    CourierTypesDetail: "Kurye Tip Parametre Detay",
    company: "Şirket",
    companies: "Şirketler",
    companyId: "Şirket Id",
    COURIER_DRAFT: "Kurye Onayladı",
    courierStatus: "Kurye Durumu",
    creditcard: "Kredi Kartı",
    creditCard: "Kredi Kartı",
    createdBy: "Duyuruyu Gönderen",
    couriersActive: "Aktif Olan Kuryeler",
    couriersLogin: "Login Olan Kuryeler",
    couriersLogout: "Logout Olan Kuryeler",
    couriersPicked: "Yolda Olan Kuryeler",
    customer: "Müşteri",
    customerName: "Müşteri Adı",
    customerLastName: 'Müşteri Soyadı',
    customerNote: "Müşteri Notu",
    Dashboard: 'Kontrol Paneli',
    dataNotFound: "Kayıt bulunamadı",
    date: "Tarih",
    dateTimeLocale: "tr",
    daysStartingToday: "Bugünden ... sonra",
    daysUpToToday: "Bugünden ... önce",
    DEDICATED: "Dedike",
    Definitions: "Tanımlamalar",
    delete: "Silindi.",
    deleteButton: "Sil",
    deleted: "Silinmiş",
    DELETED: "Silinmiş",
    deleteHub: "Bölgeyi silmek istiyor musunuz ?",
    DELIVERY_ASSIGNED: "Dağıtımda",
    DELIVERY: "Paket Dağıtım",
    delivery: "Teslim Et",
    demandbreak: "Mola Talep Et",
    description: "Tanımlama", 
    DISABLED: "Kapalı",
    district: "İlçe",
    distance: "Mesafe",
    edit: "Düzenle",
    editLocation: "Konumu Düzenle",
    efficiency: "Verimlilik",
    email: "E-Mail",
    end: "Mesaiyi Bitir",
    endAt: "Bitiş",
    endDate: "Bitiş Tarihi",
    endbreak: "Molayı Bitir",
    endCourierShift: "Kuryenin vardiyasını sonlandırmak istiyor musunuz?",
    english: "İngilizce",
    earning_period: "Hakediş Dönemi",
    FIELD_MISSING: "Lütfen zorunlu alanları doldurun",
    fileImport: "Kml dosyası yükle",
    filter: "Filtrele",
    finishDate: "Bitiş Tarihi",
    finishTime: "Bitiş Zamanı",
    firstName: "Ad",
    fixed_amount: "Sabit tutar",
    fixedAmount: "Sabit Ücret",
    firstStageDistance: "1.Kademe",
    fourthStageDistance: "4.Kademe",
    fifthStageDistance: "5.Kademe",
    FREELANCE: "Serbest",
    friday: "Cuma",
    fuel: "Yakıt",
    gastropay: "Gastropay",
    general: "Genel",
    gross_earnings: "Brüt Hakediş",
    heading: "Rota",
    helmet: "Kask",
    homeMesage: "Anasayfaya Hoşgeldiniz",
    homeTitle: "Anasayfa",
    hourly_rate: "Saat başı tutar",
    HUB_BONUS_NOT_FOUND: "Hub bonusu bulunamadı",
    HUB_IN_USED_BY_RESTAURANT:
      "Bölgeye bağlı restoran bulunmaktadır.Silme işlemi yapamazsınız.",
    HUB_NAME_IN_USED: "Girdiğiniz bölge adı kullanılmaktadır.",
    hub: "Bölge",
    HubBonus: 'Bölge Bonus',
    hubBonusDetail: "Bonus Detay",
    hubDetail: "Bölge Detay",
    hubRequiredMessage: "Lütfen bölge seçiniz.",
    hubs: "Bölgeler",
    Hubs: "Bölgeler",
    HUB_MANAGER_DRAFT: "Bölge Yöneticisi Onayladı",
    IN_DELIVERY: "Dağıtımda",
    inactive: "Pasif",
    jacket: "Ceket",
    JOKER: "Joker",
    keycloakId: 'Keycloak ID',
    kilometer: "Kilometre",
    kmlDelete : 'Kml Dosyası Sil',
    kmlFail: "Bölge sınırlarınızı aşan çizim tespit edildi.",
    kmlError: "Kml yüklenirken hata oluştu.",
    kmlSuccess: "Kml başarılı yüklendi.",
    lastActionUser: "Son İşlem Yapan Kullanıcı",
    lastMonth: "Geçen Ay",
    lastName: "Soyad",
    lastWeek: "Geçen Hafta",
    licensePlate: "Plaka",
    licensePlateMessage: "Geçersiz plaka",
    login: "Giriş Yap",
    loginCourier: "Login Kurye",
    logout: "Çıkış Yap",
    loginCourierCount: "Login Olan Kurye Sayısı",
    longtitude: "B",
    makeaccident: "Kaza",
    makeavailable: "Müsait Yap",
    manager: "Yönetici",
    maxSpeed: "Maksimum Hız",
    maxPackage: "Maksimum Paket",
    maxPackageRange: "Paket Aralığı Maksimum Değer",
    MEAL_BREAK: "Yemek Molası",
    MEAL: "Yemek",
    message: "Duyuru Metni",
    mergesettings: "Birleştirme Ayarları",
    metropolcard: "Metropol Kart",
    MIN_PACKAGE_COUNT_CANNOT_BE_GREATER_THAN_MAX:
      "Minimum paket sayısı maksimum paket sayısından büyük olamaz !",
    mileage: "Kilometre",
    minPackage: "Minimum Paket",
    minPackageRange: "Paket Aralığı Minimum Değer",
    minPackagePerDaily: "Günlük Min. Paket Sayısı",
    mobile: "Telefon",
    mobileMessage: "Geçersiz telefon 5#######",
    monday: "Pazartesi",
    motionStatus: "Hareket Durumu",
    motor_installment_rent: "Motor Taksit / Kira",
    motor_rental_day: "Motor Kira Gün",
    motorcycle: "Motor",
    refund: 'Tamamını İade Et',
    endOftheDayRefundErrorMessage: 'Lütfen \'Gün sonunda parçalı iade\'yi deneyin.',
    multinetRefundAmount: 'Multinet İade miktarı',
    multinetRollbackSuccess: 'Multinet iade işleminiz başarıyla gerçekleşmiştir.',
    multinetRollbackError: 'Multinet iade işlemi başarısız.',
    dayEndRefund: 'Gün sonunda parçalı iade et',
    name: "Ad-Soyad",
    nameMessage:
      "Geçersiz karakter içeriyor, özel karakter veya rakam bulundurmamalıdır.",
    net_earnings: "Net Hakediş",
    New: "Yeni",
    noCourierFound: "Kurye bulunamadı",
    NOT_FOUND_COURIER: "Kurye bilgisi bulunamadı",
    NOT_FOUND_HUB: "Bölge bulunamadı",
    NOT_TAKE_A_BREAK: "Diğer kuryelerin molası bitmeden molaya çıkmazsınız.",
    notAssignedOrders: "Kurye Atamayı Bekleyen Sipariş",
    notCharging: "Şarj Olmuyor",
    notMoving: "Hareketsiz",
    nowSpeed: "Anlık Hız",
    numberOfActiveAssignedPackets: 'Aktif Atanan Paket Sayısı',
    number_of_courier: "Seçilen Kurye Sayısı: ",
    number_of_days_worked: "Çalışılan Gün Sayısı",
    number_of_packages: "Paket Sayısı",
    numberOfPackagesCarriedDaily: 'Günlük Taşınan Paket Sayısı',
    OFFLINE: "Çevrimdışı",
    offlineCourierCount: "Login Olmayan Kurye Sayısı",
    onCharging: "Şarj Oluyor",
    onMoving: "Hareket Halinde",
    online:"Online",
    onlinePayment: "Online Ödeme",
    Order: "Sipariş",
    orderDetail: "Sipariş Detayı",
    OrderGetPayment: "Sipariş Ödemesi Al",
    orderId: "Sipariş ID",
    orderNumber: "Sipariş Numarası",
    Orders: "Siparişler",
    orderSource: "Sipariş Kanalı",
    orderStatus: "Sipariş Durumu",
    orderSummary: "Sipariş Özeti",
    other_deductions: "Diğer Kesintiler",
    OTHER: "Diğer",
    PASSIVE: "Pasif",
    paid: "Ödenen",
    isPrepaid: 'Ön Ödemeli mi?',
    pant: "Pantolon",
    password: "Parola",
    passwordError: "Parolalar eşit değil.",
    passwordMessage:
      "En az altı karakter olmalı, rakam ve özel karakter içermelidir",
    passwordRepeat: "Parola Tekrar",
    pastOrders: "Geçmiş Siparişler",
    parameterToUse: "Kullanılacak Parametre",
    payecard: "Paye Kart",
    PAYMENT_TYPE_IS_USED: "Girdiğiniz ödeme tipi sistemde kayıtlıdır",
    payment: "Ödeme",
    paymentId: "Ödeme ID",
    paymentInfo: "Ödeme Bilgileri",
    paymentMethods: "Ödeme Yöntemleri",
    payments: "Ödemeler",
    paymentType: "Ödeme Tipi",
    paymentAmount: 'Ödeme Tutarı',
    paymentTypeDefinition: "ÖDEME TiPi",
    PaymentTypes: "Ödeme Türleri",
    PAYROLL: "Bordrolu",
    pdfDowloand: "PDF İndir",
    per_package_multiplier: "Paket başı çarpanı",
    PICKED: "Paket Alındı",
    pickup: "Teslim alındı",
    POOL: "Havuz",
    POS_IN_USED: "Pos cihazı kullanılmaktadır",
    Pos: "Pos Cihazları",
    posDefinition: "Pos Cihazı",
    posId: "Pos ID",
    PosList: "Pos Cihazı Listesi",
    posSerial: "Pos Seri No",
    proceeds_draft:'Taslak olarak kaydedildi',
    proceeds_saved:'Onaylandı',
    province: "İl",
    previousAnnouncement: "Gönderilenler",
    receiptNo: "Fiş Numarası",
    RECEIVED: "Kabul Edildi",
    receiver: "Gönderilen Kuryeler",
    reconciliation_date: "Mutabakat Tarihi",
    reconciliation: "Mutabakat",
    reconciliationId: "Mutabakat Id",
    reconciliations: "Mutabakatlar",
    RECONCILIATION_APPROVED: "Mutabakat onaylandı",
    refOrderId: "Referans Sipariş ID",
    REJECTED: "Reddedildi",
    remainingAmount: "Kalan Tutar",
    required: "Zorunlu",
    REST: "Mola",
    RESTAURANT_NOT_FOUND: "Restoran kayıdı bulunamadı",
    RESTAURANT_REFID_USED: "Girdiğiniz restoran referans idsi kullanılmaktadır",
    restaurant: "Restoran",
    Restaurant: "Restoran",
    restaurantRef: "Restoran Referans ID",
    Restaurants: "Restoranlar",
    rowsPerPageText: "Sayfalar",
    SAME_HUB_BONUS_EXISTS: "Bu bonus zaten mevcut !",
    saturday: "Cumartesi",
    save_as_draft: "Taslak Olarak Kaydet",
    save: "Kaydet",
    saved: "Kayıt Edildi",
    paymentPreference: "Ödeme Tercihi",
    search: "Arama",
    selectDate: "Tarih",
    send: "Gönder",
    sendAgain: "Tekrar Gönder",
    sendCourierCors: "Kuryeyi CORSA Gönder",
    systemReference: 'Sistem Referansı',
    SERIAL_IS_USED: "Girdiğiniz seri numarası sistemde kayıtlıdır.",
    setcard: "Set Kart",
    Settings: "Ayarlar",
    secondStageDistance: "2.Kademe",
    shiftDetail: "Vardiya Detayı",
    shiftInfo: "Vardiya Bilgileri",
    Shifts: "Vardiya",
    shiftStatus: "Vardiya Durumu",
    shirt: "Tişört",
    showAllOrders : "Tüm Siparişleri Göster",
    speed: "Hız",
    STABLE: "Sabit",
    START_ORDER: "Sipariş başlatıldı",
    startAt: "Başlangıç",
    startDate: "Başlangıç Tarihi",
    startOrder: "Siparişi Başlat",
    startOvertime: "Mesaiye Başla",
    startTime: "Başlama Zamanı",
    state: "Durum",
    stateCharge: "Şarj Durumu",
    stateRequiredMessage: "Lütfen durum seçiniz.",
    statistics: "istatistik",
    status: "Durum",
    statusUpdate: "Durum güncellendi.",
    success: "İşlem Başarılı",
    summary: "Özet",
    sunday: "Pazar",
    tabActiveOrders: "AKTİF SİPARİŞLER",
    tabAssignedOrders: "ATANMIŞ SİPARİŞLER",
    tabHubBonus: "BONUSLAR",
    tabOrders: "SİPARİŞLER",
    tabPastOrders: "GEÇMİŞ SİPARİŞLER",
    tabPaymentType: "ÖDEME TİPLERİ",
    tabPos: "POS CİHAZLARI",
    tabShift: "VARDİYA BİLGİLERİ",
    tabStatistics: "İSTATİSTİK",
    tabTracing: "İZLEME",
    tabWorkingDay: "KURYE ÇALIŞMA GÜNLERİ",
    tabMergeSettings : "BİRLEŞTİRME AYARLARI",
    takebreak: "Molaya Çık",
    TASK_ASSIGNED: "Görevli",
    TASK: "Görev",
    tax_base: "Matrah",
    tckn: "TCKN",
    tcknMessage: "Geçersiz TC",
    tenantRequiredMessage: "Lütfen tenant seçiniz.",
    THERE_IS_ORDER:
      "Restorana ait sipariş bulunmaktadır.Durumunu değiştiremessiniz.",
    thisMonth: "Bu Ay",
    thisWeek: "Bu Hafta",
    thirdStageDistance: "3.Kademe",
    thursday: "Perşembe",
    today: "Bugün",
    before15minutes: "15 dakika önce",
    before30minutes: "30 dakika önce",
    beforeAnHour: "1 saat önce",
    before6Hour: "6 saat önce",
    tenant: "Tenant",
    tenants: "Tenants",
    tenantId: "TenantId Id",
    total: "Toplam",
    totalAmount: "Toplam Tutar",
    totalComplateOrders: "Toplam Teslim Edilen Sipariş",
    totalCourierCount: "Toplam Uygulamayı Kullanan Kurye Sayısı",
    totalCourier: "Toplam Kurye",
    transfer_amount: "Havale Tutarı",
    tuesday: "Salı",
    turkish: "Türkçe",
    type: "Tip",
    typeRequiredMessage: "Lütfen tip seçiniz.",
    UNSET: "Belirsiz",
    update: "Güncelle",
    updateReason: "Güncelleme Nedeni",
    lastUpdateReason: "Son Güncelleme Nedeni",
    USER_IN_USED:
      "Girdiğiniz kullanıcı adı ya da email adresi kullanılmaktadır.",
    USER_NOT_UPDATE: "Kullanıcı güncelleme başarısız",
    User: "Kullanıcı",
    userDefinition: "Kullanıcı",
    userMail: "Geçersiz eposta adresi",
    username: "Kullanıcı Adı",
    userNameMessage: "Geçersiz kullanıcı adı",
    Users: "Kullanıcılar",
    userType: "Kullanıcı Tipi",
    userTypeRequiredMessage: "Lütfen kullanıcı tipi seçiniz.",
    zoneManager: "Bölge Yöneticisi",
    earning: "Kazanç",
    vehicleType: "Araç Tipi",
    //Earning
    workingDate: "Çalışma Tarihi",
    working_hours: "Çalışma Saati",
    vat_deduction_will_be_applied: "Kdv Tevfikat Uygulanacak",
    vat: "Kdv",
    wednesday: "Çarşamba",
    WorkingDay: "Kurye Çalışma Günü",
    WorkingDays: "Çalışma Günleri",
    exportToExcel: 'Excel \'e aktar',
    excelError: 'Veri olmadığı için Excel dosyası oluşturulamıyor.',
    excelSuccess: 'Excel dosyası başarılı bir şekilde oluşturuldu.',
    yesterday: "Dün",
    All: 'Hepsi',
    orderStatusEvent: "Sipariş hazırlanmadan teslim alamazsınız.",
    activeCourierCount: "Aktif Kurye Sayısı",
    activeOrderCount: "Aktif Sipariş Sayısı",
    numberOfOrderByCourier: "Kurye Başına Düşen Sipariş",
    assignedOrderToCourier: "Kurye Atanan Sipariş Sayısı",
    unAssignedOrderToCourier: "Kurye Atanmamış Sipariş Sayısı",
    numberOfOrder: "Toplam Sipariş",
    orderMergeDistance: "Paket Birleştirme Mesafesi (Metre)",
    vknNumber: 'VKN(Vergi Kimlik Numarası)',
    vknValidationMessage:  
    'Lütfen geçerli bir VKN numarası giriniz. VKN numarası 10 haneli olmalıdır.',
    terminalId: 'Terminal No',
    merchantId: 'İşyeri No',
    posInfo: 'Pos Bilgisi',
    successfulUpdatePosInfo: 'Başarılı bir şekilde pos bilgisi güncellendi.',
    unSuccessfulUpdatePosInfo: 'Pos bilgisi güncellenirken bir hata oluştu.',
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: "v3",
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en,
      tr,
    },
    lng: "tr", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
export default i18n;
