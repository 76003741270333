import { LocationPicker } from "../../components/PortiaMap";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { GetCourier, TrackCourier } from "../../api/courier";
import { isEmpty } from "lodash-es";
interface OrderLocationProps {
  assignedCourierId: string;
}

export const OrderLocation: React.FC<OrderLocationProps> = ({assignedCourierId}) => {
  const stopDate = Math.floor(new Date().getTime() / 1000);
  const startDate = Math.floor(new Date().getTime() / 1000) - 60 * 60;

  const { refetch: getCourier, data: courierData } = GetCourier(assignedCourierId);
  const { data: trackData, refetch: getTrack } = TrackCourier(assignedCourierId, startDate.toString(), stopDate.toString());
  
  const [path, setPath] = useState<google.maps.LatLngLiteral[]>([]);
  const [position, setPosition] = useState<google.maps.LatLngLiteral>({
    lat: 41.10551877478612,
    lng: 29.02610099753676,
  });

  const [ctr, setCtr] = useState<google.maps.LatLngLiteral>(position);
  let newPath: any[] = [];
  const couriersPosition: any[] = [];
  const [courierPosition, setCourierPosition] = useState<google.maps.LatLngLiteral[]>([]);


useEffect(() => {
  getTrack();
  console.log(startDate);
  console.log(stopDate);
  
  
}, [assignedCourierId])
  
  useEffect(() => {
    if (assignedCourierId && assignedCourierId !== "new") {
      getCourier();
    }
  }, [assignedCourierId, getCourier]);

  useEffect(() => {
    if (!isEmpty(trackData) && trackData.data.data.length > 0) { 
      console.log("dataaaa",trackData.data.data);
      const data = trackData.data.data;
      data.map((pt: any) =>
        newPath.push({ lat: pt.lat, lng: pt.lon, time: pt._time})
      );
      setPath(newPath);
      setCtr(newPath[newPath.length - 1]);
      couriersPosition.push({
        lat: data[data.length-1].lat,
        lng: data[data.length-1].lon
      });
      setCourierPosition(couriersPosition);
    } else {
      newPath = [];
      setPath(newPath);
      setCourierPosition([]);
    }
  }, [trackData]);

  useEffect(() => {
    console.log('courier data location: ', courierData?.data.location);
    if (courierData?.data?.location === undefined) return;
    if (trackData?.data?.data?.length > 0) return;

    setPosition({
      lng: courierData.data.location.coordinates[0],
      lat: courierData.data.location.coordinates[1],
    });
   
    setCtr({
      lng: courierData.data.location.coordinates[0],
      lat: courierData.data.location.coordinates[1],
    });
   
  }, [courierData, trackData]);

  useEffect(() => {
    if (courierData?.data?.hub?.location?.coordinates?.length > 0) {
      const newLocation = courierData?.data?.hub?.location?.coordinates
      setPosition({
        lng: newLocation[0],
        lat:  newLocation[1],
      });
     
      setCtr({
        lng: newLocation[0],
        lat:  newLocation[1],
      });
  
    }
  }, [courierData])
  

   useEffect(() => {  
     
     if (trackData?.data?.coordinates) {
      couriersPosition.push({
        lat:  trackData?.data.coordinates[0],
        lng:  trackData?.data.coordinates[1],
      })
       if (couriersPosition?.length > 0) {
         setPath(couriersPosition);
       }
     }    
   }, [trackData]);

  return (
    <Grid item xs={12} marginTop={2}>
        { <LocationPicker
                position={ctr}
                title=""
                path={path}
                couriersPosition={courierPosition}
                isDisablePlate={true}
              />}
    </Grid>
  );
};
