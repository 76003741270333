
import Box from "@mui/material/Box";

import { useState } from "react";

import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "@mui/material";
import { UsersList } from "./users.list";
import { PaymentTypesList } from "../PaymentTypes";
import { WorkingDaysList } from "../WorkingDays";
import { PosList } from "../Pos";
import { CourierTypeList } from "../CourierTypes";
import { PosInfo } from "./posInfo";
import { Tenantslist } from "../Tenants/tenants.list";
import { useRoles } from "../../hooks/useRoles";
import { CompaniesList } from "../Companies/companies.list";
import { BrandsList } from "../Brands/brands.list";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const Users = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState(0);
  const tabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const hasPortiaSuperAdmin = useRoles({ allowedRoles: ["portia-super-admin"] });
  return (
    <Box sx={{}}>
      <Tabs value={tab} onChange={tabChange}>
        <Tab label={t("userDefinition")} />
        <Tab label={t("paymentTypeDefinition")} />
        <Tab label={t("WorkingDays")} />
        <Tab label={t("posDefinition")} />
        <Tab label={t("courierTypeParameters")} />
        <Tab label={t("posInfo")} />
        {hasPortiaSuperAdmin && <Tab label={t("tenants")} />}
        {hasPortiaSuperAdmin && <Tab label={t("companies")} />}
        {hasPortiaSuperAdmin && <Tab label={t("brands")} />}
      </Tabs>
      <Box>
        <TabPanel value={tab} index={0}>
          <Box sx={{ m: 2 }}>
            <UsersList />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Box sx={{ m: 2 }}>
            <PaymentTypesList />
          </Box>
        </TabPanel>

        <TabPanel value={tab} index={2}>
          <Box sx={{ m: 2 }}>
            <WorkingDaysList />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <Box sx={{ m: 2 }}>
            <PosList />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <Box sx={{ m: 2 }}>
            <CourierTypeList />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={5}>
          <Box sx={{ m: 2 }}>
            <PosInfo />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={6}>
          <Box sx={{ m: 2 }}>
          <Tenantslist />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={7}>
          <Box sx={{ m: 2 }}>
            <CompaniesList />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={8}>
          <Box sx={{ m: 2 }}>
            <BrandsList />
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};