import { useMutation } from '@tanstack/react-query';
import { debounce } from 'lodash-es';

export function useMutationWithDebounce({ mutationFn, onSuccess, onError, debounceTime }: any) {
  const mutation: any = useMutation({
    mutationFn,
    onSuccess,
    onError,
  });
  if (!debounceTime) {
    return mutation;
  }
  return {
    ...mutation,
    mutate: debounce(mutation.mutate, debounceTime),
  };
}
