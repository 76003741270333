import * as React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Payment } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { t } from "i18next";

export function RowActions({ onEdit, onDelete, onPaymentType }: any) {
  const navigate = useNavigate();

  return (
    <Box>
      {onPaymentType && (
        <Fab
          sx={{ width: 32, height: 32, mr: 1 }}
          color="info"
          aria-label="payment"
          onClick={() => onPaymentType && onPaymentType(navigate)}
        >
          <Payment />
        </Fab>
      )}
      {onEdit && (
        <Tooltip title={t("edit")} arrow>
        <Fab
          sx={{ width: 32, height: 32, mr: 1 }}
          color="secondary"
          aria-label="edit"
          onClick={() => onEdit && onEdit(navigate)}
        >
          <EditIcon />
        </Fab>
        </Tooltip>
      )}
      {onDelete && (
        <Tooltip title={t("deleteButton")} arrow>
        <Fab
          sx={{ width: 32, height: 32, mr: 1 }}
          color="primary"
          aria-label="delete"
          onClick={() => onDelete && onDelete()}
        >
          <RemoveCircleOutlineIcon />
        </Fab>
        </Tooltip>
      )}
    </Box>
  );
}
