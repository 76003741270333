import i18n from "../../translations";

export const NAMEPATTERN = { value:/^([A-Za-z]|[şŞIıİiĞğÜüÖöçÇ])+$/i, message: i18n.t('nameMessage') };
export const PASSWORDPATTERN = { value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/i, message: i18n.t('passwordMessage') };
export const EMAILPATTERN = { value: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/i, message: i18n.t('userMail') };
export const USERNAMEPATTERN = { value: /^([A-Za-z0-9]|[\w.]){4,20}$/i, message: i18n.t('userNameMessage') };
export const MOBILEPATTERN = { value: /[5]\d{9}$/i, message: i18n.t('mobileMessage') };
export const TCKNPATTERN = { value: /^[1-9]{1}[0-9]{9}[02468]{1}$$/i, message: i18n.t('tcknMessage') };
export const MULTINAMEPATTERN = { value:/^([A-Za-z]|[şŞIıİiĞğÜüÖöçÇ ])+$/i, message: i18n.t('nameMessage') };
export const LICENSEPLATEPATTERN = { value:/^(0[1-9]|[1-7][0-9]|8[01])(([A-PR-VYZ])(\d{4,5})|([A-PR-VYZ]{2})(\d{3,4})|([A-PR-VYZ]{3})(\d{2,3}))$/i, message: i18n.t('licensePlateMessage') };

