import {
  Box,
  Button,
  Chip,
  Container,
  Fab,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Orders, OrderStart } from "../../api/orders";
import { Order, Payment, OrderStatus } from "../../api/types";
import { PortiaTable } from "../../components/PortiaTable";
import { OrderRowActions } from "./components/order.row.actions";
import { OrderStatusChange } from "./order.status.change";
import { OrderCourierChange } from "./order.courier.change";
import general from "../../utils/general";
import { usePortia } from "../../context";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";
import {
  GetRestaurantByHubId,
  RestaurantsDropdown,
} from "../../api/restaurant";
import Authz from "../../components/Authz";
import i18n from "../../translations";
import { DatePicker } from "../../components/DateRangePicker";
import { addDays } from "date-fns";
import { Refresh, Payment as PaymentIcon } from "@mui/icons-material";
import { useRoles } from "../../hooks/useRoles";

export const OrdersList = ({ restaurantId }: any) => {
  const { dropdownAlert } = usePortia();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const paymentMethodsRender = useCallback((row: any) => {
    return (
      <Box>

        {row.map((item: Payment) => {
          const strItem = item.amount.toString();
          const itemAmountLen = strItem.length;
          const subStringItem = strItem.substring(0, itemAmountLen - 2);
          const pennyAmount = "." + strItem.substring(itemAmountLen - 2);
          return (
            <Chip
              key={item.type + ": " + item.amount + ""}
              label={t(item.type) + ": " + subStringItem + pennyAmount}
              style={{ marginTop: 4 }}
              variant="outlined"
            />
          );
        })}
      </Box>
    );
  }, []);

  const columns: TableColumn<Order>[] = [
    {
      name: t("orderId"),
      selector: (row) => row.orderId,
      sortable: false,
      width: "200px",
      cell: (row) => (
        <Tooltip title={t("orderDetail")} arrow>
          <Button
            sx={{ textAlign: "left" }}
            aria-label="detail"
            style={{ fontSize: "12px" }}
            onClick={() => {
              navigate(`/orders/${row?.orderId}`);
            }}
          >
            {row.orderId}
          </Button>
        </Tooltip>
      ),
    },
    {
      name: t("refOrderId"),
      selector: (row) => row?.refOrderId || "-",
      sortable: true,
      sortField: "refOrderId",
      cell: (row) => (
        <Tooltip title={row?.refOrderId || "-"} style={{ flex: 1 }}>
          <p> {row.refOrderId || "-"} </p>
        </Tooltip>
      ),
      width: "10%"
    },
    {
      name: t("Courier"),
      selector: (row) => row?.courier?.name,
      sortable: true,
      sortField: "courier.name",
      width: "200px",
      cell: (row) => (
        <Tooltip title={t("courierDetail")}>
          {row?.courier?.name ? (
            <Button
              sx={{ textAlign: "left" }}
              style={{ fontSize: "12px" }}
              aria-label="detail"
              onClick={() => {
                navigate(`/couriers/${row?.courier?.courierId}`);
              }}
            >
              {row?.courier?.name}
            </Button>
          ) : (
            <></>
          )}
        </Tooltip>
      ),
    },
    {
      name: t("date"),
      selector: (row) => row?.orderDate || "-",
      cell: (row) =>
        row?.orderDate &&
        new Date(row?.orderDate).toLocaleString(i18n.language),
      sortable: true,
      sortField: "orderDate",
    },
    {
      name: t("state"),
      selector: (row) => t(row?.status) || "-",
      cell: (row) => (
        <Tooltip title={t(row?.status) || "-"}>
          <div>
            <p>{t(row?.status) || "-"} </p>

            {row?.status == OrderStatus.CANCELED &&
              row?.fsmState?._event?.data?.cancelReason && (
                <p
                  style={{ background: "#feacac", borderRadius: 8, padding: 4 }}
                >
                  {t("cancelReason")}
                  {": "}
                  {row?.fsmState?._event?.data?.cancelReason}
                </p>
              )}
          </div>
        </Tooltip>
      ),
      sortable: true,
      sortField: "status",
    },
    {
      name: t("brand"),
      selector: (row) => row?.brand || "-",
      sortable: true,
      sortField: "brand",
    },
    {
      name: t("restaurant"),
      selector: (row) => row?.restaurant?.name || "-",
      cell: (row) => (
        <Tooltip title={row?.restaurant?.name || "-"}>
          <p> {row?.restaurant?.name || "-"} </p>
        </Tooltip>
      ),
      width: "200px",
      sortable: true,
      sortField: "restaurant.name",
    },
    {
      name: t("channel"),
      selector: (row) => row?.orderSource || "-",
      sortable: true,
      sortField: "orderSource",
    },
    {
      name: t("customer"),
      selector: (row) => row?.firstName + " " + row?.lastName || "-",
      sortable: true,
      sortField: "firstName",
    },
    {
      name: t("total"),
      selector: (row) =>
        parseFloat((Number(row?.total) / 100).toString()).toFixed(2) || "-",
      sortable: true,
      sortField: "total",
    },
    {
      name: t("paymentInfo"),
      selector: (row) => row?.orderId,
      cell: (row) =>
        row?.paymentColletions && row?.status == "COMPLETED"
          ? paymentMethodsRender(row?.paymentColletions?.payments)
          : "-",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "120px",
    },
    {
      cell: (row) => (
        <Authz allowedRoles={["portia-admin", "portia-hub-manager"]}>
          <OrderRowActions
            {...(row?.fsmState &&
              row?.assignedCourierId &&
              row?.fsmState?.nextEvents?.length > 0 &&
              row?.status != OrderStatus.COMPLETED &&
              row?.status != OrderStatus.CANCELED &&
              row?.status != OrderStatus.REJECTED && {
              onChangeStatus: () =>
                handleChangeOrderStatus(
                  row?.orderId,
                  row?.fsmState?.nextEvents
                ),
            })}
            {...(row?.orderId &&
              row?.status != OrderStatus.COMPLETED &&
              row?.status != OrderStatus.CANCELED &&
              row?.status != OrderStatus.REJECTED && {
              onChangeCourier: () =>
                handleChangeOrderCourier(
                  row?.orderId,
                  row?.restaurantId,
                  row?.assignedCourierId
                ),
            })}
            {...((row?.status == OrderStatus.RECEIVED ||
              row?.status == OrderStatus.ASSIGNMENT_FAILED) && {
              onStartOrder: () => orderStart(row?.orderId),
            })}

          // onDeliver={() => {
          //   console.log("onDeliver", row);
          // }}
          />
          <>
            {row?.status === "PICKED" && (
              <Tooltip title={t("payment")} arrow>
                <Fab
                  sx={{ width: 32, height: 32, mr: 1 }}
                  color="secondary"
                  aria-label="edit"
                  onClick={() => navigate(`/payment/${row.orderId}`)}
                >
                  <PaymentIcon />
                </Fab>
              </Tooltip>
            )}
          </>
        </Authz>
      ),
      allowOverflow: true,
      button: true,
      width: "200px",
    },
  ];

  const handleChange = (selectedRows: any) => {
    console.log("Selected Rows: ", selectedRows);
  };

  const handleChangeOrderStatus = useCallback(
    (orderId: any, nextEvents: any) => {
      setStatusChangeData({ orderId: orderId, nextEvents: nextEvents });
      setStatusChangeModal(true);
    },
    []
  );

  const handleChangeOrderCourier = useCallback(
    (orderId: string, restaurantId: string, assignedCourierId: string) => {
      setCourierChangeData({
        orderId: orderId,
        restaurantId: restaurantId,
        assignedCourierId: assignedCourierId,
      });
      setCourierChangeModal(true);
    },
    []
  );

  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [sortField, setSortField] = useState<string>("orderDate");
  const [sortDirection, setSortDirection] = useState("desc");

  const [selectedOrderStatus, setSelectedOrderStatus] = useState<string>("all");

  const [openStatusChangeModal, setStatusChangeModal] = useState(false);
  const [openCourierChangeModal, setCourierChangeModal] = useState(false);

  const [statusChangeData, setStatusChangeData] = useState<Object>();
  const [courierChangeData, setCourierChangeData] = useState<Object>();
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");

  const [selectDays, setSelectDays] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const hubId: any = params && params["hubid"];
  const { data, isLoading, isError, refetch } = Orders(
    restaurantId || "all",
    currentPage,
    perPage,
    search,
    hubId,
    sortField,
    sortDirection,
    selectedOrderStatus,
    startDate,
    finishDate
  );
  const hasPortiaSuperAdmin = useRoles({ allowedRoles: ["portia-super-admin"] });


  const { mutate: orderStart, data: orderStartData } = OrderStart();

  const { refetch: getRestaurant } =
    hubId != null ? GetRestaurantByHubId(hubId) : RestaurantsDropdown();

  useEffect(() => {
    getRestaurant();
  }, [hubId]);

  const { data: orders }: any = data || {};

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refresh();
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
    refresh();
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(
      column?.sortField === undefined ? "orderDate" : column?.sortField
    );
    setSortDirection(sortDirection);
    refresh();
  };

  useEffect(() => {
    if (!general.isNullOrEmpty(orderStartData)) {
      if (orderStartData && orderStartData.status === 200)
        dropdownAlert.current.alertWithType({
          type: "success",
          title: "OK",
          message: t("START_ORDER"),
        });
      refresh();
    }
  }, [orderStartData?.status]);

  const handleSearchChange = (searchKey: string) => {
    setSearch(searchKey);
    (searchKey.length == 0 || searchKey.length > 2) && searchRefresh();
  };

  const handleOrderStatusChange = useCallback((orderStatus: string) => {
    setSelectedOrderStatus(orderStatus);
    refresh();
  }, []);

  const searchRefresh = debounce(() => {
    refetch();
  }, 500);

  const refresh = debounce(() => {
    refetch();
  }, 50);

  const orderStatusOptions = [
    OrderStatus.APPROVED,
    OrderStatus.ASSIGNED,
    OrderStatus.RECEIVED,
    OrderStatus.ASSIGNMENT_FAILED,
    OrderStatus.CANCELED,
    OrderStatus.REJECTED,
    OrderStatus.COMPLETED,
    OrderStatus.PICKED,
    OrderStatus.IN_DELIVERY,
  ];

  const handleOnChangeDate = (ranges: any) => {
    const { selection } = ranges;
    setSelectDays([selection]);
  };
  const orderFilter = () => {
    setStartDate(new Date(selectDays[0]?.startDate).getTime().toString());
    setFinishDate(new Date(selectDays[0]?.endDate).getTime().toString());
    refresh();
  };
  const orderTableRefresh = () => {
    setStartDate("");
    setFinishDate("");
    refresh();
  };

  return (
    <>
      {orders && (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns}
          data={orders}
          customFilter={
            <Grid container spacing={2}>
              <Authz allowedRoles={["portia-admin"]}>
                <Grid item lg={2} mt={-3}>
                  <InputLabel id="select-status-label">
                    {t("status")}
                  </InputLabel>
                  <Select
                    labelId="select-status-label"
                    variant="outlined"
                    value={selectedOrderStatus}
                    sx={{ width: 150 }}
                    onChange={(e) => handleOrderStatusChange(e.target.value)}
                    label={<Typography>t("status")</Typography>}
                  >
                    {
                      hasPortiaSuperAdmin && (
                        <MenuItem key="all" value={"all"}>
                          {t("all")}
                        </MenuItem>
                      )
                    }
                    {orderStatusOptions?.map((val: any) => (
                      <MenuItem key={val} value={val}>
                        {t(val)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item lg={2}>
                  <DatePicker
                    selectDays={selectDays}
                    handleOnChange={handleOnChangeDate}
                  />
                </Grid>

                <Grid item lg={2}>
                  <Button onClick={() => orderFilter()} variant="contained">
                    {t("filter")}
                  </Button>
                </Grid>

                <Grid item lg={2}>
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="refresh"
                    style={{ float: "right" }}
                    onClick={() => orderTableRefresh()}
                  >
                    <Refresh />
                  </Fab>{" "}
                </Grid>
              </Authz>
            </Grid>
          }
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          serverSideSearch
          onSearchChange={handleSearchChange}
          onSort={handleSort}
        />
      )}

      {statusChangeData?.hasOwnProperty("orderId") && (
        <OrderStatusChange
          openDialog={openStatusChangeModal}
          setOpenDialog={setStatusChangeModal}
          data={statusChangeData}
          onListingRefresh={(e: any) => refetch()}
        />
      )}

      {courierChangeData?.hasOwnProperty("orderId") && (
        <OrderCourierChange
          openDialog={openCourierChangeModal}
          setOpenDialog={setCourierChangeModal}
          data={courierChangeData}
          onListingRefresh={(e: any) => refetch()}
        />
      )}
    </>
  );
};
