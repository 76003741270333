import { Box, Button, Grid } from "@mui/material"
import { AutocompleteElement, FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Authz from "../../components/Authz";
import { Company, Tenant } from "../../api/types";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { usePortia } from "../../context";
import { GetCompany, PatchCompany, PostCompany } from "../../api/companies";
import { Brands } from "../../api/brands";
import { GetTenant } from "../../api/tenants";
import { LocationPicker } from "../../components/PortiaMap";

export const CompaniesDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { t } = useTranslation();
    const {
        dropdownAlert,
    } = usePortia();
    const companyId: any = params && params["companyId"];
    const isNewCompany = !!!location.state && companyId == "new";
    const [company, setCompany] = useState<Company>(location?.state);
    const { data: bransData } = Brands();
    const { data: tenantData } = GetTenant();
    const [image, setImage] = useState<string | null | ArrayBuffer>(null); 
    const [position, setPosition] = useState<google.maps.LatLngLiteral>({ lat: 41.10619322476262, lng: 29.096092016594394});

    const {
        mutate: create,
        data: cResult,
        error: cError,
        isLoading: isPostLoading,
    } = PostCompany();

    const {
        mutate: save,
        data: pResult,
        error: sError,
        isLoading: isPatchLaoding,
    } = PatchCompany();

    const { refetch: getCompany, data: companyData } = GetCompany(companyId);


    useEffect(() => {
        if (companyId && companyId !== "new") {
            getCompany();
        }
    }, [params, companyId]);

    useEffect(() => {
        const companyLocation = companyData?.data?.location
        setCompany(companyData?.data);
        setPosition({lat: companyLocation?.lat, lng: companyLocation?.long})
    }, [companyData])

    useEffect(() => {
        if (
            (pResult && (pResult.status === 200 || pResult.status === 201)) ||
            (cResult && (cResult.status === 200 || cResult.status === 201))
        ) {
            dropdownAlert.current.alertWithType({
                type: "success",
                title: "OK",
                message: t("saved"),
            });
            setTimeout(() => {
                navigate(-1);
            }, 200);
        }

        if (sError || cError)
            dropdownAlert.current.alertWithType({
                type: "error",
                title: "Error",
                message:
                    t(sError?.response?.data?.message) ||
                    t(cError?.response?.data?.message),
            });
    }, [pResult, cResult, cError, sError]);

    const saveCompanies = (c: Company) => {
        c.image = image;
        c.location = {
            lat: position.lat,
            long: position.lng
        }
        if (isNewCompany) {
            create(c);
        } else {
            save(c);
        }
    };

    const onSelectImage = (e: any) => {
        const file = (e.target as HTMLInputElement).files?.[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = function(event) {
            const base64String = event.target?.result;
            if(base64String !== undefined){
                setImage(base64String);
            }
          };
          reader.readAsDataURL(file);
        }
    }

    return (
        <Box sx={{ m: 2 }}>
            <FormContainer values={company} onSuccess={(data) => saveCompanies(data)}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextFieldElement
                            sx={{ mr: 2 }}
                            validation={{ required: t("required").toString() }}
                            name={"name"}
                            label={t("firstName")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldElement
                            sx={{ mr: 2 }}
                            validation={{ required: t("required").toString() }}
                            name={"description"}
                            label={t("description")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {
                            tenantData?.data && (
                                <AutocompleteElement
                                    label={t("tenant")}
                                    name="tenantId"
                                    matchId
                                    required
                                    options={tenantData?.data.map((h: any) => {
                                        return { id: h.tenantId, label: h.name };
                                    })}
                                    rules={{
                                        required: t("tenantRequiredMessage").toString(),
                                    }}
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {
                            bransData?.data && (
                                <AutocompleteElement
                                    label={t("brand")}
                                    name="brands"
                                    multiple={true}
                                    matchId
                                    required
                                    options={bransData?.data.map((h: any) => {
                                        return { id: h.brandId, label: h.name };
                                    })}
                                    rules={{
                                        required: t("brandRequiredMessage").toString(),
                                    }}
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <input type="file" accept="image/*" onChange={onSelectImage}/>
                    </Grid>
                    <Grid item xs={12}>
                    <LocationPicker position={position} title="" onSave={(p)=>{setPosition(p)}} /> 

                    </Grid>
                    
                    <Grid item xs={12}>
                        <Authz allowedRoles={["portia-super-admin"]}>
                            <Button
                                sx={{ mr: 2 }}
                                variant="contained"
                                type={"submit"}
                                color={"primary"}
                            >
                                {t("save")}
                            </Button>
                            <Button
                                variant="outlined"
                                color={"secondary"}
                                onClick={() => navigate(-1)}
                            >
                                {t("back")}
                            </Button>
                        </Authz>

                    </Grid>
                </Grid>
            </FormContainer>
        </Box>
    )
}