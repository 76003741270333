import * as React from "react";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";
import {
  Autorenew,
  DirectionsBike,
  DoneAll,
  PlayArrow,
  Replay,
} from "@mui/icons-material";
import { t } from "i18next";

export interface OrderRowActions<T> {
  onChangeStatus?: Function;
  onChangeCourier?: Function;
  onDeliver?: Function;
  onStartOrder?: Function;
  onOrderReload?: Function;
}

export function OrderRowActions({
  onChangeStatus,
  onChangeCourier,
  onDeliver,
  onStartOrder,
  onOrderReload,
}: OrderRowActions<any>): JSX.Element {
  return (
    <Box>
      {onStartOrder && (
        <Tooltip title={t("startOrder")} arrow>
          <Fab
            sx={{ width: 34, height: 34, mr: 1 }}
            color="secondary"
            aria-label="edit"
            onClick={() => onStartOrder && onStartOrder()}
          >
            <PlayArrow />
          </Fab>
        </Tooltip>
      )}

      {onChangeStatus && (
        <Tooltip title={t("changeStatus")} arrow>
          <Fab
            sx={{ width: 34, height: 34, mr: 1 }}
            color="info"
            aria-label="edit"
            onClick={() => onChangeStatus && onChangeStatus()}
          >
            <Autorenew />
          </Fab>
        </Tooltip>
      )}

      {onChangeCourier && (
        <Tooltip title={t("changeCourier")} arrow>
          <Fab
            sx={{ width: 34, height: 34, mr: 1 }}
            color="warning"
            aria-label="edit"
            onClick={() => onChangeCourier && onChangeCourier()}
          >
            <DirectionsBike />
          </Fab>
        </Tooltip>
      )}

      {onDeliver && (
        <Tooltip title="Teslim Et" arrow>
          <Fab
            sx={{ width: 34, height: 34, mr: 1 }}
            color="success"
            aria-label="edit"
            onClick={() => onDeliver && onDeliver()}
          >
            <DoneAll />
          </Fab>
        </Tooltip>
      )}

      {onOrderReload && (
        <Tooltip title={t("changeStatus")} arrow>
          <Fab
            sx={{ width: 34, height: 34, mr: 1 }}
            color="secondary"
            aria-label="edit"
            onClick={() => onOrderReload && onOrderReload()}
          >
            <Replay />
          </Fab>
        </Tooltip>
      )}
    </Box>
  );
}
