import { usePortia } from "../context";

export function useRoles({allowedRoles}:any) {
    
    const {
        store: { session },
    } = usePortia();

    const { roles } = session;
    const hasRole = allowedRoles.find((role:any) => roles?.includes(role)) ? true : false;
    return hasRole;
}