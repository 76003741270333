import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { useMutationWithDebounce } from "./debounce";
import { WORKING_DAYS, FILTER_WORKING_DAYS, WORKING_DAYS_BY_COURIER } from "./paths";

export function PostWorkingDays(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (shift: any) => apiClient.post(WORKING_DAYS, shift),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["shifts"] });
    },
    debounceTime,
  });
}
export function PostWorkingDaysFilter(
  page: number,
  limit: number,
  data: any,
  search: string,
  sortField: string,
  sortDirection: string
) {
  return useQuery(["workingDayList"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        FILTER_WORKING_DAYS +
          `?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}`,
        data
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function PatchWorkingDays(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (shift: any) => apiClient.patch(WORKING_DAYS, shift),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["shifts"] });
    },
    debounceTime,
  });
}
export function GetWorkingDay(workingDayId: string) {
  return useQuery(["getcourier", workingDayId], {
    queryFn: () =>
      !!apiClient && apiClient.get(WORKING_DAYS + `/${workingDayId}`),
    staleTime: 100,
    cacheTime: 100,
    retry: 0,
    enabled: false,
  });
}

export function WorkingDaysByCourier(
  courierId: string,
  page: number,
  limit: number,
  search: string,
  startDate: string,
  finishDate: string,
  sortField: string,
  sortDirection: string
) {
  return useQuery(["workingDayListByCourier"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.get(
        WORKING_DAYS_BY_COURIER +
          `/${courierId}?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}&startDate=${startDate}&finishDate=${finishDate}`
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}
