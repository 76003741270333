
import { DateRangePicker, defaultInputRanges, defaultStaticRanges, createStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useState } from 'react';
import tr from 'date-fns/locale/tr';
import en from 'date-fns/locale/en-US';

import { Button, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addMinutes, addHours } from "date-fns";
import i18n from '../../translations';
export const DatePicker = (props: any) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const { 
        selectDays, 
        handleOnChange, 
        isStaticRanges,
        mounts,
        maxDate,
        minDate
     } = props;
    const [isOpen, setIsOpen] = useState(false);

    const onToggle = (event: any) => {
        const newState = !isOpen;
        setIsOpen(newState)
        setAnchorEl(newState ? event.currentTarget : null);
    }

    const staticRanges = createStaticRanges([
        {
            ...defaultStaticRanges[0],
            label: t('today').toString(),
        }, {
            ...defaultStaticRanges[1],
            label: t('yesterday').toString(),
        }, {
            ...defaultStaticRanges[2],
            label: t('before15minutes').toString(),
            range: () => ({
                startDate: addMinutes(new Date(), -15),
                endDate: new Date(),
              }),
        }, {
            ...defaultStaticRanges[3],
            label: t('before30minutes').toString(),
            range: () => ({
                startDate: addMinutes(new Date(), -30),
                endDate: new Date(),
              }),
        }, {
            ...defaultStaticRanges[4],
            label: t('beforeAnHour').toString(),
            range: () => ({
                startDate: addHours(new Date(), -1),
                endDate: new Date(),
              }),
        }, {
            ...defaultStaticRanges[5],
            label: t('before6Hour').toString(),
            range: () => ({
                startDate: addHours(new Date(), -6),
                endDate: new Date(),
              }),
        }
    ]);

    const defaultRanges = createStaticRanges([
            {
                ...defaultStaticRanges[0],
                label: t('today').toString(),
            }, {
                ...defaultStaticRanges[1],
                label: t('yesterday').toString(),
            }, {
                ...defaultStaticRanges[2],
                label: t('thisWeek').toString(),
            }, {
                ...defaultStaticRanges[3],
                label: t('lastWeek').toString(),
            }, {
                ...defaultStaticRanges[4],
                label: t('thisMonth').toString(),
            }, {
                ...defaultStaticRanges[5],
                label: t('lastMonth').toString(),
            }
        ]);
    
    return (
        <div>

            <Button variant="contained" onClick={(e) => onToggle(e)}  >
                {t('selectDate')}
            </Button>
            <Popover open={isOpen} anchorEl={anchorEl}
                onClose={() => setIsOpen(false)}>
                <DateRangePicker
                    onChange={handleOnChange}
                    moveRangeOnFirstSelection={false}
                    months={mounts ? mounts : 2}
                    ranges={selectDays}
                    startDatePlaceholder={t('startDate').toString()}
                    endDatePlaceholder={t('startDate').toString()}
                    locale={i18n.language === 'tr' ? tr : en}
                    inputRanges={[
                        {
                            ...defaultInputRanges[0],
                            label: t('daysUpToToday').toString(),
                        }, {
                            ...defaultInputRanges[1],
                            label: t('daysStartingToday').toString(),
                        }
                    ]}
                    staticRanges={isStaticRanges ? staticRanges : defaultRanges}
                    direction='horizontal'
                    rangeColors={["#00a870"]}
                    maxDate ={maxDate ? maxDate : undefined}
                    minDate={minDate ? minDate : undefined}

                />
                <Button variant="contained" onClick={(e) => onToggle(e)}  >OK</Button>
            </Popover>

        </div>)
}