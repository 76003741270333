import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { useMutationWithDebounce } from "./debounce";
import { PROCEEDS, RECONCILIATIONS, RECONCILIATIONS_DETAIL } from "./paths";

export function Reconciliations(
  courierId: string,
  page: number,
  limit: number,
  search: string,
  sortField: string,
  sortDirection: string
) {
  return useQuery(["Reconciliations", courierId], {
    queryFn: () =>
      !!apiClient &&
      apiClient.get(
        RECONCILIATIONS +
          `/list/${courierId}?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}`
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}

export function GetReconciliation(reconciliationId: string) {
  return useQuery(["getReconciliation", reconciliationId], {
    queryFn: () =>
      !!apiClient &&
      apiClient.get(RECONCILIATIONS_DETAIL + `/${reconciliationId}`),
    retry: 0,
    enabled: true,
  });
}

export function PatchReconciliation(
  reconciliationId: any,
  debounceTime: number = 500
) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (reconciliation: any) =>
      apiClient.patch(RECONCILIATIONS + `/${reconciliationId}`, reconciliation),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["reconciliation"] });
    },
    debounceTime,
  });
}

export function GetReconciliationByParams(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (proceeds: any) => apiClient.post(PROCEEDS +"/reconcilication-params-by-courierId", proceeds),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["get-reconciliation"] });
      queryClient.setQueryData(['params'], data);
    },
    debounceTime,
  });
}
