import { TableColumn } from "react-data-table-component";
import { Shift } from "../../api/types";
import { PortiaTable } from "../../components/PortiaTable";
import Box from "@mui/material/Box";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Fab,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { SHIFT_STATUS } from "../../utils/enums";
import { useTranslation } from "react-i18next";
import { debounce, isEmpty } from "lodash-es";
import Authz from "../../components/Authz";
import { Timestamp } from "../../components/Timestamp";
import { AllShifts, ForceEndShift } from "../../api/shifts";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { RowActions } from "../../components/PortiaTable/row.actions";
import { DatePicker } from "../../components/DateRangePicker";
import { Refresh } from "@mui/icons-material";
import { addDays } from "date-fns";
import { usePortia } from "../../context";
import general from "../../utils/general";

export interface ShiftsListProps {
  courierId?: string;
}

export const ShiftsList = ({ courierId }: ShiftsListProps) => {
  const navigate = useNavigate();
  const { dropdownAlert } = usePortia();
  const { t } = useTranslation();
  const { mutate: forceEndShift, data: _endShiftData } = ForceEndShift();

  const shiftStateOptions = [
    { id: "All", label: t("all") },
    { id: SHIFT_STATUS.ASSIGNED, label: SHIFT_STATUS.ASSIGNED },
    { id: SHIFT_STATUS.AVAILABLE, label: SHIFT_STATUS.AVAILABLE },
    { id: SHIFT_STATUS.OFFLINE, label: SHIFT_STATUS.OFFLINE },
    { id: SHIFT_STATUS.BREAK, label: SHIFT_STATUS.BREAK },
  ];

  const columns: TableColumn<Shift>[] = [
    {
      name: t("id"),
      selector: (row) => row._id,
      sortable: false,
      cell: (row) => (
        <Tooltip title={t("shiftDetail")} arrow>
          <Button
            aria-label="detail"
            onClick={() => {
              navigate(`/shifts/${row?._id}`);
            }}
          >
            {row._id}
          </Button>
        </Tooltip>
      ),
    },
    {
      name: t("startAt"),
      cell: (row) => <Timestamp style={{ fontSize: 14, fontWeight: 400 }} timestamp={row.startAt} date time />,
      sortable: true,
      sortField: "startAt",
      button: true,
      width: "128px",
    },
    {
      name: t("endAt"),
      cell: (row) => (row.endAt > 0) && <Timestamp style={{ fontSize: 14, fontWeight: 400 }} timestamp={row.endAt} date time />,
      sortable: true,
      sortField: "endAt",
      allowOverflow: true,
      button: true,
      width: "128px",
    },
    {
      name: t("working_hours"),
      cell: (row) => row?.shifts?.workingTime && formatMinutesToHHMM(Math.round(row?.shifts?.workingTime)) ,
      sortable: true,
      sortField: "endAt",
      allowOverflow: true,
      button: true,
      width: "128px",
    },
    {
      name: t("name"),
      selector: (row) => row.courier?.name,
      sortable: false,
      width: "200px",
    },
    {
      name: t("mobile"),
      selector: (row) => row.courier?.mobile,
      sortable: false,
      width: "128px",
    },
    {
      name: t("status"),
      selector: (row) => row.status?.code,
      cell: (row) => t(row.status?.code),
      sortable: true,
      sortField: "status.code",
    },
    {
      cell: (row) => (
        <Authz allowedRoles={["portia-admin"]}>
          {row.endAt < 0 ? (
            <RowActions
              onDelete={(_navigate: NavigateFunction) => {
                forceEndShift({ courierId: row.courierId, shiftId: row._id });
                setTimeout(() => {
                  refresh();
                }, 2000);
              }}
            />
          ) : (
            <></>
          )}
        </Authz>
      ),
      allowOverflow: true,
      button: true,
      width: "128px",
    },
  ];

  const [shiftStatus, setShiftStatus] = useState<string>("All");
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [selectedHub, setSelectedHub] = useState<string>("All");
  const [startDate, setStartDate] = useState("0");
  const [finishDate, setFinishDate] = useState("222222222222222");
  const [sortField, setSortField] = useState<string>("startAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [courierName, setCourierName] = useState("");
  const [selectDays, setSelectDays] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);


  const { data, isLoading, isError, refetch } = AllShifts(
    currentPage,
    perPage,
    selectedHub,
    search,
    shiftStatus === "All" ? undefined : shiftStatus,
    courierId,
    startDate,
    finishDate,
    sortField,
    sortDirection
  );

  const handleChange = () => {
    // console.log("Selected Rows: ", selectedRows);
  };
  const { data: shifts }: any = data || {};


  const refresh = debounce(() => {
    refetch();
  }, 50);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refresh();
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
    refresh();
  };

  const handleShiftStatusChange = useCallback(
    (statusValue: any) => {
      setShiftStatus(statusValue);
      refresh();
    },
    [refresh]
  );

  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(column?.sortField === undefined ? "startAt" : column?.sortField);
    setSortDirection(sortDirection);
    refresh();
  };

  const handleSearchChange = (searchKey: string) => {
    setSearch(searchKey);
    (searchKey.length === 0 || searchKey.length > 2) && searchRefresh();
  };

  const searchRefresh = debounce(() => {
    refetch();
  }, 500);
  const handleOnChangeDate = (ranges: any) => {
    const { selection } = ranges;
    setSelectDays([selection]);
  };
  const shiftFilter = () => {
    setStartDate(new Date(selectDays[0]?.startDate).getTime().toString());
    setFinishDate(new Date(selectDays[0]?.endDate).getTime().toString());
    refresh();
  };
  const shiftTableRefresh = () => {
    setStartDate("");
    setFinishDate("");
    refresh();
  };
  useEffect(() => {
    if (_endShiftData?.data?.errorMessage !== null) {
      dropdownAlert.current
        .alertWithType({
          type: 'error',
          title: "Vardiya Değişim",
          message: "Üzerinde sipariş olan bir kuryenin  mesai sonlandırılamaz."
        });
    }

  }, [_endShiftData]);
  useEffect(() => {
    setCourierName(shifts?.items[0].courier.name);
  }, [shifts])

  const handleExcelButton = () => {
    if (isEmpty(shifts)) {
      dropdownAlert.current.alertWithType({ type: 'error', title: "error", message: t('excelError') });
    }
    else {
      const excelShiftData = shifts?.items?.map((shift: Shift) => {

        const endAtFormatted = shift.endAt !== -1 ? general.formatDateForDisplay(shift.endAt) : '-';
        return {
          'Excel Oluşturma Tarihi': general.formatDateForDisplay(Date.now()),
          'Id': shift?._id,
          'Başlangıç': general.formatDateForDisplay(shift.startAt),
          'Bitiş': endAtFormatted,
          'Ad Soyad': shift?.courier?.name,
          'Telefon': shift?.courier?.mobile,
          'Durum': t(`${shift?.status.code}`),
        };
      });
      const headerRow = [
        'Excel Oluşturma Tarihi',
        'Id',
        'Başlangıç',
        'Bitiş',
        'Ad Soyad',
        'Telefon',
        'Durum',
      ];
      general.createExcelFromData(excelShiftData, headerRow, `${courierName}-Vardiya-Page-${currentPage}`);
      dropdownAlert.current.alertWithType({ type: 'success', title: "OK", message: t('excelSuccess') });
    }
  }
  const formatMinutesToHHMM = (minutes: number): string => {

    const hours: number = Math.floor(minutes / 60);
    const remainingMinutes: number = minutes % 60;

    const formattedHours: string = hours < 10 ? '0' + hours : hours.toString();
    const formattedMinutes: string = remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes.toString();

    const formattedTime: string = `${formattedHours}:${formattedMinutes}`;

    return formattedTime;
  }
  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      <Grid container>
        <Grid item xs={6} md={4} lg={2}>
          <InputLabel id="demo-simple-select-label">
            {t("shiftStatus")}
          </InputLabel>
          <Select
            variant="outlined"
            sx={{ width: 200 }}
            value={shiftStatus}
            onChange={(e) => handleShiftStatusChange(e.target.value)}
            label={t("shiftStatus")}
          >
            {shiftStateOptions.map((val) => (
              <MenuItem key={val.id} value={val.id}>
                {t(val.label)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} mt={3} md={8} lg={8}>
          <DatePicker
            selectDays={selectDays}
            handleOnChange={handleOnChangeDate}
          />
        </Grid>
        <Grid item xs={2} ml={-40} mt={2} mr={-10}>
          <Button onClick={handleExcelButton} sx={{ border: '1px solid #046746' }}>{t('exportToExcel')}</Button>
        </Grid>
        <Grid mt={2} item xs={6} md={6} lg={1}>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            style={{ float: "right" }}
            onClick={() => shiftFilter()}
          >
            {t("filter")}
          </Button>
        </Grid>
        <Grid mt={2} item xs={6} md={6} lg={1}>
          <Fab
            size="small"
            color="primary"
            aria-label="refresh"
            style={{ float: "right" }}
            onClick={() => shiftTableRefresh()}
          >
            <Refresh />
          </Fab>
        </Grid>
      </Grid>
      {shifts && (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns}
          data={shifts}
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          serverSideSearch
          onSearchChange={handleSearchChange}
          onSort={handleSort}
        />
      )}
    </Box>
  );
};
