import { TableColumn } from "react-data-table-component";
import { NavigateFunction, useParams } from "react-router-dom";
import { Reconciliations } from "../../api/reconciliation";
import { Reconciliation } from "../../api/types";
import { PortiaTable } from "../../components/PortiaTable";
import Box from "@mui/material/Box";
import { RowActions } from "../../components/PortiaTable/row.actions";
import Authz from "../../components/Authz";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";

export const ReconciliationList = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [search, setSearch] = useState<string>("");
  const [sortField, setSortField] = useState<string>("_id");
  const [sortDirection, setSortDirection] = useState("desc");
  const refresh = debounce(() => {
    refetch();
  }, 50);

  const columns: TableColumn<Reconciliation>[] = [
    {
      name: t("reconciliationId"),
      selector: (row) => row?.reconciliationId,
      sortable: false,
    },
    {
      name: t("workingDate"),
      selector: (row) => row?.workDate,
      sortable: false,
    },
    {
      name: t("status"),
      selector: (row) => t(row?.status).toString(),
      sortable: true,
      sortField: "name",
    },
    {
      cell: (row) => (
        <>
          <Authz allowedRoles={["portia-admin", "portia-hub-manager"]}>
            <RowActions
              onEdit={(navigate: NavigateFunction) => {
                navigate(`/reconciliations/${row.reconciliationId}`, {
                  state: row,
                });
              }}
            />
          </Authz>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "128px",
    },
  ];
  const courierId: any = params && params["courierid"];
  const { data, isLoading, isError, refetch } = Reconciliations(
    courierId,
    currentPage,
    perPage,
    search,
    sortField,
    sortDirection
  );
  const { data: reconciliations }: any = data || {};
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refresh();
  };
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
    refresh();
  };
  const handleChange = (selectedRows: any) => {
    console.log("Selected Rows: ", selectedRows);
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(column?.sortField === undefined ? "_id" : column?.sortField);
    setSortDirection(sortDirection);
    refresh();
  };
  const handleSearchChange = (searchKey: string) => {
    setSearch(searchKey);
    (searchKey.length == 0 || searchKey.length > 2) && searchRefresh();
  };
  const searchRefresh = debounce(() => {
    refetch();
  }, 500);
  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      {reconciliations && (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns}
          data={reconciliations}
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          serverSideSearch
          onSearchChange={handleSearchChange}
          onSort={handleSort}
        />
      )}
    </Box>
  );
};
