import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import {
  AutocompleteElement,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import {
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CreatePosDevice, GetPosDevice, PatchPosDevice } from "../../api/pos";
import { Pos } from "../../api/types";
import { usePortia } from "../../context";
import { useTranslation } from "react-i18next";
import { Hubs } from "../../api/hubs";
import HubIcon from "@mui/icons-material/Hub";

export const PosDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const {
    dropdownAlert,
  } = usePortia();
  const [posDevice, setPosDevice] = useState<Pos>();
  const posId: any = params && params["posId"];

  const {
    refetch: getPosDevice,
    data: posDeviceData,
    isLoading: isPosDeviceLoading,
  } = GetPosDevice(posId);
  const {
    mutate: create,
    data: cResult,
    error: cError,
  } = CreatePosDevice();
  const {
    mutate: update,
    data: pResult,
    error: pError,
  } = PatchPosDevice();

  const isNewPos = posId === "new";
  useEffect(() => {
    if (posId && posId !== "new") {
      getPosDevice();
    }
  }, [params, posId]);

  useEffect(() => {
    setPosDevice(posDeviceData?.data.data);
  }, [posDeviceData]);

  useEffect(() => {}, [isPosDeviceLoading]);

  useEffect(() => {
    if (
      (cResult && cResult.status === 201) ||
      (pResult && pResult.status === 200)
    ) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
      navigate(-1);
    }
  }, [cResult, pResult]);

  useEffect(() => {
    if (
      (cError && cError?.response?.status === 404) ||
      (pError && pError?.response?.status === 404)
    ) {
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "ERROR",
        message:
          t(pError?.response?.data?.message) ||
          t(cError?.response?.data?.message),
      });
    }
  }, [cError, pError]);

  const savePosDevice = (pos: Pos) => {
    if (isNewPos) {
      create(pos);
    } else {
      update(pos);
    }
  };

  const { data, isLoading } = Hubs();
  const hubs = (data && data?.data?.items) || [];

  return (
    <Box sx={{ m: 2 }}>
      <FormContainer
        values={posDevice}
        defaultValues={{
          hubId: searchParams.get("hubId")?.toString(),
        }}
        onSuccess={(data) => savePosDevice(data)}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AutocompleteElement
              label={t("hub")}
              name="hubId"
              matchId
              required
              loading={isLoading}
              textFieldProps={{
                InputProps: {
                  startAdornment: <HubIcon />,
                },
              }}
              options={
                searchParams.get("hubId")
                  ? hubs
                      .filter((h: any) => h.hubId === searchParams.get("hubId"))
                      .map((h: any) => {
                        return {
                          id: h.hubId,
                          label: h.name,
                        };
                      })
                  : hubs.map((h: any) => {
                      return {
                        id: h.hubId,
                        label: h.name,
                      };
                    })
              }
              rules={{ required: t("hubRequiredMessage").toString() }}
              autocompleteProps={{
                disabled: searchParams.get("hubId") || !isNewPos ? true : false,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldElement
              validation={{ required: t("required").toString() }}
              name={"serial"}
              label={t("posSerial")}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              type={"submit"}
              color={"primary"}
            >
              {t("save")}
            </Button>
            <Button
              variant="outlined"
              color={"secondary"}
              onClick={() => navigate(-1)}
            >
              {t("cancel")}
            </Button>
          </Grid>
        </Grid>
      </FormContainer>
    </Box>
  );
};
