import { Button, Chip, FormControl, Grid, InputLabel, List, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Hubs } from "../../api/hubs";
import { GetCourierHubsList } from "../../api/courier";
import { PortiaTable } from "../../components/PortiaTable";
import { TableColumn } from "react-data-table-component";
import { Courier, CourierType, Restaurant } from "../../api/types";
import 'dayjs/locale/tr';
import { usePortia } from "../../context";
import { GetWorkingDay, PatchWorkingDays, PostWorkingDays } from "../../api/workingDay";
import { useTranslation } from 'react-i18next';
import { debounce } from "lodash-es";
import { GetRestaurantByHubId } from "../../api/restaurant";
import { AllCourierType } from "../../api/courierType";

export const WorkingDaysDetail = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const { dropdownAlert } = usePortia();
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(new Date()));
    const [finishDate, setFinishDate] = useState<Dayjs | null>(dayjs(new Date()));
    const [startTime, setStartTime] = useState<Dayjs | null>(dayjs(new Date()));
    const [finishTime, setFinishTime] = useState<Dayjs | null>(dayjs(new Date()));
    const [workingDate, setWorkingDate] = useState<Dayjs | null>(dayjs(new Date()));
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [action] = useState({ fromUser: false });
    const [search, setSearch] = useState<string>("");
    const [hubId, setHubId] = useState(undefined as any)
    const [typeId, setTypeId] = useState(undefined);
    const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([] as any);

    useEffect(() => {
        if (searchParams.get("hubId")) {
            setHubId(searchParams.get("hubId"));
            setTimeout(() => {
                getRestaurant();
            }, 100);
        }
    }, [searchParams.get("hubId")])

    const workingDayId: any = params && params['workingdayid']
    const { refetch: getWorkingDay, data: workingDayData } = GetWorkingDay(workingDayId)

    const isNewWorkingDay = !!!location.state && workingDayId == 'new';

    const { data: couriersResult, isLoading: isCouriersLoading, refetch } = GetCourierHubsList(currentPage,
        perPage, hubId, search, typeId);

    const { mutate: saveWorkingDay, data: sResult, error: sError } = PostWorkingDays();
    const { mutate: updateWorkingDay, data: pResult, error: pError } = PatchWorkingDays();
    const { data } = Hubs();
    const hubs = data && data?.data?.items || [];

    const { data: AllCourierTypeData } = AllCourierType();
    const allCourierTypes =
        (AllCourierTypeData && AllCourierTypeData?.data?.items) || [];
    const typeOptions = allCourierTypes.map((type: any) => ({
        id: type.courierType,
        label: type.courierTypeName
    }));

    useEffect(() => {
        if (workingDayId && workingDayId !== 'new') {
            getWorkingDay()
        }
    }, [params, workingDayId]);

    useEffect(() => {
        if (workingDayData) {
            setStartTime(dayjs(timeFormat(workingDayData?.data?.startTime)))
            setFinishTime(dayjs(timeFormat(workingDayData?.data?.finishTime)))
            setWorkingDate(dayjs(dateFormat(workingDayData?.data?.workingDate)));
            setTimeout(() => {

                setHubId(workingDayData?.data?.hubId)
            }, 100);
        }
    }, [workingDayData]);

    useEffect(() => {

        if (sResult) {
            if (sResult.data[0]) {
                dropdownAlert.current.alertWithType({ type: 'warning', title: "OK", message: t("chooseDifferentDate") });
            } else {
                dropdownAlert.current.alertWithType({ type: 'success', title: "OK", message: t("saved") });
                navigate(-1);
            }
        }
        if (pResult && pResult.status === 200) {
            dropdownAlert.current.alertWithType({ type: 'success', title: "OK", message: t("saved") });
            navigate(-1);
        }
        if ((sError && sError?.response?.status === 404) || (pError && pError?.response?.status === 404)) {
            dropdownAlert.current.alertWithType({ type: 'error', title: "ERROR", message: sError?.response?.data?.message || pError?.response?.data?.message });
        }
    }, [sResult, sError, pResult, pError])

    const {
        refetch: getRestaurant,
        data: restaurantData,
    } = GetRestaurantByHubId(hubId);

    const save = () => {
        let couriers = [] as any;
        selectedRowsPerPage?.forEach((selectedRowsWithIn: any) => {
            selectedRowsWithIn?.forEach((row: any) => {
                couriers.push(row)
            })
        })
        if (isNewWorkingDay) {
            if (couriers.length > 0) {
                const data = {
                    hubId: hubId,
                    startDate: dayjs(startDate).format("DD-MM-YYYY"),
                    finishDate: dayjs(finishDate).format("DD-MM-YYYY"),
                    startTime: dayjs(startTime).format('HH:mm'),
                    finishTime: dayjs(finishTime).format('HH:mm'),
                    couriers: couriers,
                }
                saveWorkingDay(data);
            } else {
                dropdownAlert.current.alertWithType({ type: 'warning', title: "WARNİNG", message: t("courierRequiredMessage") });

            }
        } else {
            const data = {
                startTime: dayjs(startTime).format('HH:mm'),
                finishTime: dayjs(finishTime).format('HH:mm'),
                workingDayTime: dayjs(workingDate).format("YYYY-MM-DD"),
                workingDayId: workingDayId,
            }
            updateWorkingDay(data)
        }
    }

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        refresh();
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        if (!couriersResult?.data?.data.length) return;
        setCurrentPage(page);
        setPerPage(newPerPage);
        setSelectedRowsPerPage([]);
        refresh();
    };

    const handleChange = useCallback(
        (selectedRows: any) => {
            if (selectedRows?.length !== perPage) {
                if (!action.fromUser) return;
            }
            selectedRowsPerPage[currentPage] = selectedRows;
        },
        [currentPage, selectedRowsPerPage, action.fromUser]
    );
    const handleMouseEnter = () => {
        action.fromUser = true;
    };
    const handleMouseLeave = () => {
        action.fromUser = false;
    };
    const handleApplySelectedRows = (row: any) =>
        selectedRowsPerPage[currentPage]?.filter(
            (selectedRow: any) => selectedRow._id === row._id
        ).length > 0;

    const changeHub = (e: any) => {
        const value = e.target.value;
        setHubId(value);
        setTimeout(() => {
            getRestaurant();
        }, 100);
        refresh();
    };
    const changeType = (e: any) => {
        const value = e.target.value;
        setTypeId(value);
        refresh();
    };
    const handleSearchChange = (searchKey: string) => {
        setSearch(searchKey);
        (searchKey.length == 0 || searchKey.length > 2) && searchRefresh();
    };

    const searchRefresh = debounce(() => {
        refetch();
    }, 500);

    const refresh = debounce(() => {
        refetch();
    }, 50);

    const dateFormat = (date: any) => {
        let splitDate = date?.split(' ');
        let year = splitDate[0].split('-')[0]
        let month = splitDate[0].split('-')[1]
        let day = splitDate[0].split('-')[2]
        return new Date(year, month - 1, day);
    }
    const timeFormat = (time: any) => {
        let splitDate = time?.split(':');
        let hour = splitDate[0]
        let minute = splitDate[1]
        return new Date(1, 1, 2000, hour, minute);
    }
    const columns: TableColumn<Courier>[] = [
        {
            name: t('firstName'),
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: t('mobile'),
            selector: (row) => row.mobile,
            sortable: true,
        },
        {
            name: t('email'),
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: t('type'),
            selector: (row) => t(CourierType[row.type]).toString(),
            sortable: true,
        },
    ];
    return (
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <Grid container spacing={4}>
                <Grid item xs={6} md={3} lg={2}>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="test-select-label">{t('hub')}</InputLabel>
                        <Select disabled={searchParams.get("hubId") || !isNewWorkingDay ? true : false} onChange={changeHub} name={'hubId'} sx={{ width: '100%' }} value={hubId ?? ""} labelId="test-select-label" label={t('hub')} >
                            {hubs?.map((option: any) => {
                                return (
                                    <MenuItem key={option?.hubId} value={option?.hubId}>
                                        {option.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                {
                    isNewWorkingDay &&
                    <Grid item xs={6} md={4} lg={2}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="test-select-label">{t('type')}</InputLabel>
                            <Select disabled={!isNewWorkingDay} onChange={changeType} name={'typeId'} sx={{ width: '100%' }} value={typeId ?? ""} labelId="test-select-label" label={t("type")} >
                                <MenuItem value={"all"}>{t("all")}</MenuItem>
                                {typeOptions?.map((option: any) => {
                                    return (
                                        <MenuItem key={option?.id} value={option?.id}>
                                            {t(option.label)}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                    </Grid>
                }
                {
                    isNewWorkingDay &&
                    <Grid item xs={6} md={4} lg={2}>
                        <LocalizationProvider locale={t("dateTimeLocale").toString()} dateAdapter={AdapterDayjs}>
                            <DatePicker
                                renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                                label={t('startDate')}
                                value={startDate}
                                inputFormat="DD-MM-YYYY"
                                onChange={(newValue) => {
                                    setStartDate(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                }
                {
                    isNewWorkingDay &&
                    <Grid item xs={6} md={4} lg={2} >
                        <LocalizationProvider locale={t('dateTimeLocale').toString()} dateAdapter={AdapterDayjs}>
                            <DatePicker
                                renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                                label={t('finishDate')}
                                value={finishDate}
                                inputFormat="DD-MM-YYYY"
                                onChange={(newValue) => {
                                    setFinishDate(newValue);
                                }}

                            />
                        </LocalizationProvider>
                    </Grid>
                }
                {
                    !isNewWorkingDay &&
                    <Grid item xs={6} md={4} lg={2} >
                        <LocalizationProvider locale={t('dateTimeLocale').toString()} dateAdapter={AdapterDayjs}>
                            <DatePicker
                                renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                                label={t('date')}
                                value={workingDate}
                                inputFormat="DD-MM-YYYY"
                                onChange={(newValue) => {
                                    setWorkingDate(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                }
                <Grid item xs={6} md={4} lg={2}>
                    <LocalizationProvider locale={t('dateTimeLocale').toString()} dateAdapter={AdapterDayjs}>
                        <TimePicker
                            ampmInClock
                            openTo="hours"
                            views={['hours', 'minutes']}
                            inputFormat="HH:mm"
                            mask="__:__"
                            label={t('startTime')}
                            value={startTime}
                            ampm={false}
                            onChange={(newValue) => {
                                setStartTime(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6} md={4} lg={2} >
                    <LocalizationProvider locale={t('dateTimeLocale').toString()} dateAdapter={AdapterDayjs}>
                        <TimePicker
                            ampmInClock
                            openTo="hours"
                            views={['hours', 'minutes']}
                            inputFormat="HH:mm"
                            mask="__:__"
                            label={t('finishTime')}
                            value={finishTime}
                            ampm={false}
                            onChange={(newValue) => {
                                setFinishTime(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                {
                    isNewWorkingDay &&
                    <Grid item xs={12}>
                        <List sx={{
                            width: '100%',
                            maxWidth: '100%',
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 150,
                            '& ul': { padding: 0 },
                        }}>
                            <ListSubheader>{t('Restaurants').toString()}</ListSubheader>
                            {
                                restaurantData?.data?.map((option: Restaurant) => {
                                    return (
                                        <Chip
                                            key={option.restaurantId}
                                            label={option.name}
                                            style={{ marginTop: 4, marginRight: 4 }}
                                            variant="outlined"
                                        />
                                    );
                                })
                            }
                        </List>
                    </Grid>
                }
                {
                    isNewWorkingDay &&
                    <Grid item xs={12}>
                        {couriersResult?.data?.data && (
                            <PortiaTable
                                loading={isCouriersLoading}
                                columns={columns}
                                data={couriersResult?.data?.data}
                                onSelect={handleChange}
                                selectableRows={true}
                                onChangePage={handlePageChange}
                                onChangePerRows={handlePerRowsChange}
                                serverSideSearch
                                onSearchChange={handleSearchChange}
                                onRowMouseEnter={handleMouseEnter}
                                onRowMouseLeave={handleMouseLeave}
                                selectableRowSelected={handleApplySelectedRows}

                            />
                        )}

                    </Grid>
                }

                <Grid item xs={12} >
                    <Button sx={{ mr: 2 }} variant="contained" color={'primary'} onClick={() => save()}>{t('save')}</Button>
                    <Button variant="outlined" color={'secondary'} onClick={() => navigate(-1)}>{t('cancel')}</Button>
                </Grid>
            </Grid>

        </Box>
    )
}