import { Box, debounce } from "@mui/material"
import { useTranslation } from "react-i18next";
import { RowActions } from "../../components/PortiaTable/row.actions";
import { PortiaTable } from "../../components/PortiaTable";
import Authz from "../../components/Authz";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";
import { Company } from "../../api/types";
import { GetCompanyList } from "../../api/companies";

export const CompaniesList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [search, setSearch] = useState<string>("");
    const [sortField, setSortField] = useState<string>("_id");
    const [sortDirection, setSortDirection] = useState("desc");

    const columns: TableColumn<Company>[] = [
        {
            name: t('companyId'),
            selector: (row) => row.companyId,
            sortable: true,
        },
        {
            name: t('firstName'),
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: t('description'),
            selector: (row) => row.description,
            sortable: true,
        },
        {
            name: t('tenantId'),
            selector: (row) => row.tenant.name,
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <RowActions
                        onEdit={(navigate: NavigateFunction) => {
                            navigate(`/companies/${row.companyId}`, { state: row });
                        }}
                    />
                </>
            ),
            allowOverflow: true,
            button: true,
            width: "128px",
        },
    ];

    const { data, isLoading, isError, refetch } = GetCompanyList(
        currentPage,
        perPage,
        search,
        sortField,
        sortDirection
    );

    const { data: companies }: any = data || {};

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        refresh();
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setCurrentPage(page);
        setPerPage(newPerPage);
        refresh();
    };

    const handleChange = (selectedRows: any) => {
        console.log("Selected Rows: ", selectedRows);
    };

    const handleSort = async (column: any, sortDirection: any) => {
        setSortField(column?.sortField === undefined ? "_id" : column?.sortField);
        setSortDirection(sortDirection);
        refresh();
    };

    const handleSearchChange = (searchKey: string) => {
        setSearch(searchKey);
        (searchKey.length == 0 || searchKey.length > 2) && searchRefresh();
    };

    const searchRefresh = debounce(() => {
        refetch();
    }, 500);

    const refresh = debounce(() => {
        refetch();
    }, 50);

    return (
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
            {
                companies && (
                    <PortiaTable
                        loading={!isError && isLoading}
                        columns={columns}
                        data={companies}
                        onSelect={handleChange}
                        onChangePage={handlePageChange}
                        onChangePerRows={handlePerRowsChange}
                        serverSideSearch
                        onSearchChange={handleSearchChange}
                        onSort={handleSort}
                    />
                )
            }
            <Authz allowedRoles={["portia-admin"]}>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => navigate(`/companies/new`, { state: undefined })}
                >
                    <AddIcon />
                </Fab>
            </Authz>
        </Box>
    )
}