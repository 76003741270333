import Box from "@mui/material/Box";
import { useRoutes } from "react-router-dom";

import AppFrame from "../components/AppFrame";
import { CouriersList, CourierDetail, Earning, EarningList } from "../pages/Couriers";
import { PosList, PosDetail } from "../pages/Pos";
import WelcomePage from "../pages/Homepage";
import { RestaurantDetail, RestaurantsList } from "../pages/Restaurants";
import { Hubs, HubsList } from "../pages/Hubs";
import { OrderDetail } from "../pages/Orders/orders.detail";
import { PaymentTypesList } from "../pages/PaymentTypes";
import { PaymentTypesDetail } from "../pages/PaymentTypes/paymentTypes.detail";
import { RestaurantPaymentType } from "../pages/Restaurants/restaurants.payment";
import { PaymentList } from "../pages/Payments";
import { WorkingDaysDetail, WorkingDaysList } from "../pages/WorkingDays";
import { useTranslation } from "react-i18next";
import { Dashboard } from "../pages/Dashboard/dashboard";
import { UserDetail, Users } from "../pages/Settings";
import { DashboardDetail } from "../pages/Dashboard/dashboard.detail";
import Authz from "../components/Authz";
import Unauthorized from "../pages/Unauthorized";
import { ShiftsList } from "../pages/Shifts/shifts.list";
import { StatusHistoryList } from "../pages/Shifts/shift.detail";
import { PortiaBreadcrumbs } from "../components/Breadcrumbs";
import { Orders } from "../pages/Orders/orders.home";
import { CourierTypeDetail, CourierTypeList } from "../pages/CourierTypes";
import { CourierReconciliation } from "../pages/Couriers/courier.reconciliation";
import { ReconciliationList } from "../pages/Couriers/courier.reconciliationList";
import { Announcements } from "../pages/Announcements/announcement.home";
import { AnnouncementDetail } from "../pages/Announcements/announcement-detail";
import { HubBonusDetail } from "../pages/Hubs/hub.bonus.detail";
import { TenantsDetail } from "../pages/Tenants/tenants.detail";
import { Tenantslist } from "../pages/Tenants/tenants.list";
import { CompaniesList } from "../pages/Companies/companies.list";
import { CompaniesDetail } from "../pages/Companies/companies.detail";
import { OrderSettings } from "../pages/OrderSettings";
import { BrandsList } from "../pages/Brands/brands.list";
import { BrandsDetail } from "../pages/Brands/brands.detail";


const ParamBreadCrumb = ({ match }: any) => {
  const vals: any = Object.values(match.params);
  return <span>{vals?.length > 0 ? vals[0] : '-'}</span>
};

export interface BreadcrumbsProps {
  routes: any[];
}


const AppStack = () => {
  const { t } = useTranslation();
  const routes = [
    { path: "/", breadcrumb: t('homeTitle'), element: <WelcomePage /> },
    { path: "/unauthorized", breadcrumb: t('unauthorized'), element: <Unauthorized /> },
    { path: "/shifts", breadcrumb: t('Shifts'), element: <ShiftsList /> },
    { path: "/shifts/:shiftid", title: t('Shift'), element: <StatusHistoryList />, breadcrumb: ParamBreadCrumb },
    { path: "/couriers", breadcrumb: t('Couriers'), element: <CouriersList /> },
    { path: "/couriers/:courierid", title: t('Courier'), element: <CourierDetail />, breadcrumb: ParamBreadCrumb },
    { path: "/earning", breadcrumb: t('earning'), element: <EarningList /> },
    { path: "/earning/:profitid", title: t('earning'), element: <Earning />, breadcrumb: ParamBreadCrumb },
    // { path: "/couriers/orders/:courierid", title: t('CourierActiveOrder'), element: <CourierOrders /> },
    { path: "/pos", breadcrumb: t('PosList'), element: <PosList /> },
    { path: "/pos/:posId", title: t('Pos'), element: <PosDetail />, breadcrumb: ParamBreadCrumb },
    { path: "/hubs/restaurants", breadcrumb: t('Restaurants'), element: <RestaurantsList /> },
    { path: "/hubs/restaurants/:restaurantid", title: t('Restaurant'), element: <RestaurantDetail />, breadcrumb: ParamBreadCrumb },
    { path: "/hubs/restaurants/payment", breadcrumb: t('paymentType'), element: '#' },
    { path: "/hubs/restaurants/payment/:restaurantid", title: t('paymentType'), element: <RestaurantPaymentType /> , breadcrumb: ParamBreadCrumb },
    { path: "/hubs", breadcrumb: t('hubs'), element: <HubsList /> },
    { path: "/hubs/:hubid", title: t('hub'), element: <Hubs />, breadcrumb: ParamBreadCrumb },
    // { path: "/orders", breadcrumb: t('Orders'), element: <OrdersList /> },
    { path: "/orders", breadcrumb: t('Orders'), element: <Orders /> },
    { path: "/mergesettings", breadcrumb: t("mergesettings"), element: <Authz allowedRoles={['portia-admin', 'portia-hub-manager']}><OrderSettings /></Authz>, },
    { path: "/orders/:orderId", title: t('Order'), element: <OrderDetail />, breadcrumb: ParamBreadCrumb },
    { path: "/paymentTypes", breadcrumb: t('paymentType'), element: <PaymentTypesList /> },
    { path: "/paymentTypes/:paymentid", title: t('paymentType'), element: <PaymentTypesDetail />, breadcrumb: ParamBreadCrumb },
    { path: "/payment", breadcrumb: t('OrderGetPayment') },
    { path: "/payment/:orderId", title: t('paymentInfo'), element: <PaymentList />, breadcrumb: ParamBreadCrumb },
    { path: "/workingDays", breadcrumb: t('WorkingDays'), element: <WorkingDaysList /> },
    { path: "/workingDays/:workingdayid", title: t('WorkingDay'), element: <WorkingDaysDetail />, breadcrumb: ParamBreadCrumb },
    { path: "/dashboard", breadcrumb: t('Dashboard'), element: <Authz allowedRoles={['portia-admin', 'portia-hub-manager']}><Dashboard /></Authz> },
    { path: "/settings", breadcrumb: t('Settings'), element: <Authz allowedRoles={['portia-admin']}><Users /></Authz> },
    { path: "/settings/:userId", title: t('User'), element: <Authz allowedRoles={['portia-admin']}><UserDetail /></Authz>, breadcrumb: ParamBreadCrumb },
    { path: "/courier-detail", title: t('CourierDetailList'), element: <DashboardDetail /> },
    { path: "/definitions", breadcrumb: t('Definitions'), element: <Authz allowedRoles={['portia-admin']}><Users /></Authz> },
    { path: "/definitions/:userId", title: t('User'), element: <Authz allowedRoles={['portia-admin']}><UserDetail /></Authz>, breadcrumb: ParamBreadCrumb },
    { path: "/courierTypes", breadcrumb: t('CourierTypes'), element: <Authz allowedRoles={['portia-admin']}><CourierTypeList /></Authz> },
    { path: "/courierTypes/:courierTypeId", title: t('CourierTypesDetail'), element: <Authz allowedRoles={['portia-admin']}><CourierTypeDetail /></Authz>, breadcrumb: ParamBreadCrumb },
    { path: "/reconciliations/:reconciliationId", title: t('reconciliation'), element: <Authz allowedRoles={['portia-admin', 'portia-hub-manager']}><CourierReconciliation /></Authz>, breadcrumb: ParamBreadCrumb },
    { path: "/reconciliations", breadcrumb: t('reconciliation'), element: <Authz allowedRoles={['portia-admin']}><ReconciliationList /></Authz> },
    { path: "/announcement", breadcrumb: t('Announcement'), element: <Announcements /> },
    { path: "/announcement/:announcementId", title: t('announcement'), element: <Authz allowedRoles={['portia-admin','portia-hub-manager']}><AnnouncementDetail /></Authz>, breadcrumb: ParamBreadCrumb},
    { path: "/hubBonus", breadcrumb: t('HubBonus'), element:'#' },
    { path: "/hubBonus/:hubBonusId", title: t('HubBonus'), element: <HubBonusDetail />, breadcrumb: ParamBreadCrumb},
    { path: "/tenants", breadcrumb: t('tenants'), element: <Tenantslist /> },
    { path: "/tenants/:tenantId", title: t('tenant'), element: <TenantsDetail />, breadcrumb: ParamBreadCrumb },
    { path: "/companies", breadcrumb: t('companies'), element: <CompaniesList /> },
    { path: "/brands", breadcrumb: t('brands'), element: <BrandsList /> },
    { path: "/companies/:companyId", title: t('companies'), element: <CompaniesDetail />, breadcrumb: ParamBreadCrumb },
    { path: "/brands/:brandId", title: t('brands'), element: <BrandsDetail />, breadcrumb: ParamBreadCrumb },

  ];

  const routesToRender: any = useRoutes(routes);

  return (
    <AppFrame>
      <Box sx={{ mt: 2 }}>
        <PortiaBreadcrumbs routes={routes} />
      </Box>
      {routesToRender}
    </AppFrame>
  );
};

export default AppStack;
