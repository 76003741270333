import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import general from "../../utils/general";
import { Box } from "@mui/system";
import { OrderChangeStatus } from "../../api/orders";
import {
  FormContainer,
  TextFieldElement,
  AutocompleteElement,
} from "react-hook-form-mui";
import { usePortia } from "../../context";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const OrderStatusChange = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cancelReasonElement, setCancelReasonElement] = useState(false);

  const {
    dropdownAlert,
  } = usePortia();

  const {
    mutate: change,
    data: cResult,
    isLoading: isOrderStatusChangeLoading,
  } = OrderChangeStatus();

  useEffect(() => {
    if (!general.isNullOrEmpty(props?.data?.orderId)) {
      //
    }
  }, [props?.data?.orderId, props?.data?.nextEvents]);

  const handleDialogClose = () => {
    props.setOpenDialog(false); // Use the prop.
    setCancelReasonElement(false);
  };

  const changeStatus = (c: any) => {
    if (
      !general.isNullOrEmpty(props?.data?.orderId) &&
      !general.isNullOrEmpty(c?.status)
    )
      if (c?.status === 'complete') {
          navigate(`/payment/${props?.data?.orderId}`,{state:{isPrePaid:props?.data?.isPrePaid}})
        
      } else {
        change({
          orderId: props?.data?.orderId,
          status: c?.status,
          ...(c?.cancelReason && { cancelReason: c?.cancelReason }),
        });
      }
  };
  useEffect(() => {
    if (!general.isNullOrEmpty(props?.data?.orderId)) {

      if (cResult && cResult.data.isSuccess) {
        dropdownAlert.current.alertWithType({
          type: "success",
          title: "OK",
          message: t("Sipariş Durumu Güncellenmiştir"),
        });
        handleDialogClose();
      }
      if (cResult && !cResult.data?.isSuccess) {
        dropdownAlert.current.alertWithType({
          type: "error",
          title: "cancel",
          message: t("orderStatusEvent"),
        });
        handleDialogClose();

      }
      props.onListingRefresh(true);
      
    }
  }, [cResult]);

  return (
    <Dialog
      open={props.openDialog}
      onClose={handleDialogClose}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>{t("changeOrderStatus")}</DialogTitle>
      <FormContainer onSuccess={(data) => changeStatus(data)}>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
          >
            <Grid container spacing={2} justifyContent={"flex-start"}>
              <Grid item xs={12}>
                <AutocompleteElement
                  required
                  label={t("state")}
                  name="status"
                  textFieldProps={{}}
                  options={props?.data?.nextEvents || []}
                  autocompleteProps={{
                    getOptionLabel: (option) => t(option),
                    isOptionEqualToValue: (option, value) => option === value,
                    onChange: (event, value) =>
                      setCancelReasonElement(value === "cancel" ? true : false),
                  }}
                  rules={{ required: t("required").toString() }}
                />
                {cancelReasonElement && (
                  <TextFieldElement
                    required
                    label={t("cancelReason")}
                    name="cancelReason"
                    fullWidth
                    validation={{ required: t("required").toString() }}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
          <DialogActions>
            <Button color={"secondary"} onClick={() => handleDialogClose()}>
              {t("abort")}
            </Button>
            <Button
              color="success"
              type={"submit"}
              disabled={isOrderStatusChangeLoading || props?.data?.nextEvents?.length === 0}
            >
              {t("save")}
            </Button>
          </DialogActions>
        </DialogContent>
      </FormContainer>
    </Dialog>
  );
};
