import { Box, Button, Grid } from "@mui/material"
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Authz from "../../components/Authz";
import { Brand } from "../../api/types";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { usePortia } from "../../context";
import { GetBrand, PostBrand, UpdateBrand } from "../../api/brands";

export const BrandsDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { t } = useTranslation();
    const {
        dropdownAlert,
    } = usePortia();
    const brandId: any = params && params["brandId"];
    const isNewBrand = !!!location.state && brandId == "new";
    const [brand, setBrand] = useState<Brand>(location?.state);

    const [image, setImage] = useState<string | null | ArrayBuffer>(null); 

    const {
        mutate: create,
        data: cResult,
        error: cError,
        isLoading: isPostLoading,
    } = PostBrand();

    const {
        mutate: save,
        data: pResult,
        error: sError,
        isLoading: isPatchLaoding,
    } = UpdateBrand();

    const { refetch: getBrand, data: brandData } = GetBrand(brandId);



    useEffect(() => {
        if (brandId && brandId !== "new") {
            getBrand();
        }
    }, [params, brandId]);

    useEffect(() => {
        setBrand(brandData?.data);
    }, [brandData])

    useEffect(() => {
        if (
            (pResult && (pResult.status === 200 || pResult.status === 201)) ||
            (cResult && (cResult.status === 200 || cResult.status === 201))
        ) {
            dropdownAlert.current.alertWithType({
                type: "success",
                title: "OK",
                message: t("saved"),
            });
            setTimeout(() => {
                navigate(-1);
            }, 200);
        }

        if (sError || cError)
            dropdownAlert.current.alertWithType({
                type: "error",
                title: "Error",
                message:
                    t(sError?.response?.data?.message) ||
                    t(cError?.response?.data?.message),
            });
    }, [pResult, cResult, cError, sError]);

    const saveBrands = (c: Brand) => {
        c.logo = image;
        if (isNewBrand) {
            create(c);
        } else {
            save(c);
        }
    };

    const onSelectImage = (e: any) => {
        const file = (e.target as HTMLInputElement).files?.[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = function(event) {
            const base64String = event.target?.result;
            if(base64String !== undefined){
                setImage(base64String);
            }
          };
          reader.readAsDataURL(file);
        }
    }

    return (
        <Box sx={{ m: 2 }}>
            <FormContainer values={brand} onSuccess={(data) => saveBrands(data)}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextFieldElement
                            sx={{ mr: 2 }}
                            validation={{ required: t("required").toString() }}
                            name={"name"}
                            label={t("firstName")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <input type="file" accept="image/*" onChange={onSelectImage}/>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Authz allowedRoles={["portia-super-admin"]}>
                            <Button
                                sx={{ mr: 2 }}
                                variant="contained"
                                type={"submit"}
                                color={"primary"}
                            >
                                {t("save")}
                            </Button>
                            <Button
                                variant="outlined"
                                color={"secondary"}
                                onClick={() => navigate(-1)}
                            >
                                {t("back")}
                            </Button>
                        </Authz>

                    </Grid>
                </Grid>
            </FormContainer>
        </Box>
    )
}