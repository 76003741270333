import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { useMutationWithDebounce } from "./debounce";
import { ANNOUNCEMENTS, ANNOUNCEMENTS_COURIERS, GET_ANNOUNCEMENT_DETAILS, SEND_AGAIN } from "./paths";

export function CreateAnnouncement(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (announcement: any) =>
      apiClient.post("/api/announcements", announcement),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["sendAnnouncement"] });
    },
    debounceTime,
  });
}

export function GetCourierByHubId(
  page: number,
  limit: number,
  shiftStatus: any,
  search: string,
  hubId: string,
  sortField: string,
  sortDirection: string,
) {
  return useQuery(["GetCourierByHubId"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        ANNOUNCEMENTS +
        `/list?hubId=${hubId}&page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}&shiftStatus=${shiftStatus}`,
        
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function AnnouncementPagination(
  page: number,
  limit: number,
  search: string,
  sortField: string,
  sortDirection: string,
) {
  return useQuery(["announcementpagination"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        ANNOUNCEMENTS +
        `/announcementList?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}`,
      ),
    retry: 0,
    enabled: true,
  });
}

export function FindByAnnouncementId(announcementId: string) {
  return useQuery(["details", announcementId], {
    queryFn: () =>
      !!apiClient && apiClient.get(GET_ANNOUNCEMENT_DETAILS + `/${announcementId}`),
    staleTime: 100,
    cacheTime: 100,
    retry: 0,
    enabled: false,
  });
}

export function SendAgain(announcementId: string) {
  return useQuery(["sendAgain", announcementId], {
    queryFn: () =>
      !!apiClient && apiClient.get(SEND_AGAIN + `/${announcementId}`),
    staleTime: 100,
    cacheTime: 100,
    retry: 0,
    enabled: false,
  });
}

export function GetCourierByAnnouncementId(
  announcementId: string,
  page: number,
  limit: number,
  search: string,
) {
  return useQuery(["GetCourierByAnnouncementId", announcementId], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        ANNOUNCEMENTS_COURIERS +
        `/list?announcementId=${announcementId}&page=${page}&limit=${limit}&search=${search}`,
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}


