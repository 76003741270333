import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import general from "../../utils/general";
import { Box } from "@mui/system";
import { OrderChangeCourier } from "../../api/orders";
import CircularProgress from "@mui/material/CircularProgress";
import { FormContainer, AutocompleteElement } from "react-hook-form-mui";
import { AvailableCouriers } from "../../api/courier";
import { usePortia } from "../../context";
import { useTranslation } from "react-i18next";

export const OrderCourierChange = (props: any) => {
  const { t } = useTranslation();
  const {
    mutate: create,
    data: cResult,
    error: cError,
    isLoading: isCourierChangeLoading,
  } = OrderChangeCourier();
  const {
    dropdownAlert,
  } = usePortia();

  const restaurantId: any = props?.data && props?.data?.restaurantId;
  const {
    refetch: getCourier,
    data: courierData,
    isLoading: isCourierLoading,
  } = AvailableCouriers(restaurantId);

  useEffect(() => {
    if (!general.isNullOrEmpty(props?.data?.orderId)) {
      getCourier();
    }
  }, [props?.data?.orderId]);

  const handleDialogClose = () => {
    props.setOpenDialog(false); // Use the prop.
  };

  const changeCourier = (c: any) => {
    create({ orderId: props?.data?.orderId, courierId: c?.courier?.courierId });
  };

  useEffect(() => {
    if (!general.isNullOrEmpty(props?.data?.orderId) && (cResult || cError)) {
      if (cResult && cResult?.status === 201) {
        dropdownAlert.current.alertWithType({
          type: "success",
          title: "OK",
          message: t("changeCourierSuccess"),
        });
        props.onListingRefresh(true);
        handleDialogClose();
      }

      if (cError)
        dropdownAlert.current.alertWithType({
          type: "error",
          title: "OK",
          message: t("changeCourierError"),
        });
    }
  }, [cResult, cError]);

  return (
    <Dialog
      open={props.openDialog}
      onClose={handleDialogClose}
      fullWidth={true}
    >
      <DialogTitle>{t("changeCourier")}</DialogTitle>
      <FormContainer onSuccess={(data) => changeCourier(data)}>
        <DialogContent
          style={{
            alignSelf: "center",
          }}
        >
          {!isCourierLoading ? (
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1 },
              }}
            >
              <Grid container spacing={2} justifyContent={"flex-start"}>
                <Grid item xs={12}>
                  {!isCourierLoading && (
                    <AutocompleteElement
                      required
                      label={t("Couriers")}
                      name="courier"
                      loading={isCourierLoading}
                      options={courierData?.data || []}
                      autocompleteProps={{
                        getOptionLabel: (option) => option.courierName,
                        isOptionEqualToValue: (option, value) =>
                          option.courierId === value.courierId,
                        getOptionDisabled: (option) => {
                          return option.courierId ===
                            props?.data?.assignedCourierId
                            ? true
                            : false;
                        },
                        noOptionsText: t("noCourierFound"),
                      }}
                      rules={{ required: t("required").toString() }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <CircularProgress />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => handleDialogClose()}
          >
            {t("abort")}
          </Button>
          <Button
            color="success"
            type={"submit"}
            disabled={isCourierChangeLoading || courierData?.data?.length === 0}
          >
            {t("save")}
          </Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
};
