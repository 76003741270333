import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { useMutationWithDebounce } from "./debounce";
import {
    PROCEEDS
} from "./paths";

export function GetProceeds(
    page: number,
    limit: number,
    search: string,
    courierId: string,
    sortField: string,
    sortDirection: string
  ) {
    return useQuery(["getProceed"], {
      queryFn: () =>
        !!apiClient &&
        apiClient.post(
          PROCEEDS+`/by-courierId?page=${page}&limit=${limit}&search=${search}&courierId=${courierId}&sortField=${sortField}&sortDirection=${sortDirection}`
        ),
      retry: 0,
      refetchOnWindowFocus: false,
      onError(err) {
        console.log(err);
      },
    });
  }
  
  export function GetProceedDetail(profitId: string) {
    return useQuery(["getProceedDetail", profitId], {
      queryFn: () =>
        !!apiClient && apiClient.get(PROCEEDS+`/detail` + `/${profitId}`),
      staleTime: 100,
      cacheTime: 100,
      retry: 0,
      enabled: false,
    });
  }
  
  export function ProceedSave(debounceTime: number = 500) {
    const queryClient = useQueryClient();
    return useMutationWithDebounce({
      mutationFn: (proceeds: any) => apiClient.post(PROCEEDS, proceeds),
      onSuccess: (data: any, variables: { id: any }) => {
        queryClient.invalidateQueries({ queryKey: ["proceed-save"] });
      },
      debounceTime,
    });
  }
  
  export function ProceedSaveDraft(debounceTime: number = 500) {
    const queryClient = useQueryClient();
    return useMutationWithDebounce({
      mutationFn: (proceeds: any) => apiClient.post(PROCEEDS+"/save-draft", proceeds),
      onSuccess: (data: any, variables: { id: any }) => {
        queryClient.invalidateQueries({ queryKey: ["save-draft"] });
      },
      debounceTime,
    });
  }