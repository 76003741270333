import { Search } from "@mui/icons-material";
import { Grid, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useMemo, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";

const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  hideSearchBox,
}: any) => {
  const { t } = useTranslation();
  return (
    <>
      {!hideSearchBox && (
        <Box sx={{ m: 2, p: 1 }}>
          <TextField
            id="search"
            variant="outlined"
            label={t("search").toString()}
            type="text"
            placeholder={t("search").toString()}
            aria-label={t("search").toString()}
            value={filterText}
            onChange={onFilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Button sx={{ height: 55 }} variant="contained" onClick={onClear}>
            {" "}
            X{" "}
          </Button>
        </Box>
      )}
    </>
  );
};

export interface PortiaTableData<T> {
  loading: boolean;
  columns: TableColumn<T>[];
  data: any;
  metaData?: any;
  selectableRows?: boolean;
  customFilter?: any;
  onSelect?: Function;
  onChangePage?: Function;
  onSort?: Function;
  onChangePerRows?: Function;
  filter?: (value: T, searchText: string) => boolean;
  serverSideSearch?: boolean;
  onSearchChange?: Function;
  onRowMouseEnter?: any;
  onRowMouseLeave?: any;
  selectableRowSelected?: any;
  hideSearchBox?: boolean;
}
export const PortiaTable = ({
  loading,
  columns,
  data,
  onSelect,
  customFilter,
  filter,
  selectableRows,
  metaData,
  onChangePage,
  onSort,
  onChangePerRows,
  serverSideSearch,
  onSearchChange,
  onRowMouseEnter,
  onRowMouseLeave,
  selectableRowSelected,
  hideSearchBox,
}: PortiaTableData<any>) => {
  const { t } = useTranslation();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleChange = ({ selectedRows }: any) => {
    !!onSelect && onSelect(selectedRows);
  };

  const handlePageChange = (page: number) => {
    !!onChangePage && onChangePage(page);
  };

  const handleMouseEnter = () => {
    !!onRowMouseEnter && onRowMouseEnter();
  };
  const handleMouseLeave = () => {
    !!onRowMouseLeave && onRowMouseLeave();
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    !!onChangePerRows && onChangePerRows(newPerPage, page);
  };
  const handleApplySelectedRows = (row: any) =>
    !!selectableRowSelected && selectableRowSelected(row);

  const handleSort = async (column: any, sortDirection: any) => {
    !!onSort && onSort(column, sortDirection);
  };

  const searchBox = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        !!onSearchChange && onSearchChange("");
      }
    };
    const filter = (
      <FilterComponent
        onFilter={(e: any) => {
          setFilterText(e.target.value);
          !!onSearchChange && onSearchChange(e.target.value);
        }}
        onClear={handleClear}
        filterText={filterText}
        hideSearchBox={hideSearchBox}
      />
    );
    return customFilter ? (
      <Grid container mt={2} alignItems={"center"}>
        <Grid item lg={8}>
          {customFilter}
        </Grid>
        <Grid item lg={4}>
          {filter}
        </Grid>
      </Grid>
    ) : (
      filter
    );
  }, [filterText, customFilter, resetPaginationToggle, hideSearchBox]);

  const {
    meta: { totalItems, currentPage },
    items,
  } = data;

  const filteredItems =
    (!!items &&
      !!filter &&
      items?.filter((item: any) => filter(item, filterText))) ||
    items;

return (
  <Box>
    <DataTable
      columns={columns}
      data={serverSideSearch ? items : filteredItems}
      progressPending={loading}
      progressComponent={<CircularProgress />}
      onSelectedRowsChange={handleChange}
      selectableRowSelected={handleApplySelectedRows}
      clearSelectedRows={true}
      selectableRows={!!selectableRows}
      pagination
      paginationRowsPerPageOptions = {[ 50, 100, 250, 500, 1000, ]}
      paginationResetDefaultPage={resetPaginationToggle}
      paginationServer
      paginationTotalRows={totalItems}
      paginationDefaultPage={currentPage}
      onChangeRowsPerPage={handlePerRowsChange}
      onRowMouseEnter={handleMouseEnter}
      onRowMouseLeave={handleMouseLeave}
      onChangePage={handlePageChange}
      subHeader
      subHeaderComponent={searchBox}
      noDataComponent={t('dataNotFound')}
      paginationComponentOptions={{ rowsPerPageText: t('rowsPerPageText').toString(), rangeSeparatorText: 'of', selectAllRowsItem: true, selectAllRowsItemText: t('all').toString() }}
      responsive
      onSort={handleSort}
      sortServer
      defaultSortAsc={false}
    />
  </Box>
);
};
