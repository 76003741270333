import * as XLSX from 'xlsx';

const isNullOrEmpty = (text: string | number | null | undefined) =>
  text === undefined || text === "" || text === null || Number.isNaN(text);

const isNumber = (value: any) => matchRegexp(value, /^\d+$/);

const isFloat = (value: any) => {
  const isFloatx =
    matchRegexp(value, /^(?:-?[1-9]\d*|-?0)?(?:,)?$/i) ||
    matchRegexp(value, /^(?:-?[1-9]\d*|-?0)?(?:,\d+)?$/i);
  return isFloatx;
};

const matchRegexp = (value: string, regexp: string | RegExp) => {
  const validationRegexp =
    regexp instanceof RegExp ? regexp : new RegExp(regexp);
  return validationRegexp.test(value);
};

function formatDateForDisplay(timestamp: number): string {
  const date = new Date(timestamp);
  
  const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
  
  return formattedDate;
}
const createExcelFromData = (data: any[], headerRow: string[],filename: string) => {
  const workbook = XLSX.utils.book_new();

   const mappedData = data.map(item => {
   const row: any[] = [];
     for (const key of headerRow) {
       row.push(item[key] || '');
     }
     return row;
   });

   const worksheetData: any[] = [headerRow, ...mappedData];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  XLSX.utils.book_append_sheet(workbook, worksheet, `${filename}`);
   XLSX.writeFile(workbook, `${filename}.xlsx`);
 };
const all = {
  isNullOrEmpty,
  isNumber,
  isFloat,
  formatDateForDisplay,
  createExcelFromData,
}

export default all;
