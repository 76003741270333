import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  FormContainer,
  AutocompleteElement,
} from "react-hook-form-mui";
import { usePortia } from "../../context";
import { useTranslation } from "react-i18next";
import { CurrentShift, SendStatusEvent, StartShift } from "../../api/courier";
import _ from "lodash";

export const CourierStatusChange = (props: any) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<any>();
  const [shiftId, setShiftId] = useState();
  const { dropdownAlert } = usePortia();

  const {
    data: shiftData,
    refetch: getCurrentShift,
    isLoading: shiftLoading,
  } = CurrentShift(props?.data?.courierId);

  const { mutate: shiftStartControl, data: sData, error: sError } = StartShift(500);
  const { mutate: sendStatusEvent, data: eData, error: eError } = SendStatusEvent(500);

  useEffect(() => {
    getCurrentShift()
  }, [props?.data?.courierId])

  useEffect(() => {
  
    if ((sData && sData?.status === 201) || (eData && eData?.status === 201)) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t('saved'),
      });
      props.onListingRefresh(true);
      props.setOpenDialog(false);
    }
    if(eError && eError?.response?.status === 404){
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "ERROR",
        message:
          t(eError?.response?.data?.message)
      });
    }
  }, [sData, sError, eData, eError])

  useEffect(() => {
    if (shiftData) {
      setShiftId(shiftData?.data?.id);
    }
  }, [shiftData])
  const handleDialogClose = () => {
    props.setOpenDialog(false); // Use the prop.
  };

  const getActions = (allowedEvents: any) => {
    if (!shiftData || shiftData?.data === '' || shiftData?.data?.endAt > 0 || shiftData?.data?.status?.code == 'OFFLINE') {
      return [{ label: `${t('startOvertime')}`, event: 'startOvertime' }];
    }
    if (!allowedEvents) return [{ label: `${t('end')}`, event: 'end' }];

    if (shiftData?.data?.status?.code === 'ASSIGNED') {
      allowedEvents = allowedEvents.filter((e: any) => e !== 'takebreak');
      allowedEvents = allowedEvents.filter((e: any) => e !== 'demandbreak');
    }

    const filtered = _.without(
      allowedEvents,
      'disable',
      'assignwork',
      'complete',
    );

    const mapped = _.map(filtered, event => {
      if (event === 'takebreak' || event === 'demandbreak')
        return [
          { label: `${t(event)}`, event, data: { break: 'REST' } },
          {
            label: `${t(event)} (${t('MEAL')})`,
            event,
            data: { break: 'MEAL' },
          },
        ];
      if (event === 'assignwork')
        return [
          { label: `${t(event)}`, event, data: { work: 'DELIVERY' } },
          {
            label: `${t(event)} (${t('TASK')})`,
            event,
            data: { work: 'TASK', workOrder: { completed: false } },
          },
        ];
      if (event === 'complete')
        return [
          {
            label: `${t(event)}`,
            event,
            data: { workOrder: { completed: true } },
          },
        ];
      return { label: `${t(event)}`, event };
    });
    return _.flatten(mapped as any[]);
  };

  const changeStatus = (c: any) => {
    if (status?.event === "startOvertime") {
      shiftStartControl(props?.data?.courierId)
    } else {
      sendStatusEvent({ courierId: props?.data?.courierId, shiftId: shiftId, event: status?.event, data: status?.data })
    }
  };

  return (
    <Dialog
      open={props.openDialog}
      onClose={handleDialogClose}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>{t('changeCourierStatus')}</DialogTitle>
      <FormContainer onSuccess={(data) => changeStatus(data)} >
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
          >
            <Grid container spacing={2} justifyContent={"flex-start"}>
              <Grid item xs={12}>
                {
                  shiftData && (
                    <AutocompleteElement
                      required
                      label={t('state')}
                      name="status"
                      textFieldProps={{}}
                      options={getActions(shiftData?.data?.fsmState?.nextEvents)}
                      autocompleteProps={{
                        getOptionLabel: (option) => t(option.label),
                        isOptionEqualToValue: (option, value) => option === value,
                        onChange: (event, value) => {
                          setStatus(value);
                        }
                      }}
                      rules={{ required: t('required').toString() }}
                    />
                  )
                }

              </Grid>
            </Grid>
          </Box>
          <DialogActions>
            <Button
              color="success"
              type={"submit"}
              disabled={shiftLoading}
              onClick={handleDialogClose}
            >
              {t("save")}
            </Button>
          </DialogActions>
        </DialogContent>
      </FormContainer>
    </Dialog>
  );
};
