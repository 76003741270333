import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Announcement } from "../../api/types";
import { AnnouncementPagination, SendAgain } from "../../api/announcements";
import { PortiaTable } from "../../components/PortiaTable";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Authz from "../../components/Authz";
import { useCallback, useEffect, useState } from "react";
import { usePortia } from "../../context";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";
import i18n from "../../translations";
import { Autorenew, Create } from "@mui/icons-material";
import { AnnouncementSendAgain } from "./announcement.sendAgain";

export const AnnouncementsList = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { dropdownAlert } = usePortia();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [search, setSearch] = useState<string>("");
    const [sortField, setSortField] = useState<string>("_id");
    const [sortDirection, setSortDirection] = useState("desc");
    const [announcementId, setAnnouncementId] = useState("");
    const [announcementModal, setAnnouncementModal] = useState(false);

    const columns: TableColumn<Announcement>[] = [
        {
            name: t("announcementId"),
            width: "200px",
            selector: (row) => row?.announcementId,
            sortable: true,
            sortField: 'announcementId'
        },
        {
            name: t("announcementDate"),
            width: "160px",
            selector: (row) => row?.createdAt,
            cell: (row) =>
                row?.createdAt &&
                new Date(row?.createdAt).toLocaleString(i18n.language) || "-",
            sortable: true,
            sortField: 'createdAt'
        },
        {
            name: t("announcementEndDate"),
            width: "160px",
            selector: (row) => row?.endDate,
            cell: (row) =>
                row?.endDate &&
                new Date(row?.endDate * 1000).toLocaleString(i18n.language)  || "-",
            sortable: true,
            sortField: 'createdAt'
        },
        {
            name: t("announcementResendDate"),
            width: "160px",
            selector: (row) => row?.updatedAt || "-",
            cell: (row) =>
                row?.updatedAt &&
                new Date(row?.updatedAt).toLocaleString(i18n.language) || "-",
            sortable: true,
            sortField: 'updatedAt'
        },
        {
            name: t("message"),
            cell: (row) => (<p> {row?.message} </p>),
            sortable: true,
            sortField: 'message'
        },
        {
            name: t("createdBy"),
            width: "160px",
            selector: (row) => row?.actionUser?.username,
            sortable: false,
            sortField: 'name'
        },
        {
            name: t("hub"),
            width: "160px",
            cell: (row) => row?.hub?.name || t("all"),
            sortable: false,
            sortField: 'name',
        },
        {
            name: t("Couriers"),
            width: "160px",
            selector: (row) => row?.couriers?.length,
            sortable: false,
            sortField: 'name',
        },
        {
            cell: (row) => (
                <Authz allowedRoles={["portia-admin", "portia-hub-manager"]}>
                    <Tooltip title={t("announcementDetail")} arrow>
                        <Fab
                            sx={{ width: 34, height: 34, mr: 1 }}
                            color="secondary"
                            aria-label="edit"
                            onClick={() => handleGetAnnouncement(row?.announcementId)}
                        >
                            <Create />
                        </Fab>
                    </Tooltip>
                    <Tooltip title={t("sendAgain")} arrow>
                        <Fab
                            sx={{ width: 34, height: 34, mr: 1 }}
                            color="info"
                            aria-label="edit"
                            onClick={() => handleSendAgain(row?.announcementId)}
                        >
                            <Autorenew />
                        </Fab>
                    </Tooltip>
                </Authz>
            ),
            allowOverflow: true,
            button: true,
            width: "128px",
        },
    ];

    const { data, isLoading, isError, refetch } = AnnouncementPagination(
        currentPage,
        perPage,
        search,
        sortField,
        sortDirection,
    );
    const { data: announcements }: any = data || {};

    const handleSendAgain = useCallback((announcementId: any) => {
        setAnnouncementModal(true);
        setAnnouncementId(announcementId);
    }, []);

    const handleGetAnnouncement = useCallback((announcementId: any) => {
        setAnnouncementId(announcementId);
        navigate(`/announcement/${announcementId}`)
    }, []);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        refresh();
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setCurrentPage(page);
        setPerPage(newPerPage);
        refresh();
    };

    const handleChange = (selectedRows: any) => {
        console.log("Selected Rows: ", selectedRows);
    };

    const handleSort = async (column: any, sortDirection: any) => {
        setSortField(column?.sortField === undefined ? "_id" : column?.sortField);
        setSortDirection(sortDirection);
        refresh();
    };

    const handleSearchChange = (searchKey: string) => {
        setSearch(searchKey);
        (searchKey.length == 0 || searchKey.length > 2) && searchRefresh();
    };

    const searchRefresh = debounce(() => {
        refetch();
    }, 500);

    const refresh = debounce(() => {
        refetch();
    }, 50);

    const { refetch: sendAgain, data: sData, error: sError } = SendAgain(announcementId);
    const sendAgainAnnouncement = () => {
        sendAgain()
    };

    useEffect(() => {
        if (sData && sData.status === 200) {
            dropdownAlert.current.alertWithType({ type: 'success', title: "OK", message: t('announcementSent') });
            setAnnouncementModal(false)
        }
        if (sError) {
            dropdownAlert.current.alertWithType({ type: 'error', title: "ERROR", message: t('announcementFail') });
            setAnnouncementModal(false)
        }
    }, [sData, sError])

    return (
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
            {announcements && (
                <PortiaTable
                    loading={!isError && isLoading}
                    columns={columns}
                    data={announcements}
                    onSelect={handleChange}
                    onChangePage={handlePageChange}
                    onChangePerRows={handlePerRowsChange}
                    serverSideSearch
                    onSearchChange={handleSearchChange}
                    onSort={handleSort}
                />
            )}
            <AnnouncementSendAgain
                openDialog={announcementModal}
                setOpenDialog={setAnnouncementModal}
                data={announcements}
                sendAgainAnnouncement={sendAgainAnnouncement}
                onListingRefresh={(e: any) => refetch()}
            />
        </Box>
    );
};
