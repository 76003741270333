import { apiClient } from '../PortiaContext';

const shouldIntercept = (error: any) => {
  try {
    return error.response.status === 403 ||  error.response.status === 401;
  } catch (e) {
    return false;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setTokenData = ( axiosClient: any, tokenData: any = {} ) => {
  const { crytoGateStore } = apiClient as any;
  const { setSession } = crytoGateStore || {};
  !!setSession && setSession(tokenData)
};

const handleTokenRefresh = (keycloak:any, refresh_token:any) => {
  return new Promise((resolve, reject) => {
    if( !keycloak.authenticated){
      reject("Not authenticated");
      return;
    }
    keycloak.updateToken(30).success(() => {
      resolve({token:{ access_token: keycloak.token, refresh_token: keycloak.refreshToken }});
    }).error((err:any) => { reject(err);});
  });
};

const attachTokenToRequest = (request: any, td: any) => {
  request.headers = { ...request.headers, 'Authorization' : `Bearer ${td.token['access_token']}` };
};

const tokenInjector = (
  axiosClient: {
    request: (arg0: any) => unknown;
    interceptors: {
      response: {
        use: (arg0: any, arg1: (error: any) => Promise<unknown>) => void;
      };
    };
  },
  keycloak:any,
  customOptions = {},
) => {
  let isRefreshing = false;
  let failedQueue: {
    resolve: (value: unknown) => void;
    reject: (reason?: any) => void;
  }[] = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  };

  const processQueue = (error: null, token: any = null) => {

    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const interceptor = (error: {
    config?: any;
    response?: { status: number };
  }) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then(token => {
          originalRequest._queued = true;
          options.attachTokenToRequest(originalRequest, token);
          return axiosClient.request(originalRequest);
        })
        .catch(_err => {
          const { crytoGateStore } = apiClient as any;
          if(crytoGateStore)
            crytoGateStore.reset();
          // Ignore refresh token request's "err" and return actual "error" for the original request
          return Promise.reject(error);
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      const { crytoGateStore } = apiClient as any;
      const { session } = crytoGateStore || {};
      options.handleTokenRefresh
        .call(options.handleTokenRefresh, keycloak, session?.token?.refresh_token)
        .then(tokenData => {
          options.setTokenData(tokenData, axiosClient);
          options.attachTokenToRequest(originalRequest, tokenData);
          processQueue(null, tokenData);
          resolve(axiosClient.request(originalRequest));
        })
        .catch(err => {
          const { crytoGateStore } = apiClient as any;
          console.log("FAILED TO REFRESH TOKEN", err);
          if(crytoGateStore)
            crytoGateStore.reset();
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  axiosClient.interceptors.response.use((response: any) => {
    return response;
  }, interceptor);

  return axiosClient;
};

export default tokenInjector;