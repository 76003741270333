import { NavigateFunction } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { useState } from "react";
import { CourierType, WorkingDay } from "../../api/types";

import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { CircularProgress, Fab } from "@mui/material";
import { PortiaTable } from "../../components/PortiaTable";
import { Refresh } from "@mui/icons-material";
import { debounce } from "lodash-es";
import { WorkingDaysByCourier } from "../../api/workingDay";
import { RowActions } from "../../components/PortiaTable/row.actions";
import { DatePicker } from "../../components/DateRangePicker";
import { addDays } from "date-fns";
import dayjs from "dayjs";

export const CourierWorkingDays = ({ courierId }: { courierId: string }) => {
  const { t } = useTranslation();

  const [perPage, setPerPage] = useState(50);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [sortField, setSortField] = useState<string>("workingDate");
  const [sortDirection, setSortDirection] = useState("desc");

  const [selectDays, setSelectDays] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const { data, isLoading, isError, refetch } = WorkingDaysByCourier(
    courierId,
    currentPage,
    perPage,
    search,
    startDate,
    finishDate,
    sortField,
    sortDirection
  );

  const { data: workingDays }: any = data || {};

  const columns: TableColumn<WorkingDay>[] = [
    {
      name: t("firstName"),
      selector: (row) => row.courierId,
      cell: (row) => row?.couriers?.name,
      sortable: false,
    },
    {
      name: t("date"),
      selector: (row) => row.workingDate,
      sortable: true,
      sortField: 'workingDate'
    },
    {
      name: t("startTime"),
      selector: (row) => dayjs(row.startTime).format('YYYY-MM-DD HH:mm'),
      sortable: true,
      sortField: 'startTime'
    },
    {
      name: t("finishTime"),
      selector: (row) => dayjs(row.finishTime).format('YYYY-MM-DD HH:mm'),
      sortable: true,
      sortField: 'finishTime'
    },
    {
      name: t("type"),
      selector: (row) =>t(CourierType[row.couriers.type]).toString(),
      sortable: false,
    },
    {
      cell: (row) => (
        <>
          <RowActions
            onEdit={(navigate: NavigateFunction) => {
              navigate(`/workingDays/${row.workingDayId}`, { state: row });
            }}
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "128px",
    },
  ];
 
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
  };

  const handleChange = (selectedRows: any) => {
    console.log("Selected Rows: ", selectedRows);
  };

  const handleSearchChange = (searchKey: string) => {
    console.log("searchKey", searchKey);
    setSearch(searchKey);
    (searchKey.length == 0 || searchKey.length > 2) && searchRefresh();
  };

  const searchRefresh = debounce(() => {
    refetch();
  }, 500);

  const refresh = debounce(() => {
    refetch();
  }, 50);

  const handleOnChangeDate = (ranges: any) => {
    const { selection } = ranges;
    setSelectDays([selection]);
  }
  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(column?.sortField === undefined ? "workingDate" : column?.sortField );
    setSortDirection(sortDirection);
    refresh();
  };
  const orderFilter = () => {
    setStartDate(dayjs(selectDays[0]?.startDate).format('YYYY-MM-DD'));
    setFinishDate(dayjs(selectDays[0]?.endDate).format('YYYY-MM-DD'));
    refresh();
  }
  const orderTableRefresh = () => {
    setStartDate("");
    setFinishDate("");
    refresh();
  }

  return (
    <Box sx={{ m: 2 }}>
      <Grid container>
      <Grid item xs={12} md={8} lg={8}>
          <DatePicker selectDays={selectDays} handleOnChange={handleOnChangeDate} />
        </Grid>
        <Grid mt={2} item xs={6} md={6} lg={1} >
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            style={{ float: 'right' }}
            onClick={() => orderFilter()}
          >
            {t('filter')}
          </Button>
        </Grid>
        <Grid mt={2} item xs={6} md={6} lg={1} >
          <Fab
            size='small'
            color="primary"
            aria-label="refresh"
            style={{ float: 'right' }}
            onClick={() => orderTableRefresh()}
          >
            <Refresh />
          </Fab>
        </Grid>
      </Grid>
      {!isLoading ? (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns}
          data={workingDays}
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          serverSideSearch
          onSearchChange={handleSearchChange}
          onSort={handleSort}
        />
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
