import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { useMutationWithDebounce } from "./debounce";
import {
  RESTAURANT,
  RESTAURANTS_DROPDOWN,
  RESTAURANT_CHANGE_STATUS,
  RESTAURANT_DETAILS,
} from "./paths";
import { Restaurant as TRestaurant } from "./types";

export function GetRestaurant(restaurantId: string) {
  return useQuery(["getrestaurant", restaurantId], {
    queryFn: () =>
      !!apiClient && apiClient.get(RESTAURANT_DETAILS + `/${restaurantId}`),
    retry: 0,
    enabled: false,
  });
}

export function PostRestaurant(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (restaurant: TRestaurant) =>
      apiClient.post(RESTAURANT, restaurant),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["restaurants"] });
    },
    debounceTime,
  });
}

export function PatchRestaurant(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (restaurant: any) =>
      apiClient.patch(
        RESTAURANT + `/${restaurant.restaurantId}`,
        restaurant
      ),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["restaurantspatch"] });
    },
    debounceTime,
  });
}

export function PatchAllRestaurant(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (restaurants: any) =>
      apiClient.patch(
        RESTAURANT +'/changeCarryLimitAndDistance',
        restaurants
      ),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["allrestaurantspatch"] });
    },
    debounceTime,
  });
}


export function Restaurants(
  page: number,
  limit: number,
  search: string,
  hubId: string,
  sortField: string,
  sortDirection: string,
) {
  return useQuery(["restaurants"], {
    queryFn: () =>
      !!apiClient &&
       apiClient.get(
            RESTAURANT + `?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}&hubId=${hubId}`
          ),
    retry: 0,
    onError(err) {
      console.log(err);
    },
  });
}

export function ChangeStatusRestaurant(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (id: any) =>
      apiClient.patch(RESTAURANT_CHANGE_STATUS + `/${id}`),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["restaurantchangestatus"] });
    },
    debounceTime,
  });
}
export function GetPaymentTypeByRestaurant(restaurantId: string) {
  return useQuery(["getpaymenttypebyrestaurantid", restaurantId], {
    queryFn: () =>
      !!apiClient &&
      apiClient.get(`/api/restaurants/payment-type/${restaurantId}`),
    retry: 0,
    staleTime: 100,
    cacheTime: 100,
    enabled: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function PostRestaurantPaymentTypes(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (payments: any) =>
      apiClient.post(`/api/restaurants/payment-type`, payments),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["restaurantpayments"] });
    },
    debounceTime,
  });
}

export function RestaurantsDropdown() {
  return useQuery(["restaurantsdropdown"], {
    queryFn: () => !!apiClient && apiClient.post(RESTAURANTS_DROPDOWN),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function GetRestaurantByHubId(hubId: any) {
  return useQuery(["getrestaurantbyHubId", hubId], {
    queryFn: () =>
      !!apiClient && apiClient.get(RESTAURANT + `/hub-restaurant/${hubId}`),
    retry: 0,
    enabled: false,
  });
}
export function GetRestaurantByHubIdBrandList(hubId: any) {
  return useQuery(["getrestaurantbyHubIdbrands", hubId], {
    queryFn: () =>
      !!apiClient && apiClient.get(RESTAURANT + `/hub/${hubId}`),
    retry: 0,
    enabled: true,
  });
}
export function MergeSettings(page: number,
  limit: number,
  search: string,
  hubId: string,
  sortField: string,
  sortDirection: string,
  province: string,
  district: string,
  brand: string,
  restaurant: string) {
    return useQuery(["RestaurantSetting"], {
      queryFn: () =>
        !!apiClient &&
        apiClient.post(
          RESTAURANT +'/all-restaurant-settings'+`?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}&hubId=${hubId}`,
          { province,district,brand,restaurant }
        ),
      retry: 0,
      onError(err) {
        console.log(err);
      },
    });
}

export function PatchPosInfo(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: async (data: { id: string; posInfo: any; paymentType: string }) => {
      try {
        const response = await apiClient.patch(
          RESTAURANT + `/${data.id}/pos-info`,
          { posInfo: data.posInfo, paymentType: data.paymentType }
        );

        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error('Failed to update POS information');
        }
      } catch (error) {
        throw error;
      }
    },
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["restaurantspatchPosInfo"] });
    },
    debounceTime,
  });
}