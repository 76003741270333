import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { useMutationWithDebounce } from "./debounce";
import {
  GET_PAYMENT,
  ORDERS,
  ORDERS_ACTIVE_ORDER,
  ORDERS_ASSIGN_COURIER,
  ORDERS_CHANGE_COURIER,
  ORDERS_CHANGE_STATUS,
  ORDERS_EVENT,
  ORDERS_START,
  PAYMENT_DELIVERY,
  GET_CANCEL_PAYMENTS,
} from "./paths";

export function Orders(
  restaurantId: string,
  page: number,
  limit: number,
  search: string,
  hubId: string,
  sortField: string,
  sortDirection: string,
  orderStatus: string,
  startDate:string,
  finishDate: string,
) {
  return useQuery(["orders", restaurantId], {
    queryFn: () =>
      !!apiClient &&
      apiClient.get(
        ORDERS +
          `/list/${restaurantId}?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}&hubId=${hubId}&status=${orderStatus}&startDate=${startDate}&finishDate=${finishDate}`
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}

export function GetOrder(orderId: string) {
  return useQuery(["getorder", orderId], {
    queryFn: () => !!apiClient && apiClient.get(ORDERS + `/${orderId}`),
    staleTime: 100,
    cacheTime: 100,
    retry: 0,
    enabled: false,
    onError(err) {
      console.log(err);
    },
  });
}

export function OrderChangeCourier(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (order: any) => apiClient.post(ORDERS_CHANGE_COURIER, order),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
    debounceTime,
  });
}

export function OrderStart(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (restaurantId: string) =>
      apiClient.get(ORDERS_START + `/${restaurantId}`),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["orderstart"] });
    },
    debounceTime,
  });
}

export function OrderReload(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (orderId: string) =>
      apiClient.get(ORDERS + `/order-status-reload/${orderId}`),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["orderreload"] });
    },
    debounceTime,
  });
}

export function OrderChangeStatus(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (order: any) =>
      apiClient.post(
        ORDERS_CHANGE_STATUS + `/${order.orderId}`,
        order
      ),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
    debounceTime,
  });
}
export function AssignOrderCors(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (orderId: string) =>
      apiClient.get(ORDERS_ASSIGN_COURIER + `/${orderId}`),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["getassignordercors"] });
    },
    debounceTime,
  });
}
export function GetActiveOrdesByCourierId(courierId: string) {
  return useQuery(["getactiveordersbycourierid", courierId], {
    queryFn: () =>
      !!apiClient && apiClient.get(ORDERS_ACTIVE_ORDER + `/${courierId}`),
    retry: 0,
    onError(err) {
      console.log(err);
    },
  });
}
export function GetCanceledPayments(courierId: string) {
  return useQuery(["getCancelPayments", courierId], {
    queryFn: () =>
      !!apiClient && apiClient.get(ORDERS_ACTIVE_ORDER + `/${courierId}`),
    retry: 0,
    onError(err) {
      console.log(err);
    },
  });
}
export function OrdersByCourierId(
  courierId: string,
  page: number,
  limit: number,
  status: string,
  searchKey: string,
  startDate:string,
  finishDate:string,
  sortField: string,
  sortDirection: string
) {
  return useQuery(["orderbycourierid", courierId, page, limit, status], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        ORDERS +
          `/by-courier/${courierId}?page=${page}&limit=${limit}&search=${searchKey}&sortField=${sortField}&sortDirection=${sortDirection}&startDate=${startDate}&finishDate=${finishDate}`,
        { status }
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function GetPayment(debounceTime: number = 500) {
  return useMutationWithDebounce({
    mutationFn: (paymentInfo: any) => {
      return apiClient.post(GET_PAYMENT, paymentInfo);
    },
    debounceTime,
  });
}
export function SendOrderEvent(debounceTime: number = 500) {
  return useMutationWithDebounce({
    mutationFn: ({ orderId, data, event }: any) => {
      return apiClient.post(ORDERS_EVENT, { orderId, event, data });
    },
    debounceTime,
  });
}
export function DeliverOrder(debounceTime: number = 500) {
  return useMutationWithDebounce({
    mutationFn: ({ order, courierId }: any) => {
      return apiClient.post(PAYMENT_DELIVERY, {
        order,
        courierId,
      });
    },
    debounceTime,
  });
}

export function OrdersDynamicStatus(
  restaurantId: string,
  page: number,
  limit: number,
  search: string,
  hubId: string,
  startDate:string,
  finishDate: string,
  status: any,
  sortField: string,
  sortDirection: string
) {
  return useQuery(["orders", restaurantId], {
    queryFn: () =>
      !!apiClient &&
      apiClient.post(
        ORDERS +
          `/list/${restaurantId}?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortDirection=${sortDirection}&hubId=${hubId}&startDate=${startDate}&finishDate=${finishDate}`,
        status && { status }
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}
export function CreateOrderMutation(debounceTime: number = 500) {
  return useMutationWithDebounce({
    mutationFn: (createOrderDto: any) => {
      return apiClient.post(ORDERS, createOrderDto);
    },
    debounceTime,
  });
}