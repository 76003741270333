import { TableColumn } from "react-data-table-component";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { PortiaTable } from "../../components/PortiaTable";
import Box from "@mui/material/Box";
import { RowActions } from "../../components/PortiaTable/row.actions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";
import Authz from "../../components/Authz";
import { GetProceeds } from "../../api/proceeds";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const EarningList = ({ courierId }: any) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const columns: TableColumn<any>[] = [
        {
            name: t("Kazanç Id"),
            selector: (row) => row._id,
            sortable: true,
            sortField: "_id",
        },
        {
            name: t("Kazanç Dönem Tarihi"),
            selector: (row) => row.reconciliationDate,
            sortable: true,
            sortField: "reconciliationDate",
        },
        {
            name: t("Durum"),
            selector: (row) => t("proceeds_"+row.status),
            sortable: true,
            sortField: "status",
        },

        {
            cell: (row) => (
                <Authz allowedRoles={["portia-admin", "portia-hub-manager"]}>
                    <RowActions
                        onEdit={(navigate: NavigateFunction) => {
                            navigate(`/earning/${row.profitId}`, { state: {row, courierId:courierId} });
                        }}
                    />
                </Authz>
            ),
            allowOverflow: true,
            button: true,
            width: "128px",
        },
    ];

    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState<string>("");
    const [sortField, setSortField] = useState<string>("_id");
    const [sortDirection, setSortDirection] = useState("desc");
    const { data: proceedData, isLoading, isError, refetch } = GetProceeds(currentPage, perPage, search, courierId, sortField, sortDirection,);

    const handleChange = () => {
        // console.log("Selected Rows: ", selectedRows);
    };
    const refresh = debounce(() => {
        refetch();
    }, 50);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        refresh();
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setCurrentPage(page);
        setPerPage(newPerPage);
        refresh();
    };

    const handleSort = async (column: any, sortDirection: any) => {
        setSortField(column?.sortField === undefined ? "_id" : column?.sortField);
        setSortDirection(sortDirection);
        refresh();
    };

    const handleSearchChange = (searchKey: string) => {
        setSearch(searchKey);
        (searchKey.length === 0 || searchKey.length > 2) && searchRefresh();
    };

    const searchRefresh = debounce(() => {
        refetch();
    }, 500);

    return (
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
            {proceedData && (
                <PortiaTable
                    loading={!isError && isLoading}
                    columns={columns}
                    data={proceedData?.data}
                    onSelect={handleChange}
                    onChangePage={handlePageChange}
                    onChangePerRows={handlePerRowsChange}
                    serverSideSearch
                    onSearchChange={handleSearchChange}
                    onSort={handleSort}
                />
            )}
            <Authz allowedRoles={["portia-admin", "portia-hub-manager"]}>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => {
                        let navRoute = `/earning/new`;
                        navigate(navRoute, { state: { courierId:courierId} });
                    }}
                >
                    <AddIcon />
                </Fab>
            </Authz>
        </Box>
    );
};
