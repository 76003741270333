import {  useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "@mui/material";
import { RestaurantsList } from "../Restaurants";
import { CouriersList } from "../Couriers";
import { OrdersList } from "../Orders";
import { HubDetail } from "./hub.detail";
import { PosList } from "../Pos";
import { CouriersLocation } from "../Couriers/couriers.track";
import { HubBonus } from "./hub.bonus";
import { WorkingDaysList } from "../WorkingDays";
import { useRoles } from "../../hooks/useRoles";



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
export const Hubs = () => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const [tab, setTab] = useState(0);
  const tabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const path: google.maps.LatLngLiteral[] = [];


  const hubId: any = params && params["hubid"];
  const isNewHub = !!!location.state && hubId == "new";

  return (
    <Box sx={{}}>
      <Tabs value={tab} onChange={tabChange}>
        <Tab label={t("general")} />
        {!isNewHub && <Tab label={t("Courier")} />}
        {!isNewHub && <Tab label={t("tabTracing")} />}
        {!isNewHub && <Tab label={t("tabOrders")} />}
        {!isNewHub && <Tab label={t("Restaurants")} />}
        {!isNewHub && <Tab label={t("tabPos")} />}
        {!isNewHub && <Tab label={t("WorkingDays")}/>}
        {!isNewHub && <Tab label={t("tabHubBonus")} />}
      </Tabs>
      <Box>
        <TabPanel value={tab} index={0}>
          <HubDetail />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Box sx={{ m: 2 }}>
            <CouriersList />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Box sx={{ m: 2 }}>
            <CouriersLocation />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <Box sx={{ m: 2 }}>
            <OrdersList />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <Box sx={{ m: 2 }}>
            <RestaurantsList />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={5}>
          <Box sx={{ m: 2 }}>
            <PosList />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={6}>
          <Box sx={{m:2}}>
            <WorkingDaysList/>
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={7}>
          <Box>
            <HubBonus />
          </Box>
        </TabPanel>

      </Box>
    </Box>
  );
};
