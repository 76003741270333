import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../context";
import { useMutationWithDebounce } from "./debounce";
import { COURIER_TYPES, COURIER_TYPES_DETAIL } from "./paths";
import { CourierTypes } from "./types";

export function CourierTypesList(
  page: number,
  limit: number,
  sortDirection: string,
  sortField: string
) {
  return useQuery(["couriertypelist", page, limit], {
    queryFn: () =>
      !!apiClient &&
      apiClient.get(
        COURIER_TYPES +
          `/list?page=${page}&limit=${limit}&sortField=${sortField}&sortDirection=${sortDirection}`
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    onError(err) {
      console.log(err);
    },
  });
}

export function GetCourierTypeDetail(courierTypeId: string) {
  return useQuery(["getcouriertypedetail", courierTypeId], {
    queryFn: () =>
      !!apiClient && apiClient.get(COURIER_TYPES_DETAIL + `/${courierTypeId}`),
    staleTime: 100,
    cacheTime: 100,
    retry: 0,
    enabled: false,
  });
}

export function CreateCourierTypes(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (courierType: CourierTypes) =>
      apiClient.post(COURIER_TYPES, courierType),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["createcouriertypes"] });
    },
    debounceTime,
  });
}
export function PatchCourierTypes(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (courierType: any) =>
      apiClient.patch(
        COURIER_TYPES + `/${courierType.courierTypeId}`,
        courierType
      ),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["patchcouriertypes"] });
    },
    debounceTime,
  });
}

export function AllCourierType() {
  return useQuery(["allcouriertype"], {
    queryFn: () =>
      !!apiClient &&
      apiClient.get(COURIER_TYPES + `/list?page=1&limit=100000&search=`),
    retry: 0,
    enabled: true,
  });
}

export function DeleteCourierType(debounceTime: number = 500) {
  const queryClient = useQueryClient();
  return useMutationWithDebounce({
    mutationFn: (courierTypeId: any) =>
      apiClient.delete(COURIER_TYPES + `/${courierTypeId}`),
    onSuccess: (data: any, variables: { id: any }) => {
      queryClient.invalidateQueries({ queryKey: ["deletecouriertype"] });
    },
    debounceTime,
  });
}
