import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { LocationPicker } from "../../components/PortiaMap";
import { TrackHubCouriers } from "../../api/courier";
import { PatchHub, PostHub, GetHub } from "../../api/hubs";
import { Point } from "geojson";
import { Hub } from "../../api/types";
import { usePortia } from "../../context";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { FormContainer } from "react-hook-form-mui";

export const CouriersLocation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const [hub, setHub] = useState<Hub>(location?.state);
  const hubId: any = params && params["hubid"];
  const couriersPosition: any[] = [];
  const [position, setPosition] = useState<google.maps.LatLngLiteral>();
  const [path, setPath] = useState<google.maps.LatLngLiteral[]>([]);

  const {
    dropdownAlert,
    store: { setLoading },
  } = usePortia();
  const { refetch: getTrack, data: trackData } = TrackHubCouriers(hubId);

  useEffect(() => {
    getTrack();
  }, [hubId]);
  
  useEffect(() => {
    if (trackData?.data?.data) {
      trackData?.data?.data.map((pt: any) =>
        couriersPosition.push({
          lat: pt.lat,
          lng: pt.lon,
          courierId: pt.courier,
          courierName: pt.courierName,
          speed: pt.speed,
          licensePlate: pt.licensePlate,
        })
      );
      if (couriersPosition?.length > 0) {
        setPath(couriersPosition);
      }
    }
  }, [trackData]);
  const {
    mutate: create,
    data: cResult,
    error: cError,
    isLoading: isPostLoading,
  } = PostHub();
  const {
    mutate: save,
    data: pResult,
    error: sError,
    isLoading: isPatchLaoding,
  } = PatchHub();
  const { refetch: getHub, data: hubData } = GetHub(hubId);

  const isNewHub = !!!location.state && hubId === "new";

  const getCurrentPositionOrDefault = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) =>
        setPosition({
          lat: 41.10551877478612,
          lng: 23.02610099753676,
        })
    );
  };
  useEffect(() => {
    if (hubId && hubId !== "new") {
      getHub();
    } else {
      getCurrentPositionOrDefault();
    }
  }, [params, hubId]);

  useEffect(() => {
    setHub(hubData?.data);
    const { data: r } = hubData || {};
    if (r) {
      const { location } = r;
      setPosition({
        lng: location?.coordinates[0],
        lat: location?.coordinates[1],
      });
    }
  }, [hubData]);

  useEffect(() => {
    setLoading(isPostLoading || isPatchLaoding);
  }, [isPostLoading, isPatchLaoding]);

  useEffect(() => {
    if (
      (pResult && (pResult.status === 200 || pResult.status === 201)) ||
      (cResult && (cResult.status === 200 || cResult.status === 201))
    ) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }

    if (sError || cError)
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "Error",
        message:
          t(sError?.response?.data?.message) ||
          t(cError?.response?.data?.message),
      });
  }, [pResult, cResult, cError, sError]);

  const saveHub = (c: Hub) => {
    const l = position && {
      type: "Point",
      coordinates: [position.lat || 0, position.lng || 0],
    };
    c.location = l as Point;
    if (isNewHub) {
      create(c);
    } else {
      save(c);
    }
  };
  const getLastPositionCurier = () => {
    getTrack();
    if (trackData?.data?.data) {
      trackData?.data?.data.map((pt: any) =>
        couriersPosition.push({
          lat: pt.lat,
          lng: pt.lon,
          courierId: pt.courier,
          courierName: pt.courierName,
          speed: pt.speed,
        })
      );
    }
  };
  return (
    <Box sx={{ m: 2 }}>
      <FormContainer values={hub} onSuccess={(data) => saveHub(data)}>
        <Grid item xs={12}>
          {position && (
            <LocationPicker
              position={position}
              title={hub?.name}
              couriersPosition={path}
              onRefresh={() => getLastPositionCurier()}
            />
          )}
        </Grid>
      </FormContainer>
    </Box>
  );
};
