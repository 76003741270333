import { TableColumn } from "react-data-table-component";
import { useNavigate, NavigateFunction } from "react-router-dom";

import { ChangeStatusHub, HubsPagination } from "../../api/hubs";
import { Hub, User } from "../../api/types";
import { PortiaTable } from "../../components/PortiaTable";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { RowActions } from "../../components/PortiaTable/row.actions";
import Authz from "../../components/Authz";
import { useCallback, useEffect, useState } from "react";
import { usePortia } from "../../context";
import { Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";
import { HubDelete } from "./hub.delete";

export const HubsList = () => {
  const navigate = useNavigate();
  const { dropdownAlert } = usePortia();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [search, setSearch] = useState<string>("");
  const [openHubDeleteModal, setHubDeleteModal] = useState(false);
  const [sortField, setSortField] = useState<string>("_id");
  const [sortDirection, setSortDirection] = useState("desc");


  const managerListRender = useCallback((row: Hub) => {
    return (
      <Box>
        {row?.managers.map((item: User, index) => {
          return (
            <Box
              key={index}
              sx={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "4px",
                margin: "4px",
                fontSize: "12px",
              }}
            >
              <Box>
                {t("name") + ": " + item?.firstName + " " + item?.lastName}
              </Box>
              <Box>
                {t("email") + ": " + item?.email}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  }, []);

  const columns: TableColumn<Hub>[] = [
    {
      name: t("firstName"),
      selector: (row) => row?.name,
      sortable: true,
      sortField: 'name'
    },
    {
      name: t("manager"),
      selector: (row) => row?.hubId,
      cell: (row) => (row?.managers ? managerListRender(row) : "-"),
      sortable: false, 
    },
    {
      name: t("state"),
      cell: (row) => (
        <Authz allowedRoles={["portia-admin"]}>
          <Switch
            checked={!!row?.status}
            onChange={() => {
              handleChangeStatusHub(row?.hubId);
            }}
          />
        </Authz>
      ),
      button: true,
    },
    {
      cell: (row) => (
        <>
          <Authz allowedRoles={["portia-admin", "portia-hub-manager"]}>
            <RowActions
              onEdit={(navigate: NavigateFunction) => {
                navigate(`/hubs/${row.hubId}`, { state: row });
              }}
            />
          </Authz>
          <Authz allowedRoles={["portia-admin"]}>
            <RowActions
              onDelete={() => {
                handleHubDelete(row?.hubId);
              }}
            />
          </Authz>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "128px",
    },
  ];

  const { data, isLoading, isError, refetch } = HubsPagination(
    currentPage,
    perPage,
    search,
    sortField,
    sortDirection
  );
  const { data: hubs }: any = data || {};

  const {
    mutate: changeStatusHub,
    data: rResult,
    error: rError,
  } = ChangeStatusHub();

  const handleChangeStatusHub = useCallback((id: any) => {
    changeStatusHub(id);
  }, []);

  useEffect(() => {
    if (rResult && rResult.status === 200) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("statusUpdate"),
      });
      refresh();
    }
  }, [rResult]);

  useEffect(() => {
    if (rError) {
      dropdownAlert.current.alertWithType({
        type: "warning",
        title: "OK",
        message: rError && t(rError?.response?.data.message),
      });
    }
  }, [rError]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refresh();
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setCurrentPage(page);
    setPerPage(newPerPage);
    refresh();
  };

  const handleChange = (selectedRows: any) => {
    console.log("Selected Rows: ", selectedRows);
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSortField(column?.sortField === undefined ? "_id" : column?.sortField );
    setSortDirection(sortDirection);
    refresh();
  };

  const handleSearchChange = (searchKey: string) => {
    setSearch(searchKey);
    (searchKey.length == 0 || searchKey.length > 2) && searchRefresh();
  };

  const searchRefresh = debounce(() => {
    refetch();
  }, 500);

  const refresh = debounce(() => {
    refetch();
  }, 50);
  const [hubDeletedata, setHubDeleteData] = useState<Object>();

  const handleHubDelete = useCallback((hubId: any) => {
    setHubDeleteData({ hubId: hubId });
    setTimeout(() => {
      setHubDeleteModal(true);
    }, 150);
  }, []);
  
  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      {hubs && (
        <PortiaTable
          loading={!isError && isLoading}
          columns={columns}
          data={hubs}
          onSelect={handleChange}
          onChangePage={handlePageChange}
          onChangePerRows={handlePerRowsChange}
          serverSideSearch
          onSearchChange={handleSearchChange}
          onSort={handleSort}
        />
      )}
      {hubDeletedata?.hasOwnProperty("hubId") && (
        <HubDelete
          openDialog={openHubDeleteModal}
          setOpenDialog={setHubDeleteModal}
          data={hubDeletedata}
          onListingRefresh={(e: any) => refetch()}
        />
      )}
      <Authz allowedRoles={["portia-admin"]}>
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => navigate(`/hubs/new`, { state: undefined })}
        >
          <AddIcon />
        </Fab>
      </Authz>
    </Box>
  );
};
