import { makeObservable, action, observable } from "mobx";
import { apiClient } from "./PortiaContext";
import { MqttConnection } from "./mqtt";
import jwtDecode from "jwt-decode";

export enum StackStype {
  Auth = 1,
  App,
  Onboarding,
  CreateUser,
  OnboardingSuccess,
  Wallet,
}

let INITEDKC = false;
export class PortiaStore {
  stack = StackStype.Auth;
  user = undefined;
  mqttClient = undefined;
  keycloak: any = undefined;
  message = undefined;
  session = undefined;
  error = undefined;
  loading = false;

  constructor() {
    makeObservable(this, {
      stack: observable,
      user: observable,
      session: observable,
      message: observable,
      mqttClient: observable<any>,
      keycloak: observable<any>,
      error: observable,
      loading: observable,
      setUser: action.bound,
      setStack: action.bound,
      setSession: action.bound,
      setError: action.bound,
      setLoading: action.bound,
      reset: action.bound,
      setMessage: action.bound,
      setMqttClient: action.bound,
      setKeycloak: action.bound,
      isUserSessionStarted: action.bound,
      login: action.bound,
      logout: action.bound,
    });
  }

  setLoading(newLoading: any) {
    this.loading = newLoading;
  }

  setUser(newUser: any) {
    this.user = newUser;
    if (this.user) {
      this.connect();
    }
  }

  setStack(typ: any) {
    this.stack = typ;
  }

  setSession(newSession: any) {
    if (apiClient && newSession) {
      apiClient.defaults.headers[
        "Authorization"
      ] = `Bearer ${newSession.token["access_token"]}`;
    }
    if (!newSession.profile) newSession.profile = this.user;
    this.session = newSession;
    this.setUser(newSession.profile);
  }

  restore() {
    const thisstore = this;
    if (this.keycloak && this.keycloak.authenticated) {
      this.keycloak
        .loadUserProfile()
        .then(function (profile: any) {
          const jwtToken = jwtDecode(thisstore.keycloak.token) as any;
          const x_session = {
            profile,
            roles: jwtToken?.realm_access?.roles,
            token: {
              access_token: thisstore.keycloak.token,
              refresh_token: thisstore.keycloak.refreshToken,
            },
          };
          // console.log(JSON.stringify(x_session, null, "  "));

          thisstore.setSession(x_session);
          thisstore.setStack(StackStype.App);
        })
        .catch(function () {
          alert("Failed to load user profile");
        });
      thisstore.keycloak.onTokenExpired = () => {
        // console.log("token expired", thisstore.keycloak.token);
        thisstore.keycloak
          .updateToken(30)
          .success(() => {
            // console.log("TOKEN REFRESHED", thisstore.keycloak?.authenticated);
            thisstore.restore();
          })
          .error((e: any) => {
            // console.log("TOKEN FAILED", e);
          });
      };
    }
    /* try {
      const value = AsyncStorage.getItem('@x-session').then(
        (json: any) => {
          // console.log("RESTORED TOKEN", json)

          const x_token = !!json && JSON.parse(json);
          // console.log("RESTORED XTOKEN", x_token)

          if (x_token) {
            this.setSession(x_token)
            this.setStack(StackStype.App);
          }
        }).catch(e => {
          console.error(e);
          this.reset();
        })
    } catch (e) {
      console.error(e);
      this.reset();
    } */
  }

  setError(newError: any) {
    this.error = newError;
  }

  reset() {
    if (apiClient) {
      apiClient.defaults.headers["Authorization"] = null;
    }
    // AsyncStorage.removeItem('@x-session')

    this.stack = StackStype.Auth;
    this.error = undefined;
    this.session = undefined;
    this.user = undefined;
    // if (this.mqttClient) this.mqttClient.end();
    this.mqttClient = undefined;
  }

  setMessage(msg: any) {
    this.message = msg;
  }

  setMqttClient(clt: any) {
    this.mqttClient = clt;
  }

  setKeycloak(kc: any) {
    this.keycloak = kc;
  }

  isUserSessionStarted() {
    if (this.keycloak && !INITEDKC) {
      INITEDKC = true;
      this.keycloak
        .init({ onLoad: "login-required", checkLoginIframe: false })
        .then((authenticated: any) => {
          /* console.log(
            "login-required SESSION CHECK",
            authenticated,
            this.keycloak
          );*/
          this.restore();
        })
        .catch((e: any) => {
          console.error("login-required SESSION CHECK", e);
        });
    }
    // console.log("USER SESSION CHECK", this.keycloak?.authenticated);
    this.restore();
  }

  logout() {
    !!this.keycloak && this.keycloak.logout();
    this.reset();
  }

  login(req: any, onSuccess: Function, onFailure: Function) {
    this.error && this.setError(undefined);
    if (req?.customerNumber && req?.pin && req.pin === "123456") {
      const user = {
        name: "Mr Crypto",
        customerNumber: req?.customerNumber,
      };
      const session = { user };
      this.setUser(user);
      this.setSession(session);
      this.setStack(StackStype.App);
      onSuccess(session);
    } else {
      const error = {
        code: 1234,
        message: `The input does not match user ${JSON.stringify(req)}`,
      };
      onFailure(error);
    }
  }

  connect() {
    const connection = new MqttConnection({
      user: this.user,
      onConnect: () => {},
    });
    this.setMqttClient(connection);
  }
}
// Instantiate the store.
