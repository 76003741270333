import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  AutocompleteElement,
  FormContainer,
  PasswordElement,
  TextFieldElement,
} from "react-hook-form-mui";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import HubIcon from "@mui/icons-material/Hub";
import FlakySharpIcon from "@mui/icons-material/FlakySharp";
import SportsMotorsportsSharpIcon from "@mui/icons-material/SportsMotorsportsSharp";
import StraightenIcon from "@mui/icons-material/Straighten";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import TextField from "@mui/material/TextField";
import GarageIcon from "@mui/icons-material/Garage";
import { ShiftsList } from "../Shifts/shifts.list";
import { Courier, CourierType, ORDER_STATUS } from "../../api/types";
import {
  PatchCourier,
  PostCourier,
  GetCourier,
  TrackCourier,
} from "../../api/courier";
import {
  NAMEPATTERN,
  USERNAMEPATTERN,
  EMAILPATTERN,
  MOBILEPATTERN,
  TCKNPATTERN,
  PASSWORDPATTERN,
  MULTINAMEPATTERN,
  LICENSEPLATEPATTERN,
} from "../../components/form/patterns";
import { usePortia } from "../../context";
import { useEffect, useState } from "react";
import { Hubs } from "../../api/hubs";
import { useTranslation } from "react-i18next";
import { PortiaPasswordRepeatElement } from "../../components/PasswordRepeatElement";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { LocationPicker } from "../../components/PortiaMap";
import { CourierOrders } from "./couriers.orders";
import { CourierWorkingDays } from "./couriers.workingdays";
import { CourierActivityChart } from "./courier.chart";
import { AllCourierType } from "../../api/courierType";
import { ReconciliationList } from "./courier.reconciliationList";
import { Earning } from "./courier.earning";
import { EarningList } from "./courier.earningList";

import Authz from "../../components/Authz";
import { useRoles } from "../../hooks/useRoles";
import { isEmpty } from "lodash-es";
import { addDays, addMinutes } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker as DatePickerMui } from "@mui/x-date-pickers/DatePicker";
import { DatePicker as DateRangePicker } from "../../components/DateRangePicker";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
export const CourierDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [startDateRequest, setStartDateRequest] = useState<number>(Math.floor(addMinutes(new Date(), -15).getTime() / 1000));
  const [endDateRequest, setEndDateRequest] = useState<number>(Math.floor(new Date().getTime() / 1000));
  const [selectDays, setSelectDays] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [startTime, setStartTime] = useState<Dayjs | null>(dayjs(addMinutes(new Date(), -15)));
  const [finishTime, setFinishTime] = useState<Dayjs | null>(dayjs(new Date()));

  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const {
    dropdownAlert,
    store: { setLoading },
  } = usePortia();
  const [courier, setCourier] = useState<Courier>(location?.state);
  const [password, setPassword] = useState<string>();
  const [tab, setTab] = useState(0);

  const courierId: any = params && params["courierid"];
  const { data, isLoading } = Hubs();
  const {
    mutate: create,
    data: cResult,
    error: cError,
    isLoading: isPostLoading,
  } = PostCourier();
  const {
    mutate: save,
    data: pResult,
    error: sError,
    isLoading: isPatchLaoding,
  } = PatchCourier();
  const { refetch: getCourier, data: courierData } = GetCourier(courierId);
  const { data: trackData, refetch: getTrack } = TrackCourier(courierId, startDateRequest.toString(), endDateRequest.toString());
  const isNewCourier = !!!location.state && courierId === "new";
  const [position, setPosition] = useState<google.maps.LatLngLiteral>({
    lat: 41.10551877478612,
    lng: 29.02610099753676,
  });

  const hasPortiaDevRole = useRoles({ allowedRoles: ["portia-dev"] });
  useEffect(() => {
    if (courierId && courierId !== "new") {
      getCourier();
    }
  }, [params, courierId, getCourier]);

  useEffect(() => {
    const c = {
      ...courierData?.data,
      mobile: courierData?.data?.mobile.replace(/^(90|0)/, ""),
    };
    setCourier(c);
    setSelectedCourierType({
      courierType: courierData?.data?.type,
      ...([CourierType.DEDICATED, CourierType.JOKER].includes(
        courierData?.data?.type
      ) && {
        amountPerHour: courierData?.data?.reconciliationParams?.amountPerHour,
      }),
      ...([
        CourierType.POOL,
        CourierType.DEDICATED,
        CourierType.STABLE,
        CourierType.PAYROLL,
        CourierType.JOKER,
      ].includes(courierData?.data?.type) && {
        amountPerPack: courierData?.data?.reconciliationParams?.amountPerPack,
      }),
      ...([CourierType.PAYROLL].includes(courierData?.data?.type) && {
        fixedAmount: courierData?.data?.reconciliationParams?.fixedAmount,
      }),
      ...([CourierType.STABLE].includes(courierData?.data?.type) && {
        minAmount: courierData?.data?.reconciliationParams?.minAmount,
        minPackage: courierData?.data?.reconciliationParams?.minPackage,
      }),
    });

    if (c?.track) {
      setPosition({
        lng: c.track.location?.coordinates[0],
        lat: c.track.location?.coordinates[1],
      });
    }
    if (courierData?.data) setBlockedReasonElement(courierData?.data?.state === "BLOCKED" ? true : false);

  }, [courierData]);

  useEffect(() => {
    setLoading(isPostLoading || isPatchLaoding);
  }, [isPostLoading, isPatchLaoding, setLoading]);

  useEffect(() => {
    if (
      (pResult && (pResult.status === 200 || pResult.status === 201)) ||
      (cResult && (cResult.status === 200 || cResult.status === 201))
    ) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }

    if (sError || cError)
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "Error",
        message:
          t(sError?.response?.data?.message) ||
          t(cError?.response?.data?.message),
      });
  }, [pResult, cResult, cError, sError]);

  const hubs = (data && data?.data?.items) || [];

  const stateOptions = [
    { id: "ACTIVE", label: t("active") },
    { id: "BLOCKED", label: t("blocked") },
    { id: "INACTIVE", label: t("inactive") },
    { id: "DELETED", label: t("deleted") },
  ];

  const wearableOptions = [
    { id: "XS", label: "XS" },
    { id: "S", label: "S" },
    { id: "M", label: "M" },
    { id: "L", label: "L" },
    { id: "XL", label: "XL" },
    { id: "XXL", label: "XXL" },
  ];

  const vehicleTypeOptions = [{ id: 1, label: t("motorcycle") }];

  const tabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const saveCourier = (c: Courier) => {
    let courierSaveData: any = { ...c, selectedCourierType };
    if (isNewCourier) {
      create(courierSaveData);
    } else {
      save(courierSaveData);
    }
  };
  const [path, setPath] = useState<google.maps.LatLngLiteral[]>([]);
  const [ctr, setCtr] = useState<google.maps.LatLngLiteral>(position);
  let newPath: any[] = [];
  const couriersPosition: any[] = [];
  const [courierPosition, setCourierPosition] = useState<google.maps.LatLngLiteral[]>([]);


  useEffect(() => {
    const data = trackData?.data?.data;
    if (data && data.length > 0) {
      data.map((pt: any) =>
        newPath.push({ lat: pt.lat, lng: pt.lon, time: pt._time })
      );
      setPath(newPath);
      setCtr(newPath[newPath.length - 1]);
      couriersPosition.push({
        lat: data[data.length - 1].lat,
        lng: data[data.length - 1].lon
      });
      setCourierPosition(couriersPosition);
    } else {
      newPath = [];
      setPath(newPath);
      setCourierPosition([]);
      if (data && data?.length == 0) {
        dropdownAlert.current.alertWithType({
          type: "error",
          title: "",
          message: t("dataNotFound"),
        });
      }
    }
  }, [trackData]);

  useEffect(() => {
    console.log(courierData?.data.location);
    if (courierData?.data?.location === undefined) return;
    if (trackData?.data?.data?.length > 0) return;

    setPosition({
      lng: courierData.data.location.coordinates[0],
      lat: courierData.data.location.coordinates[1],
    });

    setCtr({
      lng: courierData.data.location.coordinates[0],
      lat: courierData.data.location.coordinates[1],
    });

  }, [courierData, trackData]);

  useEffect(() => {
    if (courierData?.data?.hub?.location?.coordinates?.length > 0) {
      const newLocation = courierData?.data?.hub?.location?.coordinates
      setPosition({
        lng: newLocation[0],
        lat: newLocation[1],
      });

      setCtr({
        lng: newLocation[0],
        lat: newLocation[1],
      });

    }
  }, [courierData])


  const getCourierLastLocation = () => {
    getTrack(courierId);
  };

  useEffect(() => {
    if (startTime && startDate && finishTime) {
      const startDateTime = startDate?.format('YYYY-MM-DD').toString().concat(" ", startTime?.format("HH:mm:ss").toString());
      const endDateTime = startDate?.format('YYYY-MM-DD').toString().concat(" ", finishTime?.format("HH:mm:ss").toString());
      console.log("Time: ", new Date(startDateTime).getTime(), "end ", new Date(endDateTime).getTime());
      setStartDateRequest(Math.floor(new Date(startDateTime).getTime() / 1000));
      setEndDateRequest(Math.floor(new Date(endDateTime).getTime() / 1000));
    }
  }, [startDate, startTime, finishTime])

  const handleOnChangeDate = (ranges: any) => {
    const { selection } = ranges;
    setSelectDays([selection]);
    const start = dayjs(selection?.startDate);
    const end = dayjs(selection?.endDate);
    setStartDate(start);
    setStartTime(start);
    setFinishTime(end);
  }

  const { data: AllCourierTypeData } = AllCourierType();

  const allCourierTypes =
    (AllCourierTypeData && AllCourierTypeData?.data?.items) || [];

  const [selectedCourierType, setSelectedCourierType] = useState<any>(null);
  const [blockedReasonElement, setBlockedReasonElement] = useState(false);

  const changeCourierTypeHandle = (data: any) => {
    if (data != null) {
      setSelectedCourierType(data?.dataVal);
    } else {
      setSelectedCourierType({
        amountPerHour: "",
        amountPerPack: "",
        fixedAmount: "",
        minAmount: "",
        minPackage: "",
      });
    }
  };
  const hasPortiaFaz2Role = useRoles({ allowedRoles: ["portia-faz-2"] });

  return (
    <Box sx={{ mt: 2 }}>
      <Tabs value={tab} onChange={tabChange}>
        <Tab label={t("general")} />
        {!isNewCourier && <Tab label={t("tabTracing")} />}
        {!isNewCourier && <Tab label={t("tabShift")} />}
        {!isNewCourier && <Tab label={t("tabActiveOrders")} />}
        {!isNewCourier && <Tab label={t("tabPastOrders")} />}
        {!isNewCourier && <Tab label={t("WorkingDays")} />}
        {!isNewCourier && <Tab label={t("reconciliations")} />}
        {!isNewCourier && hasPortiaFaz2Role && <Tab label={t("earning")} />}
        {!isNewCourier && hasPortiaDevRole && (
          <Tab label={t("tabStatistics")} />
        )}
      </Tabs>
      <Box sx={{ border: 1, borderColor: "#eee" }}>
        <TabPanel value={tab} index={0}>
          <Box sx={{ m: 2 }}>
            <FormContainer
              values={courier}
              defaultValues={{
                hub: { hubId: searchParams.get("hubId")?.toString() },
                vehicle: { type: 1 },
                // reconciliationParams: {
                //   amountPerHour: selectedCourierType?.amountPerHour,
                //   amountPerPack: selectedCourierType?.amountPerPack,
                //   fixedAmount: selectedCourierType?.fixedAmount,
                //   minAmount: selectedCourierType?.minAmount,
                //   minPackage: selectedCourierType?.minPackage,
                // },
              }}
              onSuccess={(data) => saveCourier(data)}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <AutocompleteElement
                    label={t("hub")}
                    name="hub.hubId"
                    matchId
                    required
                    loading={isLoading}
                    textFieldProps={{
                      inputProps: {
                        form: {
                          autocomplete: "off",
                        },
                      },
                      InputProps: {
                        startAdornment: <HubIcon />,
                      },
                    }}
                    options={hubs.map((h: any) => {
                      return {
                        id: h.hubId,
                        label: h.name,
                      };
                    })}
                    rules={{ required: t("hubRequiredMessage").toString() }}
                    autocompleteProps={{
                      disabled: searchParams.get("hubId") ? true : false,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteElement
                    label={t("state")}
                    name="state"
                    matchId
                    required
                    textFieldProps={{
                      inputProps: {
                        form: {
                          autocomplete: "off",
                        },
                      },
                      InputProps: {
                        startAdornment: <FlakySharpIcon />,
                      },
                    }}
                    autocompleteProps={{
                      onChange: (event, value) => {
                        setBlockedReasonElement(value?.id === "BLOCKED" ? true : false);
                      }
                    }}
                    options={stateOptions}
                    rules={{ required: t("stateRequiredMessage").toString() }}
                  />
                  {
                    blockedReasonElement && (
                      <TextFieldElement
                        required
                        label={t("blockReason")}
                        name="blockedReason"
                        fullWidth
                        validation={{ required: t("required").toString() }}
                        sx={{ marginTop: 3 }}
                      />
                    )
                  }
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteElement
                    label={t("type")}
                    name="type"
                    matchId
                    required
                    textFieldProps={{
                      inputProps: {
                        form: {
                          autocomplete: "off",
                        },
                      },
                    }}
                    options={allCourierTypes.map((x: any, index: any) => {
                      return {
                        id: x.courierType,
                        value: x.courierType,
                        label: x.courierTypeName,
                        dataVal: x,
                      };
                    })}
                    rules={{ required: t("typeRequiredMessage").toString() }}
                    autocompleteProps={{
                      onChange: (e: any, v: any) => {
                        changeCourierTypeHandle(v);
                      },
                      getOptionLabel: (option) => t(option.label),
                    }}
                  />

                  {/* Kurye mutabakat parametreleri popup/box */}
                  <Grid item xs={12} paddingY={2}>
                    <Box>
                      {[CourierType.DEDICATED, CourierType.JOKER].includes(
                        selectedCourierType?.courierType
                      ) && (
                          <Grid item xs={12} marginTop={1}>
                            <TextFieldElement
                              name={"reconciliationParams.amountPerHour"}
                              label={t("amountPerHour")}
                              fullWidth
                              type={"number"}
                              InputProps={{
                                value: selectedCourierType?.amountPerHour,
                                onChange: (e: any) => {
                                  setSelectedCourierType((prevState: any) => ({
                                    ...prevState,
                                    amountPerHour: e.target.value,
                                  }));
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        )}
                      {[
                        CourierType.POOL,
                        CourierType.DEDICATED,
                        CourierType.STABLE,
                        CourierType.PAYROLL,
                        CourierType.JOKER,
                      ].includes(selectedCourierType?.courierType) && (
                          <Grid item xs={12} marginTop={1}>
                            <TextFieldElement
                              name={"reconciliationParams.amountPerPack"}
                              label={t("amountPerPack")}
                              fullWidth
                              type={"number"}
                              InputProps={{
                                value: selectedCourierType?.amountPerPack,
                                onChange: (e: any) => {
                                  setSelectedCourierType((prevState: any) => ({
                                    ...prevState,
                                    amountPerPack: e.target.value,
                                  }));
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        )}
                      {[CourierType.PAYROLL].includes(
                        selectedCourierType?.courierType
                      ) && (
                          <Grid item xs={12} marginTop={1}>
                            <TextFieldElement
                              name={"reconciliationParams.fixedAmount"}
                              label={t("fixedAmount")}
                              fullWidth
                              type={"number"}
                              InputProps={{
                                value: selectedCourierType?.fixedAmount,
                                onChange: (e: any) => {
                                  setSelectedCourierType((prevState: any) => ({
                                    ...prevState,
                                    fixedAmount: e.target.value,
                                  }));
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        )}
                      {[CourierType.STABLE].includes(
                        selectedCourierType?.courierType
                      ) && (
                          <>
                            <Grid item xs={12} marginTop={1}>
                              <TextFieldElement
                                name={"reconciliationParams.minAmount"}
                                label={t("contractMinAmount")}
                                fullWidth
                                type={"number"}
                                InputProps={{
                                  value: selectedCourierType?.minAmount,
                                  onChange: (e: any) => {
                                    setSelectedCourierType((prevState: any) => ({
                                      ...prevState,
                                      minAmount: e.target.value,
                                    }));
                                  },
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} marginTop={1}>
                              <TextFieldElement
                                name={"reconciliationParams.minPackage"}
                                label={t("contractMinPackage")}
                                fullWidth
                                type={"number"}
                                InputProps={{
                                  value: selectedCourierType?.minPackage,
                                  onChange: (e: any) => {
                                    setSelectedCourierType((prevState: any) => ({
                                      ...prevState,
                                      minPackage: e.target.value,
                                    }));
                                  },
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </>
                        )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldElement
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    sx={{ mr: 2 }}
                    validation={{
                      required: t("required").toString(),
                      pattern: MULTINAMEPATTERN,
                    }}
                    name={"firstName"}
                    label={t("firstName")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldElement
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    validation={{
                      required: t("required").toString(),
                      pattern: NAMEPATTERN,
                    }}
                    name={"lastName"}
                    label={t("lastName")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldElement
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    disabled={!isNewCourier}
                    validation={{
                      required: t("required").toString(),
                      pattern: USERNAMEPATTERN,
                    }}
                    name={"username"}
                    label={t("username")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldElement
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    name={"email"}
                    label={t("email")}
                    fullWidth
                    validation={{
                      pattern: EMAILPATTERN,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldElement
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                      maxLength: 10,
                    }}
                    placeholder="5#########"
                    validation={{
                      required: t("required").toString(),
                      pattern: MOBILEPATTERN,
                    }}
                    name={"mobile"}
                    label={t("mobile")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldElement
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                      maxLength: 11,
                    }}
                    validation={{
                      required: t("required").toString(),
                      pattern: TCKNPATTERN,
                    }}
                    name={"tckn"}
                    label={t("tckn")}
                    fullWidth
                  />
                </Grid>
                {isNewCourier && (
                  <Grid item xs={12}>
                    <PasswordElement
                      onChange={(e) => setPassword(e.target.value)}
                      validation={{
                        required: t("required").toString(),
                        pattern: PASSWORDPATTERN,
                      }}
                      name={"password"}
                      label={t("password")}
                      sx={{ mr: 2 }}
                    />
                    <PortiaPasswordRepeatElement
                      name={"password_repeat"}
                      label={t("passwordRepeat")}
                      passwordField={password}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <AutocompleteElement
                    label={t("helmet")}
                    name="wearable.helmet"
                    matchId
                    textFieldProps={{
                      InputProps: {
                        startAdornment: <SportsMotorsportsSharpIcon />,
                      },
                    }}
                    options={wearableOptions}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutocompleteElement
                    label={t("shirt")}
                    name="wearable.shirt"
                    matchId
                    textFieldProps={{
                      InputProps: {
                        startAdornment: <StraightenIcon />,
                      },
                    }}
                    options={wearableOptions}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutocompleteElement
                    label={t("pant")}
                    name="wearable.pants"
                    matchId
                    textFieldProps={{
                      InputProps: {
                        startAdornment: <StraightenIcon />,
                      },
                    }}
                    options={wearableOptions}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutocompleteElement
                    label={t("jacket")}
                    name="wearable.jacket"
                    matchId
                    textFieldProps={{
                      InputProps: {
                        startAdornment: <StraightenIcon />,
                      },
                    }}
                    options={wearableOptions}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteElement
                    label={t("vehicleType")}
                    name="vehicle.type"
                    matchId
                    textFieldProps={{
                      InputProps: {
                        startAdornment: <GarageIcon />,
                      },
                    }}
                    options={vehicleTypeOptions}
                    autocompleteProps={{
                      disabled: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldElement
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                      style: { textTransform: "uppercase" },
                    }}
                    validation={{
                      pattern: LICENSEPLATEPATTERN,
                    }}
                    name={"vehicle.license"}
                    label={t("licensePlate")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldElement
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    name={"vehicle.mileage"}
                    label={t("mileage")}
                    fullWidth
                    type={"number"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{ mr: 2 }}
                    variant="contained"
                    type={"submit"}
                    color={"primary"}
                  >
                    {t("save")}
                  </Button>
                  <Button
                    variant="outlined"
                    color={"secondary"}
                    onClick={() => navigate(-1)}
                  >
                    {t("cancel")}
                  </Button>
                </Grid>
              </Grid>
            </FormContainer>
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Box sx={{ m: 2 }}>
            <Grid container>
              <Grid item xs={6} md={4} lg={2}>
                <LocalizationProvider locale={t("dateTimeLocale").toString()} dateAdapter={AdapterDayjs}>
                  <DatePickerMui
                    renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                    label={t('date')}
                    value={startDate}
                    inputFormat="DD-MM-YYYY"
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    disableFuture={true}
                    minDate={dayjs(addDays(new Date(), -2))}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} md={4} lg={2} marginLeft={2}>
                <LocalizationProvider locale={t('dateTimeLocale').toString()} dateAdapter={AdapterDayjs}>
                  <TimePicker
                    ampmInClock
                    openTo="hours"
                    views={['hours', 'minutes']}
                    inputFormat="HH:mm"
                    mask="__:__"
                    label={t('startTime')}
                    value={startTime}
                    ampm={false}
                    onChange={(newValue) => {
                      setStartTime(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} md={4} lg={2} marginLeft={2}>
                <LocalizationProvider locale={t('dateTimeLocale').toString()} dateAdapter={AdapterDayjs}>
                  <TimePicker
                    ampmInClock
                    openTo="hours"
                    views={['hours', 'minutes']}
                    inputFormat="HH:mm"
                    mask="__:__"
                    label={t('finishTime')}
                    value={finishTime}
                    ampm={false}
                    onChange={(newValue) => {
                      setFinishTime(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3} md={2} lg={1} marginLeft={2} marginTop={2}>
                <DateRangePicker
                  selectDays={selectDays}
                  handleOnChange={handleOnChangeDate}
                  isStaticRanges={true}
                  mounts={1}
                  maxDate={new Date()}
                  minDate={addDays(new Date(), -2)}
                />
              </Grid>
              <Grid mt={2} item xs={3} md={2} lg={1} marginBottom={2}>
                <Button
                  sx={{ mr: 1 }}
                  variant="contained"
                  style={{ float: "right" }}
                  onClick={() => getCourierLastLocation()}
                >
                  {t("filter")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} marginTop={2}>
              <LocationPicker
                position={ctr}
                title=""
                path={path}
                couriersPosition={courierPosition}
                isDisablePlate={true}
              />
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Box>
            <ShiftsList courierId={courierId} />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <Box>
            <CourierOrders
              courierId={courierId}
              orderType={ORDER_STATUS.ASSIGNED}
            />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <Box>
            <CourierOrders courierId={courierId} />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={5}>
          <Box>
            <CourierWorkingDays courierId={courierId} />
          </Box>
        </TabPanel>

        <TabPanel value={tab} index={6}>
          <Box>
            <ReconciliationList />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={7}>
          <Box>
            {/* <Earning courier={courier}/> */}
            <EarningList courierId={courierId} />
          </Box>
        </TabPanel>

        <Authz allowedRoles={["portia-dev"]}>
          <TabPanel value={tab} index={8}>
            <Box>
              <CourierActivityChart />
            </Box>
          </TabPanel>
        </Authz>
      </Box>
    </Box>
  );
};
