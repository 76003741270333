import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  AutocompleteElement,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import { Point } from "geojson";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import HubIcon from "@mui/icons-material/Hub";
import StorefrontIcon from "@mui/icons-material/Storefront";

import { Restaurant } from "../../api/types";
import {
  PatchRestaurant,
  PostRestaurant,
  GetRestaurant,
} from "../../api/restaurant";
import { SaveKmlFile, GetKmlFile, DeleteKMLFile } from "../../api/hubs";
import { usePortia } from "../../context";
import { useEffect, useRef, useState } from "react";
import { Hubs } from "../../api/hubs";
import { Brands } from "../../api/brands";
import { LocationPicker } from "../../components/PortiaMap";
import { Tab, Tabs } from "@mui/material";
import { OrdersList } from "../Orders";
import { useTranslation } from "react-i18next";
import { DistrictByProvince, Provinces } from "../../api/provinces";
import { Polygon } from "@react-google-maps/api";
import { kml } from "@tmcw/togeojson";

const DOMParser = require("xmldom").DOMParser;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const RestaurantDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(0);
  const params = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const {
    dropdownAlert,
    store: { setLoading },
  } = usePortia();
  const [restaurant, setRestaurant] = useState<Restaurant>(location?.state);
  const [position, setPosition] = useState<google.maps.LatLngLiteral>();
  const restaurantId: any = params && params["restaurantid"];
  const { data, isLoading } = Hubs();
  const { data: bdata } = Brands();
  const [kmlDataGeoJson, setKmlDataGeoJson] = useState<any>(null);
  const { data: kmlData, refetch: getKmlFile } = GetKmlFile(restaurantId || "", "restaurant");
  const { mutate: kmlDelete, data: kmlDeleteData, error: kmlDeleteError } = DeleteKMLFile(500);
  const inputFile = useRef<HTMLInputElement>(null);
  const [vknValue, setVKNValue] = useState<string>('');

  const {
    mutate: create,
    data: cResult,
    error: cError,
    isLoading: isPostLoading,
  } = PostRestaurant();
  const {
    mutate: save,
    data: pResult,
    error: pError,
    isLoading: isPatchLaoding,
  } = PatchRestaurant();
  const { refetch: getRestaurant, data: restaurantData } =
    GetRestaurant(restaurantId);

  const { data: kmlResponse, refetch: saveKmlFile } = SaveKmlFile(kmlDataGeoJson, restaurantData?.data.hubId || "", restaurantId, position);
  const isNewRestaurant = !!!location.state && restaurantId == "new";

  const openKmlFile = async () => {
    if (inputFile.current != null) {
      inputFile.current?.click();
    }
  };

  useEffect(() => {
    let msg = t("kmlSuccess");
    if (kmlResponse) {
      if (kmlResponse.data.status === "NOTCREATED") {
        msg = t("kmlFail");
        dropdownAlert.current.alertWithType({
          type: "error",
          title: "Error",
          message: msg
        });
      } else if (kmlResponse.data.status === "ERROR") {
        msg = t("kmlError");
        dropdownAlert.current.alertWithType({
          type: "error",
          title: "Error",
          message: msg
        });
      } else if (kmlResponse.data.status === "CREATED") {
        dropdownAlert.current.alertWithType({
          type: "success",
          title: "Success",
          message: msg
        });
      }
    }
  }, [kmlResponse]);
  console.log(kmlData?.data)


  const handleFileUpload = (e: any) => {
    const { files } = e.target;
    const reader = new FileReader();
    e.preventDefault();
    reader.readAsText(files[0], "utf-8");
    reader.onload = function () {
      if (reader.result != null) {
        const data = reader.result;
        const kmlData = new DOMParser().parseFromString(data);
        const converted = kml(kmlData);
        setKmlDataGeoJson(converted);
      }
      reader.onerror = function () {
        console.log(reader.error);
      };
    }
  };

  const getRegions = (data: any) => {
    if (data) {
      try {
        return data?.features.map((regionFeature: any) => {
          if (regionFeature.geometry.type == "Polygon") {
            let coordinates = regionFeature.geometry.coordinates[0];
            let coordArr: { lat: any; lng: any; }[] = [];
            coordinates.map((coordinate: any[]) => {
              return coordArr.push({
                lat: coordinate[1], lng: coordinate[0],
              });
            });
            const newColor = "#" + Math.floor(Math.random() * 1000);
            return (
              <Polygon
                path={coordArr}
                options={{
                  fillColor: newColor,
                  strokeColor: newColor,
                  strokeWeight: 2
                }}
                key={newColor}
              />
            )
          }
        });
      }
      catch (e) {
        dropdownAlert.current.alertWithType({
          type: "error",
          title: "Error",
          message: t("kmlError")
        });
      }
    } else {
      return undefined;
    }
  };

  const getCurrentPositionOrDefault = async () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) =>
        setPosition({
          lat: 41.10551877478612,
          lng: 29.02610099753676,
        })
    );
  };

  useEffect(() => {
    if (restaurantId && restaurantId !== "new") {
      getRestaurant();
      getKmlFile(restaurantId);
    } else {
      getCurrentPositionOrDefault();
    }
  }, [params, restaurantId]);

  useEffect(() => {
    let newResHubDetail = hubs.filter((obj: any) => {
      return obj.hubId === searchParams.get("hubId")?.toString();
    });

    setRestaurant(restaurantData?.data);
    if (newResHubDetail) {
      setSelectedProvinceId(newResHubDetail[0]?.provinceId);
      getDistrict();
      setRestaurant({
        ...restaurantData?.data,
        provinceId: newResHubDetail[0]?.provinceId,
        districtId: newResHubDetail[0]?.districtId,
      });
    }

    if (restaurantData?.data?.provinceId) {
      setSelectedProvinceId(restaurantData?.data?.provinceId);
      getDistrict();
    }
    const { data: r } = restaurantData || {};
    if (r) {
      const { location } = r;
      setPosition({
        lng: location?.coordinates[0],
        lat: location.coordinates[1],
      });
    }
  }, [restaurantData]);

  useEffect(() => {
    setLoading(isPostLoading || isPatchLaoding);
  }, [isPostLoading, isPatchLaoding]);

  useEffect(() => {
    if (cError || pError) {
      dropdownAlert.current.alertWithType({
        type: "error",
        title: "OK",
        message: cError
          ? t(cError?.response?.data.message)
          : t(pError?.response?.data.message),
      });
    }
  }, [cError, pError]);

  useEffect(() => {
    if (
      (pResult && (pResult.status === 200 || pResult.status === 201)) ||
      (cResult && (cResult.status === 200 || cResult.status === 201))
    ) {
      dropdownAlert.current.alertWithType({
        type: "success",
        title: "OK",
        message: t("saved"),
      });
      navigate(-1);
    }
  }, [pResult, cResult]);

  const hubs = (data && data.data.items) || [];
  const brands = (bdata && bdata.data) || [];

  const saveRestaurant = (c: Restaurant) => {
    const l = position && {
      type: "Point",
      coordinates: [position.lng || 0, position.lat || 0],
    };
    c.location = l as Point;
    if (isNewRestaurant) {
      create(c);
    } else {
      saveKmlFile();
      save(c);
    }
  };

  //Tab
  const tabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const [selectedProvinceId, setSelectedProvinceId] = useState<string>("");
  const { data: provincesData, isLoading: provincesIsLoading } = Provinces();
  const {
    data: districtsData,
    isLoading: districtsIsLoading,
    refetch: getDistrict,
  } = DistrictByProvince(selectedProvinceId);

  useEffect(() => {
    if (selectedProvinceId) getDistrict();
  }, [selectedProvinceId]);

  useEffect(()=> {
    if(kmlDeleteData || kmlDeleteError) {
     navigate(-1);
     getKmlFile(restaurantId);
    }
  },[kmlDeleteData,kmlDeleteError])

  const deleteKmlFile = async () => {
    kmlDelete({ restaurantId: restaurantId });
  }
  return (
    <Box sx={{ m: 2 }}>
      <Tabs value={tab} onChange={tabChange}>
        <Tab label={t("general")} />
        {!isNewRestaurant && <Tab label={t("tabOrders")} />}
      </Tabs>
      <Box sx={{ border: 1, borderColor: "#eee" }}>
        <TabPanel value={tab} index={0}>
          <Box sx={{ m: 2 }}>
            <FormContainer
              values={restaurant}
              defaultValues={{
                hubId: searchParams.get("hubId")?.toString(),
                provinceId: restaurant?.provinceId ? restaurant?.provinceId : restaurant?.hub?.provinceId,
                districtId: restaurant?.districtId ? restaurant?.districtId : restaurant?.hub?.districtId,
              }}
              onSuccess={(data) => saveRestaurant(data)}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <AutocompleteElement
                    label={t("hub")}
                    name="hubId"
                    matchId
                    required
                    loading={isLoading}
                    textFieldProps={{
                      inputProps: {
                        form: {
                          autocomplete: "off",
                        },
                      },
                      InputProps: {
                        startAdornment: <HubIcon />,
                      },
                    }}
                    options={hubs.map((h: any) => {
                      return {
                        id: h.hubId,
                        label: h.name,
                        provinceId: h.provinceId,
                        districtId: h.districtId,
                      };
                    })}
                    rules={{
                      required: t("hubRequiredMessage").toString(),
                    }}
                    autocompleteProps={{
                      onChange: (event, value) => {
                        if (value?.provinceId) {
                          setRestaurant({
                            ...restaurant,
                            provinceId: value?.provinceId,
                            districtId: value?.districtId,
                          });
                          setSelectedProvinceId(value?.provinceId);
                        } else {
                          setRestaurant({
                            ...restaurant,
                            provinceId: "",
                            districtId: "",
                          });
                        }
                      },
                      disabled: searchParams.get("hubId") ? true : false,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteElement
                    label={t("brand")}
                    name="brandId"
                    matchId
                    required
                    loading={isLoading}
                    textFieldProps={{
                      InputProps: {
                        startAdornment: <StorefrontIcon />,
                      },
                    }}
                    options={brands.map((h: any) => {
                      return { id: h.brandId, label: h.name };
                    })}
                    rules={{
                      required: t("brandRequiredMessage").toString(),
                    }}
                  />
                </Grid>

                {provincesData?.data?.items && (
                  <Grid item xs={6}>
                    <AutocompleteElement
                      label={t("province")}
                      name="provinceId"
                      matchId
                      required
                      loading={provincesIsLoading}
                      textFieldProps={{
                        inputProps: {
                          form: {
                            autocomplete: "off",
                          },
                        },
                      }}
                      options={provincesData?.data?.items.map((h: any) => {
                        return {
                          id: h.provinceId,
                          label: h.name,
                        };
                      })}
                      rules={{ required: t("hubRequiredMessage").toString() }}
                      autocompleteProps={{
                        onChange: (event, value) => {
                          setSelectedProvinceId(value?.id);
                        },
                      }}
                    />
                  </Grid>
                )}

                {districtsData?.data?.items && (
                  <Grid item xs={6}>
                    <AutocompleteElement
                      label={t("district")}
                      name="districtId"
                      matchId
                      required
                      loading={provincesIsLoading}
                      textFieldProps={{
                        inputProps: {
                          form: {
                            autocomplete: "off",
                          },
                        },
                      }}
                      options={districtsData?.data?.items.map((h: any) => {
                        return {
                          id: h.districtId,
                          label: h.name,
                        };
                      })}
                      rules={{ required: t("hubRequiredMessage").toString() }}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <TextFieldElement
                    sx={{ mr: 2 }}
                    validation={{ required: t("required").toString() }}
                    name={"name"}
                    label={t("firstName")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldElement
                    sx={{ mr: 2 }}
                    validation={{ required: t("required").toString() }}
                    name={"refRestaurantId"}
                    label={t("restaurantRef")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldElement
                    sx={{ mr: 2 }}
                    validation={{
                      required: t("required").toString(),
                      pattern: { value: /^\d{10}$/i, message: t("vknValidationMessage").toString() }
                    }}
                    name={"vknNo"}
                    label={t("vknNumber")}
                    required
                    fullWidth
                    value={vknValue}
                    onChange={(e) => setVKNValue(e.target.value)}
                  />
                </Grid>

                {!isNewRestaurant ?
                  (<Grid xs={12} style={{ paddingLeft: "18px", paddingTop: "10px" }}>
                    <Button
                      sx={{ height: 25 }}
                      variant="contained"
                      onClick={() => openKmlFile()}
                      color={"primary"}
                    >
                      {t("fileImport")}
                    </Button>
                    {
                      kmlData?.data?.length > 0 && kmlData?.data[0]?.id && <Button
                        sx={{ height: 25, marginLeft: 5 }}
                        variant="contained"
                        onClick={() => deleteKmlFile()}
                        color={"primary"}
                      >
                        {t("kmlDelete")}
                      </Button>
                    }
                  </Grid>) : undefined
                }
                <Grid item xs={12}>
                  {position && (
                    <LocationPicker
                      position={position}
                      title=""
                      onSave={(p) => {
                        setPosition(p);
                      }}
                      children={(kmlData ? getRegions(kmlData.data[0]) : undefined)}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{ mr: 2 }}
                    variant="contained"
                    type={"submit"}
                    color={"primary"}
                  >
                    {t("save")}
                  </Button>
                  <Button
                    variant="outlined"
                    color={"secondary"}
                    onClick={() => navigate(-1)}
                  >
                    {t("cancel")}
                  </Button>
                </Grid>
              </Grid>
              <input
                style={{ display: "none" }}
                accept=".kml"
                ref={inputFile}
                onChange={(e) => handleFileUpload(e)}
                type="file"
              />
            </FormContainer>
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Box sx={{ m: 2 }}>
            <OrdersList restaurantId={restaurantId} />
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};
